import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { spacing } from '@porsche-design-system/utilities'

const MARGIN_SIZE_IN_PX = 42
const StyledSpacingDiv = styled.div`
  &.desktop {
    margin-left: ${MARGIN_SIZE_IN_PX}px;
    margin-right: ${MARGIN_SIZE_IN_PX}px;
  }
`
export const AppendixSpacing: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { largerThan: isDesktop } = useBreakpoint('m')
  const PaddedDiv = styled.div`
    padding-left: ${isDesktop ? spacing[24] : 0};
    padding-right: ${isDesktop ? spacing[24] : 0};
    margin-right: ${spacing[24]};
    margin-left: ${spacing[24]};
  `

  return (
    <PaddedDiv>
      <StyledSpacingDiv>
        <div className={isDesktop ? 'desktop' : ''}>{children}</div>
      </StyledSpacingDiv>
    </PaddedDiv>
  )
}
