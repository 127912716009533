import { config } from '../../config'

export function getDealerContactStartHour() {
  return config().isMarket('us') ? getUSDealerContactStartHour() : getCADealerContactStartHour()
}

function getUSDealerContactStartHour() {
  return '9:00am'
}

function getCADealerContactStartHour() {
  return config().locale === 'en-CA' ? '9:00am' : '9h00'
}

export function getDealerContactEndHour(dealerName: string | undefined) {
  return config().isMarket('us') ? getUSDealerContactEndHour(dealerName) : getCADealerContactEndHour()
}

function getUSDealerContactEndHour(dealerName: string | undefined) {
  if (dealerName === 'Porsche Woodland Hills') {
    return '7:00pm'
  }

  return '5:00pm'
}

function getCADealerContactEndHour() {
  return config().locale === 'en-CA' ? '5:00pm' : '17h00'
}
