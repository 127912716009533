import React, { useContext } from 'react'
import { PresenterContext } from '../../../entry/presenterProvider'
import { Flow, getFlow, isLeaseOrFinance, isNewVehicle } from '../../../../common/checkout'
import { FormattedMessage } from 'react-intl'
import { PText } from '@porsche-design-system/components-react'
import { getAsterisksForOldCarWithCashOrOwnFinancing, getLeaseFinanceAsterisks, getMsrpAsterisks } from './util'
import { DoubleBreak } from '../../../../common/IntlElements'

export const ReviewModalDisclaimers: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const newCarWarrantyDisclaimer = opportunity.vehicle.payload?.warranty?.modal?.newCarWarrantyDisclaimer
  const hasWarrantyDisclaimer = !!newCarWarrantyDisclaimer
  const isNew = isNewVehicle(opportunity)
  const isOld = !isNew
  const isLeaseFinance = isLeaseOrFinance(opportunity)
  const isCashOrOwnFinancing = [Flow.CASH, Flow.OWN_FINANCING].includes(getFlow(opportunity))

  return (
    <>
      {newCarWarrantyDisclaimer && (
        <PText className='ada-text-width small-space-bottom'>*{newCarWarrantyDisclaimer}</PText>
      )}
      {isLeaseFinance && (
        <div className='ada-text-width small-space-bottom'>
          {getLeaseFinanceAsterisks(isLeaseFinance, hasWarrantyDisclaimer)}
          <FormattedMessage id='vehicleSelection.legal1' /> <FormattedMessage id='common.pfsDisclaimer' />
        </div>
      )}
      {isNew && (
        <div className='ada-text-width small-space-bottom'>
          {getMsrpAsterisks(isNew, isLeaseFinance)}
          <FormattedMessage id='confirmation.msrpDisclaimer' />
        </div>
      )}
      {isOld && isCashOrOwnFinancing && (
        <div className='ada-text-width small-space-bottom'>
          {getAsterisksForOldCarWithCashOrOwnFinancing(isOld, isCashOrOwnFinancing, hasWarrantyDisclaimer)}
          <FormattedMessage id='vehicleSelection.legal1' /> <FormattedMessage id='vehicleSelection.legal2' />
        </div>
      )}
      <PText weight='bold'>
        <FormattedMessage id='review.termsAndConditions' />
      </PText>
      <div className='ada-text-width'>
        <FormattedMessage id='review.termsAndConditions.disclaimer' values={{ break: DoubleBreak }} />
      </div>
    </>
  )
}
