import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react'
import * as FullStory from '@fullstory/browser'
import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import App from './App'
import buildInfo from './build-info.json'
import { config } from './config'

const environment = config().environment

// @ts-ignore
window.__build_info = buildInfo

// Matomo Config
const instance = createInstance({
  urlBase: 'https://porsche.matomo.cloud/',
  siteId: environment === 'production' ? 2 : 1,
  linkTracking: false // Outbound link tracking on SPA is flaky: we'll use enableLinkTracking method instead.
})

// FullStory Config
if (environment === 'production') {
  FullStory.init({ orgId: config().fullStoryID, devMode: environment !== 'production' }, ({ sessionUrl }) =>
    console.log(`Started session: ${sessionUrl}`)
  )
}

// Global Privacy Control, see https://jira.porsche.codes/browse/PFC-1100
if (config().isMarket('us')) {
  const script = document.createElement('script')
  script.src = 'https://www.porsche.com/redesign-scripts/vendor/udg-uc-sdk.min.js'
  document.head.appendChild(script)
}

const render = () => {
  const script = document.createElement('script')
  script.defer = true
  script.src =
    config().paymentStep.environment === 'production'
      ? 'https://pay.porsche.com/widget/v3/widget-3.2.js'
      : 'https://pay.porsche-preview.com/widget/v3/widget-3.2.js'

  document.head.appendChild(script)

  const container = document.getElementById('root')
  const root = createRoot(container!) // createRoot(container!) if you use TypeScript

  try {
    root.render(
      // @ts-ignore
      <MatomoProvider value={instance}>
        <React.StrictMode>
          <PorscheDesignSystemProvider prefix='checkout-app'>
            <App />
          </PorscheDesignSystemProvider>
        </React.StrictMode>
      </MatomoProvider>
    )
  } catch (e) {
    console.log(e)
  }
}

render()
