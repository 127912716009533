import React, { ReactNode } from 'react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { PFlex, PFlexItem } from '@porsche-design-system/components-react'
import styled from 'styled-components'
import { spacing } from '@porsche-design-system/utilities'

interface ICartItemLayoutDesktopProps {
  image: ReactNode
  heading: ReactNode
  subHeading?: ReactNode
  priceTag: ReactNode
  ownFinancing?: ReactNode
  descriptionItems: ReactNode[]
  buttons: ReactNode[]
}

const Spacing = styled.div<{ marginRight?: string; marginLeft?: string; marginTop?: string; children?: ReactNode }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
`

export const CartItemLayout: React.FC<ICartItemLayoutDesktopProps> = ({
  image,
  heading,
  subHeading,
  priceTag,
  ownFinancing,
  descriptionItems,
  buttons
}) => {
  const { largerThan: isDesktop } = useBreakpoint('m')

  return (
    <PFlex direction={isDesktop ? 'row' : 'column'}>
      <PFlexItem width={isDesktop ? 'one-quarter' : 'full'}>
        <Spacing>{image}</Spacing>
      </PFlexItem>

      <Spacing marginTop={isDesktop ? undefined : spacing[12]} marginRight={isDesktop ? spacing[24] : undefined} />

      <PFlexItem width={isDesktop ? 'two-thirds' : 'full'}>
        <PFlex direction='column'>
          <PFlex direction={isDesktop ? 'row' : 'column'}>
            <PFlexItem shrink={0}>
              <PFlex direction='column'>
                {heading}
                {subHeading}
              </PFlex>
            </PFlexItem>
            <PFlexItem grow={1}>{priceTag}</PFlexItem>
          </PFlex>

          {ownFinancing}

          <Spacing marginTop={spacing[12]} />

          {descriptionItems.map((item, index) => (
            <PFlexItem width='full' className='small-space-bottom' key={index}>
              {item}
            </PFlexItem>
          ))}
          <PFlexItem>
            <Spacing marginTop={spacing[4]}>
              <PFlex direction='row'>
                {buttons.map((btn: ReactNode, index: number) => (
                  <Spacing key={index} marginRight={spacing[32]}>
                    {btn}
                  </Spacing>
                ))}
              </PFlex>
            </Spacing>
          </PFlexItem>
        </PFlex>
      </PFlexItem>
    </PFlex>
  )
}
