import { trackClickEvent } from '@slatldisal/one-ga'
import { IFinanceOpportunity, isFinanceOpportunity } from '../../common/checkout'
import { config } from '../../config'
import { matomoPcnaTrackClickEvent } from '../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import AoacService from '../../services/aoacService'
import OpportunityService from '../../services/opportunityService'
import { defaultErrorHandler } from '../../util/defaultErrorHandler'
import { APPLICATION_COMPLETE_FIELD } from '../entry/presenterProvider'
import { ISubmitParams } from './checkout1/ReviewStackElement'

export const submitHandler = async ({ presenter, navigate, trackEvent }: ISubmitParams) => {
  presenter.submitIsInProgress = true

  const opportunity = presenter.opportunityStore.opportunityCache
  const user = presenter.user

  try {
    const confirmationPagePaths = getConfirmationPagePaths(opportunity.id, false)

    presenter.setOpportunityOrigin(confirmationPagePaths.absolute)
    presenter.updateOrderInformation()

    matomoPcnaTrackClickEvent(trackEvent, 'oo-submit-dealer')
    trackClickEvent(AnalyticsEventTemplates.REVIEW_STEP_COMPLETE(opportunity))

    const finalizedOpportunityId = await new OpportunityService(defaultErrorHandler)
      .complete(opportunity, user ?? undefined)
      .then(async (r) => r.text())

    window.scrollTo(0, 0)
    navigate(confirmationPagePaths.relative.replace(opportunity.id, finalizedOpportunityId))
  } catch (e: any) {
    defaultErrorHandler(e)
  }
}

export const applyHandler = async ({ presenter, navigate, trackEvent }: ISubmitParams) => {
  const opportunity = presenter.opportunityStore.opportunityCache
  const user = presenter.user

  matomoPcnaTrackClickEvent(trackEvent, 'maff-apply-financing')

  // Enhance object with financial data if absent
  trackClickEvent(AnalyticsEventTemplates.REVIEW_STEP_AOAC)

  if (isFinanceOpportunity(opportunity)) {
    opportunity.financialProduct.payload.sentToAoac = true
  }

  const confirmationPagePaths = getConfirmationPagePaths(opportunity.id, true)
  presenter.setOpportunityOrigin(confirmationPagePaths.absolute)
  presenter.updateOrderInformation()

  opportunity.id = await new OpportunityService(defaultErrorHandler)
    .updateOrSwitchOnPaymentSkip(opportunity, user ?? undefined)
    .then(async (r) => r.text())

  if (window.location.search.includes('skipAoac')) {
    navigate(
      `/${config().market}/${presenter.locale}/checkout/${
        opportunity.id
      }/confirmation?${APPLICATION_COMPLETE_FIELD}=true`
    )
    return
  }

  const aoacService = new AoacService(defaultErrorHandler)
  const aoacResult = await aoacService.bootstrap(opportunity as IFinanceOpportunity)

  aoacService.proceedToAOAC(aoacResult)
}

function getConfirmationPagePaths(opportunityId: string, aoacFlow: boolean): { relative: string; absolute: string } {
  let confirmationPath = `/${config().market}/${config().locale}/checkout/${opportunityId}/confirmation`

  if (aoacFlow) {
    confirmationPath += `?${APPLICATION_COMPLETE_FIELD}=true`
  }

  return { relative: confirmationPath, absolute: new URL(confirmationPath, location.href).toString() }
}
