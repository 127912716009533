import { DeliveryOptionsView, IAncillaryPlanOption } from '@pdiatl/common-external-models'
import { Flow } from '../common/checkout'
import { AnalyticsPage } from '../onega/AnalyticsEventTemplates'

export enum MatomoDeliveryOptionSelection {
  HOME = 'do-home',
  DEALERSHIP = 'do-dealership',
  SKIP = 'do-skip'
}

export function matomoPcnaTrackPageView(
  pushInstruction: (eventName: string, actionName: string) => void,
  actionName: string
): void {
  pushInstruction('trackPageView', actionName)
}

export function matomoPcnaTrackClickEvent(
  trackEvent: (params: { category: string; action: string }) => void,
  action: string
): void {
  trackEvent({ category: 'checkout', action })
}

export function matomoPaymentOptionsActionMapper(flow: Flow): string {
  switch (flow) {
    case Flow.LEASE:
      return 'po-lease'
    case Flow.FINANCE:
      return 'po-finance'
    case Flow.CASH:
      return 'po-cash'
    case Flow.OWN_FINANCING:
      return 'po-bmof'
  }
}

export function matomoTradeInActionMapper(selection: 'manual' | 'kbb' | 'none'): string {
  switch (selection) {
    case 'manual':
      return 'ti-manually'
    case 'kbb':
      return 'ti-kelleybb'
    case 'none':
      return 'ti-decline'
  }
}

export function getMatomoAncillaryProductsSelection(plans: IAncillaryPlanOption[]): string {
  const selectedPlans = plans.filter((plan) => plan.selection)

  if (!selectedPlans.length) {
    // Should never reach this because the 'Save and Continue' button will be disabled, but adding as a precaution
    throw new Error('No selected ancillary plans to add to Matomo!')
  }

  return `pp-${selectedPlans.map((plan) => plan.code).join('-')}`
}

export function getMatomoDeliveryOptionSelection(
  selectedOption: DeliveryOptionsView['deliveryOption']
): MatomoDeliveryOptionSelection {
  return selectedOption === 'HOME' ? MatomoDeliveryOptionSelection.HOME : MatomoDeliveryOptionSelection.DEALERSHIP
}

export function getDynamicMatomoPageViewActionName(actionName: string, page: AnalyticsPage): string {
  const actionNameWithUnderscores = actionName.replace(/-/g, '_')
  const pageSuffix = page.split('/')[1]

  return pageSuffix ? `${actionNameWithUnderscores}_${pageSuffix}` : `${actionNameWithUnderscores}_${page}`
}

export function getDynamicMatomoClickEventAction(action: string, page: AnalyticsPage): string {
  const actionWithUnderscores = getDynamicMatomoPageViewActionName(action, page)

  return actionWithUnderscores.replace(/_/g, '-')
}
