import React, { useContext } from 'react'
import { PresenterContext } from '../../entry/presenterProvider'
import { PGrid, PGridItem, PHeadline, PText } from '@porsche-design-system/components-react'

import { DownPayment } from '../shared/ui'

import { IFinanceOpportunity, isNewVehicle } from '../../../common/checkout'
import { FormattedMessage, FormattedNumber } from 'react-intl'

export const Finance: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache as IFinanceOpportunity

  return (
    <PGridItem size={{ base: 12, m: 7 }}>
      <PGrid>
        <PGridItem size={8}>
          <PHeadline
            variant='headline-4'
            tag='h3'
            className='small-space-bottom'
            data-testid='confirmationEstimatedMonthlyPaymentTitle'
          >
            <FormattedMessage id='common.monthlyPayment' />
            **
          </PHeadline>
        </PGridItem>
        <PGridItem size={4}>
          <PHeadline variant='headline-4' align='right' tag='h3' data-testid='confirmationEstimatedMonthlyPaymentValue'>
            <FormattedNumber
              value={opportunity.financialProduct.payload?.monthlyFinancePayment ?? 0}
              style='currency'
              currency={opportunity.product?.price?.currencyCode}
              currencyDisplay='narrowSymbol'
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              data-testid='confirmationEstimatedMonthlyPaymentValue'
            />
            /mo
          </PHeadline>
        </PGridItem>

        <PGridItem size={8}>
          <PText data-testid='confirmationAprTitle'>
            <FormattedMessage id='common.apr' />
          </PText>
        </PGridItem>
        <PGridItem size={4}>
          <PText align='right' data-testid='confirmationAprValue'>
            {opportunity.financialProduct.payload?.annualPercentageRate}%
          </PText>
        </PGridItem>

        <PGridItem size={8}>
          <PText data-testid='confirmationTermTitle'>
            <FormattedMessage id='common.term' />
          </PText>
        </PGridItem>
        <PGridItem size={4}>
          <PText align='right' data-testid='confirmationTermValue'>
            <FormattedMessage
              id='confirmation.finance.termMonths'
              values={{ term: opportunity.financialProduct.payload?.term }}
            />
          </PText>
        </PGridItem>

        <DownPayment opportunity={opportunity} />
        <PGridItem size={8}>
          <PText data-testid='confirmationAmountFinanceTitle'>
            <FormattedMessage id='common.financed' />
          </PText>
        </PGridItem>
        <PGridItem size={4}>
          <PText align='right' data-testid='confirmationAmountFinanceValue'>
            <FormattedNumber
              value={
                opportunity.financialProduct.payload?.totalPrice - opportunity.financialProduct.payload?.downPayment ??
                0
              }
              style='currency'
              currency={opportunity.product?.price?.currencyCode}
              currencyDisplay='narrowSymbol'
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              data-testid='confirmationAmountFinanceValue'
            />
          </PText>
        </PGridItem>
        <PGridItem size={8}>
          <PText data-testid='confirmationPriceTitle'>
            <FormattedMessage id={isNewVehicle(opportunity) ? 'common.msrp.price' : 'common.price'} />
          </PText>
        </PGridItem>
        <PGridItem size={4}>
          <PText align='right' data-testid='confirmationPriceValue'>
            <FormattedNumber
              value={opportunity.financialProduct.payload?.totalPrice ?? 0}
              style='currency'
              currency={opportunity.product?.price?.currencyCode}
              currencyDisplay='narrowSymbol'
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              data-testid='confirmationPriceValue'
            />
          </PText>
        </PGridItem>
      </PGrid>
    </PGridItem>
  )
}
