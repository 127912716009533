import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { IAncillaryPlanOption } from '@pdiatl/common-external-models'

import {
  carAnalytics,
  setAdditionalProducts,
  setCarPayload,
  setComponentForm,
  setFinancialPayload,
  setPartner,
  setProcess,
  setServiceProduct,
  setVehiclePrice
} from '@slatldisal/one-ga'
import { PSpinner } from '@porsche-design-system/components-react'
import { User } from 'oidc-client'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ICheckoutOpportunity } from '../../common/checkout'
import { useFlags } from '../../common/flags'
import { config } from '../../config'
import { buildFinanceDataOneGA } from '../../onega/AnalyticsEventTemplates'
import { CheckoutApp } from '../../presenter/checkoutApp'
import { OpportunityStore } from '../../services/opportunityStore'
import { SessionStorageService } from '../../services/sessionStorageService'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { IDeliveryOptionsValues } from '../deliveryOption/DeliveryOptions'
import { handleLoginAfterSignUp } from '../loginRegister/loginPrompt'
import { IProfileCardDetails } from '../profile/profileCardGuest'
import { mapGuestProfileValuesToCustomerProfile } from '../profile/profileCardGuestState'
import { ITradeInValues, mapTradeInStateToVehicleView } from '../tradeIn/tradeinState'
import { mapVehicleSelectionValuesToFinancialProduct } from '../vehicleSelection/cash/cashVehicleSelectionState'
import { VehicleSelectionValues } from '../vehicleSelection/pcna/VehicleSelectionPcna'
import { vehicleSelectionStore } from '../vehicleSelection/pcna/vehicleSelectionStore'
import './presenterProvider.css'

export const PresenterContext = React.createContext(undefined) as any as React.Context<CheckoutApp>

export const Loader = () => (
  <section className='loader'>
    <PSpinner size={{ base: 'medium', l: 'large' }} />
  </section>
)

// FIXME: browser specific stuff
export const APPLICATION_COMPLETE_FIELD = 'applicationComplete'
export const isApplicationComplete = () => !!new URLSearchParams(window.location.search).get(APPLICATION_COMPLETE_FIELD)
export const SECURE_3DS_FIELD = 'status'
export const is3DSecuredURL = () => !!new URLSearchParams(window.location.search).get(SECURE_3DS_FIELD)

const initOpportunityAnalytics = (opportunity: ICheckoutOpportunity, depositJourney: boolean) => {
  // TODO should there be a null check here
  const { vehicle, financialProduct } = opportunity ?? {}
  const { dealerId, listingId } = vehicle.payload ?? {}

  const defaults = {
    productCategory: 'financing',
    mileageUnit: 'miles'
  }

  setPartner({ companyId: dealerId })

  setCarPayload(carAnalytics(vehicle, listingId))
  // lease or finance
  if (financialProduct?.payload?.creditType) {
    const {
      downPayment,
      annualMileage,
      creditType,
      monthlyLeasePayment,
      monthlyFinancePayment,
      term,
      annualPercentageRate,
      creditScore
    } = financialProduct.payload

    const isLease = creditType === 'LSE'

    const paymentMonthly = isLease ? monthlyLeasePayment : monthlyFinancePayment

    setFinancialPayload({
      ...defaults,
      productName: isLease ? 'lease' : 'finance',

      financingDuration: term,
      downpaymentValue: downPayment,
      mileageNumber: isNaN(annualMileage) ? undefined : 1000 * annualMileage,
      interestEffective: annualPercentageRate,
      paymentMonthly
    })

    const form = {
      formFields1: [
        {
          //
          fieldId: '01',
          fieldName: 'fico score',
          fieldValue: creditScore
        }
      ],

      formFields2: [
        {
          fieldId: '01',
          fieldName: 'offer type',
          fieldValue: 'default'
        }
      ]
    }
    setComponentForm(form)
  } else if (vehicleSelectionStore.calculationResult) {
    setFinancialPayload(buildFinanceDataOneGA(vehicleSelectionStore.calculationResult))
  } else {
    // cash
    setFinancialPayload({ productName: 'cash', productCategory: 'cash' })
  }

  setVehiclePrice(opportunity.product?.price.value ?? 0)

  const additionalProductsInStorage = SessionStorageService.getPrefixedItem(
    CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES
  )

  if (opportunity.product?.payload?.ancillaryProducts) {
    setAdditionalProducts(opportunity.product.payload.ancillaryProducts)
  } else if (additionalProductsInStorage) {
    setAdditionalProducts(
      JSON.parse(additionalProductsInStorage).filter((product: IAncillaryPlanOption) => product.selection)
    )
  }

  setProcess({
    processName: depositJourney ? 'checkout_reservation' : 'checkout',
    processVersion: 'v.01',
    processType: 'checkout'
  })

  if (depositJourney) {
    setServiceProduct({
      name: 'reservation_fee',
      price: opportunity?.orderInformation?.downPayment?.value!
    })
  }
}

const loadOpportunityStorageData = (
  opportunityStore: OpportunityStore,
  opportunity: ICheckoutOpportunity,
  enablePcnaPaymentStep: boolean,
  presenter: CheckoutApp
) => {
  const tradeInValues = SessionStorageService.getAndParsePrefixedItem<ITradeInValues>(
    CheckoutStorageKeys.TRADE_IN_VALUES
  )
  const vehicleSelectionValues = SessionStorageService.getAndParsePrefixedItem<VehicleSelectionValues>(
    CheckoutStorageKeys.VEHICLE_SELECTION_VALUES
  )
  const guestProfileValues = SessionStorageService.getAndParsePrefixedItem<IProfileCardDetails>(
    CheckoutStorageKeys.GUEST_PROFILE_VALUES
  )

  const deliveryOptionsValues = SessionStorageService.getAndParsePrefixedItem<IDeliveryOptionsValues>(
    CheckoutStorageKeys.DELIVERY_OPTIONS_VALUES
  )

  const ancillaryProductsValues = SessionStorageService.getAndParsePrefixedItem<IAncillaryPlanOption[]>(
    CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES
  )

  opportunityStore.setAncillaryProducts(ancillaryProductsValues)

  if (tradeInValues) {
    const tradeIn = mapTradeInStateToVehicleView(opportunity, tradeInValues)
    opportunityStore.setTradeInVehicle(tradeIn)
  }

  if (vehicleSelectionValues) {
    const financialProduct = mapVehicleSelectionValuesToFinancialProduct(vehicleSelectionValues)
    opportunityStore.setFinancialProduct(financialProduct)
  }

  if (guestProfileValues) {
    const guestCustomerProfile = mapGuestProfileValuesToCustomerProfile(guestProfileValues)
    opportunityStore.setCustomerProfile({ ...guestCustomerProfile, payload: opportunity?.customerProfile?.payload })
  }

  if (deliveryOptionsValues?.selection === 'HOME') {
    const oppo = presenter.opportunityStore.opportunityCache
    presenter.updateOrderInformationForHomeDelivery(oppo, enablePcnaPaymentStep)
  }
}

export const PresenterProvider: React.FC<
  PropsWithChildren<{ leadId: string; oidcUser?: User; locale?: string; refresh?: boolean }>
> = (props) => {
  const [presenter, setPresenter] = React.useState<CheckoutApp | undefined>(undefined)
  const { oidcUser = props.oidcUser, login, userManager, isLoading } = useContext(AuthenticationContext)
  const { market } = config()
  const { enableTradeInStepPcna, enableTradeInStepPcl, enablePcnaPaymentStep } = useFlags()
  const useTradeIn: boolean | undefined = market === 'ca' ? enableTradeInStepPcl : enableTradeInStepPcna
  const navigate = useNavigate()

  useEffect(() => {
    if (useTradeIn !== undefined) {
      const app = new CheckoutApp(props.leadId, oidcUser, undefined, useTradeIn)

      if (props.locale) {
        app.setLocale(props.locale)
      }

      app.setStorageProvider(localStorage)

      void app.loadOpportunity(undefined, props.refresh).then((opportunity) => {
        if (opportunity && opportunity.marketPlace?.toLowerCase() === market) {
          initOpportunityAnalytics(opportunity, app.isDepositJourney(enablePcnaPaymentStep))
          loadOpportunityStorageData(app.opportunityStore, opportunity, enablePcnaPaymentStep, app)

          setPresenter(app)
        } else {
          navigate(`/${market}/${app.locale}/404`)
        }
      })
    }
  }, [enablePcnaPaymentStep, market, navigate, oidcUser, props.leadId, props.locale, props.refresh, useTradeIn])

  presenter?.setAuth(oidcUser ?? null, login, userManager, isLoading)

  const opportunityCache = presenter?.opportunityStore.opportunityCache
  const spinner = (
    <section className='loader'>
      <PSpinner size={{ base: 'medium', l: 'large' }} data-testid='checkout-loading-spinner' />
    </section>
  )

  if (presenter && opportunityCache) {
    handleLoginAfterSignUp(presenter, oidcUser, isLoading)

    return isLoading ? (
      spinner
    ) : (
      <PresenterContext.Provider value={presenter}>{props.children}</PresenterContext.Provider>
    )
  } else {
    return spinner
  }
}
