import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { PFlex, PFlexItem, PHeadline } from '@porsche-design-system/components-react'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { PresenterContext } from '../entry/presenterProvider'
import { IAnalyticsMap, PageLoadAnalytics, scrollTo, StackElementState } from './stackElement'
import { StepCircle } from './StepCircle'

export const ExpandedStep: React.FC<
  PropsWithChildren<{
    index: number
    analyticsMap?: IAnalyticsMap
  }>
> = ({ index, analyticsMap, children }) => {
  const { smallerThan: isMobile } = useBreakpoint('m')
  const { smallerThan: isSmallMobile } = useBreakpoint('s')
  const presenter = useContext(PresenterContext)
  const stepTitle = presenter.stackManager.getCurrentStep().title

  useEffect(() => scrollTo(index), [index])

  return (
    <PageLoadAnalytics state={StackElementState.EDIT} analyticsMap={analyticsMap}>
      <div className='card big-space-top' id={`wizard-step-${index + 1}`}>
        {isMobile && <StepCircle state={StackElementState.EDIT} index={index} top />}
        <PFlex
          direction={{ base: 'column', m: 'row' }}
          className='medium-space-left medium-space-right'
          style={{ marginRight: isSmallMobile ? '0 32px 0 32px' : '' }}
        >
          <PFlexItem grow={0} flex='initial'>
            {!isMobile && <StepCircle state={StackElementState.EDIT} index={index} />}
          </PFlexItem>
          <PFlexItem grow={1} style={{ position: 'relative' }}>
            <PHeadline tag='h2' variant='headline-3' className='medium-space-top'>
              {stepTitle}
            </PHeadline>
            {children}
          </PFlexItem>
        </PFlex>
      </div>
    </PageLoadAnalytics>
  )
}
