import React, { useContext } from 'react'
import { PButton, PDivider, PGrid, PGridItem, PHeadline, PText } from '@porsche-design-system/components-react'
import { IVehicleSelectionProps, VehicleSelectionViewMode } from '../VehicleSelection'
import { VehicleSelectionHeader } from '../vehicleSelectionHeader/VehicleSelectionHeader'
import { OpportunityVehicleSelectionImage } from '../vehicleSelectionImage/VehicleSelectionImage'
import { DealerInformation } from '../../dealerInformation/dealerInformation'
import { SSLInfo } from '../sslInfo'

import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { formatLocalizedCurrencyNoFraction, formatLocalizedNoFraction } from '../../../helpers/format'
import { FormattedMessage, useIntl } from 'react-intl'
import { CalculationResult } from '../pcna/leaseFinance/defiCalcFinanceData'
import { isNewVehicle } from '../../../common/checkout'
import { LeaseFinanceVehicleSelectionLegal } from '../vehicleSelectionLegal/VehicleSelectionLegal'
import { PresenterContext } from '../../entry/presenterProvider'
import { trackClickEvent } from '@slatldisal/one-ga'

export interface ILeaseFinanceProps extends IVehicleSelectionProps {
  type: 'lease' | 'finance'
  financeData: CalculationResult
  hideVehicleImageOnMobile?: boolean
}

export function GetEstimatedMonthlyPayment(
  viewMode: VehicleSelectionViewMode,
  financialProduct: { [key: string]: any },
  type: 'lease' | 'finance',
  currency: string,
  mobileVehicleSummary?: boolean
) {
  const intl = useIntl()
  const value = (type === 'lease' ? financialProduct.monthlyLeasePayment : financialProduct.monthlyFinancePayment) || 0
  const cellStyle = viewMode === 'one-third-tile' ? { marginTop: '10px' } : {}

  return mobileVehicleSummary ? (
    <div>
      <FormattedMessage id={`common.estimatedMonthlyPayment.${type}`} />:{' '}
      {formatLocalizedCurrencyNoFraction(intl, currency, value)}
      <FormattedMessage id='common.perMonth' />
    </div>
  ) : (
    <>
      <PGridItem size={8} style={cellStyle}>
        <PHeadline variant='headline-4' tag='h3'>
          <FormattedMessage id={`common.estimatedMonthlyPayment.${type}`} />
        </PHeadline>
      </PGridItem>
      <PGridItem size={4} style={cellStyle}>
        <PHeadline variant='headline-4' align='right'>
          {formatLocalizedCurrencyNoFraction(intl, currency, value)}
          <FormattedMessage id='common.perMonthAbbrev' />
        </PHeadline>
      </PGridItem>
    </>
  )
}

const TableItem: React.FC<{ titleKey: string; value: string; addSpacing?: boolean }> = (props) => {
  return (
    <>
      <PGridItem size={8}>
        <PText>
          <FormattedMessage id={props.titleKey} />
        </PText>
      </PGridItem>
      <PGridItem size={4}>
        <PText align='right'>{props.value}</PText>
      </PGridItem>
      <PGridItem size={12}>
        <PDivider style={{ padding: props.addSpacing ? '5px 0' : 0 }} />
      </PGridItem>
    </>
  )
}

const LeaseTable: React.FC<ILeaseFinanceTableProps> = (props) => {
  const { viewMode, financialProduct, currency, isNewVehicle } = props
  const intl = useIntl()

  const tableItem = (titleKey: string, value: string) => (
    <TableItem titleKey={titleKey} value={value} addSpacing={viewMode === 'one-third-tile'} />
  )

  return (
    <>
      {tableItem(
        'common.annualMileage',
        `${formatLocalizedNoFraction(intl, (financialProduct.annualMileage ?? 0) * 1000)} miles`
      )}
      {tableItem('common.term', `${financialProduct.term as number} months`)}
      {tableItem('common.down', formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.downPayment))}
      {tableItem(
        isNewVehicle ? 'common.msrp.price' : 'common.price',
        formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.totalPrice)
      )}
    </>
  )
}

const FinanceTable: React.FC<ILeaseFinanceTableProps> = (props) => {
  const { viewMode, financialProduct, currency, isNewVehicle } = props
  const intl = useIntl()

  const tableItem = (titleKey: string, value: string) => (
    <TableItem titleKey={titleKey} value={value} addSpacing={viewMode === 'one-third-tile'} />
  )

  return (
    <>
      {tableItem('common.apr', `${intl.formatNumber(financialProduct.annualPercentageRate || 0)}%`)}
      {tableItem('common.term', `${financialProduct.term as number} ${intl.formatMessage({ id: 'common.months' })}`)}
      {tableItem('common.down', formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.downPayment))}
      {tableItem(
        'common.financed',
        formatLocalizedCurrencyNoFraction(
          intl,
          currency,
          financialProduct.totalPrice - (financialProduct.downPayment ?? 0)
        )
      )}
      {tableItem(
        isNewVehicle ? 'common.msrp.price' : 'common.price',
        formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.totalPrice)
      )}
    </>
  )
}

const OneThirdLeaseFinanceVehicleSelection: React.FC<Omit<ILeaseFinanceProps, 'viewMode'>> = (props) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { type } = props

  const { modelYear, modelName } = opportunity.vehicle
  const financialProduct = props.financeData
  const currency = opportunity.product.price.currencyCode
  const leaseFinanceTableProps = {
    financialProduct,
    currency,
    isNewVehicle: isNewVehicle(opportunity)
  } as ILeaseFinanceTableProps

  return (
    <PGrid className='card-content-wrapper' direction={{ base: 'column', s: 'column' }}>
      <PGridItem size={{ base: 12 }}>
        <OpportunityVehicleSelectionImage viewMode='one-third-tile' opportunity={opportunity} />
      </PGridItem>
      <PGridItem size={{ base: 12, m: 12 }}>
        <PHeadline variant='headline-4' className='big-space-top small-space-bottom' tag='h3'>
          {modelYear} {modelName}
        </PHeadline>
        <PHeadline variant='headline-4' className='small-space-bottom' tag='h3'>
          <FormattedMessage
            id='vehicleSelection.leaseFinance.yourEstimate'
            values={{ type: financialProduct.creditType === 'LSE' ? 'Lease' : 'Finance' }}
          />
        </PHeadline>
        <div className='small-space-right'>
          <PGrid>
            <PGridItem size={12}>
              <PDivider className='medium-space-bottom' />
            </PGridItem>
            {type === 'lease' ? (
              <LeaseTable {...leaseFinanceTableProps} />
            ) : (
              <FinanceTable {...leaseFinanceTableProps} />
            )}
            {GetEstimatedMonthlyPayment('one-third-tile', financialProduct, type, currency)}
            <LeaseFinanceVehicleSelectionLegal className='small-space-top' />
          </PGrid>
        </div>
      </PGridItem>
    </PGrid>
  )
}

const FullWidthLeaseFinanceVehicleSelection: React.FC<Omit<ILeaseFinanceProps, 'viewMode'>> = (props) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { type } = props

  const financialProduct = opportunity.financialProduct!.payload
  const currency = opportunity.product.price.currencyCode
  const leaseFinanceTableProps = {
    financialProduct,
    currency,
    isNewVehicle: isNewVehicle(opportunity)
  } as ILeaseFinanceTableProps

  return (
    <PGrid className='card-content-wrapper' direction={{ base: 'column', s: 'row' }}>
      <PGridItem size={{ base: 12, m: 5 }}>
        <div className='medium-space-bottom'>
          <VehicleSelectionHeader
            modelYear={opportunity.vehicle.modelYear ?? 0}
            modelName={opportunity.vehicle?.modelName ?? ''}
            dealer={opportunity.owner?.displayName ?? ''}
          />
        </div>

        <div className='small-space-right'>
          <PGrid>
            <PGridItem size={12}>
              <PDivider className='medium-space-bottom' />
            </PGridItem>

            <>
              {GetEstimatedMonthlyPayment('full-tile', financialProduct, type, currency)}
              <PGridItem size={12} className='small-space-bottom'>
                <DealerInformation
                  dealerUrl={opportunity.vehicle.payload?.dealerUrl}
                  dealerName={opportunity.owner?.displayName}
                  analyticsEvent={AnalyticsEventTemplates.DEALER_INFO_CLICK}
                />
              </PGridItem>
            </>

            {type === 'lease' ? (
              <LeaseTable {...leaseFinanceTableProps} />
            ) : (
              <FinanceTable {...leaseFinanceTableProps} />
            )}

            <PGridItem size={12}>
              <PDivider className='small-space-bottom' />
            </PGridItem>
            <PGridItem size={12}>
              <PButton
                className='medium-space-top medium-space-bottom'
                onClick={() => {
                  trackClickEvent(AnalyticsEventTemplates.SELECTION_STEP_COMPLETE, { clickElementId: 'your_selection' })
                  presenter.stackManager.editNext()
                }}
              >
                <FormattedMessage id='vehicleSelection.confirm' />
              </PButton>
            </PGridItem>

            <LeaseFinanceVehicleSelectionLegal />
          </PGrid>
        </div>
      </PGridItem>

      <PGridItem size={{ base: 12, m: 7 }}>
        <OpportunityVehicleSelectionImage viewMode='full-tile' opportunity={opportunity} />
        <SSLInfo align='flex-end' />
      </PGridItem>
    </PGrid>
  )
}

export const LeaseFinanceVehicleSelection: React.FC<ILeaseFinanceProps> = (props) => {
  const { viewMode } = props
  const isFullWidth = viewMode === 'full-tile'

  return isFullWidth ? (
    <FullWidthLeaseFinanceVehicleSelection {...props} />
  ) : (
    <OneThirdLeaseFinanceVehicleSelection {...props} />
  )
}

interface ILeaseFinanceTableProps {
  viewMode: VehicleSelectionViewMode
  currency: string
  financialProduct: CalculationResult
  isNewVehicle: boolean
}
