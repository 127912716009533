import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  FlexItemAlignSelf,
  PButton,
  PButtonGroup,
  PContentWrapper,
  PFieldsetWrapper,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PHeadline,
  PRadioButtonWrapper,
  PSelectWrapper,
  PText,
  PTextFieldWrapper
} from '@porsche-design-system/components-react'
import { TradeInVehicleView } from '@pdiatl/common-external-models'
import { IAnalyticsClickEventTemplate, trackClickEvent } from '@slatldisal/one-ga'
import { UsTradeinFlow } from '@slatldisal/trade-in-finder-next'
import React, { PropsWithChildren, Suspense, useContext, useReducer } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isCheckout2Enabled } from '../../common/checkout'
import { conditionList } from '../../common/condition'
import { config } from '../../config'
import { matomoPcnaTrackClickEvent, matomoTradeInActionMapper } from '../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import { CheckoutApp } from '../../presenter/checkoutApp'
import { SessionStorageService } from '../../services/sessionStorageService'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { unsavedDataModalStore } from '../checkout/unsavedDataModalStore'
import { CheckoutDivider } from '../checkoutDivider/checkoutDivider'
import { PresenterContext } from '../entry/presenterProvider'
import { Questions } from '../questions/questions'
import './tradeIn.scss'
import { scrollTo } from '../stack/stackElement'
import { TradeInKbbDisclaimer, TradeInReviewTable } from './tradeInReviewTable'
import { TradeInAction, formReducer, ITradeinState, ITradeInValues, mapTradeInStateToVehicleView } from './tradeinState'

export interface ITradeInProps {
  loggedIn: boolean
  edit: { message: string }
  kbbValuationEnabled: boolean
}

export const defaultState: ITradeinState = {
  tradeInValues: {
    selection: 'none',
    brand: '',
    model: '',
    year: '',
    mileage: '',
    color: '',
    condition: 'NOT_PROVIDED',
    vin: '',
    notes: ''
  },
  status: 'clean',
  errors: {}
}

const Item: React.FC<PropsWithChildren<{ align?: FlexItemAlignSelf }>> = (props) => (
  <PFlexItem width={{ base: 'full', s: 'half' }} alignSelf={props.align} className='trade-in--item' {...props}>
    {props.children}
  </PFlexItem>
)

const Container: React.FC<PropsWithChildren<{}>> = (props) => (
  <PFlex alignItems='baseline' alignContent='space-between' direction={{ base: 'column', s: 'row' }}>
    {props.children}
  </PFlex>
)

export const TradeIn: React.FC<ITradeInProps> = (props: ITradeInProps) => {
  const { trackEvent } = useMatomo()
  const { environment, market } = config()
  const presenter = useContext<CheckoutApp>(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const intl = useIntl()
  const tradeInValuesFromStorage = SessionStorageService.getAndParsePrefixedItem<ITradeInValues>(
    CheckoutStorageKeys.TRADE_IN_VALUES
  )
  const scrollToStepTitle = () => scrollTo(presenter.stackManager.currentStepIndex)

  const tradeInValuesFromOpportunity =
    opportunity.tradeInVehicle?.valuation?.provider.toLowerCase() === 'kbb'
      ? { selection: 'kbb', kbbTradeIn: opportunity.tradeInVehicle }
      : {}

  const initialState = {
    ...defaultState,
    tradeInValues: { ...defaultState.tradeInValues, ...tradeInValuesFromOpportunity, ...tradeInValuesFromStorage },
    status: tradeInValuesFromStorage?.selection === 'manual' ? 'completed' : 'clean',
    intl
  } as ITradeinState

  const [state, dispatch] = useReducer(formReducer, initialState)

  const handleChange = (e: any) => {
    dispatch({
      type: TradeInAction.FIELD_CHANGED,
      field: e.target?.name,
      payload: e.target?.value
    })

    unsavedDataModalStore.setCheckoutFormDirty(true)
  }

  function handleSkip() {
    presenter.opportunityStore.setTradeInVehicle(undefined)
    SessionStorageService.setPrefixedItem(CheckoutStorageKeys.TRADE_IN_VALUES, { selection: 'none' })

    unsavedDataModalStore.setCheckoutFormDirty(false)

    matomoPcnaTrackClickEvent(trackEvent, matomoTradeInActionMapper('none'))
    trackClickEvent(AnalyticsEventTemplates.TRADE_IN_STEP_SKIP)

    presenter.stackManager.editNext()
  }

  function handleSubmit() {
    const tradeInValues = state.tradeInValues

    dispatch({ type: TradeInAction.FORM_SUBMITTED })
    if (tradeInValues.selection === 'manual' && state.status !== 'completed') {
      return
    }

    presenter.opportunityStore.setTradeInVehicle(mapTradeInStateToVehicleView(opportunity, tradeInValues))
    const storageValues = tradeInValues.selection === 'none' ? { selection: 'none' } : tradeInValues
    SessionStorageService.setPrefixedItem(CheckoutStorageKeys.TRADE_IN_VALUES, storageValues)

    unsavedDataModalStore.setCheckoutFormDirty(false)

    matomoPcnaTrackClickEvent(trackEvent, matomoTradeInActionMapper(tradeInValues.selection))
    trackClickEvent(AnalyticsEventTemplates.TRADE_IN_STEP_COMPLETE)

    presenter.stackManager.editNext()
  }

  /**
   * In here we check if the form is dirty when we come back to 'manual' selection from another radio, because there
   * dirty state is cleared (you cannot lose any data when on 'none'/'kbb' selections).
   */
  function hasUnsavedData(selection: string): boolean {
    const tradeInValuesFromStorage = SessionStorageService.getAndParsePrefixedItem<ITradeInValues>(
      CheckoutStorageKeys.TRADE_IN_VALUES
    )

    // we reset some fields from state, because it contains values from previous selection
    const cleanValues = (values: ITradeInValues) => ({
      ...values,
      selection: undefined,
      condition: undefined,
      kbbTradeIn: undefined
    })
    const valuesToString = (values: ITradeInValues) => JSON.stringify(cleanValues(values))

    return (
      selection === 'manual' &&
      Object.values(cleanValues(state.tradeInValues)).some((v) => v) &&
      (!tradeInValuesFromStorage || valuesToString(state.tradeInValues) !== valuesToString(tradeInValuesFromStorage))
    )
  }

  function selectRadio(clickEventTemplate: IAnalyticsClickEventTemplate, newSelection: string) {
    trackClickEvent(clickEventTemplate, { clickElementId: 'trade_in' })
    dispatch({ type: TradeInAction.FLIP, newSelection })

    scrollToStepTitle()
    unsavedDataModalStore.setCheckoutFormDirty(hasUnsavedData(newSelection))
  }

  function getManual() {
    const selectOptions = conditionList(intl)

    const mileageUnit: string = opportunity?.vehicle?.payload?.mileageUnit

    const requiredTextField = (props: { labelKey?: string; label?: string; name: string }) => (
      <PTextFieldWrapper
        label={props.label ?? intl.formatMessage({ id: props.labelKey! })}
        state={state.errors[props.name] ? 'error' : 'none'}
        message={state.errors[props.name]}
        className='trade-in--input-field'
        style={{ height: '100px' }}
      >
        <input
          onChange={handleChange}
          onBlur={handleChange}
          name={props.name}
          value={state.tradeInValues[props.name]}
          type='text'
          required
        />
      </PTextFieldWrapper>
    )

    return (
      <PFieldsetWrapper className='trade-in--input-field-set'>
        <Container>
          <Item>{requiredTextField({ labelKey: 'common.make', name: 'brand' })}</Item>
          <Item>{requiredTextField({ labelKey: 'common.model', name: 'model' })}</Item>
        </Container>
        <Container>
          <Item>{requiredTextField({ labelKey: 'common.modelYear', name: 'year' })}</Item>
          <Item>
            {requiredTextField({
              label: `${intl.formatMessage({ id: 'tradein.currentMileage' })} (${mileageUnit})`,
              name: 'mileage'
            })}
          </Item>
        </Container>
        <PText className='trade-in--optional-section' weight='bold'>
          <FormattedMessage id='common.optionalDetails' />
        </PText>
        <Container>
          <Item>
            <PTextFieldWrapper
              label={intl.formatMessage({ id: 'common.color' })}
              className='trade-in--input-field'
              state='none'
            >
              <input onChange={handleChange} name='color' value={state.tradeInValues.color} type='text' />
            </PTextFieldWrapper>
          </Item>
          <Item>
            <PSelectWrapper label={intl.formatMessage({ id: 'common.condition' })} className='trade-in--input-field'>
              {/* eslint-disable-next-line jsx-a11y/no-onchange */}
              <select onChange={handleChange} name='condition' value={state.tradeInValues.condition}>
                {selectOptions.map(({ code, title }) => (
                  <option key={code} value={code}>
                    {title}
                  </option>
                ))}
              </select>
            </PSelectWrapper>
          </Item>
        </Container>
        <Container>
          <Item>
            <PTextFieldWrapper
              label={intl.formatMessage({ id: 'common.vin' })}
              state='none'
              className='trade-in--input-field fs-mask'
            >
              <input onChange={handleChange} name='vin' value={state.tradeInValues.vin} type='text' />
            </PTextFieldWrapper>
          </Item>
          <Item>
            <PTextFieldWrapper
              label={`${intl.formatMessage({ id: 'tradein.existingLoan' })} ($)`}
              className='trade-in--input-field'
              state='none'
            >
              <input
                onChange={handleChange}
                name='existingLoan'
                value={state.tradeInValues.existingLoan}
                type='number'
              />
            </PTextFieldWrapper>
          </Item>
        </Container>
        <Container>
          <Item>
            <PTextFieldWrapper
              label={`${intl.formatMessage({ id: 'tradein.estimatedValue' })} ($)`}
              className='trade-in--input-field'
              state='none'
            >
              <input
                onChange={handleChange}
                name='estimatedValue'
                value={state.tradeInValues.estimatedValue}
                type='number'
              />
            </PTextFieldWrapper>
          </Item>
          <Item>
            <PTextFieldWrapper
              label={intl.formatMessage({ id: 'tradein.notes' })}
              state='none'
              className='trade-in--input-field'
            >
              <input onChange={handleChange} name='notes' value={state.tradeInValues.notes} type='text' />
            </PTextFieldWrapper>
          </Item>
        </Container>
      </PFieldsetWrapper>
    )
  }

  return (
    <PGrid className='trade-in--input-field-set' direction={{ base: 'column', s: 'row' }}>
      <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, m: 6, l: 5 }}>
        <PText size='small' className='small-space-bottom' color='neutral-contrast-high'>
          {props.edit.message}
        </PText>
        {!props.kbbValuationEnabled && (
          <PContentWrapper width='extended' className='trade-in--form'>
            <PHeadline variant='headline-5'>Do you want to trade in your vehicle?</PHeadline>
            <PFlex>
              <PFlexItem width={{ base: 'half', xs: 'one-quarter' }} className='trade-in--input-field'>
                <PRadioButtonWrapper label='Yes' hideLabel={false}>
                  <input
                    type='radio'
                    name='yes'
                    checked={state.tradeInValues.selection === 'manual'}
                    onChange={() => selectRadio(AnalyticsEventTemplates.TRADE_IN_STEP_TRUE, 'manual')}
                  />
                </PRadioButtonWrapper>
              </PFlexItem>
              <PFlexItem width={{ base: 'half', xs: 'one-quarter' }} className='trade-in--input-field'>
                <PRadioButtonWrapper label='No' hideLabel={false}>
                  <input
                    type='radio'
                    name='no'
                    checked={state.tradeInValues.selection === 'none'}
                    onChange={() => selectRadio(AnalyticsEventTemplates.TRADE_IN_STEP_FALSE, 'none')}
                  />
                </PRadioButtonWrapper>
              </PFlexItem>
            </PFlex>
            {state.tradeInValues.selection === 'manual' && getManual()}
          </PContentWrapper>
        )}
        {props.kbbValuationEnabled && (
          <PContentWrapper width='extended' className='trade-in--form'>
            <PFlex direction='column'>
              <PFlexItem className='trade-in--input-field'>
                <PRadioButtonWrapper label={intl.formatMessage({ id: 'tradein.option.yes' })} hideLabel={false}>
                  <input
                    type='radio'
                    name='manual'
                    checked={state.tradeInValues.selection === 'manual'}
                    onChange={() => selectRadio(AnalyticsEventTemplates.TRADE_IN_STEP_TRUE, 'manual')}
                  />
                </PRadioButtonWrapper>
                {state.tradeInValues.selection === 'manual' && getManual()}
              </PFlexItem>
              {market === 'us' && (
                <PFlexItem className='trade-in--input-field'>
                  <PRadioButtonWrapper label='I want to use my Kelley Blue Book® Trade-in Range' hideLabel={false}>
                    <input
                      type='radio'
                      name='kbb'
                      checked={state.tradeInValues.selection === 'kbb'}
                      onChange={() => selectRadio(AnalyticsEventTemplates.TRADE_IN_STEP_VALUATION, 'kbb')}
                    />
                  </PRadioButtonWrapper>

                  {state.tradeInValues.selection === 'kbb' && !!state.tradeInValues.kbbTradeIn && (
                    <div style={{ paddingLeft: '30px' }}>
                      <TradeInReviewTable
                        tradeIn={state.tradeInValues.kbbTradeIn}
                        onEdit={(kbbTradeIn: TradeInVehicleView) => {
                          scrollToStepTitle()
                          dispatch({
                            type: TradeInAction.KBB_ADDED,
                            payload: kbbTradeIn
                          })
                        }}
                      />
                      <TradeInKbbDisclaimer />
                    </div>
                  )}
                  {state.tradeInValues.selection === 'kbb' && !state.tradeInValues.kbbTradeIn && (
                    <Suspense fallback={null}>
                      <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                        <UsTradeinFlow
                          env={environment}
                          listingId={opportunity?.vehicle?.payload?.listingId}
                          onFinish={(tradeIn: TradeInVehicleView) => {
                            scrollToStepTitle()
                            dispatch({
                              type: TradeInAction.KBB_ADDED,
                              payload: tradeIn
                            })
                          }}
                          vehicleInfo={{ vehicle: opportunity.vehicle, owner: opportunity.owner }}
                          locale={market}
                          buyNowEnabled={false}
                          buttonStyle='link'
                        />
                      </div>
                    </Suspense>
                  )}
                </PFlexItem>
              )}
              <PFlexItem className='trade-in--input-field'>
                <PRadioButtonWrapper label={intl.formatMessage({ id: 'tradein.option.no' })} hideLabel={false}>
                  <input
                    type='radio'
                    name='none'
                    checked={state.tradeInValues.selection === 'none'}
                    onChange={() => selectRadio(AnalyticsEventTemplates.TRADE_IN_STEP_FALSE, 'none')}
                  />
                </PRadioButtonWrapper>
              </PFlexItem>
            </PFlex>
          </PContentWrapper>
        )}
        <PButtonGroup className='medium-space-top medium-space-bottom'>
          <PButton variant='secondary' onClick={() => handleSubmit()}>
            <FormattedMessage id='tradein.button.confirm' />
          </PButton>
          {config().isMarket('us') && (
            <PButton variant='tertiary' onClick={() => handleSkip()}>
              <FormattedMessage id='common.skip' />
            </PButton>
          )}
        </PButtonGroup>
      </PGridItem>
      {!isCheckout2Enabled() && (
        <>
          <CheckoutDivider />
          <PGridItem size={{ base: 12, m: 5, l: 6 }}>
            <Questions
              questions={[
                {
                  q: { id: 'tradein.faq.q1' },
                  a: { id: 'tradein.faq.a1' }
                },
                {
                  q: { id: 'tradein.faq.q2' },
                  a: { id: 'tradein.faq.a2' }
                },
                {
                  q: { id: 'tradein.faq.q3' },
                  a: { id: 'tradein.faq.a3' }
                }
              ]}
            />
          </PGridItem>
        </>
      )}
    </PGrid>
  )
}
