import { PDivider, PGrid, PGridItem, PText } from '@porsche-design-system/components-react'
import { TradeInVehicleView } from '@pdiatl/common-external-models'
import React, { Suspense, useContext } from 'react'
import { parseKBBNotes } from '../../common/kbbNotesParser'
import { UsTradeinFlow } from '@slatldisal/trade-in-finder-next'
import { config } from '../../config'
import { useIntl } from 'react-intl'
import { conditionList } from '../../common/condition'
import { formatKBBRange, formatMiles } from '@slatldisal/checkout-i18n'
import { CheckoutApp } from '../../presenter/checkoutApp'
import { PresenterContext } from '../entry/presenterProvider'
import { SummaryDisclaimer } from '../review/checkout1/ReviewStackElement'

const { environment, market } = config()

const TradeInLine: React.FC<{ title: string; text: string; hideDivider?: boolean; confirmationPage?: boolean }> = (
  props
) => {
  const titleJoined = props.title.replace(/[^\w]/gi, '')
  return (
    <>
      <PGrid>
        <PGridItem size={6}>
          <PText data-testid={`tradeIn${titleJoined}Title`}>{props.title}</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText
            align='right'
            className={props.confirmationPage ? '' : 'small-space-right'}
            data-testid={`tradeIn${titleJoined}Value`}
          >
            {props.text}
          </PText>
        </PGridItem>
        {!props.hideDivider && (
          <PGridItem size={12}>
            <PDivider />
          </PGridItem>
        )}
      </PGrid>
    </>
  )
}

export const TradeInKbbDisclaimer: React.FC = () => {
  return <SummaryDisclaimer translationKey='common.kbbDisclaimer' />
}

export const TradeInReviewTable: React.FC<{
  tradeIn: TradeInVehicleView
  confirmationPage?: boolean
  onEdit?: (kbbTradeIn: TradeInVehicleView) => void
}> = (props) => {
  const i = useIntl()
  const presenter = useContext<CheckoutApp>(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { tradeIn, onEdit } = props
  const { condition, notes, brand, model, trim, currentMileage, yearOfConstruction, valuation, vin } = { ...tradeIn }
  const conditionText = conditionList(i).find((c) => c.code === condition)!.title
  const color = tradeIn?.options?.color
  const existingLoan = tradeIn?.options?.existingLoan
  const estimatedValue = tradeIn?.valuation?.estimatedValue?.tradeIn[0]?.value?.value
  const isKBB = valuation && valuation.provider === 'KBB'
  const isManual = valuation && valuation.provider === 'self'
  const { zip } = isKBB && notes ? parseKBBNotes(notes) : { zip: undefined }
  const tradeInLine = (titleKey: string, text: string, titlePostfix = '') => (
    <TradeInLine
      title={i.formatMessage({ id: titleKey }) + titlePostfix}
      text={text}
      hideDivider={props.confirmationPage}
      confirmationPage={props.confirmationPage}
    />
  )

  const kbbValuationText = formatKBBRange(
    valuation?.estimatedValue.tradeIn[0].lowerBound?.formattedValue ?? 'n/a',
    valuation?.estimatedValue.tradeIn[0].upperBound?.formattedValue ?? 'n/a'
  )

  return (
    <>
      {tradeInLine('common.make', brand)}
      {tradeInLine('common.model', model)}
      {trim && tradeInLine('common.style', trim)}
      {tradeInLine('common.year', yearOfConstruction?.toString() ?? '')}
      {tradeInLine('common.mileage', formatMiles(currentMileage))}
      {isManual && color && tradeInLine('common.color', color)}
      {condition !== 'NOT_PROVIDED' && tradeInLine('common.condition', conditionText)}
      {isManual &&
        !!estimatedValue &&
        tradeInLine('vehicleSelection.tradein.estimatedValue', i.formatNumber(estimatedValue))}
      {isManual && existingLoan && tradeInLine('tradein.existingLoan', i.formatNumber(existingLoan))}
      <span className='fs-mask'>{vin && tradeInLine('common.vin', vin)}</span>
      {isManual && notes && tradeInLine('common.additionalNotes', notes)}
      {zip && tradeInLine('common.zip', zip)}
      {isKBB &&
        tradeInLine('vehicleSelection.tradein.kbbValuation', kbbValuationText, props.confirmationPage ? '***' : '*')}
      {isKBB && onEdit && (
        <Suspense fallback={null}>
          <div style={{ marginTop: '15px' }}>
            <UsTradeinFlow
              locale={market}
              buyNowEnabled={false}
              env={environment}
              listingId={opportunity?.vehicle?.payload?.listingId}
              vehicleInfo={{ vehicle: opportunity?.vehicle, owner: opportunity?.owner }}
              onFinish={onEdit}
              initialState={tradeIn}
              buttonStyle='link'
            />
          </div>
        </Suspense>
      )}
    </>
  )
}
