import { ITradeinState } from './tradeinState'
import { IntlShape } from 'react-intl'
import { validateAll, validatePositiveIntInput, validateRequired } from '../../helpers/validators'

export const getValidators = (intl: IntlShape) => ({
  brand: validateRequired(intl.formatMessage({ id: 'tradein.validation.make' })),
  model: validateRequired(intl.formatMessage({ id: 'tradein.validation.model' })),
  mileage: validatePositiveIntInput(intl.formatMessage({ id: 'tradein.validation.mileage' })),
  year: validatePositiveIntInput(intl.formatMessage({ id: 'tradein.validation.year' }))
})

export function validateForm(state: ITradeinState, validators: any): Partial<ITradeinState> {
  if (state.tradeInValues.selection === 'none') {
    return { errors: {}, status: 'completed' }
  }

  const errors = validateAll(state.tradeInValues, validators)
  const formValid = Object.getOwnPropertyNames(errors).length === 0
  const status = formValid ? 'completed' : 'dirty'
  return { errors, status }
}
