import { PGrid } from '@porsche-design-system/components-react'
import { formatKBBRange } from '@slatldisal/checkout-i18n'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { conditionList } from '../../../../common/condition'
import { Currency } from '../../../confirmation/shared/ui'
import { PresenterContext } from '../../../entry/presenterProvider'
import { TradeInKbbDisclaimer } from '../../../tradeIn/tradeInReviewTable'
import { TRADEIN_STACK_NAME } from '../../../tradeIn/tradeInStackElement'
import { NOT_PROVIDED } from '../../../tradeIn/tradeinState'
import { AccordionStepData, ClearStepData, isStepDataShown, IStepData, StepDataEditButton, StepDataRow } from './common'

export const TradeInStepData: React.FC<IStepData> = observer(({ closeModalFn }) => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const stackManager = presenter.stackManager
  const tradeIn = opportunity.tradeInVehicle
  const heading = intl.formatMessage({ id: 'tradein.vehicleTradeIn' })

  if (!isStepDataShown(TRADEIN_STACK_NAME, stackManager) || !tradeIn) {
    return <ClearStepData heading={heading} />
  }

  const { condition, notes, brand, model, trim, currentMileage, yearOfConstruction, valuation, vin } = { ...tradeIn }
  const conditionText = conditionList(intl).find((c) => c.code === condition)!.title
  const color = tradeIn?.options?.color
  const existingLoan = tradeIn?.options?.existingLoan
  const kbbValuationRange = formatKBBRange(
    valuation?.estimatedValue.tradeIn[0].lowerBound?.formattedValue ?? 'n/a',
    valuation?.estimatedValue.tradeIn[0].upperBound?.formattedValue ?? 'n/a'
  )
  const isManual = valuation && valuation.provider === 'self'
  const isKBB = valuation && valuation.provider === 'KBB'
  const estimatedValue = tradeIn?.valuation?.estimatedValue?.tradeIn[0]?.value?.value
  const noTradeInProvided = tradeIn?.model === NOT_PROVIDED

  return noTradeInProvided ? (
    <></>
  ) : (
    <AccordionStepData heading={heading}>
      <PGrid>
        <StepDataRow labelKey='common.make'>{brand}</StepDataRow>
        <StepDataRow labelKey='common.model'>{model}</StepDataRow>
        {trim && <StepDataRow labelKey='common.style'>{trim}</StepDataRow>}
        <StepDataRow labelKey='common.year'>{yearOfConstruction?.toString() ?? ''}</StepDataRow>
        <StepDataRow labelKey='common.mileage'>
          {currentMileage.numericValue ? intl.formatNumber(currentMileage.numericValue) : ''}
        </StepDataRow>
        {isManual && color && <StepDataRow labelKey='common.color'>{color}</StepDataRow>}
        {condition !== 'NOT_PROVIDED' && <StepDataRow labelKey='common.condition'>{conditionText}</StepDataRow>}
        {isManual && !!estimatedValue && (
          <StepDataRow labelKey='vehicleSelection.tradein.estimatedValue'>
            <Currency value={estimatedValue} currency={opportunity.product?.price?.currencyCode} />
          </StepDataRow>
        )}
        {isManual && existingLoan && (
          <StepDataRow labelKey='tradein.existingLoan'>
            <Currency value={existingLoan} currency={opportunity.product?.price?.currencyCode} />
          </StepDataRow>
        )}
        {vin && <StepDataRow labelKey='common.vin'>{vin}</StepDataRow>}
        {isKBB && <StepDataRow labelKey='vehicleSelection.tradein.kbbValuation'>{kbbValuationRange}</StepDataRow>}
        {isManual && notes && <StepDataRow labelKey='common.additionalNotes'>{notes}</StepDataRow>}
        <StepDataRow>
          <StepDataEditButton
            editStack={TRADEIN_STACK_NAME}
            editTitle='Edit Trade-In Details'
            closeModalFn={closeModalFn}
          />
        </StepDataRow>
      </PGrid>

      {isKBB && <TradeInKbbDisclaimer />}
    </AccordionStepData>
  )
})
