import { PButtonPure, PHeadline, PModal, PText } from '@porsche-design-system/components-react'
import { formatCurrency } from '@slatldisal/checkout-i18n'
import { trackClickEvent } from '@slatldisal/one-ga'
import React, { useContext, useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { BoldTag, DoubleBreak, ProtectionPlanLink } from '../../../common/IntlElements'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../entry/presenterProvider'
import { IFaqItem } from '../../ported/faq'
import './LearnMoreIcon.scss'

function getFaqQuestions(i18nPrefix: string, intl: IntlShape): IFaqItem[] {
  const getTranslations = (prefix: string) => Object.keys(intl.messages).filter((key) => key.startsWith(prefix))

  const questions = getTranslations(i18nPrefix + '.faq.q')
  const answers = getTranslations(i18nPrefix + '.faq.a')

  if (questions.length !== answers.length) {
    throw new Error('Failed to generate FAQ for Learn More Icon - unequal questions/answers length.')
  }

  return questions.map(
    (q, index) =>
      ({
        question: q,
        answer: answers[index]
      } as IFaqItem)
  )
}

export const LearnMoreIcon: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const presenter = useContext(PresenterContext)
  const intl = useIntl()

  const opportunity = presenter.opportunityStore.opportunityCache
  const values = {
    downpayment: formatCurrency(opportunity.orderInformation?.downPayment?.value, 'UNKNOWN DOWNPAYMENT'),
    dealer: opportunity.owner?.displayName ?? 'UNKNOWN DEALER',
    bold: BoldTag,
    a: ProtectionPlanLink,
    break: DoubleBreak
  }

  const currentStep = presenter.stackManager.getCurrentStep()
  const faqQuestions = getFaqQuestions(currentStep.stackName, intl)

  if (faqQuestions.length === 0) {
    return <></>
  }

  return (
    <>
      <div className='learn-more-container'>
        <PButtonPure
          data-testid='learn-more-icon'
          aria-label='learn more button'
          type='button'
          icon='information'
          size='small'
          onClick={() => {
            trackClickEvent(
              AnalyticsEventTemplates.LEARN_MORE_ICON_CLICK(currentStep.analyticsPage, 'open_learn_more_modal')
            )
            setModalOpen(true)
          }}
        />
      </div>
      {isModalOpen && (
        <>
          <PModal
            heading={currentStep.title}
            open={isModalOpen}
            onClose={() => {
              trackClickEvent(
                AnalyticsEventTemplates.LEARN_MORE_ICON_CLICK(currentStep.analyticsPage, 'close_learn_more_modal')
              )

              setModalOpen(false)
            }}
          >
            {faqQuestions.map((faqItem) => (
              <div key={faqItem.question}>
                <PHeadline variant='headline-4' tag='h2' className='ada-text-width xsmall-space-bottom'>
                  <FormattedMessage id={faqItem.question} />
                </PHeadline>
                <PText className='ada-text-width medium-space-bottom'>
                  <FormattedMessage id={faqItem.answer} values={values} />
                </PText>
              </div>
            ))}
          </PModal>
        </>
      )}
    </>
  )
}
