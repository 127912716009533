import { action, makeObservable, observable } from 'mobx'
import { CheckoutStorageKeys } from '../../checkout/checkoutStorageKeys'
import { CalculationResult } from './leaseFinance/defiCalcFinanceData'
import { IOwnFinancing, VehicleSelectionValues } from './VehicleSelectionPcna'
import { IOwnFinancingOptions } from './PaymentOptionDetails'
import { SessionStorageService } from '../../../services/sessionStorageService'
import { PaymentOptionType } from './VehicleSelectionPaymentOptions'

class VehicleSelectionStore {
  @observable.ref
  calculationResult: CalculationResult | undefined = undefined

  @observable.ref
  selectedPaymentOption: PaymentOptionType | undefined = undefined

  @observable
  ownFinancingOptions: IOwnFinancingOptions

  constructor() {
    const sessionStorageVehicleSelectionValues =
      SessionStorageService.getAndParsePrefixedItem<VehicleSelectionValues>(
        CheckoutStorageKeys.VEHICLE_SELECTION_VALUES
      ) ?? ({} as any)

    this.ownFinancingOptions = {
      applyingAnotherBank: {
        i18nKey: 'common.cash.ownFinancing.option.applyingAnotherBank',
        selected: (sessionStorageVehicleSelectionValues as IOwnFinancing).applyingAnotherBank ?? false
      },
      securedFinancing: {
        i18nKey: 'common.cash.ownFinancing.option.securedFinancing',
        selected: (sessionStorageVehicleSelectionValues as IOwnFinancing).securedFinancing ?? false
      }
    }
    this.selectedPaymentOption = sessionStorageVehicleSelectionValues.paymentType
    this.calculationResult = sessionStorageVehicleSelectionValues.calculatorValues
    makeObservable(this)
  }

  @action
  setSelectedPaymentOption(value: PaymentOptionType) {
    this.selectedPaymentOption = value
    this.calculationResult = undefined
  }

  @action
  setOwnFinancingOption(option: string, value: boolean) {
    this.ownFinancingOptions[option].selected = value
  }

  @action
  setCalculationResult(calculationResult: CalculationResult | undefined) {
    this.calculationResult = calculationResult
  }
}

export const vehicleSelectionStore = new VehicleSelectionStore()
