import { PContentWrapper } from '@porsche-design-system/components-react'

import React from 'react'
import { WorldOfPorsche } from '../../confirmation/pcna/WorldOfPorsche'
import { ConfirmationAutocheck } from '../../confirmation/shared/ConfirmationAutoCheck'
import { ConfirmationInfoBlocks } from '../../confirmation/shared/ConfirmationInfoBlocks'
import { isApplicationComplete } from '../../entry/presenterProvider'
import './Checkout2Confirmation.scss'
import { Checkout2ConfirmationDetails } from './Checkout2ConfirmationDetails'
import { Checkout2ConfirmationHeader } from './header/Checkout2ConfirmationHeader'

export const Checkout2Confirmation: React.FC = () => {
  return (
    <div className='confirmation2-container'>
      <Checkout2ConfirmationHeader isAoac={isApplicationComplete()} />

      <PContentWrapper width='basic' className='big-space-top'>
        <Checkout2ConfirmationDetails />
      </PContentWrapper>

      <ConfirmationAutocheck />

      <PContentWrapper width='basic'>
        <ConfirmationInfoBlocks />
        <WorldOfPorsche />
      </PContentWrapper>
    </div>
  )
}
