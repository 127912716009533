import React from 'react'
import { OwnFinancingSelectedOptions } from '../desktop/OwnFinancingVehicleSummary'
import { CashVehicleSummaryShell } from './CashVehicleSummary'

export const OwnFinancingVehicleSummary: React.FC = () => {
  return (
    <CashVehicleSummaryShell>
      <OwnFinancingSelectedOptions />
    </CashVehicleSummaryShell>
  )
}
