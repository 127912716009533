import React from 'react'
import { PHeadline, PIcon } from '@porsche-design-system/components-react'
import { StackElementState } from './stackElement'
import './StepCircle.scss'

export const StepCircle: React.FC<{ state: StackElementState; index: number; label?: string; top?: boolean }> = ({
  state,
  index,
  label,
  top
}) => {
  const circleClass = `circle ${
    top
      ? 'circle-top'
      : `${state === StackElementState.EDIT ? 'medium-space-top' : 'medium-space-left'} medium-space-right`
  }`

  switch (state) {
    case StackElementState.COMPLETED:
      return (
        <div
          className={`${circleClass} notification-success-border circle-green`}
          aria-label={`${label!} is completed`}
        >
          <PIcon size='small' name='check' color='notification-success' />
        </div>
      )
    case StackElementState.EDIT:
      return (
        <div className={`${circleClass} neutral-contrast-high-border circle-black`}>
          <PHeadline variant='headline-5' align='center' color='inherit' tag='h2'>
            {index + 1}
          </PHeadline>
        </div>
      )
    default:
      return (
        <div className={`${circleClass} neutral-contrast-high-border`} aria-label={`${label!} is locked`}>
          <PIcon size='small' name='lock' color='neutral-contrast-high' />
        </div>
      )
  }
}
