import React from 'react'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { StackElement, StackElementState } from '../../stack/stackElement'
import { CompletedViewCommon } from '../VehicleSelectionStackElement'
import { VehicleSelectionPcna } from './VehicleSelectionPcna'

export const VEHICLE_SELECTION_STACK_NAME = 'vehicleSelection'

const LockedView: React.FC = () => {
  return <div>You should never see this.</div>
}

const EditView: React.FC = () => {
  return <VehicleSelectionPcna />
}

export class VehicleSelectionPcnaStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedViewCommon,
      LockedView,
      () => StackElementState.EDIT,
      VEHICLE_SELECTION_STACK_NAME,
      index,
      label,
      'calculator',
      {
        analyticsPage: AnalyticsPage.EXPLORE_PAYMENTS,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.EXPLORE_PAYMENTS,
            matomoActionName: 'checkout/payment_options'
          }
        }
      }
    )
  }
}
