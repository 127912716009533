import {
  PButtonPure,
  PCheckboxWrapper,
  PDivider,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PIcon,
  PText
} from '@porsche-design-system/components-react'
import { color } from '@porsche-design-system/utilities'
import { APSelectionType, IAncillaryPlanOption } from '@pdiatl/common-external-models'
import { trackClickEvent } from '@slatldisal/one-ga'
import React, { useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useFlags } from '../../common/flags'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import { AncillaryProductsMap } from './AncillaryProducts'
import './AncillaryProducts.scss'
import './balloon.css'
import { PlanDescriptionModal } from './PlanDescriptionModal'
import { getViolatingEarlierSelections } from './violations/checkPlanViolation'
import { PlanViolationModal } from './violations/PlanViolationModal'

const FIRST_SUBLIST_LENGTH = 4

function getViolationMessageKey(plan: IAncillaryPlanOption, violations: IAncillaryPlanOption[]): string {
  const isViolationPackage = violations[0].isPackage

  if (plan.isPackage && isViolationPackage) {
    return 'ancillaryProducts.selection.violation.anotherPackageSelected'
  } else {
    return 'ancillaryProducts.selection.violation.conflictingPlans'
  }
}

function recalculatePlanViolations(
  ancillaryProductsMap: AncillaryProductsMap,
  intl: IntlShape,
  enableNewSelectionOfAncillaryProducts: boolean
) {
  ancillaryProductsMap.plans.forEach((plan) => (plan.violation = undefined))

  ancillaryProductsMap.plans.forEach((plan) => {
    const violatingEarlierSelections = plan.selection ? [] : getViolatingEarlierSelections(plan, ancillaryProductsMap)

    if (violatingEarlierSelections.length > 0) {
      plan.violation = intl.formatMessage(
        {
          id: enableNewSelectionOfAncillaryProducts
            ? getViolationMessageKey(plan, violatingEarlierSelections)
            : 'ancillaryProducts.selection.violation'
        },
        {
          planToSelect: plan.name,
          earlierSelections: violatingEarlierSelections.map((item) => item.name).join(', ')
        }
      )
    }
  })
}

function getBalloonProps(
  plan: IAncillaryPlanOption,
  plans: IAncillaryPlanOption[],
  intl: IntlShape,
  enableNewSelectionOfAncillaryProducts: boolean
): object {
  if (!enableNewSelectionOfAncillaryProducts) {
    return {}
  }

  const balloonText =
    plan.selection === APSelectionType.AUTO_SELECTED
      ? intl.formatMessage({ id: 'ancillaryProducts.selection.violation.childPlanSelected' })
      : plan.violation
  const balloonPos = plans.indexOf(plan) === plans.length - 1 ? 'up-left' : 'down-left'

  return balloonText
    ? {
        'data-balloon-length': 'fit',
        'data-balloon-text': balloonText,
        'data-balloon-pos': balloonPos
      }
    : {}
}

const PlansSubList: React.FC<{
  expandable?: boolean
  ancillaryProductsMap: AncillaryProductsMap
  setPlans: Function
  setPlanDescription: Function
  setPlanDescriptionModalOpen: Function
  setViolationMessage: Function
}> = ({
  expandable,
  ancillaryProductsMap,
  setPlans,
  setPlanDescription,
  setPlanDescriptionModalOpen,
  setViolationMessage
}) => {
  const intl = useIntl()
  const { enableNewSelectionOfAncillaryProducts } = useFlags()
  const [expanded, setExpanded] = useState<boolean>(false)
  const [expandButtonHighlighted, setExpandButtonHighlighted] = useState<boolean>(false)

  const plans = ancillaryProductsMap.plans
  const subPlans = plans.slice(expandable ? FIRST_SUBLIST_LENGTH : 0, expandable ? undefined : FIRST_SUBLIST_LENGTH)

  const ExpandIcon: React.FC<{ name: 'arrow-head-up' | 'arrow-head-down' }> = ({ name }) => {
    const spacingBottom = name === 'arrow-head-up'
    return <PIcon style={spacingBottom ? { marginBottom: '-3px' } : { marginTop: '-3px' }} name={name} size='medium' />
  }

  const showLessOrMore = expanded ? 'show_less' : 'show_more'

  const togglePlanSelection = (changedPlan: IAncillaryPlanOption) => {
    const isUserSelected = changedPlan.selection === APSelectionType.USER_SELECTED
    changedPlan.selection = isUserSelected ? APSelectionType.NOT_SELECTED : APSelectionType.USER_SELECTED

    if (enableNewSelectionOfAncillaryProducts) {
      const newChildSelection = isUserSelected ? APSelectionType.NOT_SELECTED : APSelectionType.AUTO_SELECTED
      changedPlan.childPlans.forEach((cp) => (ancillaryProductsMap.get(cp.code)!.selection = newChildSelection))
    }
  }

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          transition: `max-height ${expanded ? '2s ease-in-out' : '0.5s cubic-bezier(0, 1, 0, 1)'}`,
          maxHeight: expandable && !expanded ? '0px' : '9999px'
        }}
      >
        {subPlans.map((plan) => {
          const childPlansString = plan.childPlans.map((childPlan) => childPlan.name).join(', ')
          const hasViolation = !!plan.violation
          const planDisabled =
            enableNewSelectionOfAncillaryProducts && (hasViolation || plan.selection === APSelectionType.AUTO_SELECTED)

          const onPlanClick = () => {
            if (planDisabled) {
              return
            }

            trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_PLAN_SELECTION(plan.code))

            if (hasViolation) {
              setViolationMessage(plan.violation)
              return
            }

            const changedPlan = ancillaryProductsMap.get(plan.code)!

            togglePlanSelection(changedPlan)
            recalculatePlanViolations(ancillaryProductsMap, intl, enableNewSelectionOfAncillaryProducts)

            setPlans(plans)
          }

          return (
            <PGrid key={plan.code} style={{ padding: '1px' }}>
              <PGridItem size={10}>
                <div
                  data-testid={plan.code}
                  role='button'
                  className={`plan-wrapper ${planDisabled ? 'disabled' : ''}`}
                  tabIndex={(expandable && !expanded) || planDisabled ? -1 : 0}
                  onClick={() => onPlanClick()}
                  onKeyPress={() => onPlanClick()}
                  {...getBalloonProps(plan, subPlans, intl, enableNewSelectionOfAncillaryProducts)}
                >
                  <PCheckboxWrapper key={plan.code} style={{ padding: '3px 0 3px 3px', pointerEvents: 'none' }}>
                    <input
                      tabIndex={-1}
                      type='checkbox'
                      name='ancillaryPlanSelection'
                      value={plan.code}
                      aria-label={plan.name}
                      disabled={planDisabled}
                      checked={[APSelectionType.USER_SELECTED, APSelectionType.AUTO_SELECTED].includes(plan.selection)}
                      onChange={(e) => e.preventDefault()} // required, otherwise console error
                    />
                    <PText
                      weight='bold'
                      style={{ paddingLeft: '8px' }}
                      color={planDisabled ? 'neutral-contrast-medium' : 'default'}
                    >
                      {plan.name}
                    </PText>
                  </PCheckboxWrapper>
                </div>
              </PGridItem>
              <PGridItem size={2} style={{ textAlign: 'right' }}>
                <PButtonPure
                  icon='information'
                  aria-label='Information icon'
                  data-testid={`informationIcon_${plan.code}`}
                  tabIndex={expandable && !expanded ? -1 : 0}
                  style={{ paddingLeft: '8px', margin: '1px' }}
                  onClick={() => {
                    trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_PLAN_INFO(plan.code))
                    setPlanDescription(plan)
                    setPlanDescriptionModalOpen(true)
                  }}
                  onKeyPress={() => {
                    trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_PLAN_INFO(plan.code))
                    setPlanDescription(plan)
                    setPlanDescriptionModalOpen(true)
                  }}
                />
              </PGridItem>
              <PGridItem size={12}>
                <PText style={{ paddingLeft: '35px' }} color={planDisabled ? 'neutral-contrast-medium' : 'default'}>
                  {childPlansString}
                </PText>
              </PGridItem>
              <PGridItem size={12}>
                <PDivider className='small-space-top small-space-bottom' />
              </PGridItem>
            </PGrid>
          )
        })}
      </div>
      {expandable && (
        <PFlex
          tabIndex={0}
          role='button'
          direction='column'
          alignItems='center'
          className='medium-space-bottom'
          style={{ cursor: 'pointer' }}
          onMouseEnter={() => setExpandButtonHighlighted(true)}
          onFocus={() => setExpandButtonHighlighted(true)}
          onBlur={() => setExpandButtonHighlighted(false)}
          onMouseLeave={() => setExpandButtonHighlighted(false)}
          onClick={() => {
            trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_CLICK_BUTTON(showLessOrMore))
            setExpanded(!expanded)
          }}
          onKeyPress={() => {
            trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_CLICK_BUTTON(showLessOrMore))
            setExpanded(!expanded)
          }}
        >
          {expanded && (
            <PFlexItem>
              <ExpandIcon name='arrow-head-up' />
            </PFlexItem>
          )}
          {/* Using 'FormattedMessage' tag is way too slow and causes stuttering during animation  */}
          <PFlexItem>
            <PText
              weight='bold'
              color='inherit'
              style={{ color: expandButtonHighlighted ? color.state.hover : 'inherit' }}
            >
              {intl.formatMessage({ id: expanded ? 'ancillaryProducts.seeLess' : 'ancillaryProducts.seeMore' })}
            </PText>
          </PFlexItem>
          {!expanded && (
            <PFlexItem>
              <ExpandIcon name='arrow-head-down' />
            </PFlexItem>
          )}
        </PFlex>
      )}
    </>
  )
}

export const PlansList: React.FC<{ ancillaryProductsMap: AncillaryProductsMap; setPlans: Function }> = ({
  ancillaryProductsMap,
  setPlans
}) => {
  const [planDescription, setPlanDescription] = useState<IAncillaryPlanOption | undefined>(undefined)
  const [planDescriptionModalOpen, setPlanDescriptionModalOpen] = useState(false)
  const [violationMessage, setViolationMessage] = useState<string | undefined>(undefined)
  const fragmentProps = {
    ancillaryProductsMap,
    setPlans,
    setPlanDescription,
    setPlanDescriptionModalOpen,
    setViolationMessage
  }

  return (
    <>
      <PlansSubList {...fragmentProps} />
      {ancillaryProductsMap.size > FIRST_SUBLIST_LENGTH && <PlansSubList expandable {...fragmentProps} />}

      <PlanDescriptionModal
        plan={planDescription}
        open={planDescriptionModalOpen}
        onClose={() => setPlanDescriptionModalOpen(false)}
      />
      {violationMessage && (
        <PlanViolationModal
          message={violationMessage}
          open={!!violationMessage}
          onClose={() => {
            trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_CLOSE_CONFLICT_MODAL)
            setViolationMessage(undefined)
          }}
        />
      )}
    </>
  )
}
