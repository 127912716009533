import { FinancialProductView, OpportunityView, ProductView, VehicleView } from '@pdiatl/common-external-models'
import { config } from '../config'
import { SessionStorageService } from '../services/sessionStorageService'

export interface ICheckoutOpportunity extends OpportunityView {
  readonly vehicle: VehicleView
  readonly product: ProductView
}

export interface IFinanceOpportunity extends ICheckoutOpportunity {
  readonly financialProduct: FinancialProductView
}

export enum Flow {
  CASH = 'CASH',
  OWN_FINANCING = 'OWN_FINANCING',
  LEASE = 'LEASE',
  FINANCE = 'FINANCE'
}

export function isFinanceOpportunity(opportunity: OpportunityView): opportunity is IFinanceOpportunity {
  return !!opportunity.financialProduct
}

export function isLeaseOrFinance(opportunity: OpportunityView): boolean {
  return ['LSE', 'RTL'].includes(opportunity.financialProduct?.payload?.creditType)
}

export function isFinancePricingAvailable(data: ICheckoutOpportunity): boolean {
  return !!(data.financePricing && data.financePricing.vehicleFinanceCondition && data.financePricing.price)
}

export const IS_GUEST_STORAGE_KEY = 'isGuest'

export function isGuest(): boolean {
  return SessionStorageService.getPrefixedItem(IS_GUEST_STORAGE_KEY) === 'true'
}

export function isNewVehicle(opportunity: OpportunityView): boolean {
  const isNewConditionType = opportunity.vehicle?.conditionType === 'NEW'
  const isNewVehicleFinanceCondition = opportunity.financePricing?.vehicleFinanceCondition === 'New'

  return isNewConditionType && (config().isMarket('us') ? isNewVehicleFinanceCondition : true)
}

export function getPriceLabel(opportunity: OpportunityView): string {
  return isNewVehicle(opportunity) ? 'checkout.msrp.price' : 'checkout.price'
}

export function opportunityHasUnsavedData(opportunity: OpportunityView): boolean {
  const hasUnsavedGuestPersonalDetails = isGuest() && !!opportunity.customerProfile?.firstName
  const hasUnsavedManualTradeInData = opportunity.tradeInVehicle?.valuation?.provider === 'self'

  return hasUnsavedGuestPersonalDetails || hasUnsavedManualTradeInData
}

// because it's not possible to call `useFlags` hook from a function like this, flag value is cached here
// so we can minimise code repetition
let enableCheckout2Flag = false
export function isCheckout2Enabled(): boolean {
  return enableCheckout2Flag && config().isMarket('us')
}
export function setCheckout2Enabled(checkout2Enabled: boolean) {
  enableCheckout2Flag = checkout2Enabled
}

export function getFlow(opportunity: OpportunityView): Flow {
  const creditType = opportunity.financialProduct?.payload?.creditType

  if (creditType === 'RTL') {
    return Flow.FINANCE
  } else if (creditType === 'LSE') {
    return Flow.LEASE
  } else if (opportunity.financialProduct && !['LSE', 'RTL'].includes(creditType)) {
    return Flow.OWN_FINANCING
  } else {
    return Flow.CASH
  }
}

export function showCarfax(opportunity: OpportunityView, enableCarfaxInCheckout: boolean): boolean {
  return (
    config().isMarket('us') &&
    enableCarfaxInCheckout &&
    !isNewVehicle(opportunity) &&
    !!opportunity?.vehicle?.payload?.listingId
  )
}
