import { PContentWrapper } from '@porsche-design-system/components-react'
import React from 'react'
import { LogoHeader } from '../../entry/LogoHeader/LogoHeader'
import { ConfirmationDetails } from '../shared/ConfirmationDetails'
import { OrderConfirmationBox } from '../shared/OrderConfirmationBox'
import { PclConfirmationHeader } from './PclConfirmationHeader'

export const PclConfirmationPage: React.FC = () => {
  return (
    <>
      <LogoHeader />
      <PContentWrapper width='basic'>
        <OrderConfirmationBox />
        <PclConfirmationHeader />
        <ConfirmationDetails />
        <div className='big-padding-bottom' />
      </PContentWrapper>
    </>
  )
}
