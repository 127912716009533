import debounce from 'lodash.debounce'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { PresenterContext } from '../../entry/presenterProvider'
import './WizardNavigation.scss'
import { isCheckout2Enabled } from '../../../common/checkout'
import { CheckoutNavigation } from './CheckoutNavigation'
import { Checkout2Navigation } from './Checkout2Navigation'
import { StepState } from '../../../presenter/stackManager'

const MAGIC_NAV_BAR_HEIGHT = 56

export const WizardNavigation: React.FC = () => {
  const presenter = useContext(PresenterContext)

  const stepNames = presenter.stackManager.steps.map((step) => step.title)
  const stepStates = presenter.stackManager.steps.map((step) => step.state)
  const stickyRef = useRef<HTMLSpanElement>(null)
  const [isSticky, setSticky] = useState<boolean>(false)

  const getActiveStepIndexFromScrollPosition = useCallback(() => {
    return stepStates
      .map((_, index) => document.getElementById(`wizard-step-${index + 1}`))
      .filter((_, index) => stepStates[index] !== StepState.CLEAR)
      .findIndex((elem) => (elem?.getBoundingClientRect())!.top > 0)
  }, [stepStates])

  useEffect(() => {
    const handleScroll = debounce(
      () => {
        stickyRef.current !== null &&
        window.pageYOffset > stickyRef.current.getBoundingClientRect().top + MAGIC_NAV_BAR_HEIGHT
          ? setSticky(true)
          : setSticky(false)
      },
      100,
      { maxWait: 100 }
    )

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [getActiveStepIndexFromScrollPosition])

  const navProps = { stepNames, isSticky, stickyRef }
  return isCheckout2Enabled() ? <Checkout2Navigation {...navProps} /> : <CheckoutNavigation {...navProps} />
}
