import {
  AncillaryProductsView,
  APSelectionType,
  CustomerProfileView,
  FinancialProductView,
  IAncillaryPlanOption,
  OrderInfoView,
  TradeInVehicleView
} from '@pdiatl/common-external-models'
import { action, computed, makeObservable, observable } from 'mobx'
import { User } from 'oidc-client'
import { ICheckoutOpportunity } from '../common/checkout'
import OpportunityService from './opportunityService'

export class OpportunityStore {
  @observable
  private opportunity: ICheckoutOpportunity | null = null

  private requestInProgress: Promise<ICheckoutOpportunity> | undefined = undefined

  constructor(private id: string, private user: User | null, private opportunityService: OpportunityService) {
    makeObservable(this)
  }

  @computed
  get opportunityCache() {
    this.checkOpportunityExists()
    return this.opportunity!
  }

  get opportunityId() {
    return this.id
  }

  async getOpportunity(id?: string, refresh = false): Promise<ICheckoutOpportunity> {
    // reset cache and in-progress if needed
    if (id) {
      if (this.opportunity?.id !== id) {
        this.opportunity = null
      }
      if ((this.requestInProgress && this.id !== id) || refresh) {
        this.requestInProgress = undefined
      }
      this.id = id
    }

    if (!this.requestInProgress) {
      this.requestInProgress = this.loadOpportunity(this.id)
      this.opportunity = await this.requestInProgress
    }
    return this.requestInProgress
  }

  private async loadOpportunity(id: string): Promise<ICheckoutOpportunity> {
    if (!id) {
      throw new Error('Opportunity ID is not defined')
    }

    return this.opportunityService.fetch(id, this.user ?? undefined)
  }

  checkOpportunityExists() {
    if (!this.opportunity) {
      throw new Error('Opportunity is not defined')
    }
  }

  getOwner() {
    this.checkOpportunityExists()
    return this.opportunity!.owner
  }

  getVehicle() {
    this.checkOpportunityExists()
    return this.opportunity!.vehicle
  }

  get defaultErrorHandler() {
    return (e: Error) => console.log(e)
  }

  public getErrorHandler() {
    return this.defaultErrorHandler
  }

  @action
  public setTradeInVehicle(tradeInVehicle: TradeInVehicleView | undefined) {
    this.opportunity!.tradeInVehicle = tradeInVehicle
  }

  @action
  public setFinancialProduct(financialProduct: FinancialProductView | undefined) {
    this.opportunity!.financialProduct = financialProduct
  }

  @action
  public setCustomerProfile(customerProfile: CustomerProfileView | undefined) {
    this.opportunity!.customerProfile = customerProfile
  }

  @action
  public setOrderInformation(orderInformation: OrderInfoView | undefined) {
    this.opportunity!.orderInformation = orderInformation
  }

  @action
  public setAncillaryProducts(ancillaryProducts: IAncillaryPlanOption[] | undefined) {
    if (ancillaryProducts?.length) {
      const selectedAncillaryProducts: AncillaryProductsView = ancillaryProducts
        .filter((product) => product.selection === APSelectionType.USER_SELECTED)
        .map((product) => ({ name: product.name, code: product.code }))

      this.opportunity!.product.payload = {
        ancillaryProducts: selectedAncillaryProducts
      }
    } else {
      delete this.opportunity!.product.payload?.ancillaryProducts
    }
  }
}
