import { UserManagerSettings } from 'oidc-client'
import browserstackConfig from './browserstack'
import local from './local'
import devConfig from './dev'
import ppConfig from './pp'
import pConfig from './p'

export interface ITenantConfigPartial {
  ClientId: 'PORSCHEUS'
  ConsumerEnvironmentId: 'DEV' | 'PROD'
  ConsumerSystemId: 'CWP'
  TenantId: 'PORSCHE'
}

export type Market = 'us' | 'ca'
export type Environment = 'development' | 'preview' | 'production' | 'local'

export interface IAoacConfig {
  applicationURLPrefix: string
  submissionEndpoint: string
  tenantConfig: ITenantConfigPartial
}

export interface IDefiCalculatorConfig {
  endpointUrl: string
}

interface IPid {
  oauth: UserManagerSettings
  oauthDebug?: boolean
  signUpUrl: string
}

export interface ICheckoutConfig {
  showErrorPage: boolean
  environment: Environment
  launchDarklyApiKey: string
  refreshInterval: number
  // Show financial drill-down for lease and finance
  showConfirmationMyPorsche: boolean
  market: Market
  isMarket: (market: Market) => boolean
  // en-US formatted
  locale: string
  paymentStep: { enabled: boolean; environment: 'test' | 'production' }
  pid: IPid
  paymentService: {
    clientId: string
  }
  profileWidget: {
    clientId: string
  }
  profilePostfix: string
  apiEndpoint: string
  profile: {
    apiEndpoint: string
    country: string
    locale: string
  }
  defaultCarImage: string
  oneGaEnv: 'K' | 'PP' | 'P'
  aoac: IAoacConfig
  defiCalculator: IDefiCalculatorConfig
  vehicleHistory: {
    endpointUrl: string
  }
  fullStoryID: string
  customerSatisfactionSurveyLink: string
  cdnUrl: string
  addressAutocomplete: {
    apiEndpoint: string
    authToken: string
  }
  finder: {
    homePageURL: string
  }
}

export const DEFAULT_LOCALE = 'en_US'
export const DEFAULT_MARKET = 'us' as const

const ConfigMap: { [host: string]: ICheckoutConfig } = {
  localhost: local,
  'bs-local.com': browserstackConfig,
  'localhost.charlesproxy.com': local,
  'checkout-dev.slatldisal.aws.porsche-preview.cloud': devConfig,
  'carsales.porsche-preview.com': ppConfig,
  'carsales.porsche.com': pConfig
}

export function mapLocaleForProfile(locale: string) {
  switch (locale) {
    case 'fr_CA':
    case 'fr-CA':
      return 'fr_FR'
    default:
      return 'en_US'
  }
}

export function extractLocaleAndMarket(path: string) {
  if (path === '/') {
    return { locale: DEFAULT_LOCALE, market: DEFAULT_MARKET }
  }

  const pathArr = path.split('/')
  const market = pathArr[1]?.toLowerCase()
  const locale = pathArr[2]

  if (!locale || !market || locale.length !== 5) {
    return { locale: DEFAULT_LOCALE, market: DEFAULT_MARKET }
  }

  if (!['us', 'ca'].includes(market)) {
    throw new Error(`Unsupported market ${market}`)
  }

  return {
    market: market as Market,
    locale: locale.replace('_', '-')
  }
}

export function configForHost(host: string, configMap = ConfigMap): ICheckoutConfig {
  const envConfig = configMap[host]
  if (envConfig === undefined) {
    throw new Error(`Unknown host ${host}`)
  }
  return envConfig
}

export function getRedirectUri(url: string, market: string = DEFAULT_MARKET, locale: string = DEFAULT_LOCALE) {
  const MARKET = '$market'
  const LOCALE = '$locale'

  if (!url.includes(LOCALE) || !url.includes(MARKET)) {
    throw new Error('Locale/Market is missing')
  }

  return url?.replace(MARKET, market).replace(LOCALE, locale)
}

export function configForURL(path: string, config: ICheckoutConfig) {
  const { locale, market } = extractLocaleAndMarket(path)
  const profileLocale = mapLocaleForProfile(locale)
  const { redirect_uri: rUri } = config.pid.oauth
  if (!rUri) {
    throw new Error('oauth redirect_uri not defined')
  }

  const redirectUri = getRedirectUri(rUri, market, locale)

  return {
    ...config,
    locale,
    market,
    pid: {
      ...config.pid,
      oauth: {
        ...config.pid.oauth,
        extraQueryParams: {
          ...config?.pid?.oauth?.extraQueryParams,
          country: market,
          locale,
          int_ref: 'checkout_login_reg'
        },
        redirect_uri: redirectUri
      }
    },
    profile: {
      ...config.profile,
      locale: profileLocale,
      country: market
    }
  }
}
// The regular locals like fr_CA and en_CA are not supported by profile widget.
// Here is the list of supported locals: https://confluence.porsche.codes/pages/viewpage.action?spaceKey=PCCARCH&title=Language+and+Country+information

// context state (allows overrides in tests)
const coCtx = {
  configInstance: {} as ICheckoutConfig
}

// to be used in tests
export const resetConfig = (host: string = window.location.hostname) => {
  const hostConfig = configForHost(host)
  coCtx.configInstance = configForURL(window.location.pathname, hostConfig)
}

export const overrideConfig = (delta: Partial<ICheckoutConfig>) => {
  coCtx.configInstance = { ...coCtx.configInstance, ...delta }
}

export const config = () => {
  return coCtx.configInstance
}

resetConfig()
