import { StackElement, StackElementState } from '../stack/stackElement'
import { FAQList } from '../ported/faq'
import { Contact } from '../contact/contact'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { WizardNavigation } from '../ported/navigation'
import { PresenterContext } from '../entry/presenterProvider'
import { PHeadline, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { FinderBackButton } from '../../common/FinderBackButton'
import { UnsavedDataModal } from './UnsavedDataModal'
import { StepState } from '../../presenter/stackManager'

import './checkout.scss'

export const Steps: React.FC<{ elements: StackElement[] }> = observer(({ elements }) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const steps = presenter.stackManager.steps

  const stepComponents = elements.map((element, index) => {
    const step = steps[index]

    if (step.state !== StepState.CURRENT && element.visibleOnlyOnEdit) {
      return <></>
    }

    if (step.state === StepState.CURRENT) {
      const desired = element.checkDesiredState({ user: presenter.user, opportunity })

      if (desired < StackElementState.EDIT) {
        presenter.stackManager.editNext()
        return element.selectView(StackElementState.COMPLETED)
      } else {
        return element.selectView(StackElementState.EDIT)
      }
    } else if (step.state === StepState.COMPLETE) {
      return element.selectView(StackElementState.COMPLETED)
    } else {
      return element.selectView(StackElementState.LOCKED)
    }
  })

  return <>{stepComponents}</>
})

export const CheckoutContent: React.FC<{ elements: StackElement[] }> = observer(({ elements }) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  return (
    <>
      <WizardNavigation />
      <div>
        <FinderBackButton />
        <UnsavedDataModal />

        <PHeadline variant='headline-3' className='big-space-top' tag='h1'>
          <FormattedMessage id='checkout.begin' values={{ dealer: opportunity.owner?.displayName }} />
        </PHeadline>

        <PText size={{ base: 'small', m: 'medium' }} className='medium-space-top ada-text-width'>
          <FormattedMessage id='checkout.prompt' />
        </PText>
      </div>

      <Steps elements={elements} />

      <div className='big-space-top'>
        <FAQList />
      </div>

      <Contact />
    </>
  )
})
