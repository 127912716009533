import { ICheckoutConfig } from '.'
import { WebStorageStateStore } from 'oidc-client'
import { SCOPE, defaultConfig } from './default'

const baseUrl = 'https://carsales.porsche.com'

const apiEndpoint = 'https://api.porsche.com/checkout'

const OATH = {
  client_id: 'boFPvW3FzEdlQhaId9IyM8i0XkNv1Rsg',
  redirect_uri: `${baseUrl}/$market/$locale/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: baseUrl,
  scope: SCOPE,
  authority: 'https://login.porsche.com',
  silent_redirect_uri: `${baseUrl}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    country: 'us',
    locale: 'en_US'
  }
}

const pConfig: ICheckoutConfig = {
  ...defaultConfig,
  environment: 'production',
  pid: {
    oauth: {
      ...OATH,
      client_id: 'a2PnCKWborTIrKW3F9ug2TT8hytR0es4',
      authority: 'https://identity.porsche.com',
      extraQueryParams: {
        audience: 'https://api.porsche.com'
      }
    },
    signUpUrl: 'https://signup.porsche.com'
  },
  profileWidget: {
    clientId: 'boFPvW3FzEdlQhaId9IyM8i0XkNv1Rsg'
  },
  paymentService: {
    clientId: 'a2PnCKWborTIrKW3F9ug2TT8hytR0es4'
  },
  paymentStep: { enabled: true, environment: 'production' },
  profilePostfix: '',
  apiEndpoint,
  profile: {
    apiEndpoint: 'https://api.porsche.com/profiles',
    locale: 'en_US',
    country: 'us'
  },
  oneGaEnv: 'P',
  aoac: {
    tenantConfig: {
      ...defaultConfig.aoac.tenantConfig,
      ConsumerEnvironmentId: 'PROD'
    },
    submissionEndpoint: `${apiEndpoint}/pfso/api/Origination/SaveCreditAppFromFinder`,
    applicationURLPrefix: `https://pfsaccount.porscheusa.com/pfso`
  },
  defiCalculator: {
    endpointUrl: 'https://checkout-api.slatldisal.aws.porsche.cloud/pfso/AutoOrigination/'
  },
  vehicleHistory: {
    endpointUrl: 'https://vehiclehistoryreport.porsche.us'
  },
  customerSatisfactionSurveyLink: 'https://porsche.eu.qualtrics.com/jfe/form/SV_0DQLG39xIKQGVmd',
  addressAutocomplete: {
    apiEndpoint: 'https://experian-api.myatl.aws.porsche.cloud/address',
    authToken: '0542547e-c134-49a0-8c78-e3a96bb3a494'
  },
  finder: {
    homePageURL: 'https://finder.porsche.com'
  }
}

export default pConfig
