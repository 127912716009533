import { PText } from '@porsche-design-system/components-react'
import { formatCurrency } from '@slatldisal/checkout-i18n'
import React, { useContext } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { isGuest } from '../../common/checkout'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'
import { GraySimpleTile } from '../stack/GraySimpleTile'
import { StackElement, StackElementState } from '../stack/stackElement'
import { PaymentCard } from './paymentCard'

export const PAYMENT_STACK_NAME = 'payment'

const EditView: React.FC = () => {
  return <PaymentCard />
}

const LockedView: React.FC = () => {
  const intl = useIntl()

  return <GraySimpleTile prompt={intl.formatMessage({ id: 'payment.title.sub' })} />
}

const CompletedView: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const intl = useIntl()
  const opportunity = presenter.opportunityStore.opportunityCache
  const feeAmount = opportunity.orderInformation?.downPayment?.value ?? 0
  const card = opportunity?.customerProfile?.payload?.selectedCard

  return (
    <CompletedTile>
      <PText>{getStepTitle(card, feeAmount, intl)}</PText>
    </CompletedTile>
  )
}

export class PaymentStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedView,
      LockedView,
      () => StackElementState.EDIT,
      PAYMENT_STACK_NAME,
      index,
      label,
      'purchase',
      {
        analyticsPage: AnalyticsPage.PAYMENT,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.PAYMENT_STEP,
            matomoActionName: 'checkout/payment_method'
          }
        }
      }
    )
  }
}

function getStepTitle(
  card: { type?: string; displayName?: string } | undefined,
  feeAmount: number,
  intl: IntlShape
): string {
  const cardName = card?.type

  if (!cardName) {
    return intl.formatMessage({ id: 'payment.title.noReservation' })
  }

  const cardNumber = card.displayName!

  return intl.formatMessage(
    { id: 'payment.title.sub.card' },
    {
      cardDetails: isGuest() ? cardName : `${cardName} ${cardNumber}`,
      feeAmount: formatCurrency(feeAmount)
    }
  )
}
