import React from 'react'
import { PContentWrapper, PMarque } from '@porsche-design-system/components-react'
import './LogoHeader.scss'

export const LogoHeader: React.FC = () => {
  return (
    <header>
      <div>
        <PContentWrapper width='basic'>
          <div className='logo-container'>
            <PMarque />
          </div>
        </PContentWrapper>
      </div>
    </header>
  )
}
