import { config } from '../../config'
import { IAutocompleteAddress } from './AutocompleteAddress'

function parseUSAddress(parts: string[]): Partial<IAutocompleteAddress> {
  if (parts.length < 2) {
    return {}
  }
  const [houseNumber, ...remainder] = parts[0].split(' ').map((part) => part.trim())
  const street = remainder.join(' ')

  const cityStateZip = parts[1].split(' ').map((part) => part.trim())
  const city = cityStateZip.slice(0, cityStateZip.length - 2).join(' ')
  const [state, zipCode] = cityStateZip.slice(cityStateZip.length - 2)

  return {
    houseNumber,
    street,
    city,
    state,
    zipCode
  }
}

function parseCAAddress(parts: string[]): Partial<IAutocompleteAddress> {
  if (parts.length < 4) {
    return {}
  }
  const [houseNumber, ...remainder] = parts[0].split(' ').map((part) => part.trim())

  return {
    houseNumber,
    street: remainder.join(' '),
    city: parts[1],
    province: parts[2],
    zipCode: parts[3]
  }
}

// Example inputs:
// US:   1234 County Road 119, Abbeville AL 36310
// CA:   1111 GREEN LAKE SOUTH RD, 70 MILE HOUSE, BC, V0K 2K2
export function parseAddress(input: string): Partial<IAutocompleteAddress> {
  const parts = input.split(',').map((part) => part.trim())

  return config().isMarket('us') ? parseUSAddress(parts) : parseCAAddress(parts)
}
