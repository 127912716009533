import { PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFlags } from '../../common/flags'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'
import { GraySimpleTile } from '../stack/GraySimpleTile'
import { StackElement, StackElementState } from '../stack/stackElement'
import { TradeIn } from './tradeIn'

export const TRADEIN_STACK_NAME = 'tradein'

const LockedView: React.FC = () => {
  const intl = useIntl()

  return <GraySimpleTile prompt={intl.formatMessage({ id: 'tradein.title.sub' })} />
}

const CompletedView: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const { tradeInVehicle } = presenter.opportunityStore.opportunityCache
  const noTradeInProvided = tradeInVehicle?.notes === 'NO_TRADEIN_PROVIDED'

  return (
    <CompletedTile>
      <PText>
        {tradeInVehicle && !noTradeInProvided
          ? intl.formatMessage({ id: 'tradein.complete.yes' })
          : intl.formatMessage({ id: 'tradein.complete.no' })}
      </PText>
    </CompletedTile>
  )
}

const EditView: React.FC = () => {
  const intl = useIntl()
  const { enableKbbTradeIn } = useFlags()

  return (
    <TradeIn
      loggedIn
      edit={{
        message: enableKbbTradeIn
          ? intl.formatMessage({ id: 'tradein.title.sub' })
          : 'If you have a vehicle to trade in, we will capture your current vehicle information ' +
            'so we can provide a detailed evaluation to you afterwards.'
      }}
      kbbValuationEnabled={enableKbbTradeIn}
    />
  )
}

export class TradeInStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedView,
      LockedView,
      () => StackElementState.EDIT,
      TRADEIN_STACK_NAME,
      index,
      label,
      'switch',
      {
        analyticsPage: AnalyticsPage.TRADE_IN,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.TRADE_IN,
            matomoActionName: 'checkout/trade_in'
          }
        }
      }
    )
  }
}
