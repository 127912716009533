import React, { useState } from 'react'
import { FilterAutocomplete } from '../filterAutocomplete/filterAutocomplete'
import { config, Market } from '../../config'
import { parseAddress } from './addressParser'
import { useIntl } from 'react-intl'

export interface IAutocompleteAddress {
  readonly houseNumber: string
  readonly street: string
  readonly city: string
  readonly state: string
  readonly province: string
  readonly zipCode: string
}

export interface IAutocompleteAddressProps {
  country: Market
  addressSelected: (address: Partial<IAutocompleteAddress>) => void
}

async function search(country: Market, search: string): Promise<string[]> {
  const { apiEndpoint, authToken } = config().addressAutocomplete

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authtoken: authToken
    },
    body: JSON.stringify({ country: country.toLowerCase(), search })
  }
  const fetchResult = await fetch(`${apiEndpoint}/search`, options)
  const items = await fetchResult.json()

  return items.map((item: any) => item.text)
}

export const AutocompleteAddress: React.FC<IAutocompleteAddressProps> = (props) => {
  const [proposedAddresses, setProposedAddresses] = useState([] as string[])

  const [value, setValue] = useState<string>('')

  const intl = useIntl()

  const onInputChanged = async (newValue: string) => {
    setValue(newValue)
    if (newValue.length > 2) {
      const items = await search(props.country, newValue)
      setProposedAddresses(items)
    }
  }

  return (
    <FilterAutocomplete
      query={value}
      onQueryChange={onInputChanged}
      label={intl.formatMessage({ id: 'profile.startWithYourAddress' })}
      placeholder=''
      onOptionSelect={(v) => {
        if (v) {
          setValue(v.value)
          setProposedAddresses([])
          props.addressSelected(parseAddress(v.value))
        }
      }}
      options={proposedAddresses.map((address) => {
        return { label: address, value: address }
      })}
    />
  )
}
