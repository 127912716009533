import { PFlex, PFlexItem, PHeadline, PIcon, PText } from '@porsche-design-system/components-react'

import { getCurrentPage, IAnalyticsClickEventTemplate, trackClickEvent } from '@slatldisal/one-ga'
import React, { PropsWithChildren, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../entry/presenterProvider'
import { AppendixSpacing } from '../ported/shared/AppendixSpacing'
import './contact.scss'
import { getDealerContactEndHour, getDealerContactStartHour } from './dealerContactHours'

interface IContactCardProps extends PropsWithChildren {
  title: string
  phone: string
  className: string
  analyticsTemplate?: IAnalyticsClickEventTemplate
}

const ContactCard: React.FC<IContactCardProps> = ({ title, phone, className, analyticsTemplate, children }) => {
  const reportAnalytics = analyticsTemplate
    ? () => {
        trackClickEvent({
          //
          ...analyticsTemplate,
          page: getCurrentPage()
        })
      }
    : (): null => null

  return (
    <PFlex className={className} direction='column'>
      <PHeadline variant='headline-4'>{title}</PHeadline>
      <PText weight='thin' size='medium'>
        <a
          href={`tel:${phone}`}
          style={{ textDecoration: 'none' }}
          onClick={reportAnalytics}
          aria-label={`Phone number: ${phone}`}
        >
          <PIcon name='phone' size='medium' />
          {phone}
        </a>
      </PText>
      <>{children}</>
    </PFlex>
  )
}

export const Contact: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  return (
    <div className='contact big-space-top'>
      <AppendixSpacing>
        <PHeadline variant='headline-3'>
          <FormattedMessage id='contact.title' />
        </PHeadline>
        <PText className='medium-space-top'>
          <FormattedMessage id='contact.prompt' />
        </PText>

        <PFlex direction={{ base: 'column', m: 'row' }}>
          <PFlexItem>
            <ContactCard
              title={opportunity.owner?.displayName ?? '(no name)'}
              phone={opportunity.vehicle?.payload?.dealerPhone}
              analyticsTemplate={AnalyticsEventTemplates.CONTACT_CALL_DEALER}
              className='card box-padding'
            >
              <PText>
                <FormattedMessage
                  id='contact.hours'
                  values={{
                    startHour: getDealerContactStartHour(),
                    endHour: getDealerContactEndHour(opportunity.owner?.displayName)
                  }}
                />
              </PText>
              {opportunity.vehicle?.payload?.dealerAddress ? (
                <PText>
                  <FormattedMessage
                    id='contact.address'
                    values={{ dealerAddress: opportunity.vehicle.payload.dealerAddress }}
                  />
                </PText>
              ) : null}
            </ContactCard>
          </PFlexItem>
        </PFlex>
      </AppendixSpacing>
    </div>
  )
}
