import { PGrid, PText } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { useFlags } from '../../../../common/flags'
import { Currency } from '../../../confirmation/shared/ui'
import { PresenterContext } from '../../../entry/presenterProvider'
import { PAYMENT_STACK_NAME } from '../../../payments/paymentStackElement'
import { AccordionStepData, ClearStepData, isStepDataShown, IStepData, StepDataEditButton, StepDataRow } from './common'

export const PaymentsStepData: React.FC<IStepData> = observer(({ closeModalFn }) => {
  const intl = useIntl()
  const { enablePcnaPaymentStep } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const stackManager = presenter.stackManager
  const payments = opportunity.customerProfile?.payload?.selectedCard
  const heading = intl.formatMessage({ id: 'common.reservation.header' })
  const isDepositJourney = presenter.isDepositJourney(enablePcnaPaymentStep)

  if (!isDepositJourney) {
    return <></>
  }

  if (!isStepDataShown(PAYMENT_STACK_NAME, stackManager) || !payments) {
    return <ClearStepData heading={heading} />
  }

  return (
    <AccordionStepData heading={heading}>
      <PGrid>
        <StepDataRow labelKey='review.payment.cardSelected'>
          {payments.type} {payments.displayName}
        </StepDataRow>
        <StepDataRow labelKey='payment.title'>
          <PText data-testid='summaryPaymentValue'>
            <Currency
              value={opportunity.orderInformation?.downPayment?.value ?? 0}
              currency={opportunity.product?.price?.currencyCode}
            />
          </PText>
        </StepDataRow>

        <StepDataRow>
          <StepDataEditButton
            editStack={PAYMENT_STACK_NAME}
            editTitle='Edit Reservation Details'
            closeModalFn={closeModalFn}
          />
        </StepDataRow>
      </PGrid>
    </AccordionStepData>
  )
})
