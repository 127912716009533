import {
  PButtonPure,
  PDivider,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PHeadline,
  PText
} from '@porsche-design-system/components-react'
import React, { PropsWithChildren, useContext } from 'react'
import { IFinanceOpportunity } from '../../../common/checkout'
import { ReviewSection } from '../../confirmation/shared/ui'
import { formatCurrency } from '@slatldisal/checkout-i18n'
import { PresenterContext } from '../../entry/presenterProvider'
import { IFinanceOpportunityRender } from '../../confirmation/shared/IOpportunityRender'

export const BaseReviewDetails: React.FC<
  PropsWithChildren<{
    opportunity: IFinanceOpportunity
    monthlyPayment: number
  }>
> = (props) => {
  return (
    <ReviewSection title='Detailed Summary'>
      <PGrid>
        <PGridItem size={6}>
          <PHeadline variant='headline-4' tag='h3' data-testid='summaryEstimatedMonthlyPaymentTitle'>
            Estimated monthly payment
          </PHeadline>
        </PGridItem>
        <PGridItem size={6} data-testid='monthlyPaymentSummary'>
          <PHeadline
            variant='headline-4'
            align='right'
            className='small-space-right'
            data-testid='summaryEstimatedMonthlyPaymentValue'
          >
            {formatCurrency(props.monthlyPayment) ?? 0}/mo
          </PHeadline>
        </PGridItem>

        <UpdateFinanceSection {...props} />

        <PGridItem size={12}>
          <PDivider />
        </PGridItem>

        {props.children}
      </PGrid>
    </ReviewSection>
  )
}

const UpdateFinanceSection: React.FC<IFinanceOpportunityRender> = () => {
  const presenter = useContext(PresenterContext)
  return (
    <PGridItem size={12}>
      <PFlex direction='row-reverse' className='xsmall-space-top xsmall-space-bottom small-space-right'>
        <PFlexItem>
          <PButtonPure
            icon='edit'
            title='Edit button'
            onClick={() => presenter.stackManager.setCurrentEditIndex(0)}
            weight='bold'
            size='small'
          >
            <PText weight='bold'>Edit</PText>
          </PButtonPure>
        </PFlexItem>
      </PFlex>
    </PGridItem>
  )
}
