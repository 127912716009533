export const styleConfiguration = {
  clientColors: {
    primary: {
      '50': '#ff4d4d',
      '100': '#ff3334',
      '200': '#ff1a1b',
      '300': '#ff0001',
      '400': '#e60001',
      '500': '#cc0001',
      '600': '#b30001',
      '700': '#990001',
      '800': '#800001',
      '900': '#660000',
      A100: '#ff6667',
      A200: '#ff8080',
      A400: '#ff9999',
      A700: '#4d0000',
      contrastDefaultColor: 'light', // whether, by default, text (contrast) // on this palette should be dark or light
      contrastDarkColors: ['50', '100', '200', '300', '400', 'A100'], // hues which contrast should be 'dark' by default
      contrastLightColors: undefined // could also specify this if default was 'dark'
    },
    secondary: {
      '50': '#020203',
      '100': '#0e0f10',
      '200': '#1a1b1e',
      '300': '#26282b',
      '400': '#323439',
      '500': '#3e4146',
      '600': '#565962',
      '700': '#62666f',
      '800': '#6e727d',
      '900': '#7a7f8a',
      A100: '#565962',
      A200: '#4a4d54',
      A400: '#3e4146',
      A700: '#888c96',
      contrastDefaultColor: 'light', // whether, by default, text (contrast) // on this palette should be dark or light
      contrastDarkColors: ['50', '100', '200', '300', '400', 'A100'], // hues which contrast should be 'dark' by default
      contrastLightColors: undefined // could also specify this if default was 'dark'
    }
  }
}
