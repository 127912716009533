import React, { useEffect } from 'react'
import { PContentWrapper, PHeadline, PText, PLink } from '@porsche-design-system/components-react'
import { config } from '../../../config'
import { FormattedMessage } from 'react-intl'
import { LogoHeader } from '../LogoHeader/LogoHeader'

export const PageNotFound = () => {
  useEffect(() => {
    if (window && (window as any).newrelic) {
      ;(window as any).newrelic.addPageAction('Page Not Found', { error: '404' })
    }
  }, [])
  return (
    <>
      <header>
        <LogoHeader />
      </header>
      <PContentWrapper>
        <PHeadline
          align='center'
          variant='headline-2'
          className='huge-space-top small-space-bottom'
          style={{ fontSize: '1.125rem' }}
        >
          <FormattedMessage id='checkout.404.header' />
        </PHeadline>
        <PText align='center' className='large-space-bottom' size='inherit' style={{ fontSize: '1.125rem' }}>
          <FormattedMessage id='checkout.404.sub' />
        </PText>

        <div style={{ textAlign: 'center' }} className='big-space-top big-space-bottom'>
          <PLink href={config().finder.homePageURL} style={{ margin: 'auto' }}>
            <FormattedMessage id='checkout.404.cta' />
          </PLink>
        </div>

        <img src={`${config().cdnUrl}/static/image/404.png`} width='100%' alt='404 page not found' />
      </PContentWrapper>
    </>
  )
}
