import React, { useContext } from 'react'
import { PGrid, PGridItem, PHeadline, PText } from '@porsche-design-system/components-react'

import { Currency, DownPayment } from '../shared/ui'
import { PresenterContext } from '../../entry/presenterProvider'
import { formatMiles } from '@slatldisal/checkout-i18n'
import { IFinanceOpportunity } from '../../../common/checkout'
import { FormattedMessage } from 'react-intl'

export const PricingAndPayment: React.FC<{
  monthlyPayment: number
  currency: string
}> = ({ monthlyPayment, currency }) => {
  return (
    <>
      <PGridItem size={8}>
        <PHeadline
          className='small-space-bottom'
          variant='headline-4'
          tag='h3'
          data-testid='confirmationEstimatedMonthlyPaymentTitle'
        >
          <FormattedMessage id='common.monthlyPayment' />
          **
        </PHeadline>
      </PGridItem>
      <PGridItem size={4}>
        <PHeadline variant='headline-4' align='right' data-testid='confirmationEstimatedMonthlyPaymentValue'>
          <Currency value={monthlyPayment} currency={currency} />
          /mo
        </PHeadline>
      </PGridItem>
    </>
  )
}

const DEFAULT_FINANCIALS = { annualMileage: 0, monthlyLeasePayment: 0, term: 0 }

export const Lease: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache as IFinanceOpportunity

  const { annualMileage, monthlyLeasePayment, term } = opportunity.financialProduct?.payload ?? DEFAULT_FINANCIALS
  const { mileageUnit } = opportunity.vehicle?.payload ?? { mileageUnit: 'MILES' }

  const mileage = formatMiles({ numericValue: annualMileage * 1000, unitCode: mileageUnit })

  const currency = opportunity.product?.price?.currencyCode

  return (
    <PGridItem size={{ base: 12, m: 7 }}>
      <PGrid>
        <PricingAndPayment monthlyPayment={monthlyLeasePayment} currency={currency} />

        <PGridItem size={8}>
          <PText data-testid='confirmationAnnualMilageTitle'>
            <FormattedMessage id='common.annualMileage' />
          </PText>
        </PGridItem>
        <PGridItem size={4}>
          <PText align='right' data-testid='confirmationAnnualMilageValue'>
            {mileage}
          </PText>
        </PGridItem>

        <PGridItem size={8}>
          <PText data-testid='confirmationTermTitle'>
            <FormattedMessage id='common.term' />
          </PText>
        </PGridItem>
        <PGridItem size={4}>
          <PText align='right' data-testid='confirmationTermValue'>
            <FormattedMessage id='confirmation.finance.termMonths' values={{ term }} />
          </PText>
        </PGridItem>

        <DownPayment opportunity={opportunity} />
      </PGrid>
    </PGridItem>
  )
}
