import { PFlex, PFlexItem, PGrid, PGridItem, PText, TextAlign } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren, useContext } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { getPriceLabel } from '../../../../common/checkout'
import { AnalyticsPage, MsrpElementId } from '../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../entry/presenterProvider'
import { MsrpPricingSection } from '../../../vehicleSelection/cash/CashVehicleSelection'
import { OpportunityVehicleSelectionImage } from '../../../vehicleSelection/vehicleSelectionImage/VehicleSelectionImage'

import { VehicleSummaryStepsData } from '../VehicleSummaryStepsData'
import { VehicleSummaryYourPorscheModel } from './VehicleSummaryYourPorscheModel'
import { PorscheApprovedSection } from '../../porscheApproved/PorscheApprovedSection'

export const CashVehicleSummaryDisclaimer: React.FC<{ className?: string; align?: TextAlign }> = ({
  className = '',
  align = 'left'
}) => {
  return (
    <PText
      color='inherit'
      className={`neutral-contrast-medium medium-space-bottom ${className}`}
      align={align}
      data-testid='summaryLegalDisclaimer'
    >
      <FormattedMessage id='vehicleSelection.legal1' /> <FormattedMessage id='vehicleSelection.legal2' />
    </PText>
  )
}

export const CashVehicleSummaryShell: React.FC<PropsWithChildren<{}>> = observer(({ children }) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  return (
    <>
      <PGrid className='xsmall-space-bottom'>
        <PGridItem size={12}>
          <VehicleSummaryYourPorscheModel />
        </PGridItem>
        <PGridItem size={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <PFlex alignItems='center'>
            <PFlexItem>
              <PText size='medium' weight='semibold' align='left' data-testid='summaryVehiclePrice'>
                <FormattedNumber
                  value={opportunity.product?.price?.value ?? 0}
                  style='currency'
                  currency={opportunity.product?.price?.currencyCode}
                  currencyDisplay='narrowSymbol'
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </PText>
            </PFlexItem>
            <PFlexItem>
              <div className='small-space-left' data-testid='summaryVehicleDisclaimer'>
                <FormattedMessage id={getPriceLabel(opportunity)} />
              </div>
            </PFlexItem>
          </PFlex>
          <MsrpPricingSection
            opportunity={opportunity}
            analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
            analyticsElementId={MsrpElementId.SIDEBAR_DESKTOP}
          />
          <PText className='xxsmall-space-top' data-testid='summaryVehicleDealer'>
            Dealer {opportunity.owner?.displayName}
          </PText>
          <PorscheApprovedSection analyticsPage={AnalyticsPage.DESKTOP_SIDEBAR} />
        </PGridItem>
        <PGridItem size={4}>
          <OpportunityVehicleSelectionImage
            viewMode='one-third-tile'
            opportunity={opportunity}
            className='small-space-top small-space-bottom'
            data-testid='summaryVehicleImage'
          />
        </PGridItem>
      </PGrid>

      {children}

      <CashVehicleSummaryDisclaimer />

      <VehicleSummaryStepsData />
    </>
  )
})

export const CashVehicleSummary: React.FC = () => {
  return <CashVehicleSummaryShell />
}
