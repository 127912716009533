import { APSelectionType, IAncillaryPlanOption } from '@pdiatl/common-external-models'
import {
  ElementType,
  getFinancialPayload,
  IAnalyticsClickEventTemplate,
  IAnalyticsFinancialProductProps,
  IAnalyticsPageLoadEventTemplate,
  setAdditionalProducts,
  setComponentForm,
  setFinancialPayload
} from '@slatldisal/one-ga'
import { ICheckoutOpportunity } from '../common/checkout'
import { CheckoutStorageKeys } from '../components/checkout/checkoutStorageKeys'
import { OneGaComponentLocation } from '../components/confirmation/shared/OneGaComponentLocation'
import { IDeliveryOptionsValues } from '../components/deliveryOption/DeliveryOptions'
import { ITradeInValues } from '../components/tradeIn/tradeinState'
import { CalculationResult, CreditScore } from '../components/vehicleSelection/pcna/leaseFinance/defiCalcFinanceData'
import { OwnFinancingOptionsType } from '../components/vehicleSelection/pcna/PaymentOptionDetails'
import { PaymentOptionType } from '../components/vehicleSelection/pcna/VehicleSelectionPaymentOptions'
import { SessionStorageService } from '../services/sessionStorageService'

export enum AnalyticsPage {
  CART = 'checkout/cart',
  EXPLORE_PAYMENTS = 'checkout/explore_payments',
  LOGIN = 'checkout/login',
  PERSONAL_DATA = 'checkout/personal_data',
  TRADE_IN = 'checkout/trade-in',
  PROTECTION_PLANS = 'checkout/protection_plans',
  PAYMENT = 'checkout/payment_method',
  DELIVERY_OPTIONS = 'checkout/delivery_options',
  ORDER_OVERVIEW = 'checkout/order_overview',
  ORDER_CONFIRMATION = 'checkout/order_confirmation',
  DESKTOP_SIDEBAR = 'checkout/desktop_sidebar',
  MOBILE_VEHICLE_SUMMARY = 'checkout/mobile_vehicle_summary',
  MOBILE_SLIDE_OUT = 'checkout/mobile_slide_out'
}

export enum AnalyticsDeliveryOptionsSelection {
  DELIVERY_TO_HOME = 'delivery_to_home',
  PICK_UP_AT_DEALERSHIP = 'pick_up_at_dealership'
}

export enum AnalyticsDeliveryOptionsBtnName {
  SAVE_AND_CONTINUE = 'save_and_continue',
  SKIP = 'skip'
}

const PORSCHE_APPROVED_MODAL = (page: AnalyticsPage): IAnalyticsPageLoadEventTemplate => ({
  page,
  action: 'PAGFinder_PorscheApproved_Load'
})

const WARRANTY_MODAL = (page: AnalyticsPage): IAnalyticsPageLoadEventTemplate => ({
  page,
  action: 'PAGFinder_Warranty_Load'
})

const LOAD_CART: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.CART,
  action: 'PAGFinder_Cart_Pageload'
}

const EXPLORE_PAYMENTS: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.EXPLORE_PAYMENTS,
  action: 'PAGFinder_ExplorePayments_Pageload'
}

const LOGIN: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.LOGIN,
  action: 'PAGFinder_Login_Pageload'
}

const PERSONAL_DATA: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.PERSONAL_DATA,
  action: 'PAGFinder_PersonalData_Pageload'
}

const TRADE_IN: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.TRADE_IN,
  action: 'PAGFinder_TradeIn_Pageload'
}

const PROTECTION_PLANS: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.PROTECTION_PLANS,
  action: 'PAGFinder_ProtectionPlans_Pageload'
}

const PROTECTION_PLANS_CONFLICT: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.PROTECTION_PLANS,
  action: 'PAGFinder_ProtectionPlansConflict_Load'
}

const DELIVERY_OPTIONS: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.DELIVERY_OPTIONS,
  action: 'PAGFinder_DeliveryOptions_Pageload'
}

const ORDER_OVERVIEW: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.ORDER_OVERVIEW,
  action: 'PAGFinder_OrderOverview_Pageload'
}

const ORDER_CONFIRMATION: IAnalyticsPageLoadEventTemplate = {
  page: AnalyticsPage.ORDER_CONFIRMATION,
  action: 'PAGFinder_OrderConfirmation_Pageload'
}

const wasLeaseFinanceFlowBefore = (): boolean => {
  const { productCategory } = getFinancialPayload()

  return productCategory === 'financing'
}

const resetComponentFormAfterLeaseFinanceFlow = () => {
  setComponentForm(undefined)
}

const resetFinancialPayloadAfterLeaseFinanceFlow = () => {
  const { cashPrice } = getFinancialPayload()
  setFinancialPayload({ cashPrice, productName: 'cash', productCategory: 'cash' })
}

const resetComponentFormAndFinancialPayloadAfterLeaseFinanceFlow = () => {
  resetComponentFormAfterLeaseFinanceFlow()
  resetFinancialPayloadAfterLeaseFinanceFlow()
}

export type PaymentSelectionType = Exclude<PaymentOptionType, undefined>

const EXPLORE_PAYMENTS_STEP_PAYMENT_SELECTION = (
  paymentSelection: PaymentSelectionType
): IAnalyticsClickEventTemplate => {
  let clickElementName

  switch (paymentSelection) {
    case 'leaseFinance':
      clickElementName = 'lease_finance'
      break
    case 'ownFinancing':
      clickElementName = 'bringyourown_finance'
      if (wasLeaseFinanceFlowBefore()) {
        resetComponentFormAndFinancialPayloadAfterLeaseFinanceFlow()
      }
      break
    default:
      clickElementName = 'cash'
      if (wasLeaseFinanceFlowBefore()) {
        resetComponentFormAndFinancialPayloadAfterLeaseFinanceFlow()
      }
  }

  return {
    action: 'PAGFinder_ExplorePayments_Click',
    clickElementId: 'payment_selection',
    clickElementName: clickElementName,
    clickElementType: 'radio',
    page: AnalyticsPage.EXPLORE_PAYMENTS
  }
}

export const resetFinance = () => {
  setFinancialPayload({
    ...getFinancialPayload(),
    ...{
      downpaymentValue: undefined,
      mileageNumber: undefined,
      financingDuration: undefined,
      interestEffective: undefined,
      paymentMonthly: undefined,
      productName: ''
    }
  })
}

export const buildFinanceDataOneGA = (financeData: CalculationResult): IAnalyticsFinancialProductProps => {
  let paymentMonthly = 0
  let productName = ''

  if (financeData.creditType) {
    if (financeData.creditType === 'LSE') {
      paymentMonthly = financeData.monthlyLeasePayment
      productName = 'lease'
    } else {
      paymentMonthly = financeData.monthlyFinancePayment
      productName = 'finance'
    }
  }

  const { downPayment, annualMileage, term, annualPercentageRate } = financeData

  return {
    downpaymentValue: downPayment,
    mileageNumber: annualMileage !== undefined ? 1000 * annualMileage : undefined,
    mileageUnit: 'miles',
    financingDuration: term,
    interestEffective: annualPercentageRate,
    paymentMonthly,
    productCategory: 'financing',
    productName
  }
}

const getFormFields1 = (creditScore: CreditScore | undefined) => {
  return {
    formFields1: [
      {
        fieldId: '01',
        fieldName: 'fico score',
        fieldValue: creditScore
      }
    ]
  }
}

const FINANCIAL_CLICK = (
  clickElementName: string,
  clickElementId: string,
  clickElementType: ElementType = 'button'
): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_ExplorePayments_Click',
  clickElementId,
  clickElementName,
  clickElementType,
  page: 'checkout/explore_payments'
})

const EXPLORE_PAYMENTS_STEP_CALCULATOR_INIT = (creditScore: CreditScore | undefined): IAnalyticsClickEventTemplate => {
  const componentForm = {
    ...getFormFields1(creditScore),
    formFields2: [
      {
        fieldId: '01',
        fieldName: 'offer type',
        fieldValue: 'default'
      }
    ]
  }

  setComponentForm(componentForm)
  return FINANCIAL_CLICK('update', 'fico_zip_selection', 'button')
}

const EXPLORE_PAYMENTS_STEP_CALCULATOR = (isLease: boolean, fd: CalculationResult): IAnalyticsClickEventTemplate => {
  const clickElementName = fd.source

  let elementId: string
  let elementName: string
  let elementType: ElementType = 'button'

  switch (clickElementName) {
    case 'FicoScore':
      elementId = 'fico_zip_selection'
      elementName = fd.creditScore ?? 'excellent'
      break
    case 'ZipCode':
      elementId = 'fico_zip_selection'
      elementName = 'zipcode'
      elementType = 'input_field'
      break

    case 'lock_monthly_lease':
      elementName = isLease ? 'lock_monthly_lease' : 'lock_monthly_finance'
      elementId = isLease ? 'leasing_calculator' : 'financing_calculator'
      elementType = 'icon'
      break

    case 'lock_annual_mileage':
    case 'lock_term':
      elementName = clickElementName
      elementId = isLease ? 'leasing_calculator' : 'financing_calculator'
      elementType = 'icon'
      break

    case 'go_to_finance':
    case 'go_to_lease':
      elementName = clickElementName
      elementId = isLease ? 'financing_calculator' : 'leasing_calculator'
      break

    case 'DownPayment':
      elementName = clickElementName.toLowerCase()
      elementType = 'input_field'
      elementId = isLease ? 'leasing_calculator' : 'financing_calculator'
      break

    default:
      elementName = clickElementName !== undefined ? clickElementName?.toLowerCase() : ''
      elementType = 'slider'
      elementId = isLease ? 'leasing_calculator' : 'financing_calculator'
  }

  return FINANCIAL_CLICK(elementName, elementId, elementType)
}

export const EXPLORE_PAYMENTS_STEP_OWN_FINANCING_DETAILS = (
  ownFinancingOptionType: OwnFinancingOptionsType
): IAnalyticsClickEventTemplate => {
  let clickElementName

  if (ownFinancingOptionType === 'applyingAnotherBank') {
    clickElementName = 'select_other_bank'
  } else {
    clickElementName = 'select_secure_finance'
  }

  return {
    action: 'PAGFinder_ExplorePayments_Click',
    clickElementId: 'bringyourown_finance_details',
    clickElementName: clickElementName,
    clickElementType: 'checkbox',
    page: AnalyticsPage.EXPLORE_PAYMENTS
  }
}

export const EXPLORE_PAYMENTS_STEP_SAVE_AND_CONTINUE = (
  fd: CalculationResult | undefined
): IAnalyticsClickEventTemplate => {
  // This applies only to lease/finance
  if (fd) {
    const componentForm = {
      ...getFormFields1(fd.creditScore),
      formFields2: [
        {
          fieldId: '01',
          fieldName: 'mileage',
          fieldValue: fd.annualMileage ? fd.annualMileage * 1000 : undefined
        },
        {
          fieldId: '02',
          fieldName: 'downpayment',
          fieldValue: fd.downPayment
        },
        {
          fieldId: '03',
          fieldName: 'duration',
          fieldValue: fd.term
        },
        {
          fieldId: '04',
          fieldName: 'monthlylease',
          fieldValue: fd.creditType === 'LSE' ? fd.monthlyLeasePayment : fd.monthlyFinancePayment
        }
      ]
    }

    setComponentForm(componentForm)
  }

  return {
    action: 'PAGFinder_ExplorePayments_Click',
    clickElementId: 'explore_payments',
    clickElementName: 'save_and_continue',
    clickElementType: 'button',
    page: AnalyticsPage.EXPLORE_PAYMENTS
  }
}

export const EXPLORE_PAYMENTS_STEP_SKIP = (): IAnalyticsClickEventTemplate => {
  if (wasLeaseFinanceFlowBefore()) {
    resetComponentFormAndFinancialPayloadAfterLeaseFinanceFlow()
  }

  return {
    action: 'PAGFinder_ExplorePayments_Click',
    clickElementId: 'explore_payments',
    clickElementName: 'skip',
    clickElementType: 'button',
    page: AnalyticsPage.EXPLORE_PAYMENTS
  }
}

const SELECTION_STEP_COMPLETE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_Cart_Click',
  clickElementName: 'confirm_and_continue',
  clickElementType: 'link',
  page: AnalyticsPage.CART
}

const CHECKOUT_BACK_CLICK: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_Cart_Click',
  clickElementName: 'checkout_back',
  clickElementType: 'link',
  page: AnalyticsPage.CART
}

const DEALER_INFO_CLICK: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_Cart_Click',
  clickElementName: 'dealer_info',
  clickElementType: 'link',
  page: AnalyticsPage.CART
}

const REVIEW_DEALER_INFO: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderOverview_Click',
  clickElementName: 'dealer_info',
  clickElementType: 'link', // order_confirmation
  page: AnalyticsPage.ORDER_OVERVIEW
}

const CONTINUE_AS_GUEST_STEP_COMPLETE_FOR_LOGIN_REGISTER: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_Login_Click',
  clickElementId: 'continue_as_guest',
  clickElementName: 'checkout_as_guest',
  clickElementType: 'link',
  page: AnalyticsPage.LOGIN
}

const LOGIN_STEP_COMPLETE_FOR_LOGIN_REGISTER = (
  clickElementName: 'login' | 'register'
): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_Login_Click',
    clickElementId: 'login_register',
    clickElementName,
    clickElementType: 'link',
    page: AnalyticsPage.LOGIN
  }
}

const ADDRESS_STEP_COMPLETE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_PersonalData_Click',
  clickElementName: 'personal_details_confirm',
  clickElementType: 'button',
  page: AnalyticsPage.PERSONAL_DATA
}

const ADDRESS_STEP_ADDR_SAVE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_PersonalData_Click',
  clickElementName: 'personal_details_address',
  clickElementType: 'button', // save
  page: AnalyticsPage.PERSONAL_DATA
}
const ADDRESS_STEP_PHONE_SAVE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_PersonalData_Click',
  clickElementName: 'personal_details_phone',
  clickElementType: 'button', // save
  page: AnalyticsPage.PERSONAL_DATA
}

const TRADE_IN_STEP_SKIP: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_TradeIn_Click',
  clickElementId: 'trade_in',
  clickElementName: 'skip',
  clickElementType: 'button',
  page: AnalyticsPage.TRADE_IN
}

const TRADE_IN_STEP_COMPLETE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_TradeIn_Click',
  clickElementId: 'trade_in',
  clickElementName: 'trade_in',
  clickElementType: 'button',
  page: AnalyticsPage.TRADE_IN
}

const TRADE_IN_STEP_TRUE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_TradeIn_Click',
  clickElementName: 'trade_in_true',
  clickElementType: 'radio',
  page: AnalyticsPage.TRADE_IN
}

const TRADE_IN_STEP_FALSE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_TradeIn_Click',
  clickElementName: 'trade_in_false',
  clickElementType: 'radio',
  page: AnalyticsPage.TRADE_IN
}

const TRADE_IN_STEP_VALUATION: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_TradeIn_Click',
  clickElementName: 'trade_in_kelley_blue_book',
  clickElementType: 'button', // radio
  page: AnalyticsPage.TRADE_IN
}

const PROTECTION_PLANS_STEP_PLAN_SELECTION = (clickElementName: string): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_ProtectionPlans_Click',
    clickElementId: 'protection_plans_listitem',
    clickElementName,
    clickElementType: 'checkbox',
    page: AnalyticsPage.PROTECTION_PLANS
  }
}

const PROTECTION_PLANS_STEP_PLAN_INFO = (clickElementName: string): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_ProtectionPlans_Click',
    clickElementId: 'protection_plans_listitem_info',
    clickElementName,
    clickElementType: 'button',
    page: AnalyticsPage.PROTECTION_PLANS
  }
}

const PROTECTION_PLANS_STEP_CLOSE_CONFLICT_MODAL: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_ProtectionPlansConflict_Click',
  clickElementId: 'protection_plans_conflict_layer',
  clickElementName: 'close',
  clickElementType: 'button',
  page: AnalyticsPage.PROTECTION_PLANS
}

const PROTECTION_PLANS_STEP_CLICK_BUTTON = (
  clickElementName: 'show_less' | 'show_more' | 'skip' | 'save_and_continue',
  plans?: IAncillaryPlanOption[]
): IAnalyticsClickEventTemplate => {
  if (clickElementName === 'skip' || clickElementName === 'save_and_continue') {
    setAdditionalProducts(plans?.filter((plan) => plan.selection === APSelectionType.USER_SELECTED))
  }

  return {
    action: 'PAGFinder_ProtectionPlans_Click',
    clickElementId: 'protection_plans',
    clickElementName,
    clickElementType: 'button',
    page: AnalyticsPage.PROTECTION_PLANS
  }
}

export function getGADeliveryOptionOnLoad(): AnalyticsDeliveryOptionsSelection {
  const deliveryOptionFromStorage = SessionStorageService.getAndParsePrefixedItem<IDeliveryOptionsValues>(
    CheckoutStorageKeys.DELIVERY_OPTIONS_VALUES
  )

  if (!deliveryOptionFromStorage) {
    // On step load, pick up at the dealership radio button is selected by default if nothing has been previously selected
    return AnalyticsDeliveryOptionsSelection.PICK_UP_AT_DEALERSHIP
  }

  return deliveryOptionFromStorage.selection === 'HOME'
    ? AnalyticsDeliveryOptionsSelection.DELIVERY_TO_HOME
    : AnalyticsDeliveryOptionsSelection.PICK_UP_AT_DEALERSHIP
}

const DELIVERY_OPTIONS_SELECTION = (
  clickElementName: AnalyticsDeliveryOptionsSelection
): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_DeliveryOptions_Click',
    clickElementId: 'delivery_options_selection',
    clickElementName,
    clickElementType: 'radio',
    page: AnalyticsPage.DELIVERY_OPTIONS
  }
}

const DELIVERY_OPTIONS_STEP_CLICK_BUTTON = (
  clickElementName: AnalyticsDeliveryOptionsBtnName
): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_DeliveryOptions_Click',
  clickElementId: 'delivery_options',
  clickElementName,
  clickElementType: 'button',
  page: AnalyticsPage.DELIVERY_OPTIONS
})

const REVIEW_STEP_COMPLETE = (opportunity: ICheckoutOpportunity): IAnalyticsClickEventTemplate => {
  let componentForm

  // Lease or Finance
  if (opportunity.financialProduct?.payload?.creditType) {
    componentForm = {
      formFields1: [
        {
          fieldId: '01',
          fieldName: 'fico score',
          fieldValue: opportunity.financialProduct?.payload?.creditScore
        }
      ],

      formFields2: [
        {
          fieldId: '01',
          fieldName: 'offer type',
          fieldValue: 'default'
        }
      ]
    }
  }

  const tradeInValues = SessionStorageService.getAndParsePrefixedItem<ITradeInValues>(
    CheckoutStorageKeys.TRADE_IN_VALUES
  )

  const valuesMap = {
    manual: 'standard',
    kbb: 'kelley_blue_book',
    none: 'none'
  }

  if (tradeInValues) {
    // Lease or Finance: need to add trade-in
    if (componentForm) {
      componentForm.formFields1.push({
        fieldId: '02',
        fieldName: 'trade_in',
        fieldValue: valuesMap[tradeInValues.selection]
      })
    } else {
      // Cash: there is no fico score/zip code for cash path, only trade-in
      componentForm = {
        formFields1: [
          {
            fieldId: '01',
            fieldName: 'trade_in',
            fieldValue: valuesMap[tradeInValues.selection]
          }
        ]
      }
    }
  }

  setComponentForm(componentForm)

  return {
    action: 'PAGFinder_OrderOverview_Click',
    clickElementId: 'order_confirmation',
    clickElementName: 'submit_my_request_to_the_dealer',
    clickElementType: 'button',
    page: AnalyticsPage.ORDER_OVERVIEW
  }
}

const REVIEW_MODAL_CLOSE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_ReviewModal_Click',
  clickElementId: 'review_modal',
  clickElementName: 'close_review_modal',
  clickElementType: 'button',
  page: AnalyticsPage.ORDER_OVERVIEW
}

const REVIEW_MODAL_CANCEL: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_ReviewModal_Click',
  clickElementId: 'review_modal',
  clickElementName: 'cancel_review_modal',
  clickElementType: 'button',
  page: AnalyticsPage.ORDER_OVERVIEW
}

const REVIEW_STEP_AOAC: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderOverview_Click',
  clickElementId: 'order_confirmation',
  clickElementName: 'apply_for_financing',
  clickElementType: 'button',
  page: AnalyticsPage.ORDER_OVERVIEW
}

const TERMS_COND_CHECKED: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderOverview_Click',
  clickElementName: 'terms_conditions_checked',
  clickElementType: 'checkbox', // order_confirmation
  page: AnalyticsPage.ORDER_OVERVIEW
}

const EXPLORE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderConfirmation_Click',
  clickElementName: '',
  clickElementType: 'button', // explore_the_world_linkouts
  page: AnalyticsPage.ORDER_CONFIRMATION
}

const CONFIRM_CALL_RETAILER: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderConfirmation_Click',
  clickElementId: 'contact',
  clickElementName: 'call_retailer',
  clickElementType: 'link',
  page: AnalyticsPage.ORDER_CONFIRMATION
}

const CONFIRM_STEP_AOAC: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderConfirmation_Click',
  clickElementId: 'order_confirmation',
  clickElementName: 'apply_for_financing',
  clickElementType: 'button',
  page: AnalyticsPage.ORDER_CONFIRMATION
}

const EXPLORE_ONE_SHOP: IAnalyticsClickEventTemplate = {
  ...EXPLORE,
  clickElementName: 'explore_porschecom_one_shop'
}
const EXPLORE_EXPERIENCE: IAnalyticsClickEventTemplate = {
  ...EXPLORE,
  clickElementName: 'explore_experience'
}

const PAYMENT = (skip = false): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_PaymentMethod_Click',
  clickElementId: 'payment_details',
  clickElementName: skip ? 'skip' : 'confirm_card_selection',
  clickElementType: 'button',
  page: AnalyticsPage.PAYMENT
})

const FAQ: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderOverview_Click',
  clickElementName: '', // Dynamic
  clickElementId: 'checkout_faq',
  page: '', // Dynamic
  clickElementType: 'link'
}

const CONTACT_CALL_DEALER: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_ContactCall_Click',
  clickElementName: 'call_retailer',
  clickElementId: 'contact',
  clickElementType: 'link',
  page: '' // Dynamic
}

const CONTACT_CALL_PORSCHE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_ContactCall_Click',
  clickElementName: 'call_porsche',
  clickElementId: 'contact',
  clickElementType: 'link',
  page: '' // Dynamic
}

const PAYMENT_STEP: IAnalyticsPageLoadEventTemplate = {
  action: 'PAGFinder_PaymentMethod_Pageload',
  page: AnalyticsPage.PAYMENT
}

const UPDATE_FINANCE: IAnalyticsClickEventTemplate = {
  action: 'PAGFinder_OrderOverview_Click',
  page: AnalyticsPage.ORDER_OVERVIEW,
  clickElementName: 'edit_financialcalculation',
  clickElementType: 'icon'
}

const UPDATE_RESERVATION = (
  oneGaComponentLocation: OneGaComponentLocation = OneGaComponentLocation.CHECKOUT_ORDER_OVERVIEW
): IAnalyticsClickEventTemplate => {
  let action, page

  if (oneGaComponentLocation === OneGaComponentLocation.CHECKOUT_ORDER_OVERVIEW) {
    action = 'PAGFinder_OrderOverview_Click'
    page = AnalyticsPage.ORDER_OVERVIEW
  } else {
    action = 'PAGFinder_OrderConfirmation_Click'
    page = AnalyticsPage.ORDER_CONFIRMATION
  }

  return {
    action,
    clickElementId: 'order_details',
    clickElementName: 'edit',
    clickElementType: 'link',
    page
  }
}

export enum MsrpElementId {
  DEFAULT = 'openmsrp',
  SIDEBAR_DESKTOP = 'openmsrp-sidebar-desktop',
  MOBILE = 'openmsrp-sidebar-mobile',
  SLIDEOUT_MOBILE = 'openmsrp-slideout-mobile'
}

const OPEN_MSRP = (page: AnalyticsPage, clickElementId: MsrpElementId): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_MSRP_Click',
    page,
    clickElementId,
    clickElementName: 'msrp-price',
    clickElementType: 'link'
  }
}

const CLOSE_MSRP = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_MSRP_Click',
  page,
  clickElementId: 'closemsrp',
  clickElementName: 'msrp-price',
  clickElementType: 'link'
})

const PORSCHE_APPROVED_CPO_BUTTON = (page: AnalyticsPage): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_PorscheApproved_Click',
    page,
    clickElementId: 'open_porsche_approved',
    clickElementName: 'porsche_approved_cpo_car',
    clickElementType: 'button'
  }
}

const PORSCHE_APPROVED_MODAL_LEARN_MORE = (page: AnalyticsPage): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_PorscheApproved_Click',
    page,
    clickElementId: 'porsche_approved_modal',
    clickElementName: 'learn_more',
    clickElementType: 'link'
  }
}

const PORSCHE_APPROVED_MODAL_CLOSE_BUTTON = (page: AnalyticsPage): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_PorscheApproved_Click',
    page,
    clickElementId: 'porsche_approved_modal',
    clickElementName: 'close',
    clickElementType: 'button'
  }
}

const WARRANTY_BUTTON = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_Warranty_Click',
  page,
  clickElementId: 'open_warranty_modal',
  clickElementName: 'warranty',
  clickElementType: 'button'
})

const WARRANTY_MODAL_CLOSE_BUTTON = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_Warranty_Click',
  page,
  clickElementId: 'warranty_modal',
  clickElementName: 'close',
  clickElementType: 'button'
})

const ALL_IN_PRICING_CHECKOUT = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_Cart_Click',
  page,
  clickElementId: 'price_details',
  clickElementName: 'see_total_retail_price_details',
  clickElementType: 'link'
})

const CLOSE_ALL_IN_PRICING_CHECKOUT = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_Cart_Click',
  page,
  clickElementId: 'price_summary_modal',
  clickElementName: 'close',
  clickElementType: 'button'
})

const ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_OrderOverview_Click',
  page,
  clickElementId: 'price_details',
  clickElementName: 'see_total_retail_price_details',
  clickElementType: 'link'
})

const CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_OrderOverview_Click',
  page,
  clickElementId: 'price_summary_modal',
  clickElementName: 'close',
  clickElementType: 'button'
})

const ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_OrderConfirmation_Click',
  page,
  clickElementId: 'price_details',
  clickElementName: 'see_total_retail_price_details',
  clickElementType: 'link'
})

const CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION = (page: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_OrderConfirmation_Click',
  page,
  clickElementId: 'price_summary_modal',
  clickElementName: 'close',
  clickElementType: 'button'
})

const LEARN_MORE_ICON_CLICK = (
  step: string,
  clickElementName: 'open_learn_more_modal' | 'close_learn_more_modal'
): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_LearnMore_Click',
    page: step,
    clickElementId: 'learn_more_icon',
    clickElementName,
    clickElementType: 'link'
  }
}

const EDIT_STEP_CLICK = (step: string): IAnalyticsClickEventTemplate => {
  return {
    action: 'PAGFinder_EditStep_Click',
    page: step,
    clickElementId: 'edit_step_data',
    clickElementName: 'edit',
    clickElementType: 'link'
  }
}

const AUTOCHECK_VEHICLE_DETAILS_CLICK = (analyticsPage: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_AutoCheckVehicleDetails_Click',
  clickElementId: 'open_autocheck_report',
  clickElementName: 'see_vehicle_history_report',
  clickElementType: 'link',
  page: analyticsPage
})

const CARFAX_VEHICLE_HISTORY_REPORT_CLICK = (analyticsPage: AnalyticsPage): IAnalyticsClickEventTemplate => ({
  action: 'PAGFinder_CarfaxVehicleHistoryReport_Click',
  clickElementId: 'open_carfax_report',
  clickElementName: 'see_vehicle_history_report',
  clickElementType: 'link',
  page: analyticsPage
})

export const AnalyticsEventTemplates = {
  LOAD_CART,

  OPEN_MSRP,
  CLOSE_MSRP,

  PORSCHE_APPROVED_CPO_BUTTON,
  PORSCHE_APPROVED_MODAL,
  PORSCHE_APPROVED_MODAL_LEARN_MORE,
  PORSCHE_APPROVED_MODAL_CLOSE_BUTTON,

  WARRANTY_BUTTON,
  WARRANTY_MODAL,
  WARRANTY_MODAL_CLOSE_BUTTON,

  EXPLORE_PAYMENTS,
  EXPLORE_PAYMENTS_STEP_PAYMENT_SELECTION,
  EXPLORE_PAYMENTS_STEP_OWN_FINANCING_DETAILS,
  EXPLORE_PAYMENTS_STEP_CALCULATOR_INIT,
  EXPLORE_PAYMENTS_STEP_CALCULATOR,
  EXPLORE_PAYMENTS_STEP_SKIP,
  EXPLORE_PAYMENTS_STEP_SAVE_AND_CONTINUE,

  CHECKOUT_BACK_CLICK,

  LOGIN,
  PERSONAL_DATA,
  TRADE_IN,
  PROTECTION_PLANS,
  PROTECTION_PLANS_CONFLICT,
  DELIVERY_OPTIONS,
  ORDER_OVERVIEW,
  ORDER_CONFIRMATION,

  PAYMENT_STEP,

  CONTACT_CALL_DEALER,
  CONTACT_CALL_PORSCHE,

  FAQ,

  REVIEW_DEALER_INFO,
  DEALER_INFO_CLICK,

  ADDRESS_STEP_ADDR_SAVE,
  ADDRESS_STEP_PHONE_SAVE,

  PAYMENT,

  TRADE_IN_STEP_TRUE,
  TRADE_IN_STEP_FALSE,
  TRADE_IN_STEP_VALUATION,

  TERMS_COND_CHECKED,

  SELECTION_STEP_COMPLETE,
  CONTINUE_AS_GUEST_STEP_COMPLETE_FOR_LOGIN_REGISTER,
  LOGIN_STEP_COMPLETE_FOR_LOGIN_REGISTER,
  ADDRESS_STEP_COMPLETE,
  TRADE_IN_STEP_SKIP,
  TRADE_IN_STEP_COMPLETE,
  PROTECTION_PLANS_STEP_PLAN_SELECTION,
  PROTECTION_PLANS_STEP_PLAN_INFO,
  PROTECTION_PLANS_STEP_CLOSE_CONFLICT_MODAL,
  PROTECTION_PLANS_STEP_CLICK_BUTTON,
  DELIVERY_OPTIONS_SELECTION,
  DELIVERY_OPTIONS_STEP_CLICK_BUTTON,
  REVIEW_MODAL_CLOSE,
  REVIEW_MODAL_CANCEL,
  REVIEW_STEP_COMPLETE,
  REVIEW_STEP_AOAC,

  UPDATE_FINANCE,
  UPDATE_RESERVATION,

  EXPLORE_EXPERIENCE,
  EXPLORE_ONE_SHOP,

  CONFIRM_STEP_AOAC,
  CONFIRM_CALL_RETAILER,

  ALL_IN_PRICING_CHECKOUT,
  ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION,
  ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW,

  CLOSE_ALL_IN_PRICING_CHECKOUT,
  CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION,
  CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW,

  LEARN_MORE_ICON_CLICK,
  EDIT_STEP_CLICK,

  AUTOCHECK_VEHICLE_DETAILS_CLICK,
  CARFAX_VEHICLE_HISTORY_REPORT_CLICK
}
