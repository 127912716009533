import React, { useState } from 'react'
import { DividerNeutralContrastLow } from '../StyledLightDivider/StyledLightDivider'
import { AppendixSpacing } from '../shared/AppendixSpacing'
import { PHeadline, PText, PAccordion } from '@porsche-design-system/components-react'
import { getCurrentPage, trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { config } from '../../../config'
import './FAQList.scss'

export interface IFaqItem {
  question: string
  answer: string
}

const trackFAQ = (faq: IFaqItem) => {
  const clickElementName = faq.question?.trim().toLowerCase().replace(/\W/g, '_')

  const event = { ...AnalyticsEventTemplates.FAQ, clickElementName, page: getCurrentPage() }
  trackClickEvent(event, { clickElementId: 'checkout_faq' })
}

function getFAQItems(translationKeyPrefix: string, number: number, intl: IntlShape) {
  const faqItems = []
  for (let i = 1; i <= number; i++) {
    faqItems.push({
      question: intl.formatMessage({ id: `${translationKeyPrefix}${i}q` }),
      answer: intl.formatMessage({ id: `${translationKeyPrefix}${i}a` })
    })
  }
  return faqItems
}

function getFAQCategory(translationKeyPrefix: string, number: number, intl: IntlShape) {
  return {
    [intl.formatMessage({ id: `${translationKeyPrefix}title` })]: getFAQItems(translationKeyPrefix, number, intl)
  }
}

function FAQCategory(name: string | undefined, questions: IFaqItem[]) {
  const [openAccordions, setOpenAccordions] = useState<Record<number, boolean>>({})

  return (
    <>
      {!!name && (
        <PHeadline className='small-space-top' variant='headline-5'>
          {name}
        </PHeadline>
      )}
      {questions
        .filter((faq: IFaqItem) => faq.question.trim())
        .map((faq: IFaqItem, index) => (
          <React.Fragment key={faq.question}>
            <PAccordion
              heading={faq.question}
              tag='h3'
              open={openAccordions[index]}
              onAccordionChange={() => setOpenAccordions({ ...openAccordions, [index]: !openAccordions[index] })}
              onClick={() => trackFAQ(faq)}
            >
              <PText>{faq.answer}</PText>
            </PAccordion>
            <DividerNeutralContrastLow />
          </React.Fragment>
        ))}
    </>
  )
}

export const FAQList: React.FC = () => {
  const intl = useIntl()
  const translations = config().isMarket('us')
    ? getFAQItems('faq.', 4, intl)
    : {
        ...getFAQCategory('faq.general.', 5, intl),
        ...getFAQCategory('faq.payment.', 4, intl),
        ...getFAQCategory('faq.cancellation.', 2, intl)
      }

  return (
    <AppendixSpacing>
      <PHeadline variant='headline-4' className='medium-space-bottom'>
        <FormattedMessage id='faq.title' />
      </PHeadline>
      <DividerNeutralContrastLow />
      {Array.isArray(translations)
        ? FAQCategory(undefined, translations)
        : Object.keys(translations).map((category) => FAQCategory(category, translations[category]))}
    </AppendixSpacing>
  )
}
