import { PText } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getPriceLabel } from '../../../../common/checkout'
import { formatLocalizedCurrencyNoFraction } from '../../../../helpers/format'
import { AnalyticsPage, MsrpElementId } from '../../../../onega/AnalyticsEventTemplates'
import { VehicleImage } from '../../../confirmation/shared/ui'
import { PresenterContext } from '../../../entry/presenterProvider'
import { MsrpPricingSection } from '../../../vehicleSelection/cash/CashVehicleSelection'
import { CashVehicleSummaryDisclaimer } from '../desktop/CashVehicleSummary'

export const CashVehicleSummaryShell: React.FC<PropsWithChildren<{}>> = observer(({ children }) => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const currency = opportunity.product.price.currencyCode
  const price = opportunity.product.price.value ?? 0

  return (
    <>
      <PText size='medium' weight='bold'>
        {formatLocalizedCurrencyNoFraction(intl, currency, price)}
      </PText>
      <PText>
        <FormattedMessage id={getPriceLabel(opportunity)} />
      </PText>

      <MsrpPricingSection
        opportunity={opportunity}
        analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
        analyticsElementId={MsrpElementId.SLIDEOUT_MOBILE}
      />

      <VehicleImage />

      {children}

      <CashVehicleSummaryDisclaimer />
    </>
  )
})

export const CashVehicleSummary: React.FC = () => {
  return <CashVehicleSummaryShell />
}
