import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  PButton,
  PButtonGroup,
  PDivider,
  PGrid,
  PGridItem,
  PHeadline,
  PText
} from '@porsche-design-system/components-react'
import { color, spacing } from '@porsche-design-system/utilities'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { APSelectionType, IAncillaryPlanOption, IAncillaryProduct } from '@pdiatl/common-external-models'
import { trackClickEvent } from '@slatldisal/one-ga'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { getFlow, isCheckout2Enabled } from '../../common/checkout'
import { config } from '../../config'
import { generateQuestions } from '../../helpers/format'
import { getMatomoAncillaryProductsSelection, matomoPcnaTrackClickEvent } from '../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import { SessionStorageService } from '../../services/sessionStorageService'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { CheckoutDivider } from '../checkoutDivider/checkoutDivider'
import { PresenterContext } from '../entry/presenterProvider'
import { Questions } from '../questions/questions'
import { SkeletonBox, SkeletonLoader, SkeletonText } from '@slatldisal/pdus-components'
import './AncillaryProducts.scss'
import { PlansList } from './AncillaryProductsList'
import { filterAncillaryPlans } from './mappers/filterAncillaryProducts'
import { mapAncillaryPlans } from './mappers/mapAncillaryProducts'
import { sortAncillaryPlans } from './mappers/sortAncillaryProducts'

export class AncillaryProductsMap {
  private codeToPlanMap = new Map<string, IAncillaryPlanOption>()

  constructor(public plans: IAncillaryPlanOption[]) {
    plans.forEach((plan) => this.codeToPlanMap.set(plan.code, plan))
  }

  get(code: string): IAncillaryPlanOption | undefined {
    return this.codeToPlanMap.get(code)
  }

  get size(): number {
    return this.codeToPlanMap.size
  }

  hasPlans(): boolean {
    return this.codeToPlanMap.size > 0
  }
}

export const AncillaryProducts: React.FC = () => {
  const { trackEvent } = useMatomo()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const selectedAncillaryPlansFromStorage = SessionStorageService.getAndParsePrefixedItem<IAncillaryPlanOption[]>(
    CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES
  )

  const { largerThan: isDesktop } = useBreakpoint('m')
  const [isLoading, setLoading] = useState(true)

  const [ancillaryProductsMap, setAncillaryProductsMap] = useState<AncillaryProductsMap>(
    new AncillaryProductsMap(selectedAncillaryPlansFromStorage ?? [])
  )
  const setPlans = (plans: IAncillaryPlanOption[]) => setAncillaryProductsMap(new AncillaryProductsMap(plans))

  useEffect(() => {
    if (ancillaryProductsMap.plans.length > 0) {
      setLoading(false)
    } else {
      const creditTerm = opportunity.financialProduct?.payload?.term
      const flow = getFlow(opportunity)
      const dealerId = opportunity.owner?.porschePartnerNumber!
      const { vin, conditionType, mileage } = opportunity.vehicle

      void fetch(
        `${
          config().apiEndpoint
        }/ancillary-products?flow=${flow}&dealerId=${dealerId}&vin=${vin}&vehicleCondition=${conditionType}&odometer=${mileage!}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => res.json())
        .then((data: IAncillaryProduct[]) => {
          const plans: IAncillaryPlanOption[] = data
            .flatMap((product) => product.plans)
            .map((plan) => ({ ...plan, selection: APSelectionType.NOT_SELECTED }))

          const filteredPlans = filterAncillaryPlans(plans, flow, creditTerm)
          const mappedPlans = mapAncillaryPlans(filteredPlans)
          const sortedPlans = sortAncillaryPlans(mappedPlans)

          setPlans(sortedPlans)
        })
        .catch(() => setPlans([]))
        .finally(() => setLoading(false))
    }
  }, [
    opportunity.financialProduct?.payload?.creditType,
    opportunity.financialProduct?.payload?.term,
    opportunity.owner?.porschePartnerNumber,
    opportunity.vehicle,
    opportunity,
    ancillaryProductsMap.plans.length
  ])

  const enableSaveBtn = ancillaryProductsMap.plans.some((p) => p.selection === APSelectionType.USER_SELECTED)

  const skipHandler = () => {
    SessionStorageService.setPrefixedItem(CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES, [])
    presenter.opportunityStore.setAncillaryProducts([])

    matomoPcnaTrackClickEvent(trackEvent, 'pp-skip')
    trackClickEvent(AnalyticsEventTemplates.PROTECTION_PLANS_STEP_CLICK_BUTTON('skip'))

    presenter.stackManager.editNext()
  }

  const saveAndContinueHandler = () => {
    SessionStorageService.setPrefixedItem(CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES, ancillaryProductsMap.plans)
    presenter.opportunityStore.setAncillaryProducts(ancillaryProductsMap.plans)

    matomoPcnaTrackClickEvent(trackEvent, getMatomoAncillaryProductsSelection(ancillaryProductsMap.plans))
    trackClickEvent(
      AnalyticsEventTemplates.PROTECTION_PLANS_STEP_CLICK_BUTTON('save_and_continue', ancillaryProductsMap.plans)
    )

    presenter.stackManager.editNext()
  }

  return (
    <>
      <PGrid direction={{ base: 'column', s: 'row' }} className='small-space-bottom'>
        <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, m: 6 }}>
          <PText className='ada-text-width'>
            <FormattedMessage id='ancillaryProducts.sub' />
          </PText>

          <div className={`medium-space-top  ${isDesktop ? 'medium-space-bottom' : ''}`}>
            <SkeletonLoader loadingFinished={!isLoading} template={AncillaryProductsSkeleton}>
              <PHeadline variant='headline-5'>
                <FormattedMessage
                  id={ancillaryProductsMap.hasPlans() ? 'ancillaryProducts.prompt' : 'ancillaryProducts.noProducts'}
                />
              </PHeadline>
              <PlansList ancillaryProductsMap={ancillaryProductsMap} setPlans={setPlans} />
            </SkeletonLoader>
            <div style={{ backgroundColor: color.background.surface }}>
              <PText style={{ padding: `${spacing[16]} ${spacing[32]}` }} className='ada-text-width'>
                <FormattedMessage id='ancillaryProducts.note' />
              </PText>
            </div>
          </div>
        </PGridItem>

        {!isCheckout2Enabled() && (
          <>
            {isDesktop && <CheckoutDivider />}
            <PGridItem size={{ base: 12, m: 5 }} className={!isDesktop ? 'medium-space-bottom' : ''}>
              <Questions questions={generateQuestions('ancillaryProducts.faq', 1)} />
            </PGridItem>
          </>
        )}
      </PGrid>

      <PButtonGroup className='medium-space-bottom'>
        <PButton variant='secondary' disabled={!enableSaveBtn} onClick={saveAndContinueHandler}>
          <FormattedMessage id='common.saveAndContinue' />
        </PButton>
        <PButton variant='tertiary' onClick={skipHandler}>
          <FormattedMessage id='common.skip' />
        </PButton>
      </PButtonGroup>
    </>
  )
}

const AncillaryProductsSkeleton: React.FC = () => {
  const ApRowSkeleton: React.FC<{ contentLengthTitle: number; contentLength: number }> = ({
    contentLengthTitle,
    contentLength
  }) => (
    <>
      <div style={{ display: 'flex', margin: '2px 0 21px 3px' }}>
        <SkeletonBox style={{ marginRight: '7px' }} width='25px' height='25px' />
        <div style={{ width: '100%' }}>
          <SkeletonText contentLength={contentLengthTitle} width='600px' style={{ paddingRight: '74px' }} />
          <SkeletonText contentLength={contentLength} width='600px' />
        </div>
      </div>
      <PDivider style={{ margin: '17px 0' }} />
    </>
  )

  return (
    <>
      <SkeletonText contentLength={65} width='555px' fontSize='14px' />

      <ApRowSkeleton contentLengthTitle={31} contentLength={61} />
      <ApRowSkeleton contentLengthTitle={28} contentLength={49} />
      <ApRowSkeleton contentLengthTitle={29} contentLength={44} />
      <ApRowSkeleton contentLengthTitle={30} contentLength={34} />
      <SkeletonBox width='100px' height='60px' style={{ margin: '5px auto 25px' }} />
    </>
  )
}
