import React from 'react'

import './sslInfo.scss'
import { PIcon, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'

export const SSLInfo: React.FC<{ className?: string; align?: 'flex-start' | 'flex-end' | 'center' }> = ({
  className,
  align
}) => {
  return (
    <div
      style={{ justifyContent: align ?? 'flex-start', width: align ? '100%' : '125px' }}
      className={`ssl-info-container ${className ?? ''}`}
    >
      <div className='ssl-info'>
        <div className='labels'>
          <PText size='x-small' color='notification-success' align='right'>
            <FormattedMessage id='vehicleSelection.ssl' />
          </PText>
          <PText size='x-small' color='neutral-contrast-medium' align='right'>
            <FormattedMessage id='vehicleSelection.ssl.sub' />
          </PText>
        </div>
        <PIcon className='icon' name='lock' aria-label='SSL Security' color='notification-success' />
      </div>
    </div>
  )
}
