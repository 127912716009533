import React, { useContext } from 'react'
import { PLinkPure } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { PresenterContext } from '../components/entry/presenterProvider'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { observer } from 'mobx-react-lite'
import { unsavedDataModalStore, UnsavedModalMode } from '../components/checkout/unsavedDataModalStore'
import { opportunityHasUnsavedData } from './checkout'
import { trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../onega/AnalyticsEventTemplates'

export const FinderBackButton: React.FC = observer(() => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { largerThan: isDesktop } = useBreakpoint('s')

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '15px'
        }}
      >
        {opportunity.vehicle?.payload?.vehicleDetailsUrl && (
          <PLinkPure
            icon='arrow-head-left'
            style={{ justifySelf: 'flex-start', display: 'flex', alignItems: 'center', height: '64px' }}
            className={isDesktop ? 'medium-space-top' : ''}
            onClick={() => {
              const returnToFinder = () => (window.location.href = opportunity.vehicle?.payload?.vehicleDetailsUrl)

              if (unsavedDataModalStore.checkoutFormDirty || opportunityHasUnsavedData(opportunity)) {
                unsavedDataModalStore.showModal(UnsavedModalMode.RETURN_TO_FINDER, returnToFinder)
              } else {
                returnToFinder()
              }
              trackClickEvent(AnalyticsEventTemplates.CHECKOUT_BACK_CLICK)
            }}
          >
            <FormattedMessage id='checkout.back' />
          </PLinkPure>
        )}
      </div>
    </>
  )
})
