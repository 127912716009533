import React, { ReactNode } from 'react'

export const ProtectionPlanLink = (msg: ReactNode): JSX.Element => (
  <a
    target='_blank'
    href='https://www.porsche.com/usa/accessoriesandservices/porschefinancialservices/protectionplan'
    rel='noreferrer'
  >
    {msg}
  </a>
)

export const BoldTag = (msg: ReactNode) => <b>{msg}</b>

export const Break = () => <br />

export const DoubleBreak = () => (
  <>
    <br />
    <br />
  </>
)

export const MailToLink = (msg: ReactNode) => <a href='mailto:accessibility@porsche.us'>{msg}</a>
