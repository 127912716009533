import { PGrid } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SessionStorageService } from '../../../../services/sessionStorageService'
import { CheckoutStorageKeys } from '../../../checkout/checkoutStorageKeys'
import { DELIVERY_OPTIONS_WINDOW_EVENT } from '../../../deliveryOption/DeliveryOptions'
import { DELIVERY_OPTIONS_STACK_NAME } from '../../../deliveryOption/DeliveryOptionStackElement'
import { PresenterContext } from '../../../entry/presenterProvider'
import { AccordionStepData, ClearStepData, isStepDataShown, IStepData, StepDataEditButton, StepDataRow } from './common'

export const DeliveryOptionsStepData: React.FC<IStepData> = observer(({ closeModalFn }) => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const stackManager = presenter.stackManager
  const [deliveryOption, setDeliveryOption] = useState(
    JSON.parse(SessionStorageService.getPrefixedItem(CheckoutStorageKeys.DELIVERY_OPTIONS_VALUES) ?? '{}')?.selection
  )
  const heading = intl.formatMessage({ id: 'common.delivery.homeDelivery.title' })

  useEffect(() => {
    const eventHandler = (e: Event) => setDeliveryOption((e as CustomEvent).detail)
    window.addEventListener(DELIVERY_OPTIONS_WINDOW_EVENT, eventHandler)

    return () => {
      window.removeEventListener(DELIVERY_OPTIONS_WINDOW_EVENT, eventHandler)
    }
  }, [])

  if (!isStepDataShown(DELIVERY_OPTIONS_STACK_NAME, stackManager) || !deliveryOption) {
    return <ClearStepData heading={heading} />
  }

  const dealer = opportunity.owner?.displayName

  return (
    <AccordionStepData heading={heading}>
      <PGrid>
        <StepDataRow labelKey='common.mySelections'>
          <FormattedMessage
            id={deliveryOption === 'HOME' ? 'common.delivery.homeDelivery.prompt' : 'delivery.type.dealer'}
            values={{ dealer }}
          />
        </StepDataRow>
        <StepDataRow>
          <StepDataEditButton
            editStack={DELIVERY_OPTIONS_STACK_NAME}
            editTitle='Edit Delivery Options'
            closeModalFn={closeModalFn}
          />
        </StepDataRow>
      </PGrid>
    </AccordionStepData>
  )
})
