import { PFlexItem, PRadioButtonWrapper, PText } from '@porsche-design-system/components-react'
import { DeliveryOptionsView } from '@pdiatl/common-external-models'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export interface IDeliveryOptionSelectionProps {
  value: DeliveryOptionsView['deliveryOption']
  i18nKey: string
  dealer: string
  onChange(value: DeliveryOptionsView['deliveryOption']): void
  checked: boolean
  hasSeparator?: boolean
}

export const DeliveryOptionSelection: React.FC<IDeliveryOptionSelectionProps> = observer(
  (props: IDeliveryOptionSelectionProps) => {
    const intl = useIntl()

    return (
      <PFlexItem>
        <PRadioButtonWrapper label={intl.formatMessage({ id: props.i18nKey }, { dealer: props.dealer })}>
          <input
            type='radio'
            name={props.value}
            value={props.value}
            checked={props.checked}
            onChange={(e) => {
              props.onChange(e.target.value as DeliveryOptionsView['deliveryOption'])
            }}
          />
        </PRadioButtonWrapper>
        {props.checked && (
          <PText className='small-space-top'>
            <FormattedMessage
              id={props.value === 'HOME' ? 'delivery.type.home.sub' : 'delivery.type.dealer.sub'}
              values={{ dealer: props.dealer }}
            />
          </PText>
        )}
      </PFlexItem>
    )
  }
)
