import React from 'react'
import { PMarque } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import './LogoHeader2.scss'

export const LogoHeader2: React.FC = () => {
  const { smallerThan: isMobile } = useBreakpoint('xs')

  return (
    <header>
      <div className='checkout2-app-header'>
        <div
          className='logo-container'
          style={{
            padding: isMobile ? '5px 0 0 1px' : '',
            width: isMobile ? '103px' : '',
            marginLeft: isMobile ? '14px' : ''
          }}
        >
          <PMarque size='small' />
        </div>
      </div>
    </header>
  )
}
