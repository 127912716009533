import { PIcon, PText, PTextFieldWrapper } from '@porsche-design-system/components-react'
import React, { InputHTMLAttributes, useEffect, useState } from 'react'
import './filterAutocomplete.scss'

export interface IFilterAutocompleteOption {
  readonly value: string
  readonly label: string
}

export interface IFilterAutocompleteProps {
  /** The autocomplete query. */
  query: string

  options: IFilterAutocompleteOption[]

  /** The label text. */
  label: string

  /** The placeholder when no query is entered. */
  placeholder: string

  /**
   * An optional message that appears when options are empty.
   */
  noOptionsMessage?: string

  /**
   * Called when the user attempts to change the selection.
   * @param value The proposed value after the change.
   */
  onOptionSelect: (option: IFilterAutocompleteOption | null) => void

  /**
   * Called when the user atempts to change the query
   * @param query The proposed query after the change.
   */
  onQueryChange: (query: string) => void

  /**
   * Called when the user blurs the query field.
   */
  onQueryBlur?: () => void
}

/**
 * A select compononent to select a value via a searchable list.
 */

export const FilterAutocomplete: React.FunctionComponent<IFilterAutocompleteProps> = (props) => {
  const { label, query, onQueryChange, onOptionSelect, placeholder, options, onQueryBlur, noOptionsMessage } = props
  const [focusIndex, setFocusIndex] = useState<number | undefined>(undefined)

  const handleInputChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    onQueryChange(e.currentTarget.value || '')
  }

  const handleInputKeydown: InputHTMLAttributes<HTMLInputElement>['onKeyDown'] = (e) => {
    if (e.key === 'Escape' || e.key === 'Delete') {
      // Deselect value anytime when escape or delete key is pressed
      e.preventDefault()
      onOptionSelect(null)
      return
    }

    if (query.length === 0) {
      if (e.key === 'Enter') {
        // Deselect value when no query is set and enter is pressed
        e.preventDefault()
        onOptionSelect(null)
      }
      return
    }

    if (options.length === 0 && query.length > 0) {
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
        // Try and reload options if the user has a query and tries to navigate but has no options
        e.preventDefault()
        onQueryChange(query)
      }
      return
    }

    if (e.key === 'ArrowDown') {
      // Focus next result
      e.preventDefault()
      const newFocusIndex = focusIndex === undefined ? 0 : focusIndex + 1
      setFocusIndex(newFocusIndex >= options.length ? 0 : newFocusIndex)
    }

    if (e.key === 'ArrowUp') {
      // Focus previous result
      e.preventDefault()
      const newFocusIndex = focusIndex === undefined ? options.length - 1 : focusIndex - 1
      setFocusIndex(newFocusIndex < 0 ? options.length - 1 : newFocusIndex)
    }

    if (e.key === 'Enter') {
      // Select focused result
      onOptionSelect(options[focusIndex ?? 0])
    }
  }

  useEffect(() => {
    setFocusIndex(undefined)
  }, [options])

  return (
    <>
      <PTextFieldWrapper label={label}>
        <input
          aria-label={label}
          placeholder={placeholder}
          value={query}
          type='text'
          onChange={handleInputChange}
          onKeyDown={handleInputKeydown}
          onBlur={onQueryBlur}
        />
      </PTextFieldWrapper>
      {query.length > 0 && options.length > 0 && (
        <>
          {options.map((option, index) => {
            return (
              <button
                {...props}
                style={focusIndex === index ? { color: '#D5001C', backgroundColor: '#F2F2F2' } : undefined}
                className='option-button'
                tabIndex={-1}
                onClick={() => onOptionSelect(option)}
                key={option.value}
              >
                {option.label}

                <span className='option-icon'>
                  <PIcon name='arrow-head-right' />
                </span>
              </button>
            )
          })}
          <div className='styled-google-hint' />
        </>
      )}
      {noOptionsMessage && options.length === 0 && query.length > 0 && (
        <PText className='no-option-text'>{noOptionsMessage}</PText>
      )}
    </>
  )
}
