import {
  APSelectionType,
  IAncillaryChildPlan,
  IAncillaryPlan,
  IAncillaryPlanOption
} from '@pdiatl/common-external-models'

export const VSC_AGGREGATED_PLAN: IAncillaryPlan = {
  code: 'VSC',
  name: 'Vehicle Service Protection',
  isPackage: false,
  childPlans: [],
  terms: []
}

export const AGGREGATIONS: { from: string[]; to: IAncillaryPlan }[] = [
  {
    from: ['POVSPLIW', 'POVSPTIW', 'POVSPLOW', 'POVSPTOW'],
    to: VSC_AGGREGATED_PLAN
  }
]

export const RENAMES: { code: string; to: string }[] = [
  { code: 'P', to: 'Premier Tire & Wheel Protection' },
  { code: 'POMCPLAT', to: 'Multi-Coverage Platinum Protection Package' },
  { code: 'POMCGOLD', to: 'Multi-Coverage Gold Protection Package' },
  { code: 'POMCSILV', to: 'Multi-Coverage Silver Protection Package' },
  { code: 'POMCBRNZ', to: 'Multi-Coverage Bronze Protection Package' },
  { code: 'POMCCPPR', to: 'Multi-Coverage Copper Protection Package' },
  { code: 'POTSTRPR', to: 'Term Protection Plus W/O Rotors' },
  { code: 'POTSTRRT', to: 'Term Protection Plus W/ Rotors' },
  { code: 'POTRTRPR', to: 'Term Protection W/O Rotors' },
  { code: 'POTRTRRT', to: 'Term Protection W/ Rotors' },
  { code: 'POLSLEAS', to: 'Lease-end Protection' },
  { code: 'POGPGAP', to: 'Guaranteed Auto Protection (GAP)' },
  { code: 'POPLGAP', to: 'Guaranteed Auto Protection (GAP) Plus' }
]

function aggregate(plans: IAncillaryPlanOption[]): IAncillaryPlanOption[] {
  let plansCopy = JSON.parse(JSON.stringify(plans)) as IAncillaryPlanOption[]
  AGGREGATIONS.forEach((aggregation) => {
    const plansToAggregate = plansCopy.filter((plan) =>
      aggregation.from.some((codeToAggregate) => plan.code === codeToAggregate)
    )
    const filteredPlans = plansCopy.filter(
      (plan) => !aggregation.from.some((codeToAggregate) => plan.code === codeToAggregate)
    )

    if (plansToAggregate.length > 0) {
      filteredPlans.push({
        ...aggregation.to,
        selection: APSelectionType.NOT_SELECTED,
        terms: plansToAggregate[0].terms
      })
    }

    plansCopy = filteredPlans
  })

  return plansCopy
}

function renamePlan<T extends IAncillaryChildPlan>(plan: T): T {
  const renameEntry = RENAMES.find((renameEntry) => renameEntry.code === plan.code)

  return {
    ...plan,
    name: renameEntry ? renameEntry.to : plan.name
  }
}

function rename(plans: IAncillaryPlanOption[]): IAncillaryPlanOption[] {
  return plans.map((plan) => ({
    ...renamePlan(plan),
    childPlans: plan.childPlans.map(renamePlan)
  }))
}

export function mapAncillaryPlans(plans: IAncillaryPlanOption[]): IAncillaryPlanOption[] {
  const aggregatedPlans = aggregate(plans)
  return rename(aggregatedPlans)
}
