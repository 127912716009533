import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PButtonPure, PLink, PModal, PText } from '@porsche-design-system/components-react'
import { trackClickEvent, trackPageLoadEvent } from '@slatldisal/one-ga'
import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFlags } from '../../../common/flags'
import { BoldTag, Break } from '../../../common/IntlElements'
import { config } from '../../../config'
import {
  getDynamicMatomoClickEventAction,
  getDynamicMatomoPageViewActionName,
  matomoPcnaTrackClickEvent,
  matomoPcnaTrackPageView
} from '../../../matomo/matomo'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../entry/presenterProvider'

export const PorscheApprovedSection: React.FC<{ analyticsPage: AnalyticsPage }> = ({ analyticsPage }) => {
  const [showModal, setShowModal] = useState(false)
  const { enablePorscheApproved } = useFlags()
  const { trackEvent } = useMatomo()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  if (!enablePorscheApproved || !opportunity.vehicle?.payload?.isCpo) {
    return null
  }

  const clickHandler = (event: React.MouseEvent<HTMLElement>): void => {
    event.currentTarget.blur()

    matomoPcnaTrackClickEvent(trackEvent, getDynamicMatomoClickEventAction('porsche-approved-cpo-car', analyticsPage))
    trackClickEvent(AnalyticsEventTemplates.PORSCHE_APPROVED_CPO_BUTTON(analyticsPage))

    setShowModal(true)
  }

  const closeHandler = (): void => {
    matomoPcnaTrackClickEvent(trackEvent, getDynamicMatomoClickEventAction('mpa-cancel', analyticsPage))
    trackClickEvent(AnalyticsEventTemplates.PORSCHE_APPROVED_MODAL_CLOSE_BUTTON(analyticsPage))

    setShowModal(false)
  }

  return (
    <>
      <PButtonPure icon='none' onClick={clickHandler}>
        <FormattedMessage id='porscheApproved.title' />
      </PButtonPure>

      <PorscheApprovedModal open={showModal} onClose={closeHandler} analyticsPage={analyticsPage} />
    </>
  )
}

interface IPorscheApprovedModalProps {
  analyticsPage: AnalyticsPage
  open: boolean
  onClose: () => void
}

const PorscheApprovedModal: React.FC<IPorscheApprovedModalProps> = ({ analyticsPage, open, onClose }) => {
  const { pushInstruction } = useMatomo()
  const intl = useIntl()

  useEffect(() => {
    if (open) {
      matomoPcnaTrackPageView(
        pushInstruction,
        getDynamicMatomoPageViewActionName('checkout/porsche_approved', analyticsPage)
      )
      trackPageLoadEvent(AnalyticsEventTemplates.PORSCHE_APPROVED_MODAL(analyticsPage))
    }
  }, [open, pushInstruction, analyticsPage])

  const modal = (
    <PModal
      data-testid='porsche-approved-modal'
      open={open}
      onClose={onClose}
      heading={intl.formatMessage({ id: 'porscheApproved.title' })}
    >
      <SubtitleDescription />

      <CertifiedPreownedUSLearnMoreLink analyticsPage={analyticsPage} />
    </PModal>
  )
  if (open) {
    return ReactDOM.createPortal(modal, document.body)
  }
  return null
}

const SubtitleDescription: React.FC = () => {
  return (
    <>
      <img
        src='/images/porscheApproved/porsche-approved-certified.png'
        alt='Porsche Approved Certified'
        className='small-space-bottom'
      />

      <PText className='ada-text-width'>
        <FormattedMessage id='porscheApproved.description' values={{ b: BoldTag, break: Break }} />
      </PText>
    </>
  )
}

const CertifiedPreownedUSLearnMoreLink: React.FC<{ analyticsPage: AnalyticsPage }> = ({ analyticsPage }) => {
  const { trackEvent } = useMatomo()

  const clickHandler = () => {
    matomoPcnaTrackClickEvent(trackEvent, getDynamicMatomoClickEventAction('mpa-learn-more', analyticsPage))
    trackClickEvent(AnalyticsEventTemplates.PORSCHE_APPROVED_MODAL_LEARN_MORE(analyticsPage))
  }

  return (
    <PLink
      href={`${config().finder.homePageURL}/us/en-US/certified-preowned`}
      target='_blank'
      className='medium-space-top'
      onClick={clickHandler}
    >
      <FormattedMessage id='checkout.learnMore.button' />
    </PLink>
  )
}
