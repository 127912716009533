import { PModal, PText } from '@porsche-design-system/components-react'
import { IAncillaryPlanOption } from '@pdiatl/common-external-models'
import React from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage } from 'react-intl'
import { ProtectionPlanLink } from '../../common/IntlElements'

export interface IPlanDescriptionModalProps {
  plan: IAncillaryPlanOption | undefined
  open: boolean
  onClose: () => void
}

// these translations could go into i18n file if there was no text repetitions
const descriptions = new Map<string, JSX.Element>([
  [
    'D',
    <>
      More than just an eyesore, damage to the body of your car can reduce its resale or trade-in value. Maintain the
      value and beauty of your vehicle with Porsche Dent Protection, offering protection and security for your
      investment.
    </>
  ],
  [
    'K',
    <>
      High-tech and secure, the key to your Porsche vehicle isn't a toy. Stay on the road with Porsche Key Protection,
      offering protection and security for your investment.
    </>
  ],
  [
    'P',
    <>
      Potholes are just one hazard that can damage your tires and wheels. Stay on the road with Porsche Premier Tier &
      Wheel Protection, offering protection and security for your investment.
    </>
  ],
  [
    'X',
    <>
      Rocks and other road debris can damage your windshield, creating driving distractions. See the road ahead clearly
      with Porsche Windshield Protection, offering protection and security for your investment.
    </>
  ],
  [
    'POLSLEAS',
    <>
      Driving your dream Porsche vehicle should be an easy every day thrill. Don’t let the end of your lease keep you
      from enjoying your vehicle during it. Porsche Lease-end Protection makes your lease-end hand off smooth and
      simple.
    </>
  ],
  [
    'POVSPLIW',
    <>
      Vehicle Service Protection delivers benefits comparable to the high standards set by the Porsche factory warranty
      program, with coverage options up to 10 years or 100,000 miles.
      <br />
      Platinum Vehicle Service Protection provides protection for: Engine, Transmission, Drive Axle, Seals and Gaskets,
      Climate Control, Shocks, Suspension, Fuel System, Steering, Brakes, Navigation, and Audio/High-tech. Deductibles
      may apply.
    </>
  ],
  [
    'POVSPLOW',
    <>
      Vehicle Service Protection delivers benefits comparable to the high standards set by the Porsche factory warranty
      program, with coverage options up to 10 years or 100,000 miles.
      <br />
      Powertrain Vehicle Service Protection provides protection for: Engine, Transmission, Drive Axle and Seals and
      Gaskets. Deductibles may apply.
    </>
  ],
  [
    'POVSPTIW',
    <>
      Vehicle Service Protection delivers benefits comparable to the high standards set by the Porsche factory warranty
      program, with coverage options up to 10 years or 100,000 miles.
      <br />
      Powertrain Vehicle Service Protection provides protection for: Engine, Transmission, Drive Axle and Seals and
      Gaskets. Deductibles may apply.
    </>
  ],
  [
    'POVSPTOW',
    <>
      Vehicle Service Protection delivers benefits comparable to the high standards set by the Porsche factory warranty
      program, with coverage options up to 10 years or 100,000 miles.
      <br />
      Powertrain Vehicle Service Protection provides protection for: Engine, Transmission, Drive Axle and Seals and
      Gaskets. Deductibles may apply.
    </>
  ],
  [
    'POTRTRRT',
    <>
      Don't let wearable parts and components distract you from your journey. Term Protection covers parts and labor for
      replacement of select wearable components including: Front & Rear Brake Pads and Rotors, Windshield Wiper Blades
      and/or Inserts, Wheel Alignment, Belts & Hoses, Headlamps, and Bulbs & Fuses.
    </>
  ],
  [
    'POTSTRRT',
    <>
      Don't let wearable parts and components distract you from your journey. Term Protection Plus covers parts and
      labor for replacement of select wearable components including: Front & Rear Brake Pads and Rotors, Windshield
      Wiper Blades and/or Inserts, Wheel Alignment, Belts & Hoses, Headlamps, and Bulbs & Fuses, with the addition of
      Mechanical Breakdown Coverage.
    </>
  ],
  [
    'POTRTRPR',
    <>
      Don't let wearable parts and components distract you from your journey. Term Protection covers parts and labor for
      replacement of select wearable components including: Front & Rear Brake Pads, Windshield Wiper Blades and/or
      Inserts, Wheel Alignment, Belts & Hoses, Headlamps, and Bulbs & Fuses.
    </>
  ],
  [
    'POTSTRPR',
    <>
      Don't let wearable parts and components distract you from your journey. Term Protection Plus covers parts and
      labor for replacement of select wearable components including: Front & Rear Brake Pads, Windshield Wiper Blades
      and/or Inserts, Wheel Alignment, Belts & Hoses, Headlamps, and Bulbs & Fuses, with the addition of Mechanical
      Breakdown Coverage.
    </>
  ],
  [
    'VSC',
    <>
      Don't let the unexpected derail your journey. Get back on track quickly with Porsche Vehicle Service Protection,
      offering roadside assistance and covering up to 1,500 mechanical parts. It offers protection and security for your
      investment beyond your Porsche warranty.
    </>
  ],
  [
    'POGPGAP',
    <>
      Your primary insurance payout may not cover the outstanding balance on your vehicle retail contract in the event
      of a total loss. Make sure you're covered with Porsche Guaranteed Auto Protection when financing your Porsche
      vehicle, offering protection and security for your investment.
    </>
  ],
  [
    'POPLGAP',
    <>
      Your primary insurance payout may not cover the outstanding balance on your vehicle retail contract in the event
      of a total loss. Make sure you're covered with Porsche Guaranteed Auto Protection when financing your Porsche
      vehicle, offering protection and security for your investment.
    </>
  ]
])

export const PlanDescriptionModal: React.FC<IPlanDescriptionModalProps> = (props) => {
  const hasPlan = !!props.plan
  const plan = props.plan ?? ({ code: 'NONE' } as IAncillaryPlanOption)
  const hasDescription = hasPlan && descriptions.has(plan.code)
  const open = props.open

  const modal = (
    <PModal heading={plan?.name} open={open} onClose={props.onClose}>
      {hasDescription && (
        <>
          <PText className='ada-text-width'>{descriptions.get(plan.code)}</PText>
          <PText className='medium-space-top ada-text-width'>
            <FormattedMessage id='ancillaryProducts.description.sub' values={{ a: ProtectionPlanLink }} />
          </PText>
        </>
      )}
      {!hasDescription && !plan.isPackage && <FormattedMessage id='ancillaryProducts.description.missing' />}
      {plan.isPackage &&
        plan.childPlans.map((childPlan, i) => (
          <div key={childPlan.code}>
            <PText weight='bold'>{childPlan.name}</PText>
            <PText className={`ada-text-width ${i !== plan?.childPlans.length - 1 ? 'medium-space-bottom' : ''}`}>
              {descriptions.get(childPlan.code)}
            </PText>
          </div>
        ))}
    </PModal>
  )

  if (open) {
    return ReactDOM.createPortal(modal, document.body)
  }

  return null
}
