import { PButtonPure, PText } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useRef } from 'react'
import { AnalyticsPage } from '../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../entry/presenterProvider'
import { vehicleSelectionStore } from '../../../vehicleSelection/pcna/vehicleSelectionStore'
import { VehicleSummaryStepsData } from '../VehicleSummaryStepsData'
import { CashVehicleSummary } from './CashVehicleSummary'
import { LeaseFinanceVehicleSummary } from './LeaseFinanceVehicleSummary'
import { OwnFinancingVehicleSummary } from './OwnFinancingVehicleSummary'
import { PorscheApprovedSection } from '../../porscheApproved/PorscheApprovedSection'

const VehicleModelHeader: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const modelYear = opportunity.vehicle.modelYear
  const modelName = opportunity.vehicle.modelName
  const dealerName = opportunity.owner?.displayName
  return (
    <div className='large-space-left medium-space-bottom'>
      <PText weight='bold'>
        {modelYear} {modelName}
      </PText>
      <PText>At {dealerName}</PText>
      <PorscheApprovedSection analyticsPage={AnalyticsPage.MOBILE_SLIDE_OUT} />
    </div>
  )
}

interface IMobileVehicleSummaryProps {
  state: 'visible' | 'hidden'
  animation: 'open' | 'close'
  closeFn: Function
}

/** How focus trapping works:
 *  it's a combination of CSS selector ':not(:focus-within)' and 'transitionend' event listener. Selector contains
 *  unnoticeable UI transition for when modal loses focus. Once that happens, event fires and forces the focus
 *  back on the hidden element on modal, so the border doesn't appear on focused element (this happens on every single transition). */
export const MobileVehicleSummary: React.FC<IMobileVehicleSummaryProps> = observer(({ state, animation, closeFn }) => {
  const { smallerThan: isMobile } = useBreakpoint('xs')
  const selectedPaymentOption = vehicleSelectionStore.selectedPaymentOption
  const focusTrapDivRef = useRef<HTMLDivElement>(null)
  const focusFirstElement = (preventScroll: boolean) => focusTrapDivRef.current?.focus({ preventScroll })

  useEffect(() => {
    if (state === 'visible') {
      focusFirstElement(false)
    }
  }, [state])

  useEffect(() => {
    const focusFirstElementListener = () => focusFirstElement(true)
    window.addEventListener('transitionend', focusFirstElementListener)
    return () => window.removeEventListener('transitionend', focusFirstElementListener)
  }, [])

  let VehicleSummarySection: React.FC
  switch (selectedPaymentOption) {
    case 'leaseFinance':
      VehicleSummarySection = vehicleSelectionStore.calculationResult ? LeaseFinanceVehicleSummary : CashVehicleSummary
      break
    case 'ownFinancing':
      VehicleSummarySection = OwnFinancingVehicleSummary
      break
    default:
      VehicleSummarySection = CashVehicleSummary
  }

  return (
    <div
      role='dialog'
      className={`vehicle-summary-modal ${state} ${animation}`}
      style={{ padding: isMobile ? '30px 15px 15px' : '' }}
    >
      <div style={{ position: 'relative' }}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div tabIndex={0} ref={focusTrapDivRef} aria-label='header' />
        <PButtonPure icon='arrow-head-left' size='medium' className='close-modal-arrow' onClick={() => closeFn()} />
        <div>
          <VehicleModelHeader />
          <VehicleSummarySection />
          <VehicleSummaryStepsData closeModalFn={closeFn} />
        </div>
      </div>
    </div>
  )
})
