import { PFlex, PFlexItem, PText } from '@porsche-design-system/components-react'
import React, { ReactElement } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isCheckout2Enabled } from '../../common/checkout'
import { config } from '../../config'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { MailToLink } from '../../common/IntlElements'

interface IShellProps {
  children: ReactElement
}

export const Shell: React.FC<IShellProps> = (props) => {
  const intl = useIntl()

  const { smallerThan: isMobile } = useBreakpoint('s')

  return (
    <>
      <main className={isCheckout2Enabled() ? '' : 'surface-bg'}>{props.children}</main>
      <footer>
        <PFlex direction='column' className='footer-content' style={{ margin: isMobile ? '0 1rem 0 1rem' : '' }}>
          <PFlexItem grow={1} className='small-space-top small-space-bottom'>
            <PText color='inherit'>
              {intl.formatMessage({ id: 'checkout.footer.copyright' })}&nbsp;&nbsp;
              <a
                href={intl.formatMessage({ id: 'checkout.footer.legalNotice.url' })}
                target='_blank'
                rel='noreferrer noopener'
              >
                {intl.formatMessage({ id: 'checkout.footer.legalNotice' })}
              </a>
              &nbsp;&nbsp;
              {config().isMarket('us') && (
                <>
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.termsAndConditions.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.termsAndConditions' })}
                  </a>
                  &nbsp;&nbsp;
                </>
              )}
              <a
                href={intl.formatMessage({ id: 'checkout.footer.privacyNotice.url' })}
                target='_blank'
                rel='noreferrer noopener'
              >
                {intl.formatMessage({ id: 'checkout.footer.privacyNotice' })}
              </a>
              &nbsp;&nbsp;
              {config().isMarket('us') && (
                <>
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.californiaPrivacy.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.californiaPrivacy' })}
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.doNotSellMyPersonalInformation.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.doNotSellMyPersonalInformation' })}
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.openSourceSoftwareNotice.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.openSourceSoftwareNotice' })}
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.businessAndHumanRights.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.businessAndHumanRights' })}
                  </a>
                  &nbsp;&nbsp;
                </>
              )}
              {config().isMarket('ca') && (
                <>
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.openSourceSoftwareNotice.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.openSourceSoftwareNotice' })}
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href={intl.formatMessage({ id: 'checkout.footer.businessAndHumanRights.url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {intl.formatMessage({ id: 'checkout.footer.businessAndHumanRights' })}
                  </a>
                  &nbsp;&nbsp;
                </>
              )}
            </PText>
          </PFlexItem>
          {config().isMarket('ca') && (
            <PFlexItem grow={1} className='small-space-bottom'>
              <PText color='inherit'>
                <FormattedMessage id='checkout.footer.longText' />
              </PText>
            </PFlexItem>
          )}
          {config().isMarket('us') && (
            <PFlexItem grow={1} className='small-space-bottom'>
              <PText color='inherit' weight='bold'>
                <FormattedMessage id='checkout.footer.accessibilityStatement' />
                <a
                  href={intl.formatMessage({ id: 'checkout.footer.accessibilityLink.url' })}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {intl.formatMessage({ id: 'checkout.footer.accessibilityLink.title' })}.
                </a>
              </PText>
              <PText color='inherit'>
                <FormattedMessage id='checkout.footer.accessibilityDescription' values={{ a: MailToLink }} />
              </PText>
            </PFlexItem>
          )}
        </PFlex>
      </footer>
    </>
  )
}
