import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PButton, PButtonGroup } from '@porsche-design-system/components-react'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { isFinancePricingAvailable, isGuest, isLeaseOrFinance } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { matomoPcnaTrackClickEvent } from '../../../matomo/matomo'
import AoacModal from '../../aoac/aoacModal'
import { PresenterContext } from '../../entry/presenterProvider'
import { applyHandler, submitHandler } from '../common'
import { ISubmitParams } from './ReviewStackElement'

export const ReviewCTASection: React.FC = () => {
  const { enableCheckoutFinancingButton } = useFlags()
  const { trackEvent } = useMatomo()
  const navigate = useNavigate()
  const presenter = useContext(PresenterContext)
  const submitOpts: ISubmitParams = { presenter, navigate, trackEvent }
  const [applyDialogOpen, setApplyDialogOpen] = useState(false)
  const {
    termsAreAccepted,
    submitIsInProgress,
    opportunityStore: { opportunityCache: opportunity }
  } = presenter

  const showAoacCTA =
    enableCheckoutFinancingButton &&
    isFinancePricingAvailable(opportunity) &&
    isLeaseOrFinance(opportunity) &&
    !isGuest()

  return (
    <>
      <PButtonGroup className='medium-space-top'>
        <PButton
          disabled={!termsAreAccepted}
          onClick={async () => submitHandler(submitOpts)}
          loading={submitIsInProgress}
        >
          <FormattedMessage id='review.condition' />
        </PButton>
        {showAoacCTA && (
          <PButton
            variant='tertiary'
            onClick={() => {
              matomoPcnaTrackClickEvent(trackEvent, 'oo-apply-financing')
              setApplyDialogOpen(true)
            }}
            disabled={!presenter.termsAreAccepted}
          >
            <FormattedMessage id='review.cta.applyForFinancing' />
          </PButton>
        )}
      </PButtonGroup>

      <AoacModal
        applyHandler={async () => applyHandler(submitOpts)}
        onRequestClose={() => {
          matomoPcnaTrackClickEvent(trackEvent, 'maff-cancel')
          setApplyDialogOpen(false)
        }}
        open={applyDialogOpen}
      />
    </>
  )
}
