import { PDivider, PModal, PText } from '@porsche-design-system/components-react'
import { color, spacing } from '@porsche-design-system/utilities'
import { PriceBreakdownCategory } from '@pdiatl/common-external-models'
import React, { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { useFlags } from '../../common/flags'
import { config } from '../../config'
import { PresenterContext } from '../entry/presenterProvider'

interface INewAllInPricingProps {
  analyticEvents: { onOpen: () => void; onClose: () => void }
}

export const NewAllInPricing = ({ analyticEvents }: INewAllInPricingProps) => {
  const intl = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const { enableNewAllInPricing } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const priceBreakdown = opportunity.vehicle.payload?.priceBreakdown
  const isCA = config().isMarket('ca')
  const { onOpen, onClose } = analyticEvents

  const openModal = useCallback(() => {
    onOpen()
    setOpen(true)
  }, [onOpen])

  const closeModal = useCallback(() => {
    onClose()
    setOpen(false)
  }, [onClose])

  if (!isCA || !enableNewAllInPricing || !priceBreakdown) {
    return null
  }

  const { categories, totalPrice } = priceBreakdown

  return (
    <>
      <PText align='right'>
        <PriceDetailsLink onClick={openModal}>
          {intl.formatMessage({ id: 'aip.priceDetailsLinkLabel' })}
        </PriceDetailsLink>
      </PText>
      <PModal
        heading={intl.formatMessage({ id: 'aip.modal.title' })}
        open={open}
        onClose={closeModal}
        style={{ textAlign: 'left' }}
      >
        <DisclaimerText>{intl.formatMessage({ id: 'aip.modal.disclaimer' })}</DisclaimerText>
        {categories.map((category: PriceBreakdownCategory) => (
          <div key={category.name}>
            <Row verticalPadding={spacing[4]}>
              <CategoryLabelText>{category.name}</CategoryLabelText>
              {!category.items && <PText>{category.price}</PText>}
            </Row>
            {category.items?.map((item) => (
              <Row key={item.name}>
                <PText>{item.name}</PText>
                <PText>{item.price}</PText>
              </Row>
            ))}
            {category.items && (
              <Row verticalPadding={spacing[4]}>
                <CategoryLabelText>
                  {intl.formatMessage(
                    { id: 'aip.modal.totalCategoryPriceLabel' },
                    {
                      category: category.name
                    }
                  )}
                </CategoryLabelText>
                <PText>{category.price}</PText>
              </Row>
            )}
            <StyledDivider />
          </div>
        ))}
        <Row verticalPadding={spacing[8]}>
          <TotalPriceLabelText>{intl.formatMessage({ id: 'aip.modal.totalPriceLabel' })}</TotalPriceLabelText>
          <PText>{totalPrice}</PText>
        </Row>
      </PModal>
    </>
  )
}
/*
fluid: {
        "x-small": import("styled-components").FlattenSimpleInterpolation;
        small: import("styled-components").FlattenSimpleInterpolation;
*/
const buttonReset = css`
  ReadonlyArray<SimpleInterpolation>
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  border-radius: 0;
  background: transparent;
  text-align: center;
  text-decoration: none;
  color: ${color.default};
  cursor: pointer;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;
`

const PriceDetailsLink = styled.button`
  ${buttonReset};
  text-align: left;
  text-decoration: underline;

  @media (hover: hover) and (pointer: fine) {
    :hover {
      color: ${color.brand};
    }
  }

  :focus-visible {
    outline: 1px solid ${color.state.focus};
    outline-offset: 2px;
  }
`

const DisclaimerText = styled(PText)`
  text-align: center;
  color: ${color.neutralContrast.medium};
  padding-bottom: ${spacing[32]};
`

const CategoryLabelText = styled(PText)`
  font-weight: bold;
`

const TotalPriceLabelText = styled(PText)`
  font-weight: bolder;
`

const Row = styled.div<{ verticalPadding?: string }>`
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.verticalPadding};
  ${(props) => (props.verticalPadding ? `padding-bottom: ${props.verticalPadding};` : '')};
`

const StyledDivider = styled(PDivider)`
  padding-bottom: ${spacing[8]};
  padding-top: ${spacing[8]};
`
