import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PText } from '@porsche-design-system/components-react'
import { trackClickEvent } from '@slatldisal/one-ga'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { IS_GUEST_STORAGE_KEY, isCheckout2Enabled, isGuest } from '../../common/checkout'
import { useFlags } from '../../common/flags'
import { matomoPcnaTrackClickEvent } from '../../matomo/matomo'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { CheckoutApp } from '../../presenter/checkoutApp'
import { NewProfileService, selectEmail } from '../../services/newProfileService'
import { SessionStorageService } from '../../services/sessionStorageService'
import { defaultErrorHandler } from '../../util/defaultErrorHandler'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'
import { GraySimpleTile } from '../stack/GraySimpleTile'
import { IStackElementContext, StackElement, StackElementState } from '../stack/stackElement'
import { ContinueOption, getSignUpUrl, LoginPrompt } from './loginPrompt'

export const LOGIN_STACK_NAME = 'login'
export const GUEST_CHECKOUT_WINDOW_EVENT = 'change-to-guest-flow-event'
export const LOGGING_OR_REGISTERING = 'logging-or-registering'

async function trackLoginEvent(presenter: CheckoutApp) {
  trackClickEvent(AnalyticsEventTemplates.LOGIN_STEP_COMPLETE_FOR_LOGIN_REGISTER('login'), {
    targetUrl: (await presenter.authUrl)?.url
  })
}

function clearStorageDataOnFlowSwitch(
  guestFlowSelected: boolean,
  presenter: CheckoutApp,
  enablePcnaPaymentStep: boolean
) {
  const isGuestFlowString = SessionStorageService.getPrefixedItem(IS_GUEST_STORAGE_KEY)
  const isReturningToLoginStep = !!isGuestFlowString

  if (!isReturningToLoginStep) {
    return
  }

  const isGuestFlow = isGuestFlowString === 'true'
  const isSwitchingFlow = guestFlowSelected !== isGuestFlow

  if (isSwitchingFlow) {
    clearStoredData(presenter, enablePcnaPaymentStep)
  }
}

function clearStoredData(presenter: CheckoutApp, enablePcnaPaymentStep: boolean) {
  const opportunity = presenter.opportunityStore.opportunityCache

  // personal details
  opportunity.customerProfile = undefined
  SessionStorageService.deletePrefixedItem(CheckoutStorageKeys.GUEST_PROFILE_VALUES)

  // trade-in
  if (opportunity.tradeInVehicle?.valuation?.provider.toLowerCase() !== 'kbb') {
    SessionStorageService.deletePrefixedItem(CheckoutStorageKeys.TRADE_IN_VALUES)
    opportunity.tradeInVehicle = undefined
  }

  // ancillary products
  SessionStorageService.deletePrefixedItem(CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES)
  if (opportunity.product?.payload?.ancillaryProducts) {
    opportunity.product.payload.ancillaryProducts = undefined
  }

  // payment - data is saved already in SOS, so no deletion

  // delivery options
  SessionStorageService.deletePrefixedItem(CheckoutStorageKeys.DELIVERY_OPTIONS_VALUES)
  presenter.updateOrderInformationForDealershipDelivery(opportunity, enablePcnaPaymentStep)
}

export const EditView: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { enablePcnaPaymentStep } = useFlags()
  const { trackEvent } = useMatomo()

  const removeOidcTokenFromStorage = () => {
    Object.keys(localStorage)
      .filter((key) => key.startsWith('oidc.user'))
      .forEach((oidcTokenKey) => {
        localStorage.removeItem(oidcTokenKey)
      })
  }

  const continueHandler = async (continueOption: ContinueOption) => {
    clearStorageDataOnFlowSwitch(continueOption === ContinueOption.GUEST, presenter, enablePcnaPaymentStep)

    switch (continueOption) {
      case ContinueOption.GUEST:
        matomoPcnaTrackClickEvent(trackEvent, 'lor-guest')
        trackClickEvent(AnalyticsEventTemplates.CONTINUE_AS_GUEST_STEP_COMPLETE_FOR_LOGIN_REGISTER, {
          targetUrl: (await presenter.authUrl)?.url
        })

        SessionStorageService.setPrefixedItem(IS_GUEST_STORAGE_KEY, 'true')
        window.dispatchEvent(new Event(GUEST_CHECKOUT_WINDOW_EVENT))

        presenter.stackManager.editNext()
        break

      case ContinueOption.REGISTER:
        matomoPcnaTrackClickEvent(trackEvent, 'lor-register')
        trackClickEvent(AnalyticsEventTemplates.LOGIN_STEP_COMPLETE_FOR_LOGIN_REGISTER('register'), {
          targetUrl: (await presenter.authUrl)?.url
        })

        SessionStorageService.setPrefixedItem(IS_GUEST_STORAGE_KEY, 'false')

        SessionStorageService.setPrefixedItem(LOGGING_OR_REGISTERING, 'true')

        window.location.href = getSignUpUrl()
        break

      case ContinueOption.LOGIN:
        matomoPcnaTrackClickEvent(trackEvent, 'lor-login')
        await trackLoginEvent(presenter)

        SessionStorageService.setPrefixedItem(IS_GUEST_STORAGE_KEY, 'false')
        SessionStorageService.setPrefixedItem(LOGGING_OR_REGISTERING, 'true')

        removeOidcTokenFromStorage()
        presenter.login()
        break
    }
  }

  return <LoginPrompt marketPlace={opportunity.marketPlace?.toLowerCase()} onContinue={continueHandler} />
}

const CompletedView: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null)
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const intl = useIntl()

  SessionStorageService.deletePrefixedItem(LOGGING_OR_REGISTERING)

  useEffect(() => {
    function getProfileService() {
      return new NewProfileService(defaultErrorHandler)
    }

    if (opportunity && presenter.user && !isGuest()) {
      opportunity.customerProfile = opportunity.customerProfile ?? {}

      void getProfileService()
        .fetchEmails(presenter.user)
        .then(selectEmail)
        .then((emailAddress) => {
          presenter.opportunityStore.setCustomerProfile({ ...opportunity.customerProfile, emailAddress })
          return emailAddress
        })
        .then(setEmail)

      if (!opportunity.customerProfile?.ciamID) {
        void getProfileService()
          .fetchProfile(presenter.user)
          .then((profile) => {
            presenter.opportunityStore.setCustomerProfile({
              ...opportunity.customerProfile,
              ...profile,
              // discrepancy between profile and SOS
              salutation: profile.salutationValue,
              // case discrepancy between profile and SOS
              ciamID: profile.ciamId,
              payload: opportunity.customerProfile?.payload,
              addresses: opportunity.customerProfile?.addresses
            })
          })
      }
    }
  }, [opportunity, presenter.user, presenter.opportunityStore])

  const isGuestFlow = isGuest()

  return (
    <CompletedTile>
      <PText>
        {isCheckout2Enabled() && email && !isGuestFlow && (
          <>
            <FormattedMessage id='login2.title.loggedin' />
            &nbsp;{email}
          </>
        )}

        {isCheckout2Enabled() && isGuestFlow && <FormattedMessage id='login2.title.loggedin.asguest' />}

        {!isCheckout2Enabled() &&
          email &&
          `${intl.formatMessage({ id: 'login.title.loggedin' })} ${email}. ${intl.formatMessage({
            id: 'login.title.loggedin.sub'
          })}`}

        {!isCheckout2Enabled() && !email && <FormattedMessage id='login.title.loggedin.asguest' />}
      </PText>
    </CompletedTile>
  )
}

const LockedView: React.FC = () => {
  const intl = useIntl()

  return <GraySimpleTile prompt={intl.formatMessage({ id: 'login.locked.prompt' })} />
}

const desiredState = (context: IStackElementContext) => {
  if (isCheckout2Enabled() && context.user && SessionStorageService.getPrefixedItem(LOGGING_OR_REGISTERING)) {
    return StackElementState.COMPLETED
  }

  if (!isCheckout2Enabled() && context.user) {
    return StackElementState.COMPLETED
  }

  return StackElementState.EDIT
}

const trackEventForAuthenticatedUser = (presenter: CheckoutApp) => {
  if (presenter.stackManager.getCurrentStep()?.stackName === LOGIN_STACK_NAME && presenter.user) {
    void trackLoginEvent(presenter)
  }
}

export class LoginRegisterStackElement extends StackElement {
  constructor(index: number, label: string, presenter: CheckoutApp) {
    const analyticsEvent = {
      analyticsPage: AnalyticsPage.LOGIN,
      analyticsMap: presenter.authIsLoading
        ? undefined
        : {
            [StackElementState.EDIT]: {
              oneGAEvent: AnalyticsEventTemplates.LOGIN,
              matomoActionName: 'checkout/login'
            }
          }
    }

    trackEventForAuthenticatedUser(presenter)

    super(EditView, CompletedView, LockedView, desiredState, LOGIN_STACK_NAME, index, label, 'key', analyticsEvent)
  }
}
