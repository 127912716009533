import React, { useContext, useEffect, useState } from 'react'
import { PContentWrapper, PFlex, PFlexItem, PText } from '@porsche-design-system/components-react'
import { PresenterContext } from '../../../entry/presenterProvider'
import { Checkout2OrderBox } from './Checkout2OrderBox'
import { LogoHeader2 } from '../../../entry/LogoHeader/LogoHeader2'
import { formatPhoneNumber } from '@slatldisal/checkout-i18n'

const JourneyStep: React.FC<{ number: number; title: string; text: string; titleHeight?: number }> = ({
  number,
  title,
  text,
  titleHeight
}) => {
  return (
    <div className='journey-step'>
      <PText size='medium' weight='bold' className='journey-step-circle small-space-bottom'>
        {number}
      </PText>
      <PText
        size='medium'
        weight='bold'
        align='center'
        className='journey-step-title'
        style={{ height: titleHeight ? `${titleHeight}px` : 'auto' }}
      >
        {title}
      </PText>
      <PText className='medium-space-top'>{text}</PText>
    </div>
  )
}

interface IPcnaConfirmationHeaderProps {
  isAoac?: boolean
}

interface IJourneyStepProps {
  titleHeight: number
}

const JourneySteps: React.FC<IJourneyStepProps> = ({ titleHeight }) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const phoneNumber = opportunity.customerProfile?.phoneNumber
  const displayName = opportunity.owner?.displayName ?? '(name not available)'

  return (
    <PFlex direction={{ base: 'column', s: 'row' }} justifyContent='space-between' className='journey-steps'>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={1}
          title='Your Request is under Review'
          text={`A representative from your authorized designated dealer will confirm your request for your selected Porsche vehicle and contact you at ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            formatPhoneNumber(phoneNumber) ?? 'the number you provided'
          }.`}
          titleHeight={titleHeight}
        />
      </PFlexItem>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={2}
          title='Come and see the vehicle for yourself'
          text='We would love to have you come into the dealership and get in the driver’s seat for a test drive.'
          titleHeight={titleHeight}
        />
      </PFlexItem>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={3}
          title={`Discuss final steps and contracts with ${String(displayName)}`}
          text='Your Porsche representative will discuss any offers and additional options available on the selected vehicle for you to make this Porsche special.'
        />
      </PFlexItem>
    </PFlex>
  )
}

const AoacJourneySteps: React.FC<IJourneyStepProps> = ({ titleHeight }) => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const phoneNumber = opportunity.customerProfile?.phoneNumber
  const displayName = opportunity.owner?.displayName ?? '(name not available)'

  return (
    <PFlex direction={{ base: 'column', s: 'row' }} justifyContent='space-between' className='journey-steps'>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={1}
          title={`You will be contracted by  ${String(displayName)} to discuss your request`}
          text={`The authorized Porsche Dealer will confirm your request for the selected Porsche vehicle, and call you at ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            formatPhoneNumber(phoneNumber) ?? 'the number you provided'
          }.`}
          titleHeight={titleHeight}
        />
      </PFlexItem>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={2}
          title='Come and see the vehicle for yourself'
          text='We would love to have you come into the dealership and get in the drivers seat for a test drive.'
        />
      </PFlexItem>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={3}
          title={`Discuss your credit application with ${String(displayName)}.`}
          text='Your Porsche representative will review your application and call you to discuss the terms and conditions to fit your financial needs.'
          titleHeight={titleHeight}
        />
      </PFlexItem>
      <PFlexItem flex='equal'>
        <JourneyStep
          number={4}
          title={`Discuss final steps and contracts with ${String(displayName)}`}
          text='Your Porsche representative will discuss any offers and additional options available on the selected vehicle for you to make this Porsche special.'
        />
      </PFlexItem>
    </PFlex>
  )
}

/** Naive approach to get height of last title element, assuming its translation is the longest */
function getMaxStepHeight(): number {
  const stepTitleElements = document.querySelectorAll('p-text.journey-step-title')
  return stepTitleElements[stepTitleElements.length - 1].getBoundingClientRect().height
}

export const Checkout2ConfirmationHeader: React.FC<IPcnaConfirmationHeaderProps> = (props) => {
  const [maxStepTitleHeight, setMaxStepTitleHeight] = useState(0)
  const [parallaxImgOffset, setParallaxImgOffset] = useState(0)
  const resizeHandler = () => setMaxStepTitleHeight(getMaxStepHeight())
  const scrollHandler = () => setParallaxImgOffset(window.scrollY / 2.2)

  useEffect(() => {
    setTimeout(() => setMaxStepTitleHeight(getMaxStepHeight()), 100) // if without delay, it runs before render

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <>
      <div className='confirmation-header-image'>
        <img
          src='/images/confirmation/header.jpg'
          alt='Confirmation page'
          style={{ transform: `translateY(${parallaxImgOffset}px)` }}
        />
        <div className='header-image-gradient' />
      </div>
      <div>
        <LogoHeader2 />
        <Checkout2OrderBox />

        <PContentWrapper>
          <PText theme='dark' size='large' weight='semibold' className='small-space-bottom'>
            What to Expect Next
          </PText>
          {props.isAoac ? (
            <AoacJourneySteps titleHeight={maxStepTitleHeight} />
          ) : (
            <JourneySteps titleHeight={maxStepTitleHeight} />
          )}
        </PContentWrapper>
      </div>
    </>
  )
}
