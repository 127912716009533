import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PGrid } from '@porsche-design-system/components-react'
import { formatMiles } from '@slatldisal/checkout-i18n'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { trackClickEvent } from '@slatldisal/one-ga'
import { AutoCheckLine, CarfaxLine } from '@slatldisal/vehicle-history-report'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { isNewVehicle, showCarfax } from '../../../../common/checkout'
import { useFlags } from '../../../../common/flags'
import { config } from '../../../../config'
import { getDynamicMatomoClickEventAction, matomoPcnaTrackClickEvent } from '../../../../matomo/matomo'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../entry/presenterProvider'
import { AccordionStepData, StepDataRow } from './common'
import { WarrantyStepData } from './warranty/WarrantyStepData'

export const VehicleDetailsStepData: React.FC = observer(() => {
  const intl = useIntl()
  const { trackEvent } = useMatomo()
  const {
    enableVehicleDetailsInSidebar,
    enableHistoryReportInSidebar,
    enableWarrantyInformationInSidebar,
    enableCarfaxInCheckout
  } = useFlags()
  const { smallerThan: isMobile } = useBreakpoint('m')
  const analyticsPage = isMobile ? AnalyticsPage.MOBILE_SLIDE_OUT : AnalyticsPage.DESKTOP_SIDEBAR
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const isNew = isNewVehicle(opportunity)
  const previousOwners = opportunity.vehicle.numberOfPreviousOwners
  const formattedMiles = formatMiles({ numericValue: opportunity.vehicle.mileage, unitCode: 'MILES' })
  const exteriorColor = opportunity.vehicle.payload?.exteriorColor
  const interiorColor = opportunity.vehicle.payload?.interiorColor
  const transmission = opportunity.vehicle.transmission

  if (!enableVehicleDetailsInSidebar) {
    return <></>
  }

  return (
    <AccordionStepData heading={intl.formatMessage({ id: 'common.vehicleDetails' })} autoCloseOnStepIndex={1}>
      <PGrid>
        {(previousOwners || previousOwners === 0) && (
          <StepDataRow labelKey='common.previousOwners'>{previousOwners}</StepDataRow>
        )}
        {formattedMiles && <StepDataRow labelKey='common.mileage'>{formattedMiles}</StepDataRow>}

        {enableHistoryReportInSidebar && !isNew && (
          <StepDataRow label='Vehicle History'>
            <AutoCheckLine
              vin={opportunity.vehicle.vin}
              dealerId={opportunity.vehicle.payload?.dealerId}
              apiUrl={config().vehicleHistory.endpointUrl}
              onClick={() => {
                matomoPcnaTrackClickEvent(
                  trackEvent,
                  getDynamicMatomoClickEventAction('see-vehicle-history-report', analyticsPage)
                )
                trackClickEvent(AnalyticsEventTemplates.AUTOCHECK_VEHICLE_DETAILS_CLICK(analyticsPage))
              }}
            />
          </StepDataRow>
        )}

        {showCarfax(opportunity, enableCarfaxInCheckout) && (
          <StepDataRow label='Vehicle History'>
            <CarfaxLine
              env={config().environment}
              marketplace={config().market}
              listingId={opportunity.vehicle.payload?.listingId}
              onClick={() => {
                matomoPcnaTrackClickEvent(
                  trackEvent,
                  getDynamicMatomoClickEventAction('see-carfax-vehicle-history-report', analyticsPage)
                )
                trackClickEvent(AnalyticsEventTemplates.CARFAX_VEHICLE_HISTORY_REPORT_CLICK(analyticsPage))
              }}
            />
          </StepDataRow>
        )}

        {enableWarrantyInformationInSidebar && opportunity.vehicle.payload?.warranty && (
          <StepDataRow labelKey='common.warranty'>
            <WarrantyStepData />
          </StepDataRow>
        )}

        {exteriorColor && <StepDataRow labelKey='common.exterior'>{exteriorColor}</StepDataRow>}
        {interiorColor && <StepDataRow labelKey='common.interior'>{interiorColor}</StepDataRow>}
        {transmission && <StepDataRow labelKey='common.transmission'>{transmission}</StepDataRow>}
      </PGrid>
    </AccordionStepData>
  )
})
