import React from 'react'

export function onEnterKeyDown<T>(
  event: React.KeyboardEvent<T>,
  actionFunction: (event: React.KeyboardEvent<T>) => any
) {
  if (event.key === 'Enter') {
    return actionFunction(event)
  }
}
