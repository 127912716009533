export const CURRENT_OPPORTUNITY_ID_STORAGE_KEY = 'current-opportunity'

/**
 * Session storage service to set and read items prefixed with opportunity id
 */
export class SessionStorageService {
  static setPrefixedItem(key: string, value: string | Record<string, any>) {
    const opportunityId = this.getOpportunityId()
    global.sessionStorage.setItem(`${opportunityId}-${key}`, typeof value === 'string' ? value : JSON.stringify(value))
  }

  static getPrefixedItem(key: string): string | null {
    const opportunityId = this.getOpportunityId()
    return global.sessionStorage.getItem(`${opportunityId}-${key}`)
  }

  static deletePrefixedItem(key: string) {
    const opportunityId = this.getOpportunityId()
    global.sessionStorage.removeItem(`${opportunityId}-${key}`)
  }

  static getAndParsePrefixedItem<T>(key: string): T | undefined {
    const opportunityId = this.getOpportunityId()
    const sessionStorageValue = global.sessionStorage.getItem(`${opportunityId}-${key}`)

    if (sessionStorageValue) {
      try {
        return JSON.parse(sessionStorageValue)
      } catch (e) {}
    }
    return undefined
  }

  private static getOpportunityId(): string {
    return global.sessionStorage.getItem(CURRENT_OPPORTUNITY_ID_STORAGE_KEY)!
  }
}
