import { PFlex, PFlexItem, PHeadline } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import React, { useContext, useEffect } from 'react'
import { LearnMoreIcon } from '../checkout2/learnMoreIcon/LearnMoreIcon'
import { PresenterContext } from '../entry/presenterProvider'
import { IExpandedStep2Props } from './ExpandedStep2'
import './ExpandedStep2.scss'
import { PageLoadAnalytics, scrollTo, StackElementState } from './stackElement'
import { StepCircle2 } from './StepCircle2'

export const ExpandedStep2Mobile: React.FC<IExpandedStep2Props> = ({
  index,
  analyticsMap,
  children,
  visibleOnlyOnEdit
}) => {
  const presenter = useContext(PresenterContext)
  const stepTitle = presenter.stackManager.getCurrentStep().title
  const { smallerThan: isMobile } = useBreakpoint('s')

  useEffect(() => scrollTo(index), [index])

  return (
    <PageLoadAnalytics state={StackElementState.EDIT} analyticsMap={analyticsMap}>
      <div
        className={`card2 xxsmall-space-bottom ${visibleOnlyOnEdit ? 'no-border' : 'card-border'}`}
        id={`wizard-step-${index + 1}`}
      >
        {!visibleOnlyOnEdit && (
          <PFlex direction='row' alignItems='center' className='card-content medium-space-top big-space-right'>
            <PFlexItem>
              <StepCircle2 index={index} />
            </PFlexItem>
            <PFlexItem>
              <PHeadline tag='h2' variant='headline-3'>
                {stepTitle}
              </PHeadline>
            </PFlexItem>
            <PFlexItem style={{ paddingLeft: '5px' }}>
              <LearnMoreIcon />
            </PFlexItem>
          </PFlex>
        )}
        <div
          className='large-space-left medium-space-right small-space-top'
          style={{ marginRight: isMobile ? '32px' : '' }}
        >
          {children}
        </div>
      </div>
    </PageLoadAnalytics>
  )
}
