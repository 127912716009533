import React, { useContext } from 'react'
import { PGrid, PHeadline, PText } from '@porsche-design-system/components-react'
import { trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../entry/presenterProvider'
import { LabelBox } from '../shared/ui'

interface IPcnaConfirmationHeaderProps {
  isAoac?: boolean
}

const JourneyHeader: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const dealerPhone = opportunity.vehicle.payload?.dealerPhone as string

  return (
    <>
      <PHeadline variant='headline-2' align='center' className='big-space-top medium-space-bottom'>
        Your journey begins with:
      </PHeadline>
      <PText size='medium' align='center'>
        {opportunity.owner?.displayName}
      </PText>
      <PText size='medium' align='center' className='big-space-bottom'>
        <a href={`tel:${dealerPhone}`} onClick={() => trackClickEvent(AnalyticsEventTemplates.CONFIRM_CALL_RETAILER)}>
          {dealerPhone}
        </a>
      </PText>
    </>
  )
}

const JourneySteps: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const phoneNumber = opportunity.customerProfile?.phoneNumber
  const displayName = opportunity.owner?.displayName ?? '(name not available)'

  return (
    <>
      <PGrid direction={{ base: 'column', s: 'row' }}>
        <LabelBox
          title='1. The dealer will confirm the details of your request and vehicle availability.'
          text={`A representative from the authorized Porsche dealer will confirm your request for the selected Porsche 
          vehicle, and call you at ${phoneNumber ?? 'the number you provided'}.`}
          hideBackground
        />
        <LabelBox
          title='2. Come and see the vehicle for yourself.'
          text='We would love to have you come into the dealership and get in the driver’s seat for a test drive.'
          hideBackground
        />
        <LabelBox
          title={`3. Discuss final steps and contracts with  ${displayName}.`}
          text='Your Porsche representative will discuss any offers and additional options available on the selected
          vehicle for you to make this Porsche special.'
          hideBackground
        />
      </PGrid>
    </>
  )
}

export const AoacJourneySteps: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const phoneNumber = opportunity.customerProfile?.phoneNumber
  const displayName = opportunity.owner?.displayName ?? '(name not available)'

  return (
    <>
      <PGrid direction={{ base: 'column', s: 'row' }}>
        <LabelBox
          title={`1. You will be contracted by  ${displayName} to discuss your request`}
          text={`The authorized Porsche Dealer will confirm your request for the selected Porsche vehicle, and call you at ${
            (phoneNumber as string) ?? 'the number you provided'
          }.`}
          small
          hideBackground
        />
        <LabelBox
          title='2. Come and see your dream Porsche vehicle'
          text='We would love to have you come into the dealership and get in the drivers seat for a test drive.'
          small
          hideBackground
        />
        <LabelBox
          title={`3. Discuss your credit application with ${displayName}.`}
          text='Your Porsche representative will review your application and call you to discuss the terms and conditions to fit your financial needs.'
          small
          hideBackground
        />
        <LabelBox
          title={`4. Discuss final steps and contracts with  ${displayName}.`}
          text='Your Porsche representative will discuss any offers and additional options available on the selected vehicle to make this Porsche special.'
          small
          hideBackground
        />
      </PGrid>
    </>
  )
}

export const PcnaConfirmationHeader: React.FC<IPcnaConfirmationHeaderProps> = (props) => {
  return (
    <>
      <JourneyHeader />
      {props.isAoac ? <AoacJourneySteps /> : <JourneySteps />}
    </>
  )
}
