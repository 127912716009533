import { PFlex, PFlexItem, PHeadline, PText, PContentWrapper } from '@porsche-design-system/components-react'
import { formatCurrency } from '@slatldisal/checkout-i18n'
import { Checkout2ProgressBar, useBreakpoint } from '@slatldisal/checkout-shared-components'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { getPriceLabel as getTotalPrice, ICheckoutOpportunity } from '../../../common/checkout'
import { config } from '../../../config'
import { AnalyticsPage, MsrpElementId } from '../../../onega/AnalyticsEventTemplates'
import { VehicleSummaryYourPorscheModel } from '../../checkout2/vehicleSummary/desktop/VehicleSummaryYourPorscheModel'
import { MobileVehicleSummary } from '../../checkout2/vehicleSummary/mobile/MobileVehicleSummary'
import { PresenterContext } from '../../entry/presenterProvider'
import { MsrpPricingSection } from '../../vehicleSelection/cash/CashVehicleSelection'
import { vehicleSelectionStore } from '../../vehicleSelection/pcna/vehicleSelectionStore'
import { OpportunityVehicleSelectionImage } from '../../vehicleSelection/vehicleSelectionImage/VehicleSelectionImage'
import './Checkout2Navigation.scss'
import { ICheckoutNavigation } from './CheckoutNavigation'

import './WizardNavigation.scss'
import { PorscheApprovedSection } from '../../checkout2/porscheApproved/PorscheApprovedSection'

function getPriceLabel(opportunity: ICheckoutOpportunity, intl: IntlShape): string {
  const financialProduct = vehicleSelectionStore.calculationResult
  const isLease = financialProduct?.creditType === 'LSE'
  const isFinance = financialProduct?.creditType === 'RTL'

  if (isLease) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${formatCurrency(financialProduct.monthlyLeasePayment)} ${intl.formatMessage({
      id: 'common.perMonthAbbrev'
    })}`
  } else if (isFinance) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${formatCurrency(financialProduct.monthlyFinancePayment)} ${intl.formatMessage({
      id: 'common.perMonthAbbrev'
    })}`
  } else {
    return formatCurrency(opportunity.product?.price?.value ?? 0)
  }
}

export const Checkout2Navigation: React.FC<ICheckoutNavigation> = observer(({ isSticky, stickyRef }) => {
  const presenter = useContext(PresenterContext)
  const intl = useIntl()
  const opportunity = presenter.opportunityStore.opportunityCache
  const steps = presenter.stackManager.steps

  const [slideOutState, setSlideOutState] = useState<'hidden' | 'visible'>('hidden')
  const [slideOutAnimation, setSlideOutAnimation] = useState<'open' | 'close'>('close')

  const priceLabel = getPriceLabel(opportunity, intl)
  const currentStep = presenter.stackManager.currentStepIndex
  const { smallerThan: isMobile } = useBreakpoint('m')

  const ProgressBar: React.FC<{ price?: string; showMobileSummaryModal?: () => void }> = ({
    price,
    showMobileSummaryModal
  }) => (
    <div className='logo-and-progress-bar-container'>
      <div className='progress-bar'>
        <Checkout2ProgressBar
          steps={steps}
          currentStep={currentStep}
          lang={config().locale}
          price={price}
          showMobileSummaryModal={showMobileSummaryModal}
          onClick={(stepIndex: number) => presenter.stackManager.setCurrentEditIndex(stepIndex)}
        />
      </div>
    </div>
  )

  const MobileNavVehicleSummary: React.FC = () => {
    const dealerName = opportunity.owner?.displayName
    return (
      <>
        <div className='your-porsche'>
          <VehicleSummaryYourPorscheModel />
          {dealerName && <PText className='xsmall-space-top'>At {dealerName}</PText>}
          <PorscheApprovedSection analyticsPage={AnalyticsPage.MOBILE_VEHICLE_SUMMARY} />
        </div>

        <PFlex>
          <PFlexItem width='two-thirds' style={{ padding: '20px' }}>
            <OpportunityVehicleSelectionImage viewMode='one-third-tile' opportunity={opportunity} />
          </PFlexItem>

          <PFlexItem width='one-third'>
            <PFlex direction='column' justifyContent='center' style={{ height: '100%' }}>
              <PFlexItem>
                <PHeadline variant='headline-2'>{formatCurrency(opportunity.product?.price?.value ?? 0)}</PHeadline>
              </PFlexItem>

              <PFlexItem className='xsmall-space-top'>
                <PText>
                  <FormattedMessage id={getTotalPrice(opportunity)} />
                </PText>
              </PFlexItem>

              <PFlexItem>
                <MsrpPricingSection
                  opportunity={opportunity}
                  analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
                  analyticsElementId={MsrpElementId.MOBILE}
                />
              </PFlexItem>
            </PFlex>
          </PFlexItem>
        </PFlex>
      </>
    )
  }

  const openSlideOut = () => {
    setSlideOutState('visible')
    document.querySelector('body')!.classList.add('disable-scroll-y')

    setSlideOutAnimation('open')
  }

  const closeSlideOut = () => {
    setSlideOutAnimation('close')
    document.querySelector('body')!.classList.remove('disable-scroll-y')

    setTimeout(() => setSlideOutState('hidden'), 200)
  }

  return (
    <>
      <PContentWrapper className='styled-non-sticky-content-wrapper' width='fluid'>
        <ProgressBar />
        {isMobile && <MobileNavVehicleSummary />}
      </PContentWrapper>
      <span ref={stickyRef} />
      <nav id='sticky-progress-bar' className={`${isSticky ? 'visible ' : 'invisible '} styled-sticky-container`}>
        <PContentWrapper className='sticky-wrapper' width='fluid'>
          <div className='sticky-wrapper-child'>
            <ProgressBar price={priceLabel} showMobileSummaryModal={openSlideOut} />
          </div>
        </PContentWrapper>
      </nav>
      <MobileVehicleSummary state={slideOutState} animation={slideOutAnimation} closeFn={closeSlideOut} />
    </>
  )
})
