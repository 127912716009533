import { PModal } from '@porsche-design-system/components-react'
import { trackPageLoadEvent } from '@slatldisal/one-ga'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'

export interface IPlanViolationModalProps {
  message: string
  open: boolean
  onClose: () => void
}

export const PlanViolationModal: React.FC<IPlanViolationModalProps> = ({ message, open, onClose }) => {
  const intl = useIntl()

  useEffect(() => trackPageLoadEvent(AnalyticsEventTemplates.PROTECTION_PLANS_CONFLICT), [])

  const modal = (
    <PModal
      data-testid='plan-violation-modal'
      heading={intl.formatMessage({ id: 'ancillaryProducts.selection.violation.header' })}
      open={open}
      onClose={onClose}
    >
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </PModal>
  )

  return ReactDOM.createPortal(modal, document.body)
}
