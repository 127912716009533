import { observer } from 'mobx-react-lite'
import React from 'react'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { StackElement, StackElementState } from '../../stack/stackElement'
import { ReviewModal } from './ReviewModal/ReviewModal'

export const REVIEW_STACK_NAME = 'review'

const CompletedView: React.FC = () => {
  return <div>never should be here</div>
}

export const EditView: React.FC = observer(() => {
  return <ReviewModal />
})

const LockedView: React.FC = () => {
  return <></>
}

export class ReviewStackElement2 extends StackElement {
  constructor(index: number, label: string) {
    super(
      (props) => <EditView {...props} />,
      CompletedView,
      LockedView,
      () => StackElementState.EDIT,
      REVIEW_STACK_NAME,
      index,
      label,
      'list',
      {
        analyticsPage: AnalyticsPage.ORDER_OVERVIEW,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.ORDER_OVERVIEW,
            matomoActionName: 'checkout/order_overview'
          }
        }
      },
      true
    )
  }
}
