import { PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'

import { GraySimpleTile } from '../stack/GraySimpleTile'
import { StackElement, StackElementState } from '../stack/stackElement'
import { DeliveryOptions } from './DeliveryOptions'

export const DELIVERY_OPTIONS_STACK_NAME = 'delivery'

const LockedView: React.FC = () => {
  const intl = useIntl()

  return <GraySimpleTile prompt={intl.formatMessage({ id: 'delivery.locked.prompt' })} />
}

const CompletedView: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const { orderInformation } = presenter.opportunityStore.opportunityCache

  return (
    <CompletedTile>
      <PText>
        {orderInformation?.deliveryOptions.deliveryOption === 'HOME'
          ? intl.formatMessage({ id: 'delivery.complete.home' })
          : intl.formatMessage({ id: 'delivery.complete.dealer' })}
      </PText>
    </CompletedTile>
  )
}

const EditView: React.FC = () => {
  return <DeliveryOptions />
}

export class DeliveryOptionStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedView,
      LockedView,
      () => StackElementState.EDIT,
      DELIVERY_OPTIONS_STACK_NAME,
      index,
      label,
      'truck',
      { analyticsPage: AnalyticsPage.DELIVERY_OPTIONS }
    )
  }
}
