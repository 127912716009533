import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PButton, PButtonGroup } from '@porsche-design-system/components-react'
import { trackClickEvent } from '@slatldisal/one-ga'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { isFinancePricingAvailable, isGuest, isLeaseOrFinance } from '../../../../common/checkout'
import { useFlags } from '../../../../common/flags'
import { matomoPcnaTrackClickEvent } from '../../../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../../../onega/AnalyticsEventTemplates'
import AoacModal from '../../../aoac/aoacModal'
import { PresenterContext } from '../../../entry/presenterProvider'
import { applyHandler, submitHandler } from '../../common'

export const ReviewModalCTA: React.FC<{ setReviewModalOpen: Function }> = observer(({ setReviewModalOpen }) => {
  const presenter = useContext(PresenterContext)
  const { trackEvent } = useMatomo()
  const navigate = useNavigate()
  const { enableCheckoutFinancingButton } = useFlags()
  const [isApplyModalOpen, setApplyModalOpen] = useState(false)

  const opportunity = presenter.opportunityStore.opportunityCache

  const showAoacCTA =
    enableCheckoutFinancingButton &&
    isFinancePricingAvailable(opportunity) &&
    isLeaseOrFinance(opportunity) &&
    !isGuest()

  return (
    <>
      <PButtonGroup className='big-space-top'>
        <PButton
          variant='secondary'
          loading={presenter.submitIsInProgress}
          onClick={async () => submitHandler({ presenter, navigate, trackEvent })}
        >
          <FormattedMessage id='review.cta.submit' />
        </PButton>
        {showAoacCTA && (
          <PButton
            variant='tertiary'
            onClick={() => {
              setReviewModalOpen(false)
              setApplyModalOpen(true)
            }}
          >
            <FormattedMessage id='review.cta.applyForFinancing' />
          </PButton>
        )}
        <PButton
          variant='tertiary'
          onClick={() => {
            matomoPcnaTrackClickEvent(trackEvent, 'review-and-confirm-cancel')
            trackClickEvent(AnalyticsEventTemplates.REVIEW_MODAL_CANCEL)

            presenter.stackManager.editBack(1)
          }}
        >
          <FormattedMessage id='review.cta.cancel' />
        </PButton>
      </PButtonGroup>

      <AoacModal
        applyHandler={async () => applyHandler({ presenter, navigate, trackEvent })}
        onRequestClose={() => {
          matomoPcnaTrackClickEvent(trackEvent, 'maff-cancel')
          setApplyModalOpen(false)
          setReviewModalOpen(true)
        }}
        open={isApplyModalOpen}
      />
    </>
  )
})
