import { PGrid, PGridItem, PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { getPriceLabel } from '../../../../common/checkout'
import { AnalyticsPage, MsrpElementId } from '../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../entry/presenterProvider'
import { MsrpPricingSection } from '../../../vehicleSelection/cash/CashVehicleSelection'
import { OpportunityVehicleSelectionImage } from '../../../vehicleSelection/vehicleSelectionImage/VehicleSelectionImage'
import { VehicleSummaryStepsData } from '../VehicleSummaryStepsData'
import { CashVehicleSummaryDisclaimer } from './CashVehicleSummary'
import { VehicleSummaryYourPorscheModel } from './VehicleSummaryYourPorscheModel'
import { PorscheApprovedSection } from '../../porscheApproved/PorscheApprovedSection'

export const DefaultVehicleSummary: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  return (
    <div className='sidebar-card'>
      <VehicleSummaryYourPorscheModel />

      <OpportunityVehicleSelectionImage
        viewMode='one-third-tile'
        opportunity={opportunity}
        className='small-space-top small-space-bottom'
      />

      <div style={{ width: '100%' }}>
        <PGrid gutter={16}>
          <PGridItem size={6}>
            <PText size='medium' weight='semibold' align='right'>
              <FormattedNumber
                value={opportunity.product?.price?.value ?? 0}
                style='currency'
                currency={opportunity.product?.price?.currencyCode}
                currencyDisplay='narrowSymbol'
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </PText>
          </PGridItem>
          <PGridItem size={6} style={{ display: 'flex', alignItems: 'center' }}>
            <FormattedMessage id={getPriceLabel(opportunity)} />
          </PGridItem>
          <PGridItem offset={6} size={6}>
            <MsrpPricingSection
              opportunity={opportunity}
              analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
              analyticsElementId={MsrpElementId.SIDEBAR_DESKTOP}
            />
          </PGridItem>
        </PGrid>
      </div>

      <PText className='small-space-top'>Dealer {opportunity.owner?.displayName}</PText>
      <PorscheApprovedSection analyticsPage={AnalyticsPage.DESKTOP_SIDEBAR} />

      <CashVehicleSummaryDisclaimer align='center' className='medium-space-top' />

      <div style={{ width: '100%' }}>
        <VehicleSummaryStepsData />
      </div>
    </div>
  )
}
