import React, { useContext } from 'react'
import { PFlex, PHeadline, PIcon } from '@porsche-design-system/components-react'
import { PresenterContext } from '../../entry/presenterProvider'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { useFlags } from '../../../common/flags'

export const OrderConfirmationBox: React.FC = observer(() => {
  const { enablePcnaPaymentStep } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const depositJourney = presenter.isDepositJourney(enablePcnaPaymentStep)

  const referenceNo = opportunity.orderInformation?.orderId

  const vehicleModel = opportunity.vehicle.modelName

  const { largerThan: isDesktop } = useBreakpoint('m')

  return (
    <>
      <div
        className={`${
          isDesktop ? 'big-space-top big-space-bottom' : 'small-space-top small-space-bottom'
        } confirmation-order-number-box`}
      >
        <PFlex justifyContent='center' alignItems='center'>
          <PIcon className='check-icon' name='check' color='notification-success' />
          <PHeadline variant='headline-4'>
            <b>
              <FormattedMessage id='confirmation.orderBox.text' values={{ vehicleModel }} />
              {depositJourney && (
                <span style={{ whiteSpace: 'nowrap' }}>
                  {referenceNo ? (
                    <FormattedMessage id='confirmation.orderBox.referenceNo' values={{ referenceNo }} />
                  ) : (
                    <FormattedMessage id='confirmation.orderBox.referenceNoPending' />
                  )}
                </span>
              )}
            </b>
          </PHeadline>
        </PFlex>
      </div>
    </>
  )
})
