import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PText, TextWeight } from '@porsche-design-system/components-react'
import { CashVehicleSummaryShell } from './CashVehicleSummary'
import { vehicleSelectionStore } from '../../../vehicleSelection/pcna/vehicleSelectionStore'
import { observer } from 'mobx-react-lite'

export const OwnFinancingSelectedOptions: React.FC = observer(() => {
  const ownFinancingOptions = vehicleSelectionStore.ownFinancingOptions

  const TextLine: React.FC<{ id: string; weight?: TextWeight }> = ({ id, weight }) => (
    <PText {...(weight && { weight })} className='xsmall-space-bottom'>
      <FormattedMessage id={id} />
    </PText>
  )

  return (
    <div className='medium-space-bottom'>
      <TextLine id='common.financingDetails' weight='bold' />
      <TextLine id='common.paymentOptions.ownFinancing' />
      {ownFinancingOptions?.applyingAnotherBank.selected && (
        <TextLine id='common.cash.ownFinancing.option.applyingAnotherBank' />
      )}
      {ownFinancingOptions?.securedFinancing.selected && (
        <TextLine id='common.cash.ownFinancing.option.securedFinancing' />
      )}
    </div>
  )
})

export const OwnFinancingVehicleSummary: React.FC = () => {
  return (
    <CashVehicleSummaryShell>
      <OwnFinancingSelectedOptions />
    </CashVehicleSummaryShell>
  )
}
