import { IAncillaryPlanOption } from '@pdiatl/common-external-models'

export const packageLevels = ['Platinum', 'Gold', 'Silver', 'Bronze', 'Copper']

export function getPackageLevelOrder(planName: string) {
  return packageLevels.findIndex((level) => planName.includes(level)) + 1 || Infinity
}

export function sortAncillaryPlans(plans: IAncillaryPlanOption[]): IAncillaryPlanOption[] {
  const sortedPlans: IAncillaryPlanOption[] = JSON.parse(JSON.stringify(plans))
  sortedPlans.sort((plan1, plan2) => {
    if (plan1.isPackage && !plan2.isPackage) {
      return -1
    } else if (!plan1.isPackage && plan2.isPackage) {
      return 1
    } else if (plan1.isPackage && plan2.isPackage) {
      return getPackageLevelOrder(plan1.name) - getPackageLevelOrder(plan2.name)
    } else {
      return 0
    }
  })

  return sortedPlans
}
