import { PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'
import { GraySimpleTile } from '../stack/GraySimpleTile'
import { StackElement, StackElementState } from '../stack/stackElement'
import { AncillaryProducts } from './AncillaryProducts'

export const ANCILLARY_PRODUCTS_STACK_NAME = 'ancillaryProducts'

const LockedView: React.FC = () => {
  const intl = useIntl()
  return <GraySimpleTile prompt={intl.formatMessage({ id: 'ancillaryProducts.locked.prompt' })} />
}
export const CompletedView: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const selectedProducts = opportunity.product.payload?.ancillaryProducts?.map((product) => product)

  if (!selectedProducts?.length) {
    return (
      <CompletedTile>
        <FormattedMessage id='ancillaryProducts.step.completed.noPlansSelected' />
      </CompletedTile>
    )
  }

  return (
    <CompletedTile>
      <PText>
        <span>{selectedProducts.length}</span>
        {intl.formatMessage({ id: 'ancillaryProducts.step.completed' })}
      </PText>
    </CompletedTile>
  )
}

const EditView: React.FC = () => {
  return <AncillaryProducts />
}

export class AncillaryProductsStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedView,
      LockedView,
      () => StackElementState.EDIT,
      ANCILLARY_PRODUCTS_STACK_NAME,
      index,
      label,
      'garage',
      {
        analyticsPage: AnalyticsPage.PROTECTION_PLANS,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.PROTECTION_PLANS,
            matomoActionName: 'checkout/protection_plan'
          }
        }
      }
    )
  }
}
