import React from 'react'
import { PGrid, PGridItem, PHeadline, PLink, PText } from '@porsche-design-system/components-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'

const PorscheExperienceCenter: React.FC = () => {
  const intl = useIntl()

  return (
    <PGrid className='medium-space-bottom'>
      <PGridItem size={4}>
        <img alt='Porsche Experience Center' src='/images/experience-center.jpg' width='100%' />
      </PGridItem>
      <PGridItem size={8}>
        <PHeadline variant='headline-3'>
          <FormattedMessage id='confirmation.pec.header' />
        </PHeadline>
        <PText className='small-space-top small-space-bottom ada-text-width'>
          <FormattedMessage id='confirmation.pec.sub' />
        </PText>
        <PLink
          href={intl.formatMessage({ id: 'confirmation.pec.url' })}
          variant='primary'
          onClick={() =>
            trackClickEvent(AnalyticsEventTemplates.EXPLORE_EXPERIENCE, {
              clickElementId: 'explore_the_world_linkouts',
              targetUrl: intl.formatMessage({ id: 'confirmation.pec.url' })
            })
          }
        >
          <FormattedMessage id='confirmation.pec.cta' />
        </PLink>
      </PGridItem>
    </PGrid>
  )
}
const OneShop: React.FC = () => {
  const intl = useIntl()

  return (
    <PGrid className='big-padding-bottom'>
      <PGridItem size={4}>
        <img alt='Porsche One Shop' src='/images/One-shop.jpg' width='100%' />
      </PGridItem>
      <PGridItem size={8}>
        <PHeadline variant='headline-3'>
          <FormattedMessage id='confirmation.oneShop.header' />
        </PHeadline>
        <PText className='small-space-top small-space-bottom ada-text-width'>
          <FormattedMessage id='confirmation.oneShop.sub' />
        </PText>
        <PLink
          href={intl.formatMessage({ id: 'confirmation.oneShop.url' })}
          variant='primary'
          onClick={() =>
            trackClickEvent(AnalyticsEventTemplates.EXPLORE_ONE_SHOP, {
              clickElementId: 'explore_the_world_linkouts',
              targetUrl: intl.formatMessage({ id: 'confirmation.oneShop.url' })
            })
          }
        >
          <FormattedMessage id='confirmation.oneShop.cta' />
        </PLink>
      </PGridItem>
    </PGrid>
  )
}

export const WorldOfPorsche: React.FC = () => {
  return (
    <>
      <PHeadline variant='headline-2' align='center' className='medium-space-bottom'>
        Experience the World of Porsche
      </PHeadline>
      <PorscheExperienceCenter />
      <OneShop />
    </>
  )
}
