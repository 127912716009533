import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'
import { getDynamicMatomoClickEventAction, matomoPcnaTrackClickEvent } from '../../../../../matomo/matomo'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../../entry/presenterProvider'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { trackClickEvent } from '@slatldisal/one-ga'
import { PDivider, PFlex, PFlexItem, PModal, PText, TextWeight } from '@porsche-design-system/components-react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import ReactDOM from 'react-dom'

interface IWarrantyModalProps {
  open: boolean
  setOpen: Function
}

export const WarrantyModal: React.FC<IWarrantyModalProps> = observer(({ open, setOpen }) => {
  const intl = useIntl()
  const { trackEvent } = useMatomo()
  const { smallerThan: isMobile } = useBreakpoint('m')
  const presenter = useContext(PresenterContext)

  const opportunity = presenter.opportunityStore.opportunityCache
  const warranty = opportunity.vehicle.payload?.warranty!
  const modalData = warranty.modal
  const analyticsPage = isMobile ? AnalyticsPage.MOBILE_SLIDE_OUT : AnalyticsPage.DESKTOP_SIDEBAR

  const WarrantyRow = ({ label, value, weight = 'regular' }: { label: string; value: string; weight?: TextWeight }) => (
    <PFlex style={{ margin: '26px 0' }}>
      <PFlexItem width='half'>
        <PText weight={weight}>{label}</PText>
      </PFlexItem>
      <PFlexItem width='half'>
        <PText weight={weight}>{value}</PText>
      </PFlexItem>
    </PFlex>
  )

  const formatDate = (text: string) =>
    intl.formatDate(text, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })

  const modal = (
    <PModal
      open={open}
      onClose={() => {
        matomoPcnaTrackClickEvent(trackEvent, getDynamicMatomoClickEventAction('modal-warranty-close', analyticsPage))
        trackClickEvent(AnalyticsEventTemplates.WARRANTY_MODAL_CLOSE_BUTTON(analyticsPage))

        setOpen(false)
      }}
      heading='Warranty Information'
    >
      <PDivider />
      <WarrantyRow label='New Car Warranty' value={`until ${formatDate(modalData?.newCarWarrantyEndDate ?? '')}`} />
      <PDivider />
      <WarrantyRow label='Porsche Approved Warranty' value={`until ${formatDate(modalData?.pawEndDate ?? '')}`} />
      <PDivider />
      <WarrantyRow
        weight='bold'
        label={`Warranty until${modalData?.newCarWarrantyDisclaimer ? '¹' : ''}`}
        value={formatDate(modalData?.pawEndDate ?? '')}
      />

      {modalData?.newCarWarrantyDisclaimer && (
        <PText className='ada-text-width'>¹{modalData.newCarWarrantyDisclaimer}</PText>
      )}
    </PModal>
  )
  if (open) {
    return ReactDOM.createPortal(modal, document.body)
  }
  return null
})
