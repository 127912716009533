import { ICheckoutConfig } from '.'
import devConfig from './local'

const apiEndpoint = 'https://bs-local.com:3005'

const browserstackConfig: ICheckoutConfig = {
  ...devConfig,
  apiEndpoint,
  aoac: {
    ...devConfig.aoac,
    submissionEndpoint: `${apiEndpoint}/pfso/api/Origination/SaveCreditAppFromFinder`
  }
}

export default browserstackConfig
