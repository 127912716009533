import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PCheckboxWrapper, PHeadline, PIcon, PText } from '@porsche-design-system/components-react'
import { color } from '@porsche-design-system/utilities'
import { LeaseFinanceCalculator } from './leaseFinance/LeaseFinanceCalculator'
import { ICheckoutOpportunity, isCheckout2Enabled } from '../../../common/checkout'
import { vehicleSelectionStore } from './vehicleSelectionStore'
import { observer } from 'mobx-react-lite'
import { trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { PaymentOptionType } from './VehicleSelectionPaymentOptions'
import { useFlags } from '../../../common/flags'

export type OwnFinancingOptionsType = 'applyingAnotherBank' | 'securedFinancing'

export interface IOwnFinancingOptions {
  [name: string]: {
    i18nKey: string
    selected: boolean
  }
}

interface IPaymentOptionDetailsProps {
  opportunity: ICheckoutOpportunity
  option: PaymentOptionType
  dealer: string
  ownFinancingOptions: IOwnFinancingOptions
  scrollToPaymentRadios: Function
}

export const PaymentOptionDetails: React.FC<IPaymentOptionDetailsProps> = observer((props) => {
  const { opportunity, option, dealer, ownFinancingOptions } = props
  const { enableDefiFinancialCalculatorCheckoutUs } = useFlags()
  const intl = useIntl()

  return (
    <>
      {!option && (
        <PurchaseDiagram
          text='vehicleSelection.paymentOptions.noneSelected'
          subtext='vehicleSelection.paymentOptions.noneSelected.sub'
        />
      )}
      {option === 'cash' && (
        <PurchaseDiagram
          text='vehicleSelection.paymentOptions.cash.header'
          subtext='vehicleSelection.paymentOptions.saveContinue.sub'
        />
      )}
      {option === 'leaseFinance' && enableDefiFinancialCalculatorCheckoutUs && (
        <LeaseFinanceCalculator
          opportunity={opportunity}
          scrollToPaymentOptions={() => props.scrollToPaymentRadios()}
        />
      )}
      {option === 'porscheFinancialServices' && !enableDefiFinancialCalculatorCheckoutUs && (
        <PurchaseDiagram
          text='vehicleSelection.paymentOptions.porscheFinancialServices.header'
          subtext='vehicleSelection.paymentOptions.saveContinue.sub'
        />
      )}
      {option === 'ownFinancing' && (
        <div style={{ margin: '30px 0 76px' }}>
          <PHeadline variant='headline-4'>
            <FormattedMessage id='common.optionalDetails' />
          </PHeadline>
          <PText>
            <FormattedMessage id='vehicleSelection.cash.ownFinancing.sub' values={{ dealer }} />
          </PText>

          {Object.entries(ownFinancingOptions).map(([value, option]) => (
            <PCheckboxWrapper
              key={value}
              label={intl.formatMessage({ id: option.i18nKey })}
              className='small-space-top'
            >
              <input
                type='checkbox'
                value={value}
                checked={option.selected}
                aria-checked={option.selected}
                tabIndex={0}
                onChange={(e) => {
                  trackClickEvent(
                    AnalyticsEventTemplates.EXPLORE_PAYMENTS_STEP_OWN_FINANCING_DETAILS(
                      e.target.value as OwnFinancingOptionsType
                    )
                  )
                  vehicleSelectionStore.setOwnFinancingOption(e.target.value, !option.selected)
                }}
              />
            </PCheckboxWrapper>
          ))}
        </div>
      )}
    </>
  )
})

const PurchaseDiagram: React.FC<{ text: string; subtext: string }> = ({ text, subtext }) => {
  return (
    <div
      style={{ textAlign: 'center' }}
      className={isCheckout2Enabled() ? 'big-space-top big-space-bottom' : 'huge-space-top huge-space-bottom'}
    >
      <PIcon
        name='purchase'
        size='large'
        color='inherit'
        className='neutral-contrast-medium small-space-bottom'
        style={{ border: `1px solid ${color.neutralContrast.medium}` }}
      />
      <PText color='inherit' className='neutral-contrast-medium' weight='bold' align='center'>
        <FormattedMessage id={text} />
      </PText>
      <PText color='inherit' className='neutral-contrast-medium' align='center'>
        <FormattedMessage id={subtext} />
      </PText>
    </div>
  )
}
