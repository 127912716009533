import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { FormattedMessage, useIntl } from 'react-intl'
import { PText } from '@porsche-design-system/components-react'
import { PresenterContext } from '../../../entry/presenterProvider'
import { CalculationResult } from '../../../vehicleSelection/pcna/leaseFinance/defiCalcFinanceData'
import { formatLocalizedCurrencyNoFraction } from '../../../../helpers/format'
import { VehicleImage } from '../../../confirmation/shared/ui'
import { LeaseFinanceVehicleContent } from '../desktop/LeaseFinanceVehicleSummary'
import { vehicleSelectionStore } from '../../../vehicleSelection/pcna/vehicleSelectionStore'

export const LeaseFinanceVehicleSummary: React.FC = observer(() => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const financialProduct = vehicleSelectionStore.calculationResult as CalculationResult
  const isLease = financialProduct.creditType === 'LSE'
  const currency = opportunity.product.price.currencyCode
  const price = (isLease ? financialProduct.monthlyLeasePayment : financialProduct.monthlyFinancePayment) || 0

  return (
    <>
      <PText size='medium' weight='bold'>
        {formatLocalizedCurrencyNoFraction(intl, currency, price)} <FormattedMessage id='common.perMonthAbbrev' />
      </PText>

      <VehicleImage />

      <LeaseFinanceVehicleContent />
    </>
  )
})
