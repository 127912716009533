import React, { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { locateIntlBundle } from '@slatldisal/checkout-i18n'

export const guessLocale = (locale: string) => locale.replace('_', '-')

export const TranslationsProvider: React.FunctionComponent<
  PropsWithChildren<{
    locale: string
  }>
> = ({ children, locale }) => {
  const localeIdentifier = guessLocale(locale)

  const [messages, setMessages] = React.useState<Record<string, any>>()
  React.useEffect(() => {
    setMessages(locateIntlBundle(localeIdentifier))
  }, [localeIdentifier])
  if (messages) {
    return (
      <IntlProvider messages={messages} locale={localeIdentifier} defaultLocale='en'>
        {children}
      </IntlProvider>
    )
  } else {
    return <></>
  }
}
