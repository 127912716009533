import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react-lite'
import { PHeadline, PText } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { StackElement } from '../stack/stackElement'
import { FAQList } from '../ported/faq'
import { Contact } from '../contact/contact'
import { WizardNavigation } from '../ported/navigation'
import { UnsavedDataModal } from '../checkout/UnsavedDataModal'
import { StickyVehicleSummary } from './vehicleSummary/desktop/StickyVehicleSummary'
import { useFlags } from '../../common/flags'
import { Steps } from '../checkout/CheckoutContent'
import { PresenterContext } from '../entry/presenterProvider'
import '../checkout/checkout.scss'
import './Checkout2Content.scss'
import { Break } from '../../common/IntlElements'

export const Checkout2Content: React.FC<{ elements: StackElement[] }> = observer(({ elements }) => {
  const { smallerThan: isMobile } = useBreakpoint('m')
  const { smallerThan: isDesktop } = useBreakpoint('xl')
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { enablePcnaPaymentStep } = useFlags()

  useEffect(() => {
    document.body.classList.add('no-scrollbar-y')
  }, [])

  return (
    <>
      <div style={{ marginTop: isMobile ? 0 : '60px' }}>
        <WizardNavigation />
      </div>
      <div style={{ margin: isMobile ? '' : '0 48px 0 48px' }}>
        <UnsavedDataModal />
        <div style={{ display: 'flex' }}>
          <div className='main-content' style={{ flex: isDesktop ? '3' : '2' }}>
            <div style={{ padding: '40px' }} className={isMobile ? 'surface-bg' : ''}>
              <PHeadline tag='h1' variant='headline-3' align={isMobile ? 'center' : 'left'}>
                {isMobile && <FormattedMessage id='checkout2.begin' />}
                {!isMobile && (
                  <FormattedMessage id='checkout.begin' values={{ dealer: opportunity.owner?.displayName }} />
                )}
              </PHeadline>

              <PText
                align={isMobile ? 'center' : 'left'}
                className='ada-text-width xsmall-space-top'
                style={{ margin: isMobile ? `0 auto` : '0' }}
              >
                <FormattedMessage
                  id={
                    presenter.isDepositJourney(enablePcnaPaymentStep)
                      ? 'checkout2.prompt.onboarded'
                      : 'checkout2.prompt'
                  }
                  values={{ lineBreak: Break }}
                />
              </PText>
            </div>

            <Steps elements={elements} />

            <div className='big-space-top'>
              <FAQList />
            </div>

            <Contact />
          </div>
          {!isMobile && (
            <div className='sidebar-content' style={{ flex: isDesktop ? '' : '1' }}>
              <StickyVehicleSummary />
            </div>
          )}
        </div>
      </div>
    </>
  )
})
