import { PGrid, PGridItem, PHeadline, PText } from '@porsche-design-system/components-react'
import { formatMiles } from '@slatldisal/checkout-i18n'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { VehicleImage } from '../../../confirmation/shared/ui'
import { PresenterContext } from '../../../entry/presenterProvider'
import './ReviewModal.scss'
import { DataRow } from './ReviewModalDetails'

export const ReviewModalImageAndVehicleInfo: React.FC = () => {
  const { largerThan: isDesktop, smallerThan: isMobile } = useBreakpoint('m')

  return (
    <PGrid className='medium-space-top'>
      {isDesktop && (
        <>
          <PGridItem size={6} className='image'>
            <VehicleImage />
          </PGridItem>
          <PGridItem size={6}>
            <VehicleInformation />
          </PGridItem>
        </>
      )}

      {isMobile && (
        <>
          <PGridItem size={12}>
            <VehicleInformation />
          </PGridItem>
          <PGridItem size={12}>
            <VehicleImage />
          </PGridItem>
        </>
      )}
    </PGrid>
  )
}

const VehicleInformation: React.FC = () => {
  const { smallerThan: isMobile } = useBreakpoint('m')
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const dealer = opportunity.owner?.displayName
  const previousOwners = opportunity.vehicle.numberOfPreviousOwners
  const formattedMiles = formatMiles({ numericValue: opportunity.vehicle.mileage, unitCode: 'MILES' })
  const exteriorColor = opportunity.vehicle.payload?.exteriorColor
  const interiorColor = opportunity.vehicle.payload?.interiorColor
  const transmission = opportunity.vehicle.transmission
  const { modelYear, modelName } = opportunity.vehicle

  return (
    <>
      <PHeadline tag='h4' variant={`headline-${isMobile ? '2' : '4'}`}>
        {modelYear} {modelName}
      </PHeadline>
      <span>Dealer {dealer}</span>

      <PHeadline tag='h5' variant={`headline-${isMobile ? '3' : '5'}`} className='medium-space-top small-space-bottom'>
        <FormattedMessage id='common.vehicleDetails' />
      </PHeadline>

      <PGrid>
        {(previousOwners || previousOwners === 0) && (
          <DataRow labelKey='common.previousOwners' value={previousOwners} />
        )}
        {formattedMiles && <DataRow labelKey='common.mileage' value={formattedMiles} />}
        <WarrantySection />
        {exteriorColor && <DataRow labelKey='common.exterior' value={exteriorColor} />}
        {interiorColor && <DataRow labelKey='common.interior' value={interiorColor} />}
        {transmission && <DataRow labelKey='common.transmission' value={transmission} />}
      </PGrid>
    </>
  )
}

const WarrantySection: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const warranty = opportunity.vehicle.payload?.warranty

  if (!warranty) {
    return <></>
  }

  const value = warranty.modal ? (
    `${String(warranty.description)}${warranty.modal.newCarWarrantyDisclaimer ? '*' : ''}`
  ) : (
    <>
      <PText>{warranty.description}</PText>
      <PText size='x-small'>{warranty.smallDescription}</PText>
    </>
  )

  return <DataRow labelKey='common.warranty' value={value} />
}
