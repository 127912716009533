import { IntlShape } from 'react-intl'
import { Condition } from '../components/tradeIn/tradeinState'

export interface IConditionOption {
  code: Condition
  title: string
}

export const conditionList: (intl: IntlShape) => IConditionOption[] = (intl) => [
  {
    code: 'NOT_PROVIDED',
    title: intl.formatMessage({ id: 'tradein.condition.notProvided' })
  },
  {
    code: 'EXCELLENT',
    title: intl.formatMessage({ id: 'tradein.condition.excellent' })
  },
  {
    code: 'VERY_GOOD',
    title: intl.formatMessage({ id: 'tradein.condition.veryGood' })
  },
  {
    code: 'GOOD',
    title: intl.formatMessage({ id: 'tradein.condition.good' })
  },
  {
    code: 'FAIR',
    title: intl.formatMessage({ id: 'tradein.condition.fair' })
  }
]
