import { PGrid, PGridItem, PHeadline } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { isCheckout2Enabled } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { PresenterContext } from '../../entry/presenterProvider'
import { CommonConfirmationDetails } from './CommonConfirmationDetails'
import { KBBChart, VehicleImage } from './ui'

export const ConfirmationDetails: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  const { enableKbbChartOnConfirmationPage } = useFlags()

  return (
    <div className='medium-space-bottom'>
      <PHeadline variant='headline-4' className='big-space-top medium-space-bottom'>
        <FormattedMessage id={isCheckout2Enabled() ? 'confirmation.yourPorsche' : 'vehicleSelection.title'} />
      </PHeadline>

      <PGrid direction={{ base: 'column', s: 'row' }}>
        <PGridItem size={{ base: 12, m: 5 }}>
          <VehicleImage />
          <div className='small-space-top' style={{ textAlign: 'center' }}>
            {opportunity.tradeInVehicle && enableKbbChartOnConfirmationPage && (
              <KBBChart tradeInVehicle={opportunity.tradeInVehicle} />
            )}
          </div>
        </PGridItem>

        <PGridItem size={{ base: 12, m: 7 }}>
          <CommonConfirmationDetails />
        </PGridItem>
      </PGrid>
    </div>
  )
}
