import React from 'react'
import { useIntl } from 'react-intl'
import { TradeInVehicleView } from '@pdiatl/common-external-models'
import { NOT_PROVIDED } from '../../tradeIn/tradeinState'
import { DeliveryReviewContent, ReviewSection } from '../../confirmation/shared/ui'
import { TradeInKbbDisclaimer, TradeInReviewTable } from '../../tradeIn/tradeInReviewTable'
import { IOpportunityRender } from '../../confirmation/shared/IOpportunityRender'
import { AncillaryProductsReviewContent } from '../../ancillaryProducts/AncillaryProductsReview'

export const CashReviewDetails: React.FC<IOpportunityRender> = (props) => {
  const tradeIn = props.opportunity.tradeInVehicle

  return (
    <>
      {tradeIn && <TradeInReviewContent tradeIn={tradeIn} />}
      <AncillaryProductsReviewContent />
      <DeliveryReviewContent />
    </>
  )
}

const TradeInReviewContent: React.FC<{
  tradeIn: TradeInVehicleView
}> = ({ tradeIn }) => {
  const intl = useIntl()

  const isKBB = tradeIn.valuation?.provider === 'KBB'
  const tradeInProvided = tradeIn.model !== NOT_PROVIDED

  if (!tradeInProvided) {
    return <></>
  }

  return (
    <ReviewSection title={intl.formatMessage({ id: 'review.tradein.details' })}>
      <TradeInReviewTable tradeIn={tradeIn} />
      {isKBB && <TradeInKbbDisclaimer />}
    </ReviewSection>
  )
}
