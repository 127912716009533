import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PDivider, PFlex, PFlexItem, PHeadline, PText } from '@porsche-design-system/components-react'
import { trackClickEvent } from '@slatldisal/one-ga'
import { CarfaxLine } from '@slatldisal/vehicle-history-report'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ICheckoutOpportunity, isCheckout2Enabled, isNewVehicle, showCarfax } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { config } from '../../../config'
import { getDynamicMatomoClickEventAction, matomoPcnaTrackClickEvent } from '../../../matomo/matomo'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { AncillaryProductsReviewTable } from '../../ancillaryProducts/AncillaryProductsReview'
import { WarrantyStepData } from '../../checkout2/vehicleSummary/stepData/warranty/WarrantyStepData'
import { isApplicationComplete, PresenterContext } from '../../entry/presenterProvider'
import { Finance } from '../finance/Finance'
import { Lease } from '../lease/Lease'
import { OneGaComponentLocation } from './OneGaComponentLocation'
import { ReservationDepositDetails } from './ReservationDepositDetails'
import { DeliveryReviewContent, KBBChart, PricingHeading, TradeInDetails } from './ui'

export const checkoutTypeDetailsMapping = {
  LSE: <Lease />,
  RTL: <Finance />
}

export function getDisclaimerId(opportunity: ICheckoutOpportunity) {
  return isNewVehicle(opportunity) ? 'confirmation.msrpDisclaimer' : 'confirmation.marketDisclaimer'
}

export const DividerAndText: React.FC<{ text: string }> = ({ text }) => (
  <>
    <PDivider className='small-space-top small-space-bottom' />
    <PText>{text}</PText>
  </>
)

export const CommonConfirmationDetails: React.FC = () => {
  const intl = useIntl()
  const { trackEvent } = useMatomo()
  const { enableKbbChartOnConfirmationPage, enableCarfaxInCheckout } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  const ancillaryProducts = opportunity.product.payload?.ancillaryProducts
  const kbbEvaluation = opportunity.tradeInVehicle?.valuation?.provider === 'KBB'
  const creditType = opportunity?.financialProduct?.payload?.creditType
  const lseRtlCreditType = ['LSE', 'RTL'].includes(creditType)

  const checkoutTypeDetails = checkoutTypeDetailsMapping[creditType]

  return (
    <>
      <PricingHeading opportunity={opportunity} showAsterisk={config().isMarket('us')} />

      {!isApplicationComplete() && checkoutTypeDetails && (
        <>
          <PDivider className='small-space-top small-space-bottom' />
          <div className='small-space-top small-space-bottom'>{checkoutTypeDetails}</div>
        </>
      )}

      {isApplicationComplete() && (
        <>
          <PDivider className='small-space-top small-space-bottom' />
          <PText className='ada-text-width'>
            <FormattedMessage
              id='confirmation.temporaryAOACInfo'
              values={{
                dealer: opportunity?.owner?.displayName ?? 'UNKNOWN DEALER',
                phone: opportunity?.vehicle?.payload?.dealerPhone ?? 'UNKNOWN PHONE NUMBER'
              }}
            />
          </PText>
        </>
      )}

      <ReservationDepositDetails oneGaComponentLocation={OneGaComponentLocation.CHECKOUT_ORDER_CONFIRMATION} />

      <TradeInDetails opportunity={opportunity} />
      {isCheckout2Enabled() && enableKbbChartOnConfirmationPage && (
        <div className='small-space-top' style={{ textAlign: 'center' }}>
          {opportunity.tradeInVehicle && <KBBChart tradeInVehicle={opportunity.tradeInVehicle} />}
        </div>
      )}

      {config().isMarket('us') && ancillaryProducts?.length && (
        <>
          <PDivider className='small-space-top small-space-bottom' />
          <PHeadline className='small-space-bottom' variant='headline-4' data-testid='confirmationAPTitle'>
            <FormattedMessage id='ancillaryProducts.title' />
          </PHeadline>
          <AncillaryProductsReviewTable products={ancillaryProducts} confirmationPage />
        </>
      )}

      <PDivider className='small-space-top small-space-bottom' />
      <DeliveryReviewContent />

      {showCarfax(opportunity, enableCarfaxInCheckout) && (
        <>
          <PHeadline className='small-space-bottom' variant='headline-4' data-testid='confirmationVHRTitle'>
            <FormattedMessage id='vehicleHistoryReport.title' />
          </PHeadline>
          <CarfaxLine
            env={config().environment}
            marketplace={config().market}
            listingId={opportunity.vehicle.payload?.listingId}
            isConfirmationPage
            onClick={() => {
              matomoPcnaTrackClickEvent(
                trackEvent,
                getDynamicMatomoClickEventAction('see-carfax-vehicle-history-report', AnalyticsPage.ORDER_CONFIRMATION)
              )
              trackClickEvent(
                AnalyticsEventTemplates.CARFAX_VEHICLE_HISTORY_REPORT_CLICK(AnalyticsPage.ORDER_CONFIRMATION)
              )
            }}
          />
          <PDivider className='small-space-top small-space-bottom' />
        </>
      )}

      {!isNewVehicle(opportunity) && config().isMarket('us') && opportunity.vehicle.payload?.warranty && (
        <>
          <PFlex className='small-space-top'>
            <PFlexItem grow={1} width='full'>
              <PHeadline variant='headline-4'>
                <FormattedMessage id='common.warranty' />
              </PHeadline>
            </PFlexItem>
            <PFlexItem shrink={0}>
              <WarrantyStepData />
            </PFlexItem>
          </PFlex>
          <PDivider className='small-space-top small-space-bottom' />
        </>
      )}

      {config().isMarket('us') && <PText>{`* ${intl.formatMessage({ id: getDisclaimerId(opportunity) })}`}</PText>}

      {!isApplicationComplete() && lseRtlCreditType && (
        <DividerAndText text={`** ${intl.formatMessage({ id: 'common.pfsDisclaimer' })}`} />
      )}

      {kbbEvaluation && <DividerAndText text={`*** ${intl.formatMessage({ id: 'common.kbbDisclaimer' })}`} />}
    </>
  )
}
