import React, { PropsWithChildren } from 'react'
import { config } from '../config'
import { AuthenticationProvider, oidcLog } from '@axa-fr/react-oidc-context'

const LoadingState = () => <div>Loading...</div>

export const AuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { pid } = config()
  return (
    <AuthenticationProvider
      configuration={pid.oauth}
      loggerLevel={pid.oauthDebug ? oidcLog.DEBUG : oidcLog.ERROR}
      isEnabled
      callbackComponentOverride={LoadingState}
      location={window.location}
      // TODO: to be replaced with common error screen
      notAuthenticated={() => <div>Authentication Error</div>}
      notAuthorized={() => <div>Authorization Error</div>}
    >
      {children}
    </AuthenticationProvider>
  )
}
