import { IAncillaryPlanOption } from '@pdiatl/common-external-models'
import { AncillaryProductsMap } from '../AncillaryProducts'

interface ISelectedPlan {
  code: string
  name: string
  parentPackageCode?: string
}

// gets a list of all selected plans, including childPlans from packages
function getAllSelectedPlans(plans: IAncillaryPlanOption[]): ISelectedPlan[] {
  const selectedChildPlans = plans
    .filter((p) => p.selection && p.isPackage)
    .map((p) => p.childPlans.map((cp) => ({ code: cp.code, name: cp.name, parentPackageCode: p.code })))
    .flatMap((cp) => cp)
  const selectedPlans = plans
    .filter((plan) => plan.selection && !plan.isPackage && !selectedChildPlans.some((p) => plan.code === p.code))
    .map((p) => ({ code: p.code, name: p.name }))

  return selectedPlans.concat(selectedChildPlans)
}

// multiple possible results (can also occur together), when a package is selected and:
// - another already selected plan(s) are a subset - return list of plan names
// - another already selected package has a common part - return package name
function getViolatingEarlierSelectionsForPackage(selectedPlans: ISelectedPlan[], planToSelect: IAncillaryPlanOption) {
  const violatingPlansAndPackages: string[] = []
  for (const plan of selectedPlans) {
    const isPackage = !!plan.parentPackageCode
    if (
      planToSelect.childPlans.some((cp) => cp.code === plan.code) &&
      (!isPackage || !violatingPlansAndPackages.includes(plan.parentPackageCode!))
    ) {
      violatingPlansAndPackages.push(isPackage ? plan.parentPackageCode! : plan.code)
    }
  }

  return violatingPlansAndPackages
}

function plansInGroup(plan1: string, plan2: string, group: string[]) {
  return group.includes(plan1) && group.includes(plan2)
}

function isWrongPlanCombination(plan1: string, plan2: string): boolean {
  const incompatibleGroup1 = ['POTSTRPR', 'POTSTRRT', 'POTRTRPR', 'POTRTRRT']
  const incompatibleGroup2 = ['VSC', 'POTSTRPR', 'POTSTRRT']

  return plansInGroup(plan1, plan2, incompatibleGroup1) || plansInGroup(plan1, plan2, incompatibleGroup2)
}

function getViolatedSelectedPlans(selectedPlans: ISelectedPlan[], planToSelectCode: string): string[] {
  return selectedPlans.filter((plan) => isWrongPlanCombination(plan.code, planToSelectCode)).map((plan) => plan.code)
}

function getViolatingEarlierSelectionsForPlan(
  selectedPlans: ISelectedPlan[],
  planToSelect: IAncillaryPlanOption
): string[] {
  // the selected plan is contained in childPlans of some already selected package
  const violatedSelectedPackage = selectedPlans.find((p) => p.code === planToSelect.code)

  return violatedSelectedPackage
    ? [violatedSelectedPackage.parentPackageCode!]
    : getViolatedSelectedPlans(selectedPlans, planToSelect.code)
}

export function getViolatingEarlierSelections(
  planToCheck: IAncillaryPlanOption,
  ancillaryProductsMap: AncillaryProductsMap
): IAncillaryPlanOption[] {
  const selectedPlans = getAllSelectedPlans(ancillaryProductsMap.plans)

  const violatingEarlierSelectionCodes = planToCheck.isPackage
    ? getViolatingEarlierSelectionsForPackage(selectedPlans, planToCheck)
    : getViolatingEarlierSelectionsForPlan(selectedPlans, planToCheck)

  return violatingEarlierSelectionCodes.map((code) => ancillaryProductsMap.get(code)!)
}
