import React from 'react'
import { PText } from '@porsche-design-system/components-react'

import { IAnalyticsClickEventTemplate, trackClickEvent } from '@slatldisal/one-ga'

export interface IDealerInformationProps {
  dealerUrl?: string
  dealerName?: string
  analyticsEvent?: IAnalyticsClickEventTemplate
}

export const DealerInformation: React.FC<IDealerInformationProps> = (props) => {
  return (
    <PText size='small'>
      Dealer:{' '}
      {props.dealerUrl ? (
        <a
          href={props.dealerUrl}
          target='_blank'
          rel='noreferrer'
          onClick={() =>
            props.analyticsEvent &&
            trackClickEvent(props.analyticsEvent, { targetUrl: props.dealerUrl, clickElementId: 'your_selection' })
          }
        >
          {props.dealerName ?? 'Porsche'}
        </a>
      ) : (
        props.dealerName ?? 'Porsche'
      )}
    </PText>
  )
}
