import { VehicleSelectionValues } from '../pcna/VehicleSelectionPcna'
import { CalculationResult } from '../pcna/leaseFinance/defiCalcFinanceData'
import { IOwnFinancingOptions } from '../pcna/PaymentOptionDetails'
import { FinancialProductView } from '@pdiatl/common-external-models'
import { PaymentOptionType } from '../pcna/VehicleSelectionPaymentOptions'

export function getVehicleSelectedValues(
  paymentType: PaymentOptionType,
  financeData: CalculationResult,
  ownFinancingOptions: IOwnFinancingOptions
): VehicleSelectionValues {
  switch (paymentType) {
    case 'leaseFinance':
      return { paymentType, calculatorValues: financeData }
    case 'porscheFinancialServices':
      return { paymentType: 'porscheFinancialServices' }
    case 'ownFinancing':
      return {
        paymentType: 'ownFinancing',
        applyingAnotherBank: ownFinancingOptions.applyingAnotherBank.selected,
        securedFinancing: ownFinancingOptions.securedFinancing.selected
      }
    default:
      return { paymentType: 'cash' }
  }
}

export function mapVehicleSelectionValuesToFinancialProduct(
  vehicleSelectionValues: VehicleSelectionValues
): FinancialProductView | undefined {
  switch (vehicleSelectionValues.paymentType) {
    case 'leaseFinance':
      return { payload: vehicleSelectionValues.calculatorValues }
    case 'porscheFinancialServices':
      return { payload: { financingType: 'porscheFinancialServices' } }
    case 'ownFinancing':
      return {
        payload: {
          applyingAnotherBank: vehicleSelectionValues.applyingAnotherBank,
          securedFinancing: vehicleSelectionValues.securedFinancing
        }
      }
    default:
      return undefined
  }
}
