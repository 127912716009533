import { useMatomo } from '@datapunt/matomo-tracker-react'
import { PButtonPure, PText } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import {
  getDynamicMatomoClickEventAction,
  getDynamicMatomoPageViewActionName,
  matomoPcnaTrackClickEvent,
  matomoPcnaTrackPageView
} from '../../../../../matomo/matomo'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../../entry/presenterProvider'
import { trackClickEvent, trackPageLoadEvent } from '@slatldisal/one-ga'
import { WarrantyModal } from './WarrantyModal'

export const WarrantyStepData: React.FC = observer(() => {
  const { pushInstruction, trackEvent } = useMatomo()
  const { smallerThan: isMobile } = useBreakpoint('m')
  const presenter = useContext(PresenterContext)

  const [isWarrantyModalOpen, setWarrantyModalOpen] = useState(false)
  const opportunity = presenter.opportunityStore.opportunityCache
  const warranty = opportunity.vehicle.payload?.warranty!
  const modalData = warranty.modal
  const analyticsPage = isMobile ? AnalyticsPage.MOBILE_SLIDE_OUT : AnalyticsPage.DESKTOP_SIDEBAR

  return (
    <>
      {!modalData && (
        <>
          <PText size='small'>{warranty.description}</PText>
          <PText size='x-small'>{warranty.smallDescription}</PText>
        </>
      )}

      {modalData && (
        <PButtonPure
          icon='none'
          onClick={() => {
            matomoPcnaTrackClickEvent(
              trackEvent,
              getDynamicMatomoClickEventAction('warranty-modal-button', analyticsPage)
            )
            trackClickEvent(AnalyticsEventTemplates.WARRANTY_BUTTON(analyticsPage))

            matomoPcnaTrackPageView(
              pushInstruction,
              getDynamicMatomoPageViewActionName('checkout/warranty', analyticsPage)
            )
            trackPageLoadEvent(AnalyticsEventTemplates.WARRANTY_MODAL(analyticsPage))

            setWarrantyModalOpen(true)
          }}
        >
          {warranty.description}
        </PButtonPure>
      )}

      <WarrantyModal open={isWarrantyModalOpen} setOpen={setWarrantyModalOpen} />
    </>
  )
})
