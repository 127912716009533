import React, { useEffect } from 'react'
import { Shell } from '../shell/shell'
import { BrowserRouter as Router, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { Checkout } from '../checkout/checkout'
import { AuthenticationContext } from '@axa-fr/react-oidc-context'
import { AuthProvider } from '../../auth/AuthProvider'
import { PresenterProvider } from './presenterProvider'
import { Confirmation } from '../confirmation/Confirmation'

import {
  initializeAnalytics,
  setApplication,
  setEnvironment,
  setLanguage,
  setLoggedIn,
  setMarketplace
} from '@slatldisal/one-ga'
import { TranslationsProvider } from './translationProvider'
import { config } from '../../config'
import { useFlags } from '../../common/flags'
import { PageNotFound } from './PageNotFound/PageNotFound'
import { PorscheDesignSystemProvider } from '@porsche-design-system/components-react'
import { isCheckout2Enabled, isGuest, setCheckout2Enabled } from '../../common/checkout'
import { LogoHeader } from './LogoHeader/LogoHeader'
import { Checkout2 } from '../checkout2/checkout2'
import { LogoHeader2 } from './LogoHeader/LogoHeader2'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'

function configureAnalytics() {
  const { oneGaEnv, market } = config()

  const locale = config().locale ?? 'en-US'

  setApplication('carsales_checkout')

  setLanguage(locale)
  setMarketplace(market)

  setEnvironment(oneGaEnv)

  void initializeAnalytics(oneGaEnv)
}

const ConfirmationWithContextShell: React.FC = () => {
  const { leadId, locale } = useParams()
  return (
    <Shell>
      <AuthenticationContext.Consumer>
        {({ oidcUser }) => {
          if (!isGuest() && !oidcUser) {
            return null
          }

          setLoggedIn(!!oidcUser)

          return (
            <PresenterProvider leadId={leadId ?? ''} locale={locale} oidcUser={oidcUser!} refresh>
              <Confirmation />
            </PresenterProvider>
          )
        }}
      </AuthenticationContext.Consumer>
    </Shell>
  )
}

// TODO: workaround removal of useHistory
export const Redirect: React.FunctionComponent<{ to: string }> = ({ to }) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [navigate, to])
  return <></>
}

const CheckoutWithContextShell: React.FC = () => {
  const { leadId, locale } = useParams()
  const { smallerThan: isMobile } = useBreakpoint('m')
  return (
    <Shell>
      <PresenterProvider leadId={leadId ?? ''} locale={locale}>
        <AuthenticationContext.Consumer>
          {({ oidcUser }) => {
            setLoggedIn(!!oidcUser)

            return isCheckout2Enabled() ? (
              <>
                <LogoHeader2 />
                <div>
                  <Checkout2 />
                </div>
              </>
            ) : (
              <>
                <LogoHeader />
                <div style={{ margin: isMobile ? '' : '0 48px 0 48px' }}>
                  <Checkout />
                </div>
              </>
            )
          }}
        </AuthenticationContext.Consumer>
      </PresenterProvider>
    </Shell>
  )
}

export const EntryComponent: React.FC = () => {
  useEffect(() => {
    configureAnalytics()
  }, [])
  const { locale = 'en-US', environment } = config()
  const { enableCheckout2 } = useFlags()

  const params = new URLSearchParams(location.search)
  const checkoutVersionFromUrlParameter =
    (params.get('checkoutVersion') as '1' | '2' | null) ?? sessionStorage.getItem('checkoutVersion')
  if (environment !== 'production' && checkoutVersionFromUrlParameter) {
    sessionStorage.setItem('checkoutVersion', checkoutVersionFromUrlParameter)
    setCheckout2Enabled(checkoutVersionFromUrlParameter === '2')
  } else {
    setCheckout2Enabled(enableCheckout2) // from launchDarkly
  }

  return (
    <PorscheDesignSystemProvider>
      <TranslationsProvider locale={locale}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path='/checkout/:leadId' element={<CheckoutWithContextShell />} />
              <Route path='/:market/:locale/checkout/:leadId' element={<CheckoutWithContextShell />} />
              <Route path='/checkout/:leadId/confirmation' element={<ConfirmationWithContextShell />} />
              <Route path='/:market/:locale/checkout/:leadId/confirmation' element={<ConfirmationWithContextShell />} />
              <Route path='/:market/:locale/404' element={<PageNotFound />} />
              <Route path='*' element={<Redirect to='/us/en-US/404' />} />
            </Routes>
          </Router>
        </AuthProvider>
      </TranslationsProvider>
    </PorscheDesignSystemProvider>
  )
}
