import { ICheckoutConfig, ITenantConfigPartial, Market } from './index'

export const defaultConfig = {
  market: 'ca',
  locale: 'fr_CA',
  isMarket: function (market: Market) {
    return this.market === market
  },

  refreshInterval: 3000,

  defaultCarImage: './images/default-vehicle-img.jpeg',
  showErrorPage: true,
  environment: 'development',
  showConfirmationMyPorsche: false,

  aoac: {
    submissionEndpoint: '',
    applicationURLPrefix: '',
    tenantConfig: {
      ClientId: 'PORSCHEUS',
      ConsumerEnvironmentId: 'DEV',
      ConsumerSystemId: 'CWP',
      TenantId: 'PORSCHE'
    } as ITenantConfigPartial
  },

  defiCalculator: {
    endpointUrl: 'https://checkout-api.slatldisal.aws.porsche-preview.cloud/pfso/AutoOrigination/'
  },
  vehicleHistory: {
    endpointUrl: 'https://vehicle-history.dev.slatldisal.aws.porsche-preview.cloud'
  },

  paymentStep: {
    enabled: true,
    environment: 'test'
  },
  fullStoryID: 'o-1EPBP6-na1',
  cdnUrl: 'https://digital-sales-cdn.slatldisal.aws.porsche.cloud/checkout',

  customerSatisfactionSurveyLink: 'http://www.porsche.com',
  addressAutocomplete: {
    apiEndpoint: 'https://experian-api.local.myatl.aws.porsche-preview.cloud/address',
    authToken: '0542547e-c134-49a0-8c78-e3a96bb3a494'
  },
  finder: {
    homePageURL: 'https://finder-preview.porsche.com'
  }
} as ICheckoutConfig

export const SCOPE =
  'openid profile email pid:user_profile.addresses:read pid:user_profile.name:read pid:user_profile.emails:read pid:user_profile.phones:read pid:user_profile.porscheid:read'
