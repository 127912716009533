import {
  PButton,
  PDivider,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PHeadline,
  PLink,
  PText
} from '@porsche-design-system/components-react'
import { formatCurrency } from '@slatldisal/checkout-i18n'
import { FinancialProductView, TradeInVehicleView } from '@pdiatl/common-external-models'
import { AllInPricing } from '@slatldisal/msrp-pricing-us'
import { trackClickEvent } from '@slatldisal/one-ga'
import { KBBPriceAdvisor } from '@slatldisal/trade-in-finder-next'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState, PropsWithChildren } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { getPriceLabel, isCheckout2Enabled, isFinancePricingAvailable } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { parseKBBNotes } from '../../../common/kbbNotesParser'
import { config } from '../../../config'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { CheckoutApp } from '../../../presenter/checkoutApp'
import AoacService from '../../../services/aoacService'
import { defaultErrorHandler } from '../../../util/defaultErrorHandler'
import AoacModal from '../../aoac/aoacModal'
import { PresenterContext } from '../../entry/presenterProvider'
import { NewAllInPricing } from '../../newAllInPricing/newAllInPricing'
import { TradeInReviewTable } from '../../tradeIn/tradeInReviewTable'
import { NOT_PROVIDED } from '../../tradeIn/tradeinState'
import { MsrpPricingSection } from '../../vehicleSelection/cash/CashVehicleSelection'
import { IFinanceOpportunityRender, IOpportunityRender } from './IOpportunityRender'

const { showConfirmationMyPorsche, environment } = config()

interface ILabelBoxProps {
  title: string
  text: string
  small?: boolean
  hideBackground?: boolean
  className?: string
}

export interface ICurrencyProps {
  value: number
  currency: string
}

export const ReviewSection: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <>
      <PGridItem size={{ base: 12, s: 4, m: 6, l: 7 }}>
        <PHeadline variant='headline-4' className='small-space-bottom'>
          {title}
        </PHeadline>
      </PGridItem>
      <PGridItem className='small-space-bottom' size={{ base: 12, s: 8, m: 6, l: 5 }}>
        {children}
      </PGridItem>
      {!isCheckout2Enabled() && (
        <PGridItem size={12}>
          <PDivider className='small-space-top small-space-bottom' />
        </PGridItem>
      )}
    </>
  )
}

export const DeliveryReviewContent: React.FC = observer(() => {
  const intl = useIntl()

  const presenter = useContext(PresenterContext)
  const { orderInformation } = presenter.opportunityStore.opportunityCache

  const isHome = orderInformation?.deliveryOptions.deliveryOption === 'HOME'

  return config().isMarket('us') && isHome ? (
    <>
      <PGrid className='small-space-bottom small-space-right'>
        <PGridItem size={8}>
          <PHeadline variant='headline-4'>{intl.formatMessage({ id: 'common.delivery.homeDelivery.title' })}</PHeadline>
          <PText>{intl.formatMessage({ id: 'common.delivery.homeDelivery.prompt' })}</PText>
        </PGridItem>
      </PGrid>
      <PDivider className='small-space-top small-space-bottom' />
    </>
  ) : (
    <></>
  )
})

export const Currency: React.FC<ICurrencyProps> = ({ value, currency }) => {
  return (
    <FormattedNumber
      value={value}
      style='currency'
      currency={currency}
      currencyDisplay='narrowSymbol'
      minimumFractionDigits={0}
      maximumFractionDigits={0}
    />
  )
}

export const LabelBox: React.FC<ILabelBoxProps> = (props) => {
  return (
    <PGridItem size={{ base: 12, s: props.small ? 3 : 4 }}>
      <div className={`${props.hideBackground ? '' : 'surface-box'} small-space-bottom ${props.className ?? ''}`}>
        <div style={{ height: '60px' }}>
          <PHeadline variant='headline-5' tag='h3'>
            {props.title}
          </PHeadline>
        </div>
        <div className='small-space-bottom'>
          <PText>{props.text}</PText>
        </div>
      </div>
    </PGridItem>
  )
}

export const OwnFinanceSubHeading = () => {
  return (
    <PText weight='bold'>
      <FormattedMessage id='common.financingDetails' />
    </PText>
  )
}

export const OwnFinanceDetails: React.FC<{ financialProduct: FinancialProductView | undefined }> = ({
  financialProduct
}) => {
  return (
    <>
      <PText align='right'>
        <FormattedMessage id='common.paymentOptions.ownFinancing' />
      </PText>
      {financialProduct?.payload?.applyingAnotherBank && (
        <PText align='right'>
          <FormattedMessage id='common.cash.ownFinancing.option.applyingAnotherBank' />
        </PText>
      )}
      {financialProduct?.payload?.securedFinancing && (
        <PText align='right'>
          <FormattedMessage id='common.cash.ownFinancing.option.securedFinancing' />
        </PText>
      )}
    </>
  )
}

export const OwnFinancing: React.FC<{ financialProduct: FinancialProductView | undefined }> = ({
  financialProduct
}) => {
  const presenter = useContext<CheckoutApp>(PresenterContext)

  return presenter.isOwnFinancing() ? (
    <PFlex className='small-space-top'>
      <PFlexItem shrink={0}>
        <OwnFinanceSubHeading />
      </PFlexItem>
      <PFlexItem grow={1}>
        <OwnFinanceDetails financialProduct={financialProduct} />
      </PFlexItem>
    </PFlex>
  ) : null
}

export const PricingHeading: React.FC<IOpportunityRender> = ({ showAsterisk, opportunity }) => {
  const { enableNewAllInPricing } = useFlags()
  const { product, vehicle, financialProduct } = opportunity
  const { modelYear, modelName } = opportunity.vehicle

  return product && vehicle ? (
    <PGrid>
      <PGridItem size={8}>
        <PHeadline variant='headline-4' tag='h3' data-testid='generalVehicleModel'>
          {modelYear} {modelName}
        </PHeadline>
      </PGridItem>
      <PGridItem size={4}>
        <PHeadline variant='headline-4' align='right' data-testid='generalPriceValue'>
          <Currency value={product?.price?.value ?? 0} currency={product?.price?.currencyCode} />
        </PHeadline>
      </PGridItem>
      <PGridItem size={12}>
        {enableNewAllInPricing ? (
          <NewAllInPricing
            analyticEvents={{
              onOpen: () =>
                trackClickEvent(
                  AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION(AnalyticsPage.ORDER_CONFIRMATION)
                ),
              onClose: () =>
                trackClickEvent(
                  AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION(
                    AnalyticsPage.ORDER_CONFIRMATION
                  )
                )
            }}
          />
        ) : (
          <AllInPricing
            requestBodyParams={{
              vin: opportunity.vehicle.vin,
              listingId: opportunity.vehicle.payload?.listingId,
              partnerNumber: opportunity.vehicle.payload?.dealerId,
              price: opportunity.product.price.value!
            }}
            locale={config().locale}
            env={config().environment}
            marketplaceKey={config().market}
            conditionType={vehicle?.conditionType}
            analyticEvents={{
              onOpen: () =>
                trackClickEvent(
                  AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION(AnalyticsPage.ORDER_CONFIRMATION)
                ),
              onClose: () =>
                trackClickEvent(
                  AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_CONFIRMATION(
                    AnalyticsPage.ORDER_CONFIRMATION
                  )
                )
            }}
          />
        )}
        {config().isMarket('ca') && (
          <PText align='right'>
            <FormattedMessage id='common.dealerSetsFinalPrice' />
          </PText>
        )}
      </PGridItem>
      <PGridItem size={12}>
        {config().isMarket('us') && (
          <PText align='right'>
            <FormattedMessage id={getPriceLabel(opportunity)} />
            {showAsterisk && <>*</>}
          </PText>
        )}
        <MsrpPricingSection
          opportunity={opportunity}
          analyticsPage={AnalyticsPage.ORDER_CONFIRMATION}
          alignment='end'
        />
      </PGridItem>
      <PGridItem size={12}>
        <OwnFinancing financialProduct={financialProduct} />
      </PGridItem>
    </PGrid>
  ) : (
    <></>
  )
}

export const PfsText: React.FC = () => {
  return (
    <>
      <PGridItem size={12}>
        <PFlex justifyContent='center'>
          <PText align='center' className='ada-text-width'>
            Porsche Financial Services offers a range of customizable solutions for drivers who know exactly what they
            want. The same power, confidence, and control you experience behind the wheel of a Porsche vehicle can be
            found in every step of our process, helping make your dream a reality.
          </PText>
        </PFlex>
      </PGridItem>
      <PGridItem size={12}>
        <PHeadline variant='headline-2' align='center' className='medium-space-top small-space-bottom'>
          Lease vs. Retail Finance
        </PHeadline>
      </PGridItem>
      <PGridItem size={12}>
        <PFlex justifyContent='center'>
          <PText align='center' className='ada-text-width'>
            Porsche Financial Services offers lease and retail finance options that fit your lifestyle, allowing you to
            focus on the driving experience.
          </PText>
        </PFlex>
      </PGridItem>
      <PGridItem size={12}>
        <PHeadline variant='headline-2' align='center' className='medium-space-top small-space-bottom'>
          Lease
        </PHeadline>
      </PGridItem>
      <PGridItem size={12}>
        <PFlex justifyContent='center'>
          <PText align='center' className='ada-text-width'>
            Leasing through Porsche Financial Services provides flexible terms and mileage options to meet your needs.
            Monthly lease payments are typically lower than retail finance payments when compared over the same term,
            and lease customers may become eligible for attractive loyalty programs.
          </PText>
        </PFlex>
      </PGridItem>
      <PGridItem size={12}>
        <PHeadline variant='headline-2' align='center' className='medium-space-top small-space-bottom'>
          Retail Finance
        </PHeadline>
      </PGridItem>
      <PGridItem size={12}>
        <PFlex justifyContent='center'>
          <PText align='center' className='ada-text-width'>
            Financing through Porsche Financial Services means no mileage limitations on your vehicle, may include no
            up-front cash payment requirement, and keeps you in control of your vehicle's future once payments are
            finished.
          </PText>
        </PFlex>
      </PGridItem>
    </>
  )
}

const TradeInContent: React.FC<{
  tradeInVehicle: TradeInVehicleView
}> = ({ tradeInVehicle }) => {
  const intl = useIntl()

  return (
    <>
      <PDivider className='small-space-top small-space-bottom' />
      <PGrid>
        <PGridItem size={12}>
          <PHeadline className='small-space-bottom' variant='headline-4'>
            {intl.formatMessage({ id: 'review.tradein.details' })}
          </PHeadline>
          <TradeInReviewTable tradeIn={tradeInVehicle} confirmationPage />
        </PGridItem>
      </PGrid>
    </>
  )
}

export const TradeInDetails: React.FC<IOpportunityRender> = ({ opportunity }) => {
  const noTradeInProvided = opportunity.tradeInVehicle?.model === NOT_PROVIDED
  if (!opportunity.tradeInVehicle || noTradeInProvided) {
    return null
  }

  return <TradeInContent tradeInVehicle={opportunity.tradeInVehicle} />
}

export const DownPaymentOld: React.FC<IOpportunityRender> = ({ opportunity }) => {
  return (
    <>
      <PGridItem size={8}>
        <PText size='medium'>Down payment</PText>
      </PGridItem>
      <PGridItem size={4}>
        <PText align='right' size='medium'>
          {formatCurrency(
            opportunity?.financialProduct?.payload?.downPayment
              ? opportunity?.financialProduct?.payload?.downPayment
              : 0,
            config().locale
          )}
        </PText>
      </PGridItem>
    </>
  )
}

export const DownPayment: React.FC<IOpportunityRender> = ({ opportunity }) => {
  return (
    <>
      <PGridItem size={8}>
        <PText data-testid='confirmationDownPaymentTitle'>Down payment</PText>
      </PGridItem>
      <PGridItem size={4}>
        <PText align='right' data-testid='confirmationDownPaymentValue'>
          {formatCurrency(
            opportunity?.financialProduct?.payload?.downPayment
              ? opportunity?.financialProduct?.payload?.downPayment
              : 0,
            config().locale
          )}
        </PText>
      </PGridItem>
    </>
  )
}

export const KBBChart: React.FC<{
  tradeInVehicle: TradeInVehicleView
}> = ({ tradeInVehicle }) => {
  const { notes, valuation } = tradeInVehicle
  const isKBB = valuation && valuation.provider === 'KBB'
  const displayKBBPriceAdvisor = isKBB && notes
  return displayKBBPriceAdvisor && notes ? (
    <KBBPriceAdvisor urlToDisplay={parseKBBNotes(notes).priceAdvisorUrl} env={environment} />
  ) : null
}

export const VehicleImage: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  return config().isMarket('ca') || isCheckout2Enabled() ? (
    <>
      <img
        src={opportunity.product?.imageUrl ?? './images/default-vehicle-img.jpeg'}
        width='100%'
        alt={opportunity.vehicle?.modelName ?? 'Porsche Car'}
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        className='small-space-top small-space-bottom'
      />
      {!opportunity.product?.imageUrl ? <PText size='small'>Actual vehicle not shown</PText> : <></>}
    </>
  ) : (
    <PGridItem size={{ base: 12, m: 5 }}>
      <img
        src={opportunity.product?.imageUrl ?? './images/default-vehicle-img.jpeg'}
        width='100%'
        alt={opportunity.vehicle?.modelName ?? 'Porsche Car'}
        className='small-space-top small-space-bottom'
      />
      {!opportunity.product?.imageUrl ? <PText size='small'>Actual vehicle not shown</PText> : <></>}
    </PGridItem>
  )
}

export const Footer: React.FC<IFinanceOpportunityRender> = ({ opportunity }) => {
  const { enableCheckoutFinancingButton } = useFlags()

  const aoacApply = async () => {
    opportunity.financialProduct.payload.sentToAoac = true

    // Enhance object with financial data if absent
    trackClickEvent(AnalyticsEventTemplates.CONFIRM_STEP_AOAC)

    const aoacService = new AoacService(defaultErrorHandler)

    const aoacResult = await aoacService.bootstrap(opportunity)

    aoacService.proceedToAOAC(aoacResult)
  }

  const [applyDialogOpen, setApplyDialogOpen] = useState(false)
  const showApplyDialog = () => {
    setApplyDialogOpen(true)
  }

  return (
    <PGrid className='big-space-bottom'>
      <PfsText />

      {enableCheckoutFinancingButton && isFinancePricingAvailable(opportunity) ? (
        <PGridItem size={12}>
          <PFlex justifyContent='center'>
            <PButton
              variant='primary'
              onClick={showApplyDialog}
              className='small-space-top'
              aria={{ 'aria-haspopup': 'dialog' }}
            >
              Apply for Financing
            </PButton>
            <AoacModal
              applyHandler={aoacApply}
              onRequestClose={() => setApplyDialogOpen(false)}
              open={applyDialogOpen}
            />
          </PFlex>
        </PGridItem>
      ) : (
        <></>
      )}
    </PGrid>
  )
}

export const AoacFooter: React.FC = () => {
  return (
    <PGrid>
      <PGridItem size={12}>
        <PFlex direction='column' alignItems='center'>
          <PHeadline variant='headline-2' align='center' className='medium-space-top small-space-bottom ada-text-width'>
            Porsche Financial Services
          </PHeadline>
          <PText align='center' className='small-space-top medium-space-bottom ada-text-width'>
            Porsche Financial Services offers a range of customizable solutions for drivers who know exactly what they
            want. The same power, confidence, and control you experience behind the wheel of a Porsche vehicle can be
            found in every step of our process, helping make your dream a reality.
          </PText>
          <PHeadline variant='headline-2' align='center' className='medium-space-top small-space-bottom ada-text-width'>
            Porsche Protection Plan Products. High Performance Coverage.
          </PHeadline>
          <PText align='center' className='small-space-top huge-space-bottom ada-text-width'>
            You made a smart decision with Porsche Financial Services. Don’t miss the opportunity to give your new
            Porsche the protection it deserves with the optional Porsche Protection Plan¹ suite of products. Help keep
            your Porsche vehicle looking its best and performing even better. Coverage terms and monthly payment options
            are available if you include a multi-coverage package or product in your financing contract. Please refer to
            the applicable Porsche Protection Plan Agreement or Addendum for details of terms, conditions, and specific
            coverage details, including limitations, exclusions, transfers, and cancellation.
          </PText>
        </PFlex>
      </PGridItem>
      {showConfirmationMyPorsche && (
        <>
          <PGridItem size={12}>
            <PText size='medium' align='center' className='small-space-top medium-space-bottom'>
              To help keep you in control of your Porsche Financial Services account, you can check the status of your
              application through your My Porsche Account.
            </PText>
          </PGridItem>

          <PGridItem size={12} className='huge-space-bottom'>
            <PFlex justifyContent='center'>
              <PLink href='https://my.porsche.com/' variant='primary'>
                Link to My Porsche
              </PLink>
            </PFlex>
          </PGridItem>
        </>
      )}
    </PGrid>
  )
}

export const CustomerSatisfactionSurvey: React.FC = () => {
  return (
    <PGrid>
      <PGridItem size={12}>
        <PHeadline variant='headline-2' align='center' className='small-space-bottom'>
          <FormattedMessage id='confirmation.customerSatisfactionSurvey.header' />
        </PHeadline>

        <PFlex direction='column' alignItems='center'>
          <PText align='center' className='small-space-bottom ada-text-width'>
            <FormattedMessage id='confirmation.customerSatisfactionSurvey.sub' />
          </PText>
        </PFlex>

        <PFlex justifyContent='center'>
          <PButton variant='primary' onClick={() => window.open(config().customerSatisfactionSurveyLink)}>
            <FormattedMessage id='confirmation.customerSatisfactionSurvey.cta' />
          </PButton>
        </PFlex>
      </PGridItem>
    </PGrid>
  )
}
