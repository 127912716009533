export const CA_PROVINCES = [
  {
    value: 'AB',
    label: 'Alberta'
  },
  {
    value: 'BC',
    label: 'British Columbia'
  },
  {
    value: 'MB',
    label: 'Manitoba'
  },
  {
    value: 'NB',
    label: 'New Brunswick'
  },
  {
    value: 'NL',
    label: 'Newfoundland and Labrador'
  },
  {
    value: 'NT',
    label: 'Northwest Territories'
  },
  {
    value: 'NS',
    label: 'Nova Scotia'
  },
  {
    value: 'NU',
    label: 'Nunavut'
  },
  {
    value: 'ON',
    label: 'Ontario'
  },
  {
    value: 'PE',
    label: 'Prince Edward Island'
  },
  {
    value: 'QC',
    label: 'Quebec'
  },
  {
    value: 'SK',
    label: 'Saskatchewan'
  },
  {
    value: 'YT',
    label: 'Yukon'
  }
]

export const CA_PROVINCES_FR = [
  {
    value: 'AB',
    label: 'Alberta'
  },
  {
    value: 'BC',
    label: 'Colombie-Britannique'
  },
  {
    value: 'MB',
    label: 'Manitoba'
  },
  {
    value: 'NB',
    label: 'Nouveau-Brunswick'
  },
  {
    value: 'NL',
    label: 'Newfoundland & Labr.'
  },
  {
    value: 'NT',
    label: 'Terr. du Nord-Ouest'
  },
  {
    value: 'NS',
    label: 'Nouvelle-Écosse'
  },
  {
    value: 'NU',
    label: 'Nunavut'
  },
  {
    value: 'ON',
    label: 'Ontario'
  },
  {
    value: 'PE',
    label: 'I. du Prince-Edouard'
  },
  {
    value: 'QC',
    label: 'Québec'
  },
  {
    value: 'SK',
    label: 'Saskatchewan'
  },
  {
    value: 'YT',
    label: 'Territoire du Yukon'
  }
]
