import React from 'react'
import { PText } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { isCheckout2Enabled } from '../../common/checkout'

export interface IGraySimpleTileProps {
  prompt: string
}

export const GraySimpleTile: React.FC<IGraySimpleTileProps> = (props) => {
  const { smallerThan: isMobile } = useBreakpoint('m')

  return (
    <>
      {isMobile && isCheckout2Enabled() ? null : (
        <PText color='inherit' className='neutral-contrast-high ada-text-width'>
          {props.prompt}
        </PText>
      )}
    </>
  )
}
