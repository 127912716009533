import React from 'react'
import { config } from '../../../config'
import { PText } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { FormattedMessage } from 'react-intl'
import { VehicleSelectionViewMode } from '../VehicleSelection'
import { ICheckoutOpportunity } from '../../../common/checkout'

export interface ISummaryImageProps {
  url: string
  altText: string
  style?: any
  className?: string
}

const VehicleSelectionImage: React.FC<ISummaryImageProps> = ({ url, altText, style }) => {
  return (
    <>
      <img src={url || config().defaultCarImage} alt={altText || 'Porsche'} width='100%' style={style} />
      {!url && (
        <PText size='small'>
          <FormattedMessage id='vehicleSelection.notShown' />
        </PText>
      )}
    </>
  )
}

export const OpportunityVehicleSelectionImage: React.FC<{
  viewMode: VehicleSelectionViewMode
  opportunity: ICheckoutOpportunity
  className?: string
}> = ({ viewMode, opportunity, className }) => {
  const { smallerThan: isMobile } = useBreakpoint('l')

  return (
    <VehicleSelectionImage
      url={opportunity.product.imageUrl ?? ''}
      altText={opportunity.vehicle?.modelName ?? ''}
      style={
        viewMode === 'one-third-tile'
          ? {
              marginLeft: 'auto',
              marginRight: 'auto',
              maxHeight: '300px',
              objectFit: 'contain',
              display: isMobile ? 'none !important' : ''
            }
          : {}
      }
      className={className}
    />
  )
}
