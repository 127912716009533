import { DeliveryOptionsView, FinancialProductView, OrderInfoView } from '@pdiatl/common-external-models'
import { action, makeObservable, observable } from 'mobx'
import { User, UserManager } from 'oidc-client'
import { ICheckoutOpportunity, isFinanceOpportunity } from '../common/checkout'
import { generateOrderInformation } from '../components/deliveryOption/DeliveryOptions'

import { config } from '../config'
import { NewProfileService } from '../services/newProfileService'
import OpportunityService from '../services/opportunityService'
import { OpportunityStore } from '../services/opportunityStore'
import { defaultErrorHandler } from '../util/defaultErrorHandler'
import { StackManager } from './stackManager'

interface IStorage {
  setItem: (key: string, item: any) => void
  getItem: (key: string) => any
}

export class InMemory implements IStorage {
  items = {}

  setItem(key: string, item: any) {
    this.items[key] = item
  }

  getItem(key: string) {
    return this.items[key]
  }
}

export class CheckoutApp {
  readonly opportunityStore: OpportunityStore
  readonly stackManager: StackManager
  @observable
  locale = 'en-US'

  @observable
  private oidcUser: User | null

  @observable
  private oidcLogin: Function | undefined

  @observable
  private userManager: UserManager | undefined

  @observable
  private oidcIsLoading = false

  @observable
  submitIsInProgress = false

  @observable
  termsAreAccepted = false

  private storage: IStorage = new InMemory()

  // todo: PRES OIDC user should move out into separate store
  constructor(
    private id: string,
    user: User | null = null,
    opportunityStore?: OpportunityStore,
    private readonly tradeInIsEnabled = false
  ) {
    this.oidcUser = user
    this.opportunityStore =
      opportunityStore ?? new OpportunityStore(this.id, this.user, new OpportunityService(this.getErrorHandler()))
    this.stackManager = new StackManager(this)

    void this.loadOpportunity()

    makeObservable(this)
  }

  /**
   * Based on opportunity decides if payment is required
   */
  public isDepositJourney(enablePcnaPaymentStep: boolean): boolean {
    const oppo = this.getOpportunity()

    const isPaymentEnabled = config().isMarket('us') ? enablePcnaPaymentStep : config().paymentStep?.enabled

    // downpayment is defined and submerchant is present
    const dealerIsConfigured = !!(oppo?.orderInformation?.downPayment?.value && oppo?.vehicle?.payload?.submerchantId)

    return isPaymentEnabled && dealerIsConfigured && oppo?.source === 'VEHICLERESERVATION'
  }

  get defaultErrorHandler() {
    return (e: Error) => console.log(e)
  }

  public setLocale(locale: string) {
    this.locale = locale
  }

  public getErrorHandler() {
    return this.defaultErrorHandler
  }

  @action
  public updateFinance(res: FinancialProductView) {
    const oppo = this.getOpportunity()
    if (oppo && isFinanceOpportunity(oppo)) {
      oppo.financialProduct.payload = res
    }
  }

  @action
  public setAuth(user: User | null, login?: Function, userManager?: UserManager, isLoading = false) {
    this.oidcUser = user
    this.oidcLogin = login
    this.userManager = userManager
    this.oidcIsLoading = isLoading
  }

  public get user(): User | null {
    return this.oidcUser
  }

  public get authUrl() {
    return this.userManager?.createSigninRequest()
  }

  public get authIsLoading() {
    return this.oidcIsLoading
  }

  public login() {
    if (!this.oidcLogin) {
      throw new Error('No login callback is set')
    }

    this.oidcLogin()
  }

  public refreshOpportunity() {
    const cancel = setInterval(async () => {
      const opportunity = this.getOpportunity()
      if (opportunity?.opportunityState === 'READY') {
        clearTimeout(cancel)
      } else {
        await this.loadOpportunity(opportunity?.id, true)
      }
    }, config().refreshInterval)
  }

  public async loadOpportunity(id?: string, refresh?: boolean) {
    if (!id && !this.id) {
      throw new Error('Opportunity ID is not defined')
    }
    return await this.opportunityStore.getOpportunity(id ?? this.id, refresh)
  }

  get opportunity() {
    return this.opportunityStore.getOpportunity()
  }

  public tradeInEnabled() {
    return this.tradeInIsEnabled
  }

  @action
  public updateOrderInformation() {
    const oppo = this.getOpportunity()

    if (oppo && oppo.orderInformation) {
      const fieldsToUpdate: Partial<OrderInfoView> = {
        contractType: this.getContractType(),
        orderDateTime: new Date().toISOString(),
        termsAccepted: true,
        paymentMethodId: +(oppo?.customerProfile?.payload?.selectedCard?.id ?? '0'),
        customerProvidesTradeInVehicle: oppo?.tradeInVehicle !== undefined
      }

      oppo.orderInformation = {
        ...oppo.orderInformation,
        ...fieldsToUpdate
      }
    }
  }

  getContractType(): 'LEASING' | 'LOAN' | 'CASH' {
    if (config().isMarket('ca')) {
      return 'CASH'
    }

    const oppo = this.getOpportunity()

    if (oppo?.financialProduct?.payload?.creditType === 'LSE') {
      return 'LEASING'
    } else if (oppo?.financialProduct?.payload?.creditType === 'RTL') {
      return 'LOAN'
    } else {
      return 'CASH'
    }
  }

  public setStorageProvider(storage: Storage) {
    this.storage = storage
  }

  store(key: string, value: any) {
    this.storage?.setItem(key, value)
  }

  restore(key: string): any {
    return this.storage?.getItem(key)
  }

  public static getReservationValueAndCurrency(opportunity: ICheckoutOpportunity) {
    let value, currency

    if (opportunity?.orderInformation?.downPayment?.value) {
      value = opportunity.orderInformation.downPayment.value
      currency = config().isMarket('us') ? 'USD' : 'CAD'
    } else {
      return null
    }

    return { value, currency }
  }

  getOpportunity(): ICheckoutOpportunity | undefined {
    return this.opportunityStore.opportunityCache
  }

  /**
   * Stores URL of the front-end application for further redirects
   * @param href
   */
  @action
  setOpportunityOrigin(href: string) {
    const oppo = this.getOpportunity()

    if (oppo) {
      oppo.customerProfile = oppo.customerProfile ?? {}
      oppo.customerProfile.payload = oppo.customerProfile?.payload ?? {}
      oppo.customerProfile.payload.confirmationPage = href
    }
  }

  /**
   * Sets preferred delivery option though UI
   * @param deliveryOption
   * @param enablePcnaPaymentStep
   */
  @action
  setDeliveryMethod(deliveryOption: DeliveryOptionsView['deliveryOption'], enablePcnaPaymentStep: boolean) {
    const oppo = this.getOpportunity()!

    if (deliveryOption === 'HOME') {
      this.updateOrderInformationForHomeDelivery(oppo, enablePcnaPaymentStep)
    } else {
      this.updateOrderInformationForDealershipDelivery(oppo, enablePcnaPaymentStep)
    }
  }

  updateOrderInformationForHomeDelivery(oppo: ICheckoutOpportunity, enablePcnaPaymentStep: boolean) {
    if (this.isDepositJourney(enablePcnaPaymentStep)) {
      oppo.orderInformation = {
        ...oppo.orderInformation!,
        deliveryOptions: {
          deliveryOption: 'HOME'
        }
      }
    } else {
      oppo.orderInformation = generateOrderInformation()
    }
  }

  updateOrderInformationForDealershipDelivery(oppo: ICheckoutOpportunity, enablePcnaPaymentStep: boolean) {
    if (this.isDepositJourney(enablePcnaPaymentStep)) {
      oppo.orderInformation = {
        ...oppo.orderInformation!,
        deliveryOptions: {
          deliveryOption: 'SELLING_PZ'
        }
      }
    } else {
      oppo.orderInformation = undefined
    }
  }

  /**
   * This is bootstrap method to create empty orderInformation
   * @returns orderInfo
   */

  isOwnFinancing() {
    const oppo = this.getOpportunity()

    if (!oppo || !config().isMarket('us')) {
      return false
    }

    const { financialProduct } = oppo
    return !!financialProduct && !['LSE', 'RTL'].includes(financialProduct?.payload?.creditType)
  }

  private get profileService() {
    return new NewProfileService(defaultErrorHandler)
  }

  get addresses() {
    return this.profileService.fetchAddresses(this.user!)
  }

  get phones() {
    return this.profileService.fetchPhones(this.user!)
  }

  isProfileValid(): boolean {
    const opportunity = this.getOpportunity()

    return !!(
      opportunity?.customerProfile?.phoneNumber &&
      opportunity.customerProfile.addresses &&
      opportunity.customerProfile.addresses.length > 0
    )
  }

  @action
  async selectAddress(addressId: string) {
    const opportunity = this.getOpportunity()
    if (opportunity) {
      localStorage.setItem(`${String(opportunity.id)}-addressId`, addressId)
      const address = (await this.addresses).find((a) => a.addressId === addressId)

      if (address) {
        opportunity.customerProfile!.addresses = [
          { ...address, countryCode: address.country, usage: address.usage.toUpperCase() }
        ]
      }
    }
  }

  @action
  async selectPhone(phoneId: string) {
    const opportunity = this.getOpportunity()
    if (opportunity) {
      localStorage.setItem(`${String(opportunity.id)}-phoneId`, phoneId)
      const phone = (await this.phones).find((a) => a.id === phoneId)
      if (phone) {
        opportunity.customerProfile!.phoneNumber = phone.number
      }
    }
  }

  @action
  async loadProfile() {
    const opportunity = this.getOpportunity()

    if (opportunity) {
      if (!opportunity.customerProfile) {
        opportunity.customerProfile = {}
      }

      const addrId = localStorage.getItem(`${String(opportunity.id)}-addressId`)
      const phoneId = localStorage.getItem(`${String(opportunity.id)}-phoneId`)

      if (addrId) {
        await this.selectAddress(addrId)
      }

      if (phoneId) {
        await this.selectPhone(phoneId)
      }
    }
  }
}
