import { action, makeObservable, observable } from 'mobx'

export enum UnsavedModalMode {
  HIDDEN = '',
  RETURN_TO_FINDER = 'unsavedDataModal.message.finder',
  RETURN_TO_PREVIOUS_STEP = 'unsavedDataModal.message.previousStep'
}

class UnsavedDataModalStore {
  @observable
  showModalWithKey = ''

  @observable
  checkoutFormDirty = false

  onConfirm: Function | undefined

  constructor() {
    makeObservable(this)
  }

  @action
  setCheckoutFormDirty(checkoutFormDirty: boolean) {
    this.checkoutFormDirty = checkoutFormDirty

    if (!checkoutFormDirty) {
      this.onConfirm = undefined
    }
  }

  @action
  showModal(mode: UnsavedModalMode, onConfirm: Function) {
    this.showModalWithKey = mode
    this.onConfirm = onConfirm
  }

  @action
  hideModal() {
    this.showModalWithKey = UnsavedModalMode.HIDDEN
  }
}
export const unsavedDataModalStore = new UnsavedDataModalStore()
