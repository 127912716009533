export type IValidator = (value: string | undefined) => string | undefined
export type IValidators = Record<string, IValidator | IValidator[]>

function isEmpty(value: string | undefined) {
  return value === undefined || value.trim().length === 0
}

export function validateRequired(message: string): IValidator {
  return (value: string | undefined) => (isEmpty(value) ? message : undefined)
}

export function validatePattern(pattern: RegExp, message: string): IValidator {
  return (value: string | undefined) => (!value?.match(pattern) ? message : undefined)
}

export function validatePositiveIntInput(message: string): IValidator {
  return (value: string | undefined) => (isEmpty(value) || isNaN(Number(value!.trim())) ? message : undefined)
}

export function validateField(
  value: string | undefined,
  validatorOrArray: IValidator | IValidator[]
): string | undefined {
  if (Array.isArray(validatorOrArray)) {
    for (const fieldValidator of validatorOrArray) {
      const err = fieldValidator(value)

      if (err) {
        return err
      }
    }
  } else {
    const err = validatorOrArray(value)

    if (err) {
      return err
    }
  }

  return undefined
}

export function validateAll(
  values: Record<string, any>,
  allValidators: IValidators
): Record<string, string | undefined> {
  return Object.getOwnPropertyNames(allValidators).reduce((res, name) => {
    const fieldValidatorOrArray = allValidators[name]
    const err = validateField(values[name], fieldValidatorOrArray)

    if (err) {
      res[name] = err
    } else {
      delete res[name]
    }

    return res
  }, {})
}
