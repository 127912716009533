import React, { useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'

import { useFlags } from '../../common/flags'
import { PresenterContext } from '../entry/presenterProvider'
import { StackElement } from '../stack/stackElement'
import { Checkout2Content } from './Checkout2Content'
import { LoginRegisterStackElement } from '../loginRegister/loginRegisterStackElement'
import { VehicleSelectionStackElement } from './vehicleSelection/VehicleSelectionStackElement'
import { ProfileStackElement } from '../profile/profileStackElement'
import { TradeInStackElement } from '../tradeIn/tradeInStackElement'
import { AncillaryProductsStackElement } from '../ancillaryProducts/AncillaryProductsStackElement'
import { PaymentStackElement } from '../payments/paymentStackElement'
import { DeliveryOptionStackElement } from '../deliveryOption/DeliveryOptionStackElement'
import { ReviewStackElement2 } from '../review/checkout2/ReviewStackElement2'

export const Checkout2: React.FC = observer(() => {
  const presenter = useContext(PresenterContext)
  const intl = useIntl()

  const { enableTradeInStepPcna, enablePcnaPaymentStep, enableAncillaryProductsPcna } = useFlags()

  const [elements, setElements] = useState<StackElement[]>([])

  useMemo(() => {
    const translate = (key: string) => intl.formatMessage({ id: key })

    let index = 0
    const elementsArr = [
      new VehicleSelectionStackElement(index++, translate('vehicleSelection2.title')),
      new LoginRegisterStackElement(index++, translate('login2.title'), presenter),
      new ProfileStackElement(index++, translate('profile.title')),
      enableTradeInStepPcna && new TradeInStackElement(index++, translate('tradein2.title')),
      enableAncillaryProductsPcna && new AncillaryProductsStackElement(index++, translate('ancillaryProducts2.title')),
      presenter.isDepositJourney(enablePcnaPaymentStep) &&
        new PaymentStackElement(index++, translate('payment2.title')),
      new DeliveryOptionStackElement(index++, translate('delivery.title')),
      new ReviewStackElement2(index++, translate('review.header'))
    ].filter(Boolean) as StackElement[]

    presenter.stackManager.initSteps(elementsArr)
    setElements(elementsArr)
  }, [intl, presenter, enableAncillaryProductsPcna, enablePcnaPaymentStep, enableTradeInStepPcna])

  return elements.length ? <Checkout2Content elements={elements} /> : <></>
})
