import { observer } from 'mobx-react-lite'
import React from 'react'
import { CashVehicleSelection } from './cash/CashVehicleSelection'
import { ILeaseFinanceProps, LeaseFinanceVehicleSelection } from './leaseFinance/LeaseFinanceVehicleSelection'
import { CalculationResult } from './pcna/leaseFinance/defiCalcFinanceData'
import { vehicleSelectionStore } from './pcna/vehicleSelectionStore'

export type VehicleSelectionViewMode = 'full-tile' | 'one-third-tile'

export interface IVehicleSelectionProps {
  viewMode: VehicleSelectionViewMode
  financeData?: CalculationResult
  hideVehicleImageOnMobile?: boolean
}

export const VehicleSelection: React.FC<IVehicleSelectionProps> = observer((props) => {
  const { calculationResult } = vehicleSelectionStore
  const { hideVehicleImageOnMobile, financeData = calculationResult } = props

  // Calculator populates 'totalTax' after providing zip code
  if (financeData) {
    const financeProps = props as ILeaseFinanceProps
    switch (financeData?.creditType) {
      case 'LSE':
        return (
          <LeaseFinanceVehicleSelection
            {...financeProps}
            financeData={financeData}
            type='lease'
            hideVehicleImageOnMobile={hideVehicleImageOnMobile}
          />
        )
      case 'RTL':
        return (
          <LeaseFinanceVehicleSelection
            {...financeProps}
            financeData={financeData}
            type='finance'
            hideVehicleImageOnMobile={hideVehicleImageOnMobile}
          />
        )
    }
  }

  return <CashVehicleSelection {...props} />
})
