import { PButtonPure, PFlex, PFlexItem, PHeadline, PIcon, PModal } from '@porsche-design-system/components-react'
import './MobileVehicleSummary.scss'
import React, { useContext, useState } from 'react'
import { Currency } from '../../../confirmation/shared/ui'
import { FormattedMessage } from 'react-intl'
import { PresenterContext } from '../../../entry/presenterProvider'
import { getPriceLabel, ICheckoutOpportunity } from '../../../../common/checkout'
import { VehicleSelection } from '../../VehicleSelection'
import { AnalyticsPage } from '../../../../onega/AnalyticsEventTemplates'
import { observer } from 'mobx-react-lite'
import { vehicleSelectionStore } from '../vehicleSelectionStore'
import { GetEstimatedMonthlyPayment } from '../../leaseFinance/LeaseFinanceVehicleSelection'
import { MsrpPricingSection } from '../../cash/CashVehicleSelection'

const MobileVehicleSummaryModal: React.FC<{
  open: boolean
  onClose: () => void
  opportunity: ICheckoutOpportunity
}> = ({ open, onClose }) => {
  const shadowSeparator = {
    boxShadow: '1px 6px 6px -6px #c9cacb',
    margin: '-20px 0 20px -20vw',
    paddingBottom: '14px',
    width: '100%'
  }

  return (
    <PModal open={open} onClose={onClose} fullscreen={{ base: true }} disableCloseButton>
      <PFlex direction='column'>
        <PFlexItem style={{ width: '100%' }}>
          <PFlex justifyContent='space-between' style={{ padding: '30px 0 30px 0' }}>
            <PFlexItem alignSelf='flex-start'>
              <PButtonPure icon='arrow-head-left' onClick={() => onClose()} style={{ padding: '10px 0px' }} />
            </PFlexItem>
            <PFlexItem alignSelf='center'>
              <PHeadline variant='headline-4' tag='h3'>
                Vehicle Summary
              </PHeadline>
            </PFlexItem>
            <PFlexItem />
          </PFlex>
        </PFlexItem>
        <PFlexItem style={{ ...shadowSeparator }} />
        <PFlexItem>
          <VehicleSelection viewMode='one-third-tile' />
        </PFlexItem>
      </PFlex>
    </PModal>
  )
}

export const MobileVehicleSummary: React.FC = observer(() => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const calculationResult = vehicleSelectionStore.calculationResult
  const [modalOpenState, setModalOpenState] = useState(false)

  return (
    <PFlex className='mobile-vehicle-summary' justifyContent='space-between'>
      <PFlexItem className='mobile-vehicle-summary-details'>
        <PHeadline variant='headline-4' tag='h2'>
          Vehicle Summary
        </PHeadline>
        <div>
          <FormattedMessage id={getPriceLabel(opportunity)} />:{' '}
          <Currency value={opportunity.product.price.value!} currency={opportunity.product.price.currencyCode} />
        </div>
        <div>
          <MsrpPricingSection opportunity={opportunity} analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS} />
        </div>
        {calculationResult &&
          GetEstimatedMonthlyPayment(
            'full-tile',
            calculationResult,
            calculationResult.creditType === 'LSE' ? 'lease' : 'finance',
            opportunity.product.price.currencyCode,
            true
          )}
      </PFlexItem>
      <PFlexItem>
        <button
          className='mobile-vehicle-summary-modal-arrow'
          onClick={() => {
            setModalOpenState(true)
          }}
        >
          <PIcon
            className='mobile-vehicle-summary-modal-arrow-icon'
            color='inherit'
            size='inherit'
            name='arrow-head-right'
          />
        </button>
        <MobileVehicleSummaryModal
          open={modalOpenState}
          onClose={() => setModalOpenState(false)}
          opportunity={opportunity}
        />
      </PFlexItem>
    </PFlex>
  )
})
