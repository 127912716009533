import React, { useContext, useEffect, useState } from 'react'
import { PButton, PButtonGroup, PFlex, PGrid, PGridItem, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { Questions } from '../questions/questions'
import { generateQuestions } from '../../helpers/format'
import { observer } from 'mobx-react-lite'
import { PresenterContext } from '../entry/presenterProvider'
import { DeliveryOptionSelection } from './DeliveryOptionSelection'
import { CheckoutDivider } from '../checkoutDivider/checkoutDivider'
import { DeliveryOptionsView, OrderInfoView } from '@pdiatl/common-external-models'
import { SessionStorageService } from '../../services/sessionStorageService'
import { useFlags } from '../../common/flags'
import { config } from '../../config'
import { trackClickEvent, trackPageLoadEvent } from '@slatldisal/one-ga'
import {
  AnalyticsDeliveryOptionsBtnName,
  AnalyticsDeliveryOptionsSelection,
  AnalyticsEventTemplates,
  getGADeliveryOptionOnLoad
} from '../../onega/AnalyticsEventTemplates'
import { isCheckout2Enabled } from '../../common/checkout'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  getMatomoDeliveryOptionSelection,
  MatomoDeliveryOptionSelection,
  matomoPcnaTrackClickEvent,
  matomoPcnaTrackPageView
} from '../../matomo/matomo'

export const DELIVERY_OPTIONS_WINDOW_EVENT = 'delivery-options-selected-event'

export interface IDeliveryOptionsValues {
  selection: DeliveryOptionsView['deliveryOption']
}

interface IDeliveryOption {
  i18nKey: string
  value: DeliveryOptionsView['deliveryOption']
}

const deliveryOptions: IDeliveryOption[] = [
  { i18nKey: 'delivery.type.dealer', value: 'SELLING_PZ' },
  { i18nKey: 'delivery.type.home', value: 'HOME' }
]

export function generateOrderInformation(): OrderInfoView {
  return {
    orderId: '',
    paymentTransactionStatusUrl: '',
    contractType: 'CASH',
    emailForOrderConfirmation: '',
    deliveryOptions: { deliveryOption: 'HOME' },
    orderDateTime: new Date().toISOString(),
    customerLocale: config().locale,
    termsAccepted: false
  }
}

export const DeliveryOptions: React.FC = observer(() => {
  const { pushInstruction, trackEvent } = useMatomo()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const dealer = opportunity.owner?.displayName ?? 'Porsche Centre'
  const { enablePcnaPaymentStep } = useFlags()

  const deliveryOptionFromStorage = SessionStorageService.getAndParsePrefixedItem<IDeliveryOptionsValues>(
    CheckoutStorageKeys.DELIVERY_OPTIONS_VALUES
  )
  const [selectedOption, setSelectedOption] = useState<DeliveryOptionsView['deliveryOption']>(
    deliveryOptionFromStorage?.selection ?? 'SELLING_PZ'
  )

  useEffect(() => {
    matomoPcnaTrackPageView(pushInstruction, 'checkout/delivery_options')

    trackPageLoadEvent(AnalyticsEventTemplates.DELIVERY_OPTIONS)

    trackClickEvent(AnalyticsEventTemplates.DELIVERY_OPTIONS_SELECTION(getGADeliveryOptionOnLoad()))
  }, [pushInstruction])

  const btnClickHandler = (
    selection: DeliveryOptionsView['deliveryOption'],
    clickElementName: AnalyticsDeliveryOptionsBtnName
  ) => {
    SessionStorageService.setPrefixedItem(CheckoutStorageKeys.DELIVERY_OPTIONS_VALUES, {
      selection
    })
    presenter.setDeliveryMethod(selection, enablePcnaPaymentStep)

    window.dispatchEvent(new CustomEvent(DELIVERY_OPTIONS_WINDOW_EVENT, { detail: selection }))

    matomoPcnaTrackClickEvent(
      trackEvent,
      clickElementName === AnalyticsDeliveryOptionsBtnName.SKIP
        ? MatomoDeliveryOptionSelection.SKIP
        : getMatomoDeliveryOptionSelection(selection)
    )
    trackClickEvent(AnalyticsEventTemplates.DELIVERY_OPTIONS_STEP_CLICK_BUTTON(clickElementName))

    presenter.stackManager.editNext()
  }

  return (
    <>
      <PGrid direction={{ base: 'column', s: 'row' }}>
        <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, m: 5 }}>
          <PText className='ada-text-width'>
            <FormattedMessage id='delivery.prompt' />
          </PText>

          <PFlex
            direction={{ base: 'column', s: 'column' }}
            style={{
              border: '1px solid #D8D8D8',
              borderRadius: '2px',
              boxSizing: 'border-box',
              borderBottom: 'none'
            }}
            className='small-space-top medium-space-bottom'
          >
            {deliveryOptions.map((option) => (
              <PFlex
                direction='column'
                style={{ borderBottom: '1px solid #D8D8D8', padding: '15px' }}
                key={option.value}
              >
                <DeliveryOptionSelection
                  value={option.value}
                  i18nKey={option.i18nKey}
                  dealer={dealer}
                  onChange={(value) => {
                    trackClickEvent(
                      AnalyticsEventTemplates.DELIVERY_OPTIONS_SELECTION(
                        value === 'HOME'
                          ? AnalyticsDeliveryOptionsSelection.DELIVERY_TO_HOME
                          : AnalyticsDeliveryOptionsSelection.PICK_UP_AT_DEALERSHIP
                      )
                    )
                    setSelectedOption(value)
                  }}
                  checked={selectedOption === option.value}
                />
              </PFlex>
            ))}
          </PFlex>

          <PButtonGroup className='medium-space-bottom'>
            <PButton
              variant='secondary'
              onClick={() => btnClickHandler(selectedOption, AnalyticsDeliveryOptionsBtnName.SAVE_AND_CONTINUE)}
            >
              <FormattedMessage id='common.saveAndContinue' />
            </PButton>
            <PButton
              variant='tertiary'
              onClick={() => btnClickHandler('SELLING_PZ', AnalyticsDeliveryOptionsBtnName.SKIP)}
            >
              <FormattedMessage id='common.skip' />
            </PButton>
          </PButtonGroup>
        </PGridItem>

        {!isCheckout2Enabled() && (
          <>
            <CheckoutDivider />
            <PGridItem size={{ base: 12, m: 6 }}>
              <Questions questions={generateQuestions('delivery.faq', 3, { dealer })} />
            </PGridItem>
          </>
        )}
      </PGrid>
    </>
  )
})
