import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import React, { useContext } from 'react'
import { PresenterContext } from '../entry/presenterProvider'
import { PIcon } from '@porsche-design-system/components-react'
import { StepState } from '../../presenter/stackManager'

import './StepCircle2.scss'

export const StepCircle2: React.FC<{
  index: number
}> = ({ index }) => {
  const presenter = useContext(PresenterContext)
  const { smallerThan: isMobile } = useBreakpoint('m')
  const { smallerThan: isSmallMobile } = useBreakpoint('s')

  const name = presenter.stackManager.steps[index].icon
  const state = presenter.stackManager.steps[index].state
  const theme = state === StepState.CLEAR ? 'light' : 'dark'
  const circleSize = state === StepState.CLEAR && isMobile ? 'medium' : 'small'
  const circleClass = `circle2 medium-space-right medium-space-left ${state}`

  return (
    <div className={circleClass} style={{ marginRight: isSmallMobile ? '32px' : '' }}>
      <div className='circle-border' />
      <PIcon size={circleSize} data-testid={`${name}_${theme}_${circleSize}`} name={name} theme={theme} />
    </div>
  )
}
