// @ts-ignore
import { DefiFinderCalculator } from '@slatldisal/defi-finder-calc'
import { getFinancialPayload, setFinancialPayload, trackClickEvent } from '@slatldisal/one-ga'
import debounce from 'lodash.debounce'
import React, { useState } from 'react'
import { ICheckoutOpportunity } from '../../../../common/checkout'
import { config } from '../../../../config'
import { AnalyticsEventTemplates, buildFinanceDataOneGA, resetFinance } from '../../../../onega/AnalyticsEventTemplates'
import { SkeletonBox, SkeletonLoader, SkeletonText } from '@slatldisal/pdus-components'
import { vehicleSelectionStore } from '../vehicleSelectionStore'
import { CalculationResult } from './defiCalcFinanceData'
import { styleConfiguration } from './defiCalcStylesConf'
import { IDefiCalcVehicleData } from './defiCalcVehicleData'
import './LeaseFinanceCalculator.scss'

interface ILeaseFinanceCalculatorProps {
  opportunity: ICheckoutOpportunity
  scrollToPaymentOptions: Function
}

const mapOpportunityToModelData = (oppo: ICheckoutOpportunity): IDefiCalcVehicleData => {
  const { vehicle, financePricing } = oppo
  const year = vehicle.modelYear ? String(vehicle.modelYear) : '0'

  return {
    dealerId: vehicle.payload?.dealerId,
    make: 'Porsche',
    porscheCode: vehicle.payload?.finderId,
    modelDesc: vehicle.modelName ?? '',
    modelColor: vehicle.colorName ?? '',
    modelModelType: vehicle.modelCode ?? '',
    modelYear: year,
    modelYearCode: year,
    modelYearDesc: year,
    listPrice: financePricing?.price,
    saleClass: financePricing?.vehicleFinanceCondition
  }
}

export const LeaseFinanceCalculator: React.FC<ILeaseFinanceCalculatorProps> = ({
  opportunity,
  scrollToPaymentOptions
}) => {
  const [isLoading, setLoading] = useState(true)
  const [isCalculatorRerender, setIsCalculatorRerender] = useState(false)
  const checkCalcLoading = setInterval(() => {
    if (!document.querySelector('div[role="progressbar"]')) {
      setLoading(false)
      document.querySelector('#update')?.addEventListener('click', () => scrollToPaymentOptions())
      clearInterval(checkCalcLoading)
    }
  }, 200)

  const model = mapOpportunityToModelData(opportunity)

  return !model.listPrice || !model.saleClass ? (
    <div>
      A payment estimate is currently unavailable for this vehicle. Please contact your authorized Porsche dealer for
      payment options.
    </div>
  ) : (
    <div style={{ paddingTop: '1.125rem' }}>
      <SkeletonLoader template={LeaseFinanceCalculatorSkeleton} loadingFinished={!isLoading}>
        <div className='calc medium-space-bottom'>
          <DefiFinderCalculator
            calcProps={{
              financeData: {
                lease: {
                  lockAnnualMileage: false,
                  lockDownPayment: false,
                  lockTerms: false,
                  lockMonthlyPayment: false
                },
                finance: { lockDownPayment: false, lockTerms: false, lockMonthlyPayment: false },
                currentMileage: opportunity.vehicle.mileage
              },
              config: {
                endpointUrl: config().defiCalculator.endpointUrl,
                styleConfiguration
              },
              model,
              onFinanceDataChange: debounce(async (response: { fd: CalculationResult }) => {
                const data = response.fd

                if (data.showRatesError) {
                  resetFinance()
                }

                setFinancialPayload({
                  ...getFinancialPayload(),
                  ...buildFinanceDataOneGA(data)
                })

                const isValidZip = data.zipCode && data.zipCode.length >= 5

                if (data?.creditType) {
                  const isLease = data.creditType === 'LSE'

                  const event = isCalculatorRerender
                    ? AnalyticsEventTemplates.EXPLORE_PAYMENTS_STEP_CALCULATOR(isLease, data)
                    : AnalyticsEventTemplates.EXPLORE_PAYMENTS_STEP_CALCULATOR_INIT(data.creditScore)

                  if (isValidZip) {
                    trackClickEvent(event)
                  }
                }

                const showFinanceSummary =
                  !data.showRatesError && data.source !== 'ZipCode' && data.source !== 'FicoScore'
                vehicleSelectionStore.setCalculationResult(showFinanceSummary ? data : undefined)

                setIsCalculatorRerender(true)

                return Promise.resolve()
              }, 300)
            }}
          />
        </div>
      </SkeletonLoader>
    </div>
  )
}

const LeaseFinanceCalculatorSkeleton: React.FC = () => {
  return (
    <div style={{ padding: '18px 24px', backgroundColor: '#f2f2f2' }} className='medium-space-bottom'>
      <SkeletonText fontSize='2rem' contentLength={24} style={{ marginBottom: '35px', marginRight: '60px' }} />
      <SkeletonText width='730px' fontSize='1rem' contentLength={310} />

      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '3px' }}>
        <SkeletonBox width='139px' height='51px' style={{ marginTop: '12px', marginRight: '11px' }} />
        <SkeletonBox width='171px' height='51px' style={{ marginTop: '12px', marginRight: '11px' }} />
        <SkeletonBox width='171px' height='51px' style={{ marginTop: '12px', marginRight: '11px' }} />
        <SkeletonBox width='169px' height='51px' style={{ marginTop: '12px', marginRight: '11px' }} />
      </div>

      <SkeletonText contentLength={30} style={{ marginTop: '24px' }} />
      <SkeletonBox width='38%' height='56px' style={{ marginTop: '10px' }} />
      <SkeletonBox width='100px' height='42px' style={{ marginTop: '27px' }} />
    </div>
  )
}
