import { trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import React, { useContext } from 'react'
import { PresenterContext } from '../../entry/presenterProvider'
import { PGrid, PGridItem, PText, PTextList, PTextListItem, PHeadline } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'

export const PclConfirmationHeader: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const dealerAddress = opportunity.vehicle.payload?.dealerAddress as string
  const dealerPhone = opportunity.vehicle.payload?.dealerPhone as string

  return (
    <>
      <PGrid className='medium-space-bottom' direction={{ base: 'column', s: 'row' }}>
        <PGridItem size={{ base: 12, m: 5 }} className='small-space-bottom'>
          <PHeadline variant='headline-5'>
            <FormattedMessage id='confirmation.nextStep' />
          </PHeadline>
          <PTextList>
            <PTextListItem className='small-space-top small-space-bottom'>
              <FormattedMessage id='confirmation.nextStep.a1' />
            </PTextListItem>
            <PTextListItem>
              <FormattedMessage id='confirmation.nextStep.a2' />
            </PTextListItem>
          </PTextList>
        </PGridItem>
        <PGridItem size={{ base: 12, m: 7 }} className='small-space-bottom'>
          <PHeadline variant='headline-5'>
            <FormattedMessage id='confirmation.questions' />
          </PHeadline>
          <PText className='small-space-top xsmall-space-bottom'>{dealerAddress}</PText>
          <PText>
            <a
              href={`tel:${dealerPhone}`}
              onClick={() => trackClickEvent(AnalyticsEventTemplates.CONFIRM_CALL_RETAILER)}
            >
              {dealerPhone}
            </a>
          </PText>
        </PGridItem>
      </PGrid>
    </>
  )
}
