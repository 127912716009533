import { observer } from 'mobx-react-lite'
import React from 'react'
import { vehicleSelectionStore } from '../../../vehicleSelection/pcna/vehicleSelectionStore'
import { CashVehicleSummary } from './CashVehicleSummary'
import { DefaultVehicleSummary } from './DefaultVehicleSummary'
import { LeaseFinanceVehicleSummary } from './LeaseFinanceVehicleSummary'
import { OwnFinancingVehicleSummary } from './OwnFinancingVehicleSummary'

import './StickyVehicleSummary.scss'

export const StickyVehicleSummary: React.FC = observer(() => {
  const selectedPaymentOption = vehicleSelectionStore.selectedPaymentOption

  let VehicleSummarySection: React.FC
  switch (selectedPaymentOption) {
    case 'cash':
      VehicleSummarySection = CashVehicleSummary
      break
    case 'ownFinancing':
      VehicleSummarySection = OwnFinancingVehicleSummary
      break
    case 'leaseFinance':
      VehicleSummarySection = vehicleSelectionStore.calculationResult
        ? LeaseFinanceVehicleSummary
        : DefaultVehicleSummary
      break
    default:
      VehicleSummarySection = DefaultVehicleSummary
  }

  return (
    <div className='sidebar-inner'>
      <VehicleSummarySection />
    </div>
  )
})
