import { IAoacBootstrap, IAoacBootstrapResponse } from '@pdiatl/common-external-models'
import { config } from '../config'
import { APPLICATION_COMPLETE_FIELD } from '../components/entry/presenterProvider'
import { httpStatusHandler } from './httpStatusHandler'
import { IFinanceOpportunity } from '../common/checkout'

export default class AoacService {
  cfg = config()

  constructor(private errorHandler: (e: Error) => any) {}

  async bootstrap(opportunity: IFinanceOpportunity): Promise<IAoacBootstrapResponse> {
    try {
      const completeRequest: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.mapToAoacBootstrap(opportunity))
      }

      return fetch(`${this.cfg.aoac.submissionEndpoint}`, completeRequest)
        .then(httpStatusHandler)
        .then(async (r) => r.json())
        .catch(this.errorHandler)
    } catch (e: any) {
      return this.errorHandler(e)
    }
  }

  proceedToAOAC(aoacResult: IAoacBootstrapResponse) {
    const { CallbackUrl } = aoacResult
    const isAbsolute = CallbackUrl?.startsWith('http')

    const callBackURL = isAbsolute ? CallbackUrl : `${this.cfg.aoac.applicationURLPrefix}${String(CallbackUrl)}`

    global.location.assign(callBackURL)
  }

  mapToAoacBootstrap(opportunity: IFinanceOpportunity): IAoacBootstrap {
    const { id, customerProfile, financialProduct, product, vehicle, owner } = opportunity

    const financialPayload = financialProduct.payload

    const CallbackURL = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }/${this.cfg.market}/${this.cfg.locale}/checkout/${String(
      opportunity.id
    )}/confirmation?${APPLICATION_COMPLETE_FIELD}=true`

    // PCNA dealer ids, which you have, start with 45 to indicate that they are in the US market
    // then the remaining numbers are the dealer ID.
    // PFS dealer ids will only match on the trailing 4 digits,
    // so if you concatenate 000000 + last 4 of the PCNA dealer ID you will get the corresponding PFS dealer ID.
    const DealerId = owner?.porschePartnerNumber.slice(-4).padStart(10, '0') ?? ''
    if (
      !(
        opportunity.financePricing &&
        opportunity.financePricing.price &&
        opportunity.financePricing.vehicleFinanceCondition
      )
    ) {
      throw new Error('Finance Pricing does not exist')
    }
    return {
      Language: 'en-US',
      CallbackURL,
      Image: product.imageUrl ?? '',
      UserGuid: customerProfile?.ciamID ?? '',
      CreditScore: financialPayload.creditScore,
      CreditType: financialPayload.creditType,
      AnnualMileage: financialPayload.annualMileage,
      DownPayment: financialPayload.downPayment,
      Term: financialPayload.term,
      PostalCode: financialPayload.zipCode,
      DealerId,
      ListPrice: opportunity.financePricing.price,
      Make: 'Porsche',
      ModelModelType: vehicle.modelCode ?? '',
      ModelDesc: vehicle.modelSeriesName ?? '',
      ModelColor: vehicle.colorName ?? '',
      ModelYear: `${String(vehicle.modelYear) ?? 0}`,
      ModelYearDesc: `${String(vehicle.modelYear) ?? 0} Porsche ${String(vehicle.modelName) ?? ''}`,
      SaleClass: opportunity.financePricing.vehicleFinanceCondition,
      CurrentMileage: vehicle.mileage ?? 0,
      PorscheCode: vehicle.payload?.finderId,
      VehicleVIN: vehicle.vin,
      TenantContext: {
        ...this.cfg.aoac.tenantConfig
      },
      ActivityContext: {
        UserId: customerProfile?.emailAddress ?? '',
        ActivityId: id
      }
    }
  }
}
