import { PButton, PButtonGroup, PGrid, PGridItem, PHeadline, PText } from '@porsche-design-system/components-react'
import debounce from 'lodash.debounce'
import { User } from 'oidc-client'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isCheckout2Enabled } from '../../common/checkout'
import { useFlags } from '../../common/flags'
import { config } from '../../config'
import { generateQuestions } from '../../helpers/format'
import { CheckoutApp } from '../../presenter/checkoutApp'
import { CheckoutDivider } from '../checkoutDivider/checkoutDivider'
import { Questions } from '../questions/questions'

export enum ContinueOption {
  GUEST,
  LOGIN,
  REGISTER
}

/** After PID account sign-up, we return to OCS to Step 2 and app doesn't know that we're already logged-in
 *  (missing 'oidc.user' session storage parameter), so we execute OIDC logic to log-in user. */
export function handleLoginAfterSignUp(presenter: CheckoutApp, oidcUser: User | null | undefined, isLoading: boolean) {
  const returnedFromSignUp = new URLSearchParams(window.location.search).get('int_ref') === 'sign_up_new_reg'

  if (!oidcUser && returnedFromSignUp && !isLoading) {
    presenter.login()
  }
}

export function getSignUpUrl(): string {
  const encodedRedirectUrl = encodeURIComponent(window.location.href)
  const locale = config().locale === 'fr-CA' ? 'fr_FR' : 'en_US'

  const { pid } = config()
  return `${pid.signUpUrl}/${config().market}/${locale}/register?target_url=${encodedRedirectUrl}`
}

export interface ILoginPrompt {
  onContinue: (continueOption: ContinueOption) => void
  marketPlace: string | undefined
}

export const LoginPrompt: React.FC<ILoginPrompt> = (props) => {
  const { enableGuestCheckoutPcl } = useFlags()
  const [loginClicked, setLoginClicked] = useState(false)
  const guestCheckoutFlag = props.marketPlace === 'us' || enableGuestCheckoutPcl

  const debouncedClickHandler = debounce(
    () => {
      props.onContinue(ContinueOption.GUEST)
    },
    1000,
    { leading: true, trailing: false }
  )

  return (
    <PGrid className='small-space-bottom' direction={{ base: 'column', s: 'row' }}>
      <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, m: 5 }}>
        <PText className='small-space-top ada-text-width'>
          <FormattedMessage id='login.edit.prompt' />
        </PText>

        <PButtonGroup className='medium-space-top medium-space-bottom'>
          <PButton
            loading={loginClicked}
            onClick={() => {
              setLoginClicked(true)
              props.onContinue(ContinueOption.LOGIN)
            }}
          >
            <FormattedMessage id='common.login' />
          </PButton>
          <PButton onClick={() => props.onContinue(ContinueOption.REGISTER)}>
            <FormattedMessage id='login.continue.register' />
          </PButton>
        </PButtonGroup>

        {guestCheckoutFlag && (
          <>
            <PHeadline variant='headline-3' tag='h2'>
              <FormattedMessage id='login.continue.title' />
            </PHeadline>
            <PText className='small-space-top ada-text-width'>
              <FormattedMessage id='login.guest.prompt' />
            </PText>
            <PButtonGroup className='medium-space-top medium-space-bottom'>
              <PButton variant='tertiary' onClick={debouncedClickHandler}>
                <FormattedMessage id='login.continue.guest' />
              </PButton>
            </PButtonGroup>
          </>
        )}
      </PGridItem>
      {!isCheckout2Enabled() && (
        <>
          <CheckoutDivider />
          <PGridItem size={{ base: 12, m: 6 }}>
            <Questions questions={generateQuestions('login.faq', 3)} />
          </PGridItem>
        </>
      )}
    </PGrid>
  )
}
