import { PContentWrapper } from '@porsche-design-system/components-react'
import React from 'react'
import { LogoHeader } from '../../entry/LogoHeader/LogoHeader'
import { isApplicationComplete } from '../../entry/presenterProvider'
import { ConfirmationAutocheck } from '../shared/ConfirmationAutoCheck'
import { ConfirmationDetails } from '../shared/ConfirmationDetails'
import { ConfirmationInfoBlocks } from '../shared/ConfirmationInfoBlocks'
import { OrderConfirmationBox } from '../shared/OrderConfirmationBox'
import { PcnaConfirmationHeader } from './PcnaConfirmationHeader'
import { WorldOfPorsche } from './WorldOfPorsche'

export const PcnaConfirmationPage: React.FC = () => {
  return (
    <>
      <header>
        <LogoHeader />
      </header>

      <PContentWrapper width='basic'>
        <OrderConfirmationBox />
        <PcnaConfirmationHeader isAoac={isApplicationComplete()} />
        <ConfirmationDetails />
        <ConfirmationAutocheck />
        <ConfirmationInfoBlocks />
        <WorldOfPorsche />
      </PContentWrapper>
    </>
  )
}
