export const getLeaseFinanceAsterisks = (isLeaseFinance: boolean, hasWarrantyDisclaimer: boolean): string =>
  isLeaseFinance ? (hasWarrantyDisclaimer ? '**' : '*') : ''

export const getMsrpAsterisks = (isNew: boolean, isLeaseFinance: boolean): string =>
  isNew ? (isLeaseFinance ? '**' : '*') : ''

export const getAsterisksForOldCarWithCashOrOwnFinancing = (
  isOld: boolean,
  isCashOrOwnFinancing: boolean,
  hasWarrantyDisclaimer: boolean
): string => (isOld && isCashOrOwnFinancing ? (hasWarrantyDisclaimer ? '**' : '*') : '')
