import { PFlex, PFlexItem, PHeadline, PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonConfirmationDetails } from '../../confirmation/shared/CommonConfirmationDetails'
import { VehicleImage } from '../../confirmation/shared/ui'
import { PresenterContext } from '../../entry/presenterProvider'

export const Checkout2ConfirmationDetails: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { modelYear, modelName } = opportunity.vehicle

  return (
    <div className='big-space-top big-space-bottom'>
      <PFlex direction='column' alignItems='center'>
        <PFlexItem>
          <PText weight='semibold' size='medium'>
            <FormattedMessage id='confirmation.yourPorsche' />
          </PText>
        </PFlexItem>

        <PFlexItem>
          <PHeadline variant='headline-2' data-testid='confirmationVehicleModel'>
            {modelYear} {modelName}
          </PHeadline>
        </PFlexItem>

        <PFlexItem data-testid='confirmationVehicleImage'>
          <VehicleImage />
        </PFlexItem>
      </PFlex>

      <CommonConfirmationDetails />
    </div>
  )
}
