import {
  PCheckboxWrapper,
  PDivider,
  PFlex,
  PFlexItem,
  PGrid,
  PGridItem,
  PHeadline,
  PText
} from '@porsche-design-system/components-react'
import { spacing } from '@porsche-design-system/utilities'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { AllInPricing } from '@slatldisal/msrp-pricing-us'
import { trackClickEvent } from '@slatldisal/one-ga'

import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { NavigateFunction } from 'react-router-dom'
import { ICheckoutOpportunity, isFinanceOpportunity } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { DoubleBreak } from '../../../common/IntlElements'
import { config } from '../../../config'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { CheckoutApp } from '../../../presenter/checkoutApp'
import { OneGaComponentLocation } from '../../confirmation/shared/OneGaComponentLocation'
import { ReservationDepositDetails } from '../../confirmation/shared/ReservationDepositDetails'
import { OwnFinancing } from '../../confirmation/shared/ui'
import { DealerInformation } from '../../dealerInformation/dealerInformation'
import { PresenterContext } from '../../entry/presenterProvider'
import { NewAllInPricing } from '../../newAllInPricing/newAllInPricing'
import { countryCode } from '../../profile/profileStackElement'
import { GraySimpleTile } from '../../stack/GraySimpleTile'
import { StackElement, StackElementState } from '../../stack/stackElement'
import { MsrpPricingSection } from '../../vehicleSelection/cash/CashVehicleSelection'
import { SSLInfo } from '../../vehicleSelection/sslInfo'
import { CartItemLayout } from './CartItemLayout'
import { CashReviewDetails } from './CashReviewDetails'
import { FinanceReviewDetails } from './FinanceReviewDetails'
import { LeaseReviewDetails } from './LeaseReviewDetails'
import { ReviewCTASection } from './ReviewCTASection'

export const REVIEW_STACK_NAME = 'review'

const CompletedView: React.FC = () => {
  return <div>never should be here</div>
}

export interface ISubmitParams {
  navigate: NavigateFunction
  presenter: CheckoutApp
  trackEvent: (params: { category: string; action: string }) => void
}

export const SummaryDisclaimer: React.FC<{ translationKey: string }> = ({ translationKey }) => {
  return (
    <PText style={{ marginTop: spacing[24] }}>
      <FormattedMessage id={translationKey} />
    </PText>
  )
}

function summaryDetails(opportunity: ICheckoutOpportunity) {
  if (isFinanceOpportunity(opportunity)) {
    switch (opportunity.financialProduct.payload?.creditType) {
      case 'LSE':
        return <LeaseReviewDetails opportunity={opportunity} />
      case 'RTL':
        return <FinanceReviewDetails opportunity={opportunity} />
    }
  }

  return <CashReviewDetails opportunity={opportunity} />
}

export const EditView: React.FC = observer(() => {
  const { enableNewAllInPricing } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { largerThan: isDesktop } = useBreakpoint('m')
  const { smallerThan: isMobile } = useBreakpoint('s')

  const { modelYear, modelName } = opportunity.vehicle
  const profile = opportunity.customerProfile
  const address = (opportunity.customerProfile?.addresses ?? [])[0]
  const financialProduct = opportunity.financialProduct

  return (
    <div>
      <PGrid>
        <PGridItem size={12}>
          <PText className='neutral-contrast-low'>
            <FormattedMessage id='review.disclaimer' />
          </PText>
        </PGridItem>
        <PGridItem size={12} className='medium-space-top small-space-bottom'>
          <PHeadline variant='headline-5' tag='h3'>
            <FormattedMessage id='profile.title' />
          </PHeadline>
          <PHeadline variant='headline-5' tag='h3'>
            {profile?.firstName}&nbsp;
            {profile?.lastName}
          </PHeadline>
          <PText>
            {address?.houseNumber}&nbsp;
            {address?.street1}
          </PText>
          {address?.street2 ? <PText>{address?.street2}</PText> : null}
          <PText>
            {address?.city}&nbsp;
            {address?.postalCode}
          </PText>
          <PText>{countryCode(address?.countryCode)}</PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider className='small-space-top small-space-bottom' />
        </PGridItem>
        <PGridItem size={12} className='small-space-right small-space-top'>
          <CartItemLayout
            heading={
              <PHeadline variant='headline-4'>
                {modelYear} {modelName}
              </PHeadline>
            }
            image={<img src={opportunity.product?.imageUrl ?? config().defaultCarImage} width='100%' alt={modelName} />}
            subHeading={
              <DealerInformation
                dealerUrl={opportunity.owner?.dealerUrl}
                dealerName={opportunity.owner?.displayName}
                analyticsEvent={AnalyticsEventTemplates.REVIEW_DEALER_INFO}
              />
            }
            ownFinancing={<OwnFinancing financialProduct={financialProduct} />}
            priceTag={
              <PFlex
                direction='column'
                alignItems={isDesktop ? 'flex-end' : 'flex-start'}
                className={!isDesktop ? 'small-space-top' : ''}
              >
                <PHeadline variant='headline-4'>
                  <FormattedNumber
                    value={opportunity.product?.price?.value ?? 0}
                    style='currency'
                    currency={opportunity.product?.price?.currencyCode}
                    currencyDisplay='narrowSymbol'
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />
                </PHeadline>
                {enableNewAllInPricing ? (
                  <NewAllInPricing
                    analyticEvents={{
                      onOpen: () =>
                        trackClickEvent(
                          AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW(AnalyticsPage.ORDER_OVERVIEW)
                        ),
                      onClose: () =>
                        trackClickEvent(
                          AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW(
                            AnalyticsPage.ORDER_OVERVIEW
                          )
                        )
                    }}
                  />
                ) : (
                  <AllInPricing
                    requestBodyParams={{
                      vin: opportunity.vehicle.vin,
                      listingId: opportunity.vehicle.payload?.listingId,
                      partnerNumber: opportunity.vehicle.payload?.dealerId,
                      price: opportunity.product.price.value!
                    }}
                    locale={config().locale}
                    marketplaceKey={config().market}
                    env={config().environment}
                    conditionType={opportunity.vehicle.conditionType}
                    alignment={isDesktop ? 'end' : 'start'}
                    analyticEvents={{
                      onOpen: () =>
                        trackClickEvent(
                          AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW(AnalyticsPage.ORDER_OVERVIEW)
                        ),
                      onClose: () =>
                        trackClickEvent(
                          AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT_ORDER_OVERVIEW(
                            AnalyticsPage.ORDER_OVERVIEW
                          )
                        )
                    }}
                  />
                )}

                <MsrpPricingSection
                  opportunity={opportunity}
                  analyticsPage={AnalyticsPage.ORDER_OVERVIEW}
                  alignment={isDesktop ? 'end' : 'start'}
                />

                <PText size='small' align='right' className={isDesktop ? '' : 'small-space-top'}>
                  <FormattedMessage id='review.price.disclaimer' />
                </PText>
              </PFlex>
            }
            buttons={[]}
            descriptionItems={[
              <ReservationDepositDetails
                editable
                key='reservation-details'
                oneGaComponentLocation={OneGaComponentLocation.CHECKOUT_ORDER_OVERVIEW}
              />
            ]}
          />
        </PGridItem>
        <PGridItem size={12}>
          <PDivider className='medium-space-top medium-space-bottom' />
        </PGridItem>

        {summaryDetails(opportunity)}

        <PGridItem size={12}>
          <PHeadline variant='headline-5'>
            <FormattedMessage id='review.termsAndConditions' />
          </PHeadline>
          <div className='surface-box'>
            <div className='small-space-top small-space-bottom'>
              <PFlex className='medium-space-right small-space-top' style={{ marginRight: isMobile ? '32px' : '' }}>
                <PFlexItem alignSelf='flex-start'>
                  <PCheckboxWrapper hideLabel>
                    <input
                      type='checkbox'
                      name='terms_conditions'
                      id='terms_conditions'
                      value='true'
                      aria-label='Accept Terms'
                      checked={presenter.termsAreAccepted}
                      onChange={(e) => {
                        presenter.termsAreAccepted = e.target.checked
                        trackClickEvent(AnalyticsEventTemplates.TERMS_COND_CHECKED, {
                          clickElementId: 'order_confirmation'
                        })
                      }}
                    />
                  </PCheckboxWrapper>
                </PFlexItem>
                <PFlexItem grow={1}>
                  <label htmlFor='terms_conditions' className='small-space-left is-block ada-text-width'>
                    <PText size='x-small'>
                      <FormattedMessage id='review.termsAndConditions.disclaimer' values={{ break: DoubleBreak }} />
                    </PText>
                  </label>
                </PFlexItem>
              </PFlex>
            </div>
          </div>
        </PGridItem>
        <PGridItem size={12}>
          <ReviewCTASection />
          <SSLInfo className='medium-space-top' align='flex-end' />
        </PGridItem>
      </PGrid>
    </div>
  )
})

const LockedView: React.FC = () => {
  const intl = useIntl()

  return <GraySimpleTile prompt={intl.formatMessage({ id: 'review.title.sub' })} />
}

export class ReviewStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      (props) => <EditView {...props} />,
      CompletedView,
      LockedView,
      () => StackElementState.EDIT,
      REVIEW_STACK_NAME,
      index,
      label,
      'list',
      {
        analyticsPage: AnalyticsPage.ORDER_OVERVIEW,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.ORDER_OVERVIEW,
            matomoActionName: 'checkout/order_overview'
          }
        }
      }
    )
  }
}
