import React from 'react'
import { PHeadline, PText } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { FormattedMessage } from 'react-intl'
import './questions.scss'

export interface ITranslationKey {
  id: string
  values?: Record<string, any>
}

export interface IQuestion {
  q: ITranslationKey
  a: ITranslationKey
  subs?: ITranslationKey[]
}

export interface IQuestionsProps {
  questions: IQuestion[]
}

export const Questions: React.FC<IQuestionsProps> = (props) => {
  const { smallerThan: isMobile } = useBreakpoint('s')

  return (
    <div
      className='medium-space-right medium-space-bottom styled-questions'
      style={{ marginRight: isMobile ? '32px' : '' }}
    >
      {props.questions.map((q, i) => (
        <div key={q.q.id}>
          <PHeadline variant='headline-4' className={`${i !== 0 ? 'medium-space-top' : ''} ada-text-width`}>
            <FormattedMessage id={q.q.id} values={q.q.values} />
          </PHeadline>
          <PText className='neutral-contrast-low ada-text-width'>
            <FormattedMessage id={q.a.id} values={q.a.values} />
          </PText>
          {q.subs?.map((sub) => (
            <PText key={`${q.q.id}.sub`} className='neutral-contrast-low xsmall-space-top ada-text-width'>
              <FormattedMessage id={sub.id} values={sub.values} />
            </PText>
          ))}
        </div>
      ))}
    </div>
  )
}
