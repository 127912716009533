import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  PButton,
  PButtonGroup,
  PDivider,
  PGrid,
  PGridItem,
  PHeadline,
  PText
} from '@porsche-design-system/components-react'

import { formatCurrency } from '@slatldisal/checkout-i18n'
import { IEncryptedCard, IStrongCustomerAuthenticationDataRestModel } from '@pdiatl/common-external-models'
import { trackClickEvent } from '@slatldisal/one-ga'
import { SkeletonBox, SkeletonLoader, SkeletonText } from '@slatldisal/pdus-components'
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { isCheckout2Enabled, isGuest } from '../../common/checkout'
import { useFlags } from '../../common/flags'
import { BoldTag } from '../../common/IntlElements'

import { config } from '../../config'
import { matomoPcnaTrackClickEvent } from '../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import OpportunityService from '../../services/opportunityService'
import { defaultErrorHandler } from '../../util/defaultErrorHandler'
import { unsavedDataModalStore } from '../checkout/unsavedDataModalStore'
import { CheckoutDivider } from '../checkoutDivider/checkoutDivider'

import { PresenterContext } from '../entry/presenterProvider'
import { IQuestion, Questions } from '../questions/questions'
import { SSLInfo } from '../vehicleSelection/sslInfo'
import './paymentCard.scss'

export interface IPaymentWidgetAttributes {
  id?: string
  environment?: 'test' | 'production'
  apikey?: string
  locale?: string
  countrycode?: string
  redirecturl?: string
  oauthtoken?: string
  ref?: React.MutableRefObject<HTMLElement>
  assortmentswithamounts: string
  submerchantid?: string
  currency?: 'USD' | 'CAD'
}

type AssortmentsWithAmounts = {
  assortment: string
  amount: number
}[]

declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface IntrinsicElements {
      'payment-widget': IPaymentWidgetAttributes
      'guest-payment-widget': IPaymentWidgetAttributes
    }
  }
}

export interface IPaymentPayload extends Event {
  detail?: {
    paymentMethodDetails: {
      paymentMethod: ISelectedMethod
      strongCustomerAuthenticationData?: IStrongCustomerAuthenticationDataRestModel
    }
  }
}

export interface ISelectedMethod {
  typeDisplayName: string
  id?: string
  encryptedCard?: IEncryptedCard
  type?: string
  holderName?: string
  preferred?: boolean
  storeMethod?: boolean
}

interface ICardInfo {
  id: string
  type: string
  displayName: string
  holderName: string
  expiryDate: string
  addedAt: string
  preferred: boolean
}

function getQuestions(dealer?: string, downpayment?: number, locale = 'en_US'): IQuestion[] {
  const q1 = {
    q: { id: 'payment.faq.q1' },
    a: { id: 'payment.faq.a1' }
  }
  const q2 = {
    q: { id: 'payment.faq.q2' },
    a: { id: 'payment.faq.a2' }
  }
  const q3 = {
    q: { id: 'payment.faq.q3' },
    a: {
      id: 'payment.faq.a3',
      values: {
        downpayment: formatCurrency(downpayment, 'UNKNOWN DOWNPAYMENT', locale),
        dealer,
        bold: BoldTag
      }
    }
  }
  const q4 = {
    q: { id: 'payment.faq.q4' },
    a: { id: 'payment.faq.a4' }
  }
  const q5 = {
    q: { id: 'payment.faq.q5' },
    a: { id: 'payment.faq.a5' }
  }

  return config().isMarket('us') ? [q1, q2, q3, q4, q5] : [q1, q2, q3, q4, q5]
}

export const PaymentCard: React.FC = () => {
  const { enablePcnaPaymentStepSkip } = useFlags()

  const { trackEvent } = useMatomo()

  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  const cfg = config()

  const dealer = opportunity.owner?.displayName
  const downpayment = opportunity.orderInformation?.downPayment?.value
  const locale = cfg.locale

  const questions = getQuestions(dealer, downpayment, locale)
  const paymentWidgetRef = useRef<HTMLElement>()
  const [isSelected, setSelected] = useState<boolean>(false)
  const [isProceedClicked, setProceedClicked] = useState<boolean>(false)
  const [isSkipClicked, setSkipClicked] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(true)
  const currency = config().isMarket('us') ? 'USD' : 'CAD'
  const assortmentsWithAmounts: AssortmentsWithAmounts = [
    { assortment: 'CAR', amount: opportunity.orderInformation?.downPayment?.value ?? 0 }
  ]

  const attributes: IPaymentWidgetAttributes = {
    environment: cfg.paymentStep.environment,
    apikey: cfg.paymentService.clientId,
    countrycode: opportunity.marketPlace?.toLowerCase(),
    locale,
    redirecturl: window.location.href,
    oauthtoken: presenter.user?.access_token,
    assortmentswithamounts: JSON.stringify(assortmentsWithAmounts),
    currency
  }

  const { countrycode, oauthtoken } = attributes
  if (!countrycode) {
    throw new Error('Country code not provided to PaymentCard')
  }
  if (!oauthtoken && !isGuest()) {
    throw new Error('Access token not provided to PaymentCard')
  }

  const checkWidgetLoading = setInterval(() => {
    const paymentShadow = document.querySelector(`payment-widget`)?.shadowRoot
    // TODO: Find a better selector this also could potentially break if or dependency change something
    if (
      document.querySelector(`div.payment-widget input[type=${isGuest() ? 'text' : 'radio'}]`) ||
      (paymentShadow && paymentShadow.querySelector(`div.payment-widget input[type=${isGuest() ? 'text' : 'radio'}]`))
    ) {
      setTimeout(() => {
        // delay, because widgets spinner still visible for a while when hiding skeleton
        setLoading(false)
        clearInterval(checkWidgetLoading)
      }, 200)
    }
  }, 200)

  const fetchCardInfo = async (id: string, oauthtoken: string): Promise<ICardInfo> => {
    const hostName = attributes.environment === 'production' ? 'api.porsche.com' : 'api.porsche-preview.com'
    const url = `https://${hostName}/payments/v1/mypaymentmethods/${countrycode}/${id}?method=creditcard&currency=${currency}&assortment=CAR`

    const request: RequestInit = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${oauthtoken}`
      }
    }

    if (cfg.paymentService.clientId) {
      // @ts-ignore
      request.headers!.apikey = cfg.paymentService.clientId
    }

    return fetch(url, request)
      .then(async (res) => {
        setTimeout(() => {
          // delay, because widgets spinner still visible for a while when hiding skeleton
          setLoading(false)
        }, 200)

        return res.json()
      })
      .then((res) => {
        const cardInfo = {
          ...res?.details,
          type: res?.displayName
        }
        cardInfo.displayName = cardInfo.displayName.replaceAll('X', '*')

        return cardInfo
      })
      .catch((E) => console.log(E))
  }

  const onPaymentMethodSelected = async (e: IPaymentPayload) => {
    if (isSkipClicked) {
      return
    }

    if (!opportunity.customerProfile) {
      opportunity.customerProfile = {}
    }
    if (!opportunity.customerProfile.payload) {
      opportunity.customerProfile.payload = {}
    }

    if (isGuest() && e?.detail) {
      opportunity.customerProfile.payload = {
        ...opportunity.customerProfile.payload,
        selectedCard: { type: e.detail.paymentMethodDetails.paymentMethod.typeDisplayName },
        paymentMethod: e.detail.paymentMethodDetails.paymentMethod,
        strongCustomerAuthenticationData: e.detail.paymentMethodDetails.strongCustomerAuthenticationData
      }

      setSelected(true)

      unsavedDataModalStore.setCheckoutFormDirty(true)
    } else {
      setSelected(false)
    }

    if (e?.detail?.paymentMethodDetails.paymentMethod.id && attributes.oauthtoken) {
      opportunity.customerProfile.payload.selectedCard = await fetchCardInfo(
        e.detail.paymentMethodDetails.paymentMethod.id,
        attributes.oauthtoken
      )

      opportunity.customerProfile.payload.strongCustomerAuthenticationData =
        e.detail.paymentMethodDetails.strongCustomerAuthenticationData

      if (opportunity.customerProfile?.payload?.selectedCard?.id) {
        setSelected(true)
      }
    }
  }

  useEffect(() => {
    /* TODO: moving this into useEffer. updating observable state causes "Cannot update a component (`wrappedComponent`) while rendering a different component"
     * See detailed stacktrace here -> https://github.com/pdiatl/main-monorepo/issues/4547#issuecomment-1602954378
     */

    opportunity.customerProfile = opportunity.customerProfile ?? {}
    opportunity.customerProfile.payload = opportunity.customerProfile.payload ?? {}

    const currentPaymentWidgetRef = paymentWidgetRef?.current
    if (currentPaymentWidgetRef) {
      currentPaymentWidgetRef.addEventListener('paymentMethodSelectedHandler', onPaymentMethodSelected)
    }

    return () => {
      if (currentPaymentWidgetRef) {
        currentPaymentWidgetRef.removeEventListener('paymentMethodSelectedHandler', onPaymentMethodSelected)
      }
    }
  })

  const proceed = async () => {
    unsavedDataModalStore.setCheckoutFormDirty(false)
    setProceedClicked(true)

    matomoPcnaTrackClickEvent(trackEvent, 'payment_method_confirm')
    trackClickEvent(AnalyticsEventTemplates.PAYMENT(false))

    await new OpportunityService(defaultErrorHandler).update(
      { id: opportunity.id, customerProfile: { payload: opportunity.customerProfile!.payload } },
      presenter.user ?? undefined
    )

    presenter.stackManager.editNext()
  }

  const skip = async () => {
    unsavedDataModalStore.setCheckoutFormDirty(false)
    setSkipClicked(true)

    matomoPcnaTrackClickEvent(trackEvent, 'payment_method_skipped')
    trackClickEvent(AnalyticsEventTemplates.PAYMENT(true))

    opportunity.customerProfile!.payload = {
      ...opportunity.customerProfile!.payload,
      selectedCard: undefined,
      paymentMethod: undefined
    }

    await new OpportunityService(defaultErrorHandler).update(
      { id: opportunity.id, customerProfile: { payload: opportunity.customerProfile!.payload } },
      presenter.user ?? undefined
    )

    presenter.stackManager.editNext()
  }

  return (
    <>
      <PGrid direction={{ base: 'column', s: 'row' }}>
        <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, m: 6 }}>
          <div>
            <PText>
              {config().isMarket('us') && enablePcnaPaymentStepSkip ? (
                <FormattedMessage id='payment.disclaimer' />
              ) : (
                <FormattedMessage
                  id='payment.disclaimerWithoutSkip'
                  values={{ feeAmount: formatCurrency(opportunity.orderInformation?.downPayment?.value) }}
                />
              )}
            </PText>
          </div>

          {config().isMarket('ca') && (
            <div className='small-space-top'>
              <PHeadline variant='headline-4' tag='h4'>
                <FormattedMessage id='payment.help.header' />
              </PHeadline>

              <PText>
                <FormattedMessage id='payment.help.disclaimer' />
              </PText>
            </div>
          )}
          {isGuest() && (
            <SkeletonLoader loadingFinished={!isLoading} template={PaymentGuestCardSkeleton} className='fs-mask'>
              <guest-payment-widget
                ref={paymentWidgetRef as MutableRefObject<HTMLElement>}
                id='guest-payment-widget'
                {...attributes}
              />
            </SkeletonLoader>
          )}

          {!isGuest() && (
            <SkeletonLoader loadingFinished={!isLoading} template={PaymentCardSkeleton} className='fs-mask'>
              <payment-widget
                ref={paymentWidgetRef as MutableRefObject<HTMLElement>}
                id='payment-widget'
                {...attributes}
              />
            </SkeletonLoader>
          )}

          {!isCheckout2Enabled() && (
            <PButtonGroup>
              <PButton
                loading={isProceedClicked}
                className='medium-space-bottom'
                disabled={!isSelected}
                onClick={async () => await proceed()}
              >
                <FormattedMessage id='payment.proceed' />
              </PButton>
            </PButtonGroup>
          )}
        </PGridItem>

        {isCheckout2Enabled() && (
          <PGridItem size={12}>
            <div className='cta-section'>
              <PButtonGroup className='medium-space-bottom'>
                <PButton loading={isProceedClicked} disabled={!isSelected} onClick={proceed}>
                  <FormattedMessage id='payment.proceed' />
                </PButton>

                {enablePcnaPaymentStepSkip && (
                  <PButton loading={isSkipClicked} variant='tertiary' onClick={skip}>
                    <FormattedMessage id='common.skip' />
                  </PButton>
                )}
              </PButtonGroup>
              <SSLInfo />
            </div>
          </PGridItem>
        )}

        {!isCheckout2Enabled() && (
          <>
            <CheckoutDivider />
            <PGridItem size={{ base: 12, m: 5 }}>
              <Questions questions={questions} />
            </PGridItem>
          </>
        )}
      </PGrid>
    </>
  )
}

const PaymentCardSkeleton: React.FC = () => {
  const RadioWithDivider: React.FC = () => (
    <>
      <PDivider style={{ margin: '13px 0 11px' }} />
      <SkeletonBox width='330px' height='55px' />
    </>
  )

  return (
    <div style={{ padding: '32px 14px 32px 30px' }} className='payment-card-skeleton medium-space-bottom'>
      <SkeletonBox className='title-box' width='136px' height='30px' />
      <RadioWithDivider />
      <RadioWithDivider />
      <RadioWithDivider />
      <RadioWithDivider />
      <RadioWithDivider />
    </div>
  )
}

const PaymentGuestCardSkeleton: React.FC = () => {
  return (
    <div style={{ padding: '32px 14px 32px 30px' }} className='payment-card-skeleton medium-space-bottom'>
      <SkeletonBox className='title-box' width='136px' height='30px' />
      <PDivider style={{ margin: '13px 0 20px' }} />
      <SkeletonBox width='155px' height='35px' />

      <div style={{ margin: '33px 17px 0 42px' }}>
        <SkeletonText contentLength={25} fontSize='20px' />
        <SkeletonBox width='130px' height='30px' style={{ marginTop: '12px' }} />
        <SkeletonBox width='500px' height='50px' style={{ marginTop: '5px' }} flexible />

        <div style={{ display: 'flex', marginTop: '19px', maxWidth: '500px' }} className='card-number-info'>
          <div style={{ flexGrow: 1 }}>
            <SkeletonBox width='100px' height='25px' style={{ marginBottom: '6px' }} />
            <SkeletonBox width='100%' height='50px' flexible />
          </div>
          <div style={{ marginLeft: '18px' }} className='card-expiration'>
            <SkeletonBox width='115px' height='25px' style={{ marginBottom: '6px' }} />
            <SkeletonBox width='141px' height='50px' />
          </div>
        </div>

        <SkeletonBox width='140px' height='25px' style={{ marginTop: '20px', marginBottom: '6px' }} />
        <SkeletonBox width='141px' height='50px' />
      </div>
    </div>
  )
}
