import React from 'react'
import { useIntl } from 'react-intl'
import { PDivider, PGridItem, PText } from '@porsche-design-system/components-react'
import { IFinanceOpportunity, isNewVehicle } from '../../../common/checkout'
import { BaseReviewDetails } from './BaseReviewDetails'
import { config } from '../../../config'
import { CashReviewDetails } from './CashReviewDetails'
import { SummaryDisclaimer } from './ReviewStackElement'
import { formatCurrency } from '@slatldisal/checkout-i18n'

interface IFinanceOpportunityRender {
  opportunity: IFinanceOpportunity
}

export const FinanceReviewDetails: React.FC<IFinanceOpportunityRender> = (props) => {
  const monthlyPayment = props.opportunity.financialProduct.payload.monthlyFinancePayment
  const intl = useIntl()

  return (
    <>
      <BaseReviewDetails monthlyPayment={monthlyPayment} opportunity={props.opportunity}>
        <PGridItem size={6}>
          <PText data-testid='summaryAprTitle'>APR</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryAprValue'>
            {props.opportunity.financialProduct.payload.annualPercentageRate}%
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryTermTitle'>Term</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryTermValue'>
            {props.opportunity.financialProduct.payload.term} months
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryDownPaymentTitle'>Down payment</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryDownPaymentValue'>
            {formatCurrency(
              props.opportunity.financialProduct.payload?.downPayment
                ? props.opportunity.financialProduct.payload?.downPayment
                : 0,
              config().locale
            )}
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryAmountFinanceTitle'>Amount financed</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryAmountFinanceValue'>
            {formatCurrency(
              props.opportunity.financialProduct.payload.totalPrice -
                props.opportunity.financialProduct.payload.downPayment,
              config().locale
            )}
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryPriceTitle'>
            {intl.formatMessage({ id: isNewVehicle(props.opportunity) ? 'common.msrp.price' : 'common.price' })}
          </PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryPriceValue'>
            {formatCurrency(props.opportunity.product?.price?.value ?? 0)}
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={12} data-testid='summaryPfsDisclaimer'>
          <SummaryDisclaimer translationKey='common.pfsDisclaimer' />
        </PGridItem>
      </BaseReviewDetails>
      <CashReviewDetails opportunity={props.opportunity} />
    </>
  )
}
