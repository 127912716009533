import React from 'react'
import { PDivider, PGridItem, PText } from '@porsche-design-system/components-react'
import { formatCurrency, formatMiles } from '@slatldisal/checkout-i18n'
import { config } from '../../../config'
import { BaseReviewDetails } from './BaseReviewDetails'
import { isNewVehicle } from '../../../common/checkout'
import { CashReviewDetails } from './CashReviewDetails'
import { SummaryDisclaimer } from './ReviewStackElement'
import { IFinanceOpportunityRender } from '../../confirmation/shared/IOpportunityRender'
import { useIntl } from 'react-intl'

export const LeaseReviewDetails: React.FC<IFinanceOpportunityRender> = (props) => {
  const { annualMileage, monthlyLeasePayment } = props.opportunity.financialProduct.payload
  const { mileageUnit } = props.opportunity.vehicle.payload ?? { mileageUnit: 'MILES' }
  const intl = useIntl()

  const mileage = formatMiles({ numericValue: annualMileage * 1000, unitCode: mileageUnit })

  return (
    <>
      <BaseReviewDetails monthlyPayment={monthlyLeasePayment} opportunity={props.opportunity}>
        <PGridItem size={6}>
          <PText data-testid='summaryDownPaymentTitle'>Down payment</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryDownPaymentValue'>
            {formatCurrency(
              props.opportunity.financialProduct.payload.downPayment
                ? props.opportunity.financialProduct.payload.downPayment
                : 0,
              config().locale
            )}
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryTermTitle'>Term</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryTermValue'>
            {props.opportunity.financialProduct.payload.term} months
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryAnnualMilageTitle'>Annual mileage</PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryAnnualMilageValue'>
            {mileage}
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={6}>
          <PText data-testid='summaryPriceTitle'>
            {intl.formatMessage({ id: isNewVehicle(props.opportunity) ? 'common.msrp.price' : 'common.price' })}
          </PText>
        </PGridItem>
        <PGridItem size={6}>
          <PText align='right' className='small-space-right' data-testid='summaryPriceValue'>
            {formatCurrency(props.opportunity.product?.price?.value ?? 0)}
          </PText>
        </PGridItem>
        <PGridItem size={12}>
          <PDivider />
        </PGridItem>
        <PGridItem size={12} data-testid='summaryPfsDisclaimer'>
          <SummaryDisclaimer translationKey='common.pfsDisclaimer' />
        </PGridItem>
      </BaseReviewDetails>
      <CashReviewDetails opportunity={props.opportunity} />
    </>
  )
}
