import { CheckoutApp } from './checkoutApp'
import { action, computed, makeObservable, observable } from 'mobx'
import { unsavedDataModalStore, UnsavedModalMode } from '../components/checkout/unsavedDataModalStore'
import { StackElement } from '../components/stack/stackElement'
import { IconName } from '@porsche-design-system/components-react'
import { AnalyticsPage } from '../onega/AnalyticsEventTemplates'

export enum StepState {
  CLEAR = 'clear',
  CURRENT = 'current',
  COMPLETE = 'complete'
}

export interface IStep {
  stackName: string
  title: string
  state: StepState
  icon: IconName
  analyticsPage: AnalyticsPage
}

export class StackManager {
  @observable
  steps: IStep[] = []

  @observable
  private activeStepIdx = 0

  constructor(protected readonly app: CheckoutApp) {
    makeObservable(this)
  }

  initSteps(elements: StackElement[]) {
    this.steps = new Array(elements.length)
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      this.steps[i] = {
        stackName: element.stackName,
        title: element.label,
        state: StepState.CLEAR,
        icon: element.icon,
        analyticsPage: element.analytics.analyticsPage
      }
    }
    this.steps[0].state = StepState.CURRENT

    this.restoreSavedStep()
  }

  restoreSavedStep() {
    const storeIdx = this.app.restore(`${this.app.opportunityStore.opportunityId}-step`) ?? 0
    const idx = storeIdx ? parseInt(storeIdx, 10) : 0
    this.setCurrentEditIndex(idx)
  }

  private saveStep() {
    this.app.store(`${this.app.opportunityStore.opportunityId}-step`, this.activeStepIdx)
  }

  private updateStates() {
    for (let i = 0; i < this.steps.length; i++) {
      this.steps[i].state =
        i < this.activeStepIdx ? StepState.COMPLETE : i > this.activeStepIdx ? StepState.CLEAR : StepState.CURRENT
    }
  }

  getStepIndex(stackName: string): number {
    return this.steps.findIndex((step) => step.stackName === stackName)
  }

  @action
  setCurrentEditIndex(idx: number) {
    const updateStep = () => {
      this.activeStepIdx = idx
      this.updateStates()
      this.saveStep()
    }

    if (unsavedDataModalStore.checkoutFormDirty) {
      unsavedDataModalStore.showModal(UnsavedModalMode.RETURN_TO_PREVIOUS_STEP, updateStep)
    } else {
      updateStep()
    }
  }

  @action
  editNext() {
    this.setCurrentEditIndex(this.activeStepIdx + 1)
  }

  @action
  editBack(stepsBackward: number) {
    this.setCurrentEditIndex(this.activeStepIdx - stepsBackward)
  }

  public getCurrentStep(): IStep {
    return this.steps[this.activeStepIdx]
  }

  @computed
  get currentStepIndex() {
    return this.activeStepIdx
  }

  // todo: PRES \|/ to be moved to test helper

  at<T>(step: { new (stack: StackManager, ...otherParams: any): T }): { [P in keyof T]: T[P] } {
    if (!(this.getCurrentStep() instanceof step)) {
      throw new Error(`Should be at ${step.name}, but is at ${this.getCurrentStep()?.constructor.name}`)
    }
    return this.getCurrentStep() as any
  }

  for<T>(step: { new (stack: StackManager, ...otherParams: any): T }): { [P in keyof T]: T[P] } {
    for (const check of this.steps) {
      if (check instanceof step) {
        return check as any
      }
    }
    throw new Error(`${step.toString()} not found`)
  }
}
