import { PFlex, PFlexItem } from '@porsche-design-system/components-react'
import React from 'react'
import { VehicleSelection } from '../VehicleSelection'
import { CalculationResult } from './leaseFinance/defiCalcFinanceData'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { observer } from 'mobx-react-lite'
import { VehicleSelectionButtons, VehicleSelectionPaymentOptions } from './VehicleSelectionPaymentOptions'

export type VehicleSelectionValues =
  | { paymentType: 'cash' }
  | { paymentType: 'porscheFinancialServices' }
  | { paymentType: 'leaseFinance'; calculatorValues: CalculationResult }
  | IOwnFinancing

export interface IOwnFinancing {
  paymentType: 'ownFinancing'
  applyingAnotherBank: boolean
  securedFinancing: boolean
}

export const VehicleSelectionPcna: React.FC = observer(() => {
  const { smallerThan: isMobile } = useBreakpoint('m')

  return (
    <>
      <PFlex direction={{ base: 'column', l: 'row' }} className='columns-container'>
        <PFlexItem width={{ base: 'full', l: 'two-thirds' }} className='left-column'>
          <VehicleSelectionPaymentOptions />
        </PFlexItem>

        {!isMobile && (
          <PFlexItem width={{ base: 'full', l: 'one-third' }}>
            <VehicleSelection viewMode='one-third-tile' hideVehicleImageOnMobile />
          </PFlexItem>
        )}
      </PFlex>
      <PFlex direction={{ base: 'column', l: 'row' }} className='columns-container'>
        <PFlexItem width={{ base: 'full', l: 'two-thirds' }}>
          <VehicleSelectionButtons />
        </PFlexItem>
        <PFlexItem width={{ base: 'full', l: 'one-third' }} />
      </PFlex>
    </>
  )
})
