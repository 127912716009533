import { PButtonPure, PGrid, PText } from '@porsche-design-system/components-react'
import { APSelectionType, IAncillaryPlanOption } from '@pdiatl/common-external-models'
import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SessionStorageService } from '../../../../services/sessionStorageService'
import { ANCILLARY_PRODUCTS_STACK_NAME } from '../../../ancillaryProducts/AncillaryProductsStackElement'
import { PlanDescriptionModal } from '../../../ancillaryProducts/PlanDescriptionModal'
import { CheckoutStorageKeys } from '../../../checkout/checkoutStorageKeys'
import { PresenterContext } from '../../../entry/presenterProvider'
import { AccordionStepData, ClearStepData, isStepDataShown, IStepData, StepDataEditButton, StepDataRow } from './common'

export const AncillaryProductsStepData: React.FC<IStepData> = observer(({ closeModalFn }) => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const [selectedPlan, setSelectedPlan] = useState<IAncillaryPlanOption | undefined>(undefined)
  const [planDescriptionModalOpen, setPlanDescriptionModalOpen] = useState(false)
  const stackManager = presenter.stackManager
  const selectedProducts = SessionStorageService.getAndParsePrefixedItem<IAncillaryPlanOption[]>(
    CheckoutStorageKeys.ANCILLARY_PRODUCTS_VALUES
  )?.filter((p) => p.selection === APSelectionType.USER_SELECTED)
  const heading = intl.formatMessage({ id: 'ancillaryProducts.title' })

  if (!isStepDataShown(ANCILLARY_PRODUCTS_STACK_NAME, stackManager) || !selectedProducts) {
    return <ClearStepData heading={heading} />
  }

  return (
    <AccordionStepData heading={heading}>
      <PGrid data-testid='summaryAncillaryProducts'>
        <StepDataRow labelKey='common.mySelections'>
          {selectedProducts.length === 0 && (
            <div>
              <PText>
                <FormattedMessage id='ancillaryProducts.noSelections' />
              </PText>
            </div>
          )}
          {selectedProducts.map((product) => (
            <div key={product.code} style={{ marginBottom: '5px' }} data-testid={`summaryAP${product.code}`}>
              <PButtonPure
                icon='none'
                onClick={() => {
                  setSelectedPlan(product)
                  setPlanDescriptionModalOpen(true)
                }}
              >
                {product.name}
              </PButtonPure>
            </div>
          ))}
        </StepDataRow>
        <StepDataRow>
          <StepDataEditButton
            editStack={ANCILLARY_PRODUCTS_STACK_NAME}
            editTitle='Edit Protection Plans'
            closeModalFn={closeModalFn}
          />
        </StepDataRow>
      </PGrid>

      <PlanDescriptionModal
        plan={selectedPlan}
        open={planDescriptionModalOpen}
        onClose={() => setPlanDescriptionModalOpen(false)}
      />
    </AccordionStepData>
  )
})
