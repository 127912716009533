import './ReviewModal.scss'
import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PDivider, PModal } from '@porsche-design-system/components-react'
import { trackClickEvent } from '@slatldisal/one-ga'
import { observer } from 'mobx-react-lite'
import { PresenterContext } from '../../../entry/presenterProvider'
import { AnalyticsEventTemplates } from '../../../../onega/AnalyticsEventTemplates'
import { ReviewModalImageAndVehicleInfo } from './ReviewModalImageAndVehicleInfo'
import { ReviewModalDetails } from './ReviewModalDetails'
import { ReviewModalDisclaimers } from './ReviewModalDisclaimers'
import { ReviewModalCTA } from './ReviewModalCTA'
import { matomoPcnaTrackClickEvent } from '../../../../matomo/matomo'
import { useMatomo } from '@datapunt/matomo-tracker-react'

export const ReviewModal: React.FC = observer(() => {
  const intl = useIntl()
  const { trackEvent } = useMatomo()
  const presenter = useContext(PresenterContext)
  const [isReviewModalOpen, setReviewModalOpen] = useState(true)

  return (
    <PModal
      className='fs-mask'
      heading={intl.formatMessage({ id: 'checkout.nav.reviewStep' })}
      open={isReviewModalOpen}
      onClose={() => {
        matomoPcnaTrackClickEvent(trackEvent, 'review-and-confirm-close-modal')
        trackClickEvent(AnalyticsEventTemplates.REVIEW_MODAL_CLOSE)

        setReviewModalOpen(false)
        presenter.stackManager.editBack(1)
      }}
    >
      <div className='ada-text-width'>
        <FormattedMessage id='review.disclaimer' />
      </div>
      <ReviewModalImageAndVehicleInfo />

      <PDivider className='medium-space-top medium-space-bottom' />

      <ReviewModalDetails />

      <PDivider className='medium-space-top medium-space-bottom' />

      <ReviewModalDisclaimers />
      <ReviewModalCTA setReviewModalOpen={setReviewModalOpen} />
    </PModal>
  )
})
