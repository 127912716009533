import React, { useContext, useMemo } from 'react'
import { PresenterContext } from '../../entry/presenterProvider'
import { CheckoutProgressBar, useBreakpoint } from '@slatldisal/checkout-shared-components'
import { config } from '../../../config'
import './WizardNavigation.scss'
import { MobileVehicleSummary } from '../../vehicleSelection/pcna/summary/MobileVehicleSummary'
import { observer } from 'mobx-react-lite'

export interface ICheckoutNavigation {
  isSticky: boolean
  stickyRef: React.RefObject<HTMLSpanElement>
}
export const CheckoutNavigation: React.FC<ICheckoutNavigation> = observer(({ isSticky, stickyRef }) => {
  const presenter = useContext(PresenterContext)
  const currentStep = presenter.stackManager.currentStepIndex
  const stepNames = presenter.stackManager.steps.map((step) => step.title)

  const { smallerThan: isMobile } = useBreakpoint('m')
  const MobileVehicleSummaryForUS = useMemo(
    () => () => <>{config().isMarket('us') && currentStep === 0 && <MobileVehicleSummary />}</>,
    [currentStep]
  )

  function getProgressBar() {
    return (
      <div className='logo-and-progress-bar-container'>
        <div className='progress-bar'>
          <CheckoutProgressBar
            steps={stepNames}
            currentStep={currentStep}
            mode='checkout'
            lang={config().locale}
            onClick={(stepIndex: number) => presenter.stackManager.setCurrentEditIndex(stepIndex)}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      {getProgressBar()}
      {isMobile && <MobileVehicleSummaryForUS />}
      <span ref={stickyRef} />
      <nav
        id='sticky-progress-bar'
        className={isSticky ? 'visible styled-sticky-container' : 'invisible styled-sticky-container'}
      />
    </>
  )
})
