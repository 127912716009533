import React, { useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'

import { config } from '../../config'
import { useFlags } from '../../common/flags'
import { PresenterContext } from '../entry/presenterProvider'
import { StackElement } from '../stack/stackElement'
import { CheckoutContent } from './CheckoutContent'
import { VehicleSelectionStackElement } from '../vehicleSelection/VehicleSelectionStackElement'
import { LoginRegisterStackElement } from '../loginRegister/loginRegisterStackElement'
import { ProfileStackElement } from '../profile/profileStackElement'
import { TradeInStackElement } from '../tradeIn/tradeInStackElement'
import { DeliveryOptionStackElement } from '../deliveryOption/DeliveryOptionStackElement'
import { PaymentStackElement } from '../payments/paymentStackElement'
import { VehicleSelectionPcnaStackElement } from '../vehicleSelection/pcna/VehicleSelectionPcnaStackElement'
import { AncillaryProductsStackElement } from '../ancillaryProducts/AncillaryProductsStackElement'
import { ReviewStackElement } from '../review/checkout1/ReviewStackElement'

export const Checkout: React.FC = observer(() => {
  const presenter = useContext(PresenterContext)
  const intl = useIntl()
  const { enableTradeInStepPcna, enableTradeInStepPcl, enablePcnaPaymentStep, enableAncillaryProductsPcna } = useFlags()

  const [elements, setElements] = useState<StackElement[]>([])
  const useTradeIn = config().isMarket('ca') ? enableTradeInStepPcl : enableTradeInStepPcna

  useMemo(() => {
    const translate = (key: string) => intl.formatMessage({ id: key })

    let index = 0
    const elementsArr = [
      config().isMarket('us')
        ? new VehicleSelectionPcnaStackElement(index++, translate('vehicleSelection.header'))
        : new VehicleSelectionStackElement(index++, translate('vehicleSelection.header')),

      new LoginRegisterStackElement(index++, translate('common.login'), presenter),
      new ProfileStackElement(index++, translate('profile.title')),

      useTradeIn ? new TradeInStackElement(index++, translate('checkout.nav.tradeInStep')) : null,

      enableAncillaryProductsPcna &&
        config().isMarket('us') &&
        new AncillaryProductsStackElement(index++, translate('ancillaryProducts.title')),

      presenter.isDepositJourney(enablePcnaPaymentStep)
        ? new PaymentStackElement(index++, translate('checkout.nav.paymentStep'))
        : null,

      config().isMarket('us') && new DeliveryOptionStackElement(index++, translate('delivery.title')),
      new ReviewStackElement(index++, translate('checkout.nav.reviewStep'))
    ].filter(Boolean) as StackElement[]

    presenter.stackManager.initSteps(elementsArr)
    setElements(elementsArr)
  }, [enableAncillaryProductsPcna, enablePcnaPaymentStep, intl, presenter, useTradeIn])

  return elements.length ? <CheckoutContent elements={elements} /> : <></>
})
