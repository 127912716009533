import React from 'react'
import { observer } from 'mobx-react-lite'
import { AncillaryProductsStepData } from './stepData/AncillaryProductsStepData'
import { DeliveryOptionsStepData } from './stepData/DeliveryOptionsStepData'
import { PaymentsStepData } from './stepData/PaymentStepData'
import { ProfileStepData } from './stepData/ProfileStepData'
import { TradeInStepData } from './stepData/TradeInStepData'
import { VehicleDetailsStepData } from './stepData/VehicleDetailsStepData'

export const VehicleSummaryStepsData: React.FC<{ closeModalFn?: Function }> = observer(({ closeModalFn }) => {
  const commonProps = { closeModalFn }
  const stepsData = [
    VehicleDetailsStepData,
    ProfileStepData,
    TradeInStepData,
    AncillaryProductsStepData,
    PaymentsStepData,
    DeliveryOptionsStepData
  ]

  return (
    <>
      {stepsData.map((StepData, i) => (
        <StepData key={`step-data-${i}`} {...commonProps} />
      ))}
    </>
  )
})
