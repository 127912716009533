import { PButton, PButtonGroup, PHeadline, PText, PModal } from '@porsche-design-system/components-react'
import React, { useState } from 'react'

export interface IAoacModalProps {
  applyHandler: () => void
  onRequestClose: () => void
  open: boolean
}

const AoacModal: React.FC<IAoacModalProps> = (props) => {
  const [loading, setLoading] = useState(false)
  return (
    <PModal open={props.open} onClose={props.onRequestClose} hidden={!props.open}>
      <PHeadline variant='headline-4' className='medium-space-bottom'>
        Apply for Financing
      </PHeadline>
      <PText>
        You are now leaving Porsche Finder in order to explore financing options with your selected Porsche dealer
        through Porsche Financial Services. Your information, including personal details, vehicle selection, and
        financing terms requested, will be shared with Porsche Financial Services. Please review the Porsche Financial
        Services{' '}
        <a href='https://pfsaccount.porscheusa.com/Privacy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>{' '}
        and
        <a href='https://pfsaccount.porscheusa.com/LegalNotice' target='_blank' rel='noreferrer'>
          {' '}
          Legal Notice
        </a>
        .
      </PText>
      <PButtonGroup className='medium-space-top'>
        <PButton
          onClick={() => {
            setLoading(!loading)
            props.applyHandler()
          }}
          loading={loading}
        >
          Ok
        </PButton>
        <PButton variant='tertiary' onClick={props.onRequestClose} className='small-space-left'>
          Cancel
        </PButton>
      </PButtonGroup>
    </PModal>
  )
}

export default AoacModal
