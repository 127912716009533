import React from 'react'
import { PDivider, PGridItem } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import './checkoutDivider.scss'

export const CheckoutDivider = () => {
  const { smallerThan: isMobile } = useBreakpoint('m')

  return (
    <PGridItem size={{ base: 12, m: 1 }}>
      <PDivider
        className='checkout-divider'
        style={{
          height: isMobile ? '100%' : '',
          justifyContent: isMobile ? 'center' : ''
        }}
        orientation={{ base: 'horizontal', xs: 'horizontal', m: 'vertical' }}
      />
    </PGridItem>
  )
}
