import React from 'react'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../../onega/AnalyticsEventTemplates'
import { StackElement, StackElementState } from '../../stack/stackElement'
import {
  VehicleSelectionButtons,
  VehicleSelectionPaymentOptions
} from '../../vehicleSelection/pcna/VehicleSelectionPaymentOptions'
import { CompletedViewCommon } from '../../vehicleSelection/VehicleSelectionStackElement'

const LockedView: React.FC = () => {
  return <div>You should never see this.</div>
}

const EditView: React.FC = () => {
  return (
    <div>
      <VehicleSelectionPaymentOptions />
      <VehicleSelectionButtons />
    </div>
  )
}

export class VehicleSelectionStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedViewCommon,
      LockedView,
      () => StackElementState.EDIT,
      'vehicleSelection',
      index,
      label,
      'calculator',
      {
        analyticsPage: AnalyticsPage.EXPLORE_PAYMENTS,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.EXPLORE_PAYMENTS,
            matomoActionName: 'checkout/payment_options'
          }
        }
      }
    )
  }
}
