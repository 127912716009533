const SEPARATOR = ', '

export function parseKBBNotes(notes: string) {
  const index = notes.indexOf(SEPARATOR)

  const zip = notes.substr(0, index)
  const priceAdvisorUrl = notes.substr(index + SEPARATOR.length)

  return { zip, priceAdvisorUrl }
}
