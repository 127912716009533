import { OneGaComponentLocation } from './OneGaComponentLocation'
import { PresenterContext } from '../../entry/presenterProvider'
import { CheckoutApp } from '../../../presenter/checkoutApp'
import { config } from '../../../config'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { Currency } from './ui'
import React, { useContext } from 'react'
import { PButtonPure, PDivider, PFlex, PFlexItem, PHeadline, PText } from '@porsche-design-system/components-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { trackClickEvent } from '@slatldisal/one-ga'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { useFlags } from '../../../common/flags'
import { OpportunityView } from '@pdiatl/common-external-models'
import { observer } from 'mobx-react-lite'
import { IPropsWithClassName } from '../../../util/reactUtils'

interface IEditButtonProps {
  editable: boolean | undefined
  oneGaComponentLocation: OneGaComponentLocation | undefined
  presenter: CheckoutApp
}
const EditButton: React.FC<IEditButtonProps> = ({ editable, oneGaComponentLocation, presenter }) => {
  const { enablePcnaPaymentStep } = useFlags()
  const useCustomIndex = config().isMarket('us') && enablePcnaPaymentStep
  const customIndex = useCustomIndex ? 2 : 1
  return (
    <>
      {editable && (
        <PButtonPure
          icon='edit'
          onClick={() => {
            trackClickEvent(AnalyticsEventTemplates.UPDATE_RESERVATION(oneGaComponentLocation))
            presenter.stackManager.editBack(customIndex)
          }}
        >
          <u>
            <FormattedMessage id='common.edit' />
          </u>
        </PButtonPure>
      )}
    </>
  )
}

function ReservationDepositDetailsDesktop(
  reservation: { currency: any; value: any },
  opportunity: OpportunityView,
  props: IReservationDepositDetailsProps,
  presenter: CheckoutApp,
  selectedCard: any
): JSX.Element {
  return (
    <PFlex direction='column' className='small-space-top'>
      <PFlex direction='row'>
        <PFlexItem grow={1} width='full'>
          <PHeadline variant='headline-4'>
            <FormattedMessage id='common.reservation.header' />
          </PHeadline>
        </PFlexItem>
        <PFlexItem shrink={0}>
          <PHeadline variant='headline-4' align='right'>
            <Currency value={reservation.value} currency={reservation.currency} />
          </PHeadline>
        </PFlexItem>
      </PFlex>
      <PFlex direction='row'>
        <PFlexItem>
          <PText className='ada-text-width'>
            <FormattedMessage id='common.reservation.sub' values={{ dealer: opportunity.owner?.displayName }} />
          </PText>
        </PFlexItem>
      </PFlex>
      <PFlex direction='row'>
        <PFlexItem grow={1} width='full'>
          <div className='small-space-top'>
            <FormattedMessage id='review.payment.cardSelected' />:
            <PText>
              {selectedCard.type} {selectedCard.displayName}
            </PText>
          </div>
        </PFlexItem>
        <PFlexItem shrink={0}>
          <div className='small-space-top'>
            <EditButton
              editable={props.editable}
              oneGaComponentLocation={props.oneGaComponentLocation}
              presenter={presenter}
            />
          </div>
        </PFlexItem>
      </PFlex>
    </PFlex>
  )
}

function ReservationDepositDetailsMobile(
  reservation: { currency: any; value: any },
  opportunity: OpportunityView,
  props: IReservationDepositDetailsProps,
  presenter: CheckoutApp,
  selectedCard: any
) {
  const intl = useIntl()
  return (
    <PFlex direction='column' className='small-space-top'>
      <PFlexItem>
        <PHeadline variant='headline-4'>
          <FormattedMessage id='common.reservation.header' />
        </PHeadline>
      </PFlexItem>
      <PFlexItem className='small-space-bottom'>
        <PHeadline variant='headline-4' align='left' className='big-space-right' style={{ display: 'inline-block' }}>
          <Currency value={reservation.value} currency={reservation.currency} />
        </PHeadline>
      </PFlexItem>
      <PFlexItem>
        <PText className='ada-text-width align small-space-bottom'>
          <FormattedMessage id='common.reservation.sub' values={{ dealer: opportunity.owner?.displayName }} />
        </PText>
      </PFlexItem>
      <PFlex direction='row'>
        <PFlexItem shrink={1} width='full'>
          <div className='small-space-top'>
            <PText>{intl.formatMessage({ id: 'review.payment.cardSelected' })}:</PText>
            <PText>
              {selectedCard.type}
              {selectedCard.displayName}
            </PText>
          </div>
        </PFlexItem>
        <PFlexItem grow={1}>
          <div className='small-space-top'>
            <EditButton
              editable={props.editable}
              oneGaComponentLocation={props.oneGaComponentLocation}
              presenter={presenter}
            />
          </div>
        </PFlexItem>
      </PFlex>
    </PFlex>
  )
}

interface IReservationDepositDetailsProps extends IPropsWithClassName {
  oneGaComponentLocation?: OneGaComponentLocation
  editable?: boolean
}

export const ReservationDepositDetails: React.FC<IReservationDepositDetailsProps> = observer(
  ({ className = '', ...props }) => {
    const { enablePcnaPaymentStep } = useFlags()
    const presenter = useContext(PresenterContext)
    const opportunity = presenter.opportunityStore.opportunityCache
    const depositJourney = presenter.isDepositJourney(enablePcnaPaymentStep)
    const selectedCard = opportunity.customerProfile?.payload?.selectedCard
    const reservation = CheckoutApp.getReservationValueAndCurrency(opportunity)
    const { largerThan: isDesktop } = useBreakpoint('m')

    return (
      <>
        {depositJourney && selectedCard && reservation && (
          <>
            <PDivider className={className} />
            {isDesktop && ReservationDepositDetailsDesktop(reservation, opportunity, props, presenter, selectedCard)}
            {!isDesktop && ReservationDepositDetailsMobile(reservation, opportunity, props, presenter, selectedCard)}
          </>
        )}
      </>
    )
  }
)
