import { ICheckoutConfig } from '.'
import { WebStorageStateStore } from 'oidc-client'
import { SCOPE, defaultConfig } from './default'

const baseUrl = 'https://carsales.porsche-preview.com'

const apiEndpoint = 'https://api.porsche-preview.com/checkout-pp'

const OAUTH = {
  redirect_uri: `${baseUrl}/$market/$locale/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: baseUrl,
  scope: SCOPE,
  silent_redirect_uri: `${baseUrl}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    country: 'us',
    locale: 'en_US'
  }
}

const ppConfig: ICheckoutConfig = {
  ...defaultConfig,
  environment: 'preview',
  pid: {
    oauth: {
      ...OAUTH,
      client_id: 'sLSB4lg4moKm1JncdJgvJiRNH5onhBVy',
      authority: 'https://identity.porsche-preview.com',
      extraQueryParams: {
        audience: 'https://api.porsche-preview.com'
      }
    },
    signUpUrl: 'https://signup.porsche-preview.com'
  },
  profileWidget: {
    clientId: 'vkFJmi7GKacEXlsHcDLaMfth0vEr7v5C'
  },
  paymentService: {
    clientId: 'sLSB4lg4moKm1JncdJgvJiRNH5onhBVy'
  },
  paymentStep: {
    enabled: true,
    environment: 'test'
  },
  profilePostfix: '-preview',
  apiEndpoint,
  profile: {
    apiEndpoint: 'https://api.porsche-preview.com/profiles',
    locale: 'en_US',
    country: 'us'
  },
  oneGaEnv: 'PP',
  aoac: {
    tenantConfig: defaultConfig.aoac.tenantConfig,
    submissionEndpoint: `${apiEndpoint}/pfso/api/Origination/SaveCreditAppFromFinder`,
    applicationURLPrefix: `https://ac-test00.autolendingapps.com/pfso`
  },
  defiCalculator: {
    endpointUrl: 'https://checkout-api-pp.slatldisal.aws.porsche-preview.cloud/pfso/AutoOrigination/'
  },
  vehicleHistory: {
    endpointUrl: 'https://vehicle-history.slatldisal.aws.porsche-preview.cloud'
  }
}

export default ppConfig
