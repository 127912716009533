import { useMatomo } from '@datapunt/matomo-tracker-react'
import { IconName } from '@porsche-design-system/components-react'
import { IAnalyticsPageLoadEventTemplate, trackPageLoadEvent } from '@slatldisal/one-ga'
import { User } from 'oidc-client'
import React, { ReactElement, useEffect } from 'react'
import { ICheckoutOpportunity, isCheckout2Enabled } from '../../common/checkout'
import { matomoPcnaTrackPageView } from '../../matomo/matomo'
import { AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { SessionStorageService } from '../../services/sessionStorageService'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { CollapsedStep } from './CollapsedStep'
import { CollapsedStep2 } from './CollapsedStep2'
import { ExpandedStep } from './ExpandedStep'
import { ExpandedStep2 } from './ExpandedStep2'
import './stackelement.scss'

export enum StackElementState {
  COMPLETED,
  LOCKED,
  EDIT,
  EDITABLE
}

interface ICombinedAnalytics {
  oneGAEvent: IAnalyticsPageLoadEventTemplate
  matomoActionName: string
}

export type IAnalyticsMap = {
  [K in StackElementState]?: ICombinedAnalytics
}

interface IPageLoadAnalyticsProps {
  children: ReactElement
  state: StackElementState
  analyticsMap?: IAnalyticsMap
}

interface IAnalytics {
  analyticsPage: AnalyticsPage
  analyticsMap?: IAnalyticsMap
}

function getScrollToPosition(node: HTMLElement): number {
  const offsetBeforeStep = 10
  const progressBarOffset = getStickyProgressBarOffset()

  return node.offsetTop - progressBarOffset - offsetBeforeStep
}

export function getStickyProgressBarOffset(): number {
  const stickyProgressBar = window.document.getElementById(`sticky-progress-bar`)
  return stickyProgressBar ? stickyProgressBar.getBoundingClientRect().height : 0
}

/** When opening Checkout without any step completed, scrolls to top, otherwise to current step */
export const scrollTo = (index: number) => {
  const firstStepCompleted = !!SessionStorageService.getPrefixedItem(CheckoutStorageKeys.VEHICLE_SELECTION_VALUES)

  if (firstStepCompleted) {
    // A little delay is needed for scrolling to work properly, because previous steps might still be refreshing
    setTimeout(() => {
      const node = window.document.getElementById(`wizard-step-${index + 1}`)

      if (!node) {
        return
      }

      const y = getScrollToPosition(node)
      window.scrollTo({ top: y, behavior: 'auto' })
    }, 50)
  }
}

export const PageLoadAnalytics: React.FC<IPageLoadAnalyticsProps> = ({ children, state, analyticsMap }) => {
  const { pushInstruction } = useMatomo()

  useEffect(() => {
    const combinedAnalytics = analyticsMap && analyticsMap[state]

    if (combinedAnalytics) {
      matomoPcnaTrackPageView(pushInstruction, combinedAnalytics.matomoActionName)

      trackPageLoadEvent(combinedAnalytics.oneGAEvent)
    }
  }, [analyticsMap, state, pushInstruction])

  return children
}

export interface IStackElementContext {
  user: User | null
  opportunity: ICheckoutOpportunity
}

export class StackElement {
  constructor(
    private editView: React.FC,
    private completedView: React.FC,
    private lockedView: React.FC,
    private desiredState: (context: IStackElementContext) => StackElementState,
    public stackName: string,
    private index: number,
    public label: string,
    public icon: IconName,
    public analytics: IAnalytics,
    public visibleOnlyOnEdit?: boolean
  ) {}

  checkDesiredState(ctx: IStackElementContext) {
    return this.desiredState(ctx)
  }

  selectView(renderState: StackElementState): JSX.Element {
    const stepProps = {
      index: this.index,
      analyticsMap: this.analytics.analyticsMap,
      renderState,
      label: this.label,
      key: `stack-${this.index}`,
      visibleOnlyOnEdit: this.visibleOnlyOnEdit
    }

    const createStep = (Step: React.FC<any>, Content: React.FC) => {
      return (
        <Step {...stepProps}>
          <Content />
        </Step>
      )
    }

    switch (renderState) {
      case StackElementState.EDIT:
        return createStep(isCheckout2Enabled() ? ExpandedStep2 : ExpandedStep, this.editView)
      case StackElementState.COMPLETED:
        return createStep(isCheckout2Enabled() ? CollapsedStep2 : CollapsedStep, this.completedView)
      default:
        return createStep(isCheckout2Enabled() ? CollapsedStep2 : CollapsedStep, this.lockedView)
    }
  }
}
