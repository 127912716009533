import { PDivider, PGrid, PGridItem, PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ReviewSection } from '../confirmation/shared/ui'
import { AncillaryProductsView } from '@pdiatl/common-external-models'
import { PresenterContext } from '../entry/presenterProvider'
import { observer } from 'mobx-react-lite'
import { isCheckout2Enabled } from '../../common/checkout'

export const AncillaryProductsReviewTable: React.FC<{
  products: AncillaryProductsView
  confirmationPage?: boolean
}> = ({ products, confirmationPage }) => {
  const ProductLine: React.FC<{ name: string }> = ({ name }) => (
    <PGridItem size={12}>
      <PText>{name}</PText>
      {!confirmationPage && <PDivider />}
    </PGridItem>
  )

  return (
    <>
      <PGrid data-testid='confirmationAPList'>
        {products.map((product) => (
          <ProductLine key={product.name} name={product.name} />
        ))}
      </PGrid>
    </>
  )
}

export const AncillaryProductsReviewContent: React.FC = observer(() => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const selectedProducts = opportunity?.product?.payload?.ancillaryProducts

  if (!selectedProducts?.length) {
    return <></>
  }

  return (
    <>
      {isCheckout2Enabled() && <PDivider className='small-space-top medium-space-bottom' />}
      <ReviewSection title={intl.formatMessage({ id: 'ancillaryProducts.title' })}>
        <AncillaryProductsReviewTable products={selectedProducts} />
      </ReviewSection>
    </>
  )
})
