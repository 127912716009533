import { PGrid, PGridItem, PHeadline } from '@porsche-design-system/components-react'
import { formatCurrency, formatMiles } from '@slatldisal/checkout-i18n'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Flow, getFlow, getPriceLabel, isLeaseOrFinance, isNewVehicle } from '../../../../common/checkout'
import { useFlags } from '../../../../common/flags'
import { getProfileDetails } from '../../../checkout2/vehicleSummary/stepData/ProfileStepData'
import { Currency } from '../../../confirmation/shared/ui'
import { PresenterContext } from '../../../entry/presenterProvider'
import { getAsterisksForOldCarWithCashOrOwnFinancing, getLeaseFinanceAsterisks, getMsrpAsterisks } from './util'

export const ReviewModalDetails: React.FC = observer(() => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const { enablePcnaPaymentStep } = useFlags()
  const { smallerThan: isMobile } = useBreakpoint('m')
  const opportunity = presenter.opportunityStore.opportunityCache

  const isNew = isNewVehicle(opportunity)
  const isLeaseFinance = isLeaseOrFinance(opportunity)
  const isCashOrOwnFinancing = [Flow.CASH, Flow.OWN_FINANCING].includes(getFlow(opportunity))
  const tradeIn = opportunity.tradeInVehicle
  const ancillaryProducts = opportunity.product.payload?.ancillaryProducts
  const hasAncillaryProducts = (ancillaryProducts ?? []).length > 0
  const hasWarrantyDisclaimer = !!opportunity.vehicle.payload?.warranty?.modal?.newCarWarrantyDisclaimer
  const postfix = isNew
    ? getMsrpAsterisks(isNew, isLeaseFinance)
    : isCashOrOwnFinancing
    ? getAsterisksForOldCarWithCashOrOwnFinancing(!isNew, isCashOrOwnFinancing, hasWarrantyDisclaimer)
    : ''

  const totalPriceDataRow = (
    <>
      <DataRow
        labelKey={getPriceLabel(opportunity)}
        value={opportunity.product.price.value!}
        postfix={postfix}
        currency
      />
    </>
  )

  return (
    <PGrid>
      <PGridItem
        size={{ base: 12, l: 6 }}
        className='pricing-details'
        style={{
          padding: isMobile ? '15px 30px !important' : '',
          boxShadow: isMobile ? '3px 3px 13px lightgray' : '',
          overflowWrap: isMobile ? 'break-word' : 'normal'
        }}
      >
        <PHeadline tag='h5' variant={`headline-${isMobile ? '3' : '5'}`} className='small-space-bottom'>
          <FormattedMessage id='review.pricingDetails' />
        </PHeadline>

        <PGrid>
          {isCashOrOwnFinancing && totalPriceDataRow}

          {isLeaseFinance && <LeaseFinanceDetails />}
          {presenter.isOwnFinancing() && <OwnFinancingDetails />}
          {opportunity.vehicle.payload?.msrpPricing?.map((item: { label: string; formattedValue: string }) => (
            <DataRow key={item.label} label={item.label} value={item.formattedValue} />
          ))}

          {isLeaseFinance && totalPriceDataRow}
        </PGrid>
      </PGridItem>
      <PGridItem
        size={{ base: 12, l: 6 }}
        className='additional-details'
        style={{
          padding: isMobile ? '15px 30px !important' : '',
          boxShadow: isMobile ? '3px 3px 13px lightgray' : '',
          marginTop: isMobile ? '20px' : '',
          overflowWrap: isMobile ? 'break-word' : 'normal'
        }}
      >
        <PHeadline tag='h5' variant={`headline-${isMobile ? '3' : '5'}`} className='small-space-bottom'>
          <FormattedMessage id='review.additionalDetails' />
        </PHeadline>
        <PGrid>
          <DataRow
            labelKey='review.additionalDetails.personalDetails'
            value={getProfileDetails(opportunity)?.toString()}
          />
          <DataRow
            labelKey='review.additionalDetails.tradeIn'
            value={
              tradeIn
                ? `${tradeIn.yearOfConstruction ?? ''} ${tradeIn.brand} ${tradeIn.model}`
                : intl.formatMessage({ id: 'review.additionalDetails.noTradeIn' })
            }
          />
          <DataRow
            labelKey='review.additionalDetails.protectionPlans'
            value={`${hasAncillaryProducts ? `${ancillaryProducts!.length} ` : ''}${intl.formatMessage({
              id: hasAncillaryProducts
                ? 'ancillaryProducts.step.completed'
                : 'review.additionalDetails.noProtectionPlans'
            })}`}
          />
          <DataRow
            labelKey='review.additionalDetails.deliveryDetails'
            value={intl.formatMessage(
              {
                id:
                  opportunity.orderInformation?.deliveryOptions.deliveryOption === 'HOME'
                    ? 'common.delivery.homeDelivery.prompt'
                    : 'delivery.type.dealer'
              },
              { dealer: opportunity.owner!.displayName }
            )}
          />
          <DataRow
            labelKey='review.additionalDetails.reservationDetails'
            value={
              presenter.isDepositJourney(enablePcnaPaymentStep) && opportunity.customerProfile?.payload?.selectedCard
                ? `${
                    opportunity.customerProfile.payload.selectedCard.displayName ??
                    opportunity.customerProfile.payload.selectedCard.type
                  }\n${intl.formatMessage(
                    { id: 'review.additionalDetails.reservationDetails.charged' },
                    { amount: formatCurrency(opportunity?.orderInformation!.downPayment!.value) }
                  )}`
                : intl.formatMessage({ id: 'review.additionalDetails.reservationDetails.notCharged' })
            }
          />
        </PGrid>
      </PGridItem>
    </PGrid>
  )
})

export const DataRow = ({
  label,
  labelKey,
  value,
  postfix,
  currency = false
}: {
  label?: string
  labelKey?: string
  value: string | number | JSX.Element
  postfix?: string
  currency?: boolean
}) => {
  const presenter = useContext(PresenterContext)
  const { largerThan: isDesktop } = useBreakpoint('m')
  const opportunity = presenter.opportunityStore.opportunityCache

  return (
    <>
      <PGridItem
        size={{ base: 12, s: 6 }}
        className={`neutral-contrast-medium ${isDesktop ? 'xsmall-space-bottom' : ''}`}
      >
        {label}
        {!label && <FormattedMessage id={labelKey} />}
      </PGridItem>
      <PGridItem size={{ base: 12, s: 6 }} className='data-row-value xsmall-space-bottom'>
        {currency && <Currency value={+value} currency={opportunity.product.price.currencyCode} />}
        {!currency && value}
        {postfix}
      </PGridItem>
    </>
  )
}

const LeaseFinanceDetails: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { annualMileage, monthlyFinancePayment, monthlyLeasePayment, term, downPayment } =
    opportunity.financialProduct!.payload
  const hasWarrantyDisclaimer = !!opportunity.vehicle.payload?.warranty?.modal?.newCarWarrantyDisclaimer
  const postfix = ` /mo${getLeaseFinanceAsterisks(true, hasWarrantyDisclaimer)}`

  return (
    <>
      <DataRow
        labelKey='common.estimatedMonthlyPayment.finance'
        value={getFlow(opportunity) === Flow.FINANCE ? monthlyFinancePayment : monthlyLeasePayment}
        postfix={postfix}
        currency
      />
      <DataRow
        labelKey='common.annualMileage'
        value={formatMiles({ numericValue: annualMileage * 1000, unitCode: 'MILES' })}
      />
      <DataRow labelKey='common.term' value={`${term as number} ${intl.formatMessage({ id: 'common.months' })}`} />
      <DataRow labelKey='common.down' value={downPayment} currency />
    </>
  )
}

const OwnFinancingDetails: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const ownFinancingOptions = opportunity.financialProduct!.payload
  const selectedOptionsString = [
    ownFinancingOptions.applyingAnotherBank &&
      intl.formatMessage({ id: 'common.cash.ownFinancing.option.applyingAnotherBank' }),
    ownFinancingOptions.securedFinancing &&
      intl.formatMessage({ id: 'common.cash.ownFinancing.option.securedFinancing' })
  ]
    .filter(Boolean)
    .join('\n')

  return <DataRow labelKey='common.financingDetails' value={selectedOptionsString} />
}
