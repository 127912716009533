import { Flow } from '../../../common/checkout'
import { IAncillaryPlanOption } from '@pdiatl/common-external-models'

export interface IPlansAvailability {
  fromTerm: number
  toTerm?: number
  plans: string[]
}

export const MULTI_COVERAGE_PLANS: IPlansAvailability = {
  fromTerm: 0,
  plans: ['POMCPLAT', 'POMCGOLD', 'POMCSILV', 'POMCBRNZ', 'POMCCPPR', 'K', 'X', 'D', 'P']
}
export const LEASE_END_PLANS: IPlansAvailability = { fromTerm: 0, plans: ['POLSLEAS'] }
export const TERM_PLANS: IPlansAvailability = { fromTerm: 24, plans: ['POTRTRPR', 'POTRTRRT'] }
export const TERM_PLUS_PLANS: IPlansAvailability = { fromTerm: 60, plans: ['POTSTRPR', 'POTSTRRT'] }
export const GAP_PLUS_PLANS: IPlansAvailability = {
  fromTerm: 0,
  plans: ['POPLGAP']
}
export const GAP_PLANS: IPlansAvailability = {
  fromTerm: 0,
  plans: ['POGPGAP']
}
export const VEHICLE_SERVICE_PLUS_PLANS: IPlansAvailability = {
  fromTerm: 60,
  plans: ['POVSPLIW', 'POVSPTIW', 'POVSPLOW', 'POVSPTOW']
}

function isAvailable(plan: IAncillaryPlanOption, availability: IPlansAvailability[], term?: number) {
  return availability.some((a) => {
    if (term === undefined) {
      return a.plans.includes(plan.code)
    }
    return a.plans.includes(plan.code) && term >= a.fromTerm && term <= (a.toTerm ?? 60)
  })
}

const preferGapOverGapPlus = (plans: IAncillaryPlanOption[]): IAncillaryPlanOption[] => {
  if (
    plans.find((plan) => GAP_PLANS.plans.includes(plan.code)) &&
    plans.find((plan) => GAP_PLUS_PLANS.plans.includes(plan.code))
  ) {
    return plans.filter((plan) => plan.code !== GAP_PLUS_PLANS.plans[0])
  } else {
    return plans
  }
}

export function filterAncillaryPlans(plans: IAncillaryPlanOption[], flow: Flow, term: number): IAncillaryPlanOption[] {
  if (flow === Flow.LEASE) {
    return plans.filter((plan) =>
      isAvailable(plan, [MULTI_COVERAGE_PLANS, LEASE_END_PLANS, TERM_PLANS, TERM_PLUS_PLANS], term)
    )
  } else if (flow === Flow.FINANCE) {
    const filteredPlans = plans.filter((plan) =>
      isAvailable(
        plan,
        [MULTI_COVERAGE_PLANS, GAP_PLANS, GAP_PLUS_PLANS, VEHICLE_SERVICE_PLUS_PLANS, TERM_PLANS, TERM_PLUS_PLANS],
        term
      )
    )
    return preferGapOverGapPlus(filteredPlans)
  } else if (flow === Flow.OWN_FINANCING) {
    return plans.filter((plan) => isAvailable(plan, [MULTI_COVERAGE_PLANS, TERM_PLANS, VEHICLE_SERVICE_PLUS_PLANS]))
  } else {
    return plans.filter((plan) =>
      isAvailable(plan, [MULTI_COVERAGE_PLANS, TERM_PLANS, TERM_PLUS_PLANS, VEHICLE_SERVICE_PLUS_PLANS])
    )
  }
}
