import { User } from 'oidc-client'
import { config } from '../config'
import { httpStatusHandler } from './httpStatusHandler'

export interface IProfileAddress {
  addressId: string
  houseNumber: string
  street1: string
  street2?: string
  city: string
  postalCode: string
  country: string
  usage: 'HOME' | 'WORK' // Checkout service uses upper-case but Profile service provides lowercase
  postalCodeRegionValue?: string
}

interface IReturnedPhoneNumber {
  phoneId: string
  number: string
}

interface IReturnedMobileNumber {
  mobileId: string
  number: string
}

export interface IPhoneNumber {
  id: string
  number: string
}

export interface IEmail {
  email: string
  isStandard?: boolean
}

export interface IProfile {
  firstName: string
  lastName: string
  salutationValue?: string
  ciamId?: string
  emails: IEmail[]
  addresses: IProfileAddress[]
  mobiles?: IReturnedMobileNumber[]
  phones?: IReturnedPhoneNumber[]
}

const CACHE_LIFE_VALID_TIME_MS = 500

const cache: { [accessToken: string]: IProfile } = {}
const cacheTimestamp: { [accessToken: string]: number } = {}

export const selectEmail = (emails: IEmail[]) => {
  const filtered = emails.filter((e) => e.isStandard)
  if (filtered.length > 0) {
    return filtered[0].email
  } else if (emails.length > 0) {
    return emails[0].email
  } else {
    return 'UNKNOWN EMAIL' // this should not happen
  }
}

export class NewProfileService {
  constructor(private errorHandler: (e: Error) => any) {}

  private async get<T>(url: string, oidcUser: User): Promise<T> {
    return fetch(url, {
      headers: {
        Authorization: `Bearer ${oidcUser.access_token}`
      }
    })
      .then(httpStatusHandler)
      .then(async (r) => r.json())
      .catch(this.errorHandler)
  }

  async fetchAddresses(oidcUser: User): Promise<IProfileAddress[]> {
    return (await this.fetchProfileInternal(oidcUser)).addresses
  }

  async fetchPhones(oidcUser: User): Promise<IPhoneNumber[]> {
    const { phones, mobiles } = await this.fetchProfileInternal(oidcUser)
    return (phones ?? [])
      .map((phone) => ({ id: phone.phoneId, number: phone.number }))
      .concat((mobiles ?? []).map((mobile) => ({ id: mobile.mobileId, number: mobile.number })))
  }

  async fetchEmails(oidcUser: User): Promise<IEmail[]> {
    return (await this.fetchProfileInternal(oidcUser)).emails
  }

  async fetchProfile(oidcUser: User): Promise<Omit<IProfile, 'emails' | 'addresses' | 'phones'>> {
    return this.fetchProfileInternal(oidcUser)
  }

  async fetchProfileInternal(oidcUser: User): Promise<IProfile> {
    if (cache[oidcUser.access_token] && Date.now() - cacheTimestamp[oidcUser.access_token] < CACHE_LIFE_VALID_TIME_MS) {
      return cache[oidcUser.access_token]
    }

    return this.get<IProfile>(`${config().profile.apiEndpoint}/mydata`, oidcUser).then((p: IProfile) => {
      cache[oidcUser.access_token] = p
      cacheTimestamp[oidcUser.access_token] = Date.now()
      return p
    })
  }
}
