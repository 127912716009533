import { IProfileCardDetails } from './profileCardGuest'
import { CustomerProfileView } from '@pdiatl/common-external-models'

export function mapGuestProfileValuesToCustomerProfile(guestProfileValues: IProfileCardDetails): CustomerProfileView {
  const address = {
    usage: 'HOME',
    street1: guestProfileValues.street,
    houseNumber: guestProfileValues.houseNumber,
    city: guestProfileValues.city,
    postalCode: guestProfileValues.zipCode,
    countryCode: guestProfileValues.country
  }
  return {
    salutation: guestProfileValues.salutation,
    firstName: guestProfileValues.firstName,
    lastName: guestProfileValues.lastName,
    emailAddress: guestProfileValues.email,
    phoneNumber: guestProfileValues.phone,
    phone: {
      number: guestProfileValues.phone
    },
    address,
    addresses: [address]
  }
}
