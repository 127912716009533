import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import React, { PropsWithChildren, useEffect } from 'react'
import { unsavedDataModalStore } from '../checkout/unsavedDataModalStore'
import { ExpandedStep2Desktop } from './ExpandedStep2Desktop'
import { ExpandedStep2Mobile } from './ExpandedStep2Mobile'
import { IAnalyticsMap } from './stackElement'

export type IExpandedStep2Props = PropsWithChildren<{
  index: number
  analyticsMap?: IAnalyticsMap
  visibleOnlyOnEdit: boolean
}>

export const ExpandedStep2: React.FC<PropsWithChildren<IExpandedStep2Props>> = (props) => {
  const { smallerThan: isMobile } = useBreakpoint('m')

  useEffect(() => {
    unsavedDataModalStore.setCheckoutFormDirty(false)
  }, [])

  return isMobile ? <ExpandedStep2Mobile {...props} /> : <ExpandedStep2Desktop {...props} />
}
