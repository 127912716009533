import { PGrid, PGridItem, PText } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isNewVehicle } from '../../../../common/checkout'
import { formatLocalizedCurrencyNoFraction, formatLocalizedNoFraction } from '../../../../helpers/format'
import { AnalyticsPage, MsrpElementId } from '../../../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../../../entry/presenterProvider'
import { MsrpPricingSection } from '../../../vehicleSelection/cash/CashVehicleSelection'
import { CalculationResult } from '../../../vehicleSelection/pcna/leaseFinance/defiCalcFinanceData'
import { vehicleSelectionStore } from '../../../vehicleSelection/pcna/vehicleSelectionStore'
import { OpportunityVehicleSelectionImage } from '../../../vehicleSelection/vehicleSelectionImage/VehicleSelectionImage'

import { StepDataRow } from '../stepData/common'
import { VehicleSummaryStepsData } from '../VehicleSummaryStepsData'
import { VehicleSummaryYourPorscheModel } from './VehicleSummaryYourPorscheModel'
import { PorscheApprovedSection } from '../../porscheApproved/PorscheApprovedSection'

const LeaseTable: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const financialProduct = vehicleSelectionStore.calculationResult as CalculationResult
  const currency = opportunity.product.price.currencyCode

  return (
    <>
      <PText weight='bold'>Leasing Details</PText>
      <PGrid>
        <StepDataRow labelKey='common.annualMileage'>{`${formatLocalizedNoFraction(
          intl,
          (financialProduct.annualMileage ?? 0) * 1000
        )} miles`}</StepDataRow>
        <StepDataRow labelKey='common.term'>
          {financialProduct.term as number}{' '}
          {intl.formatMessage({
            id: 'common.months'
          })}
        </StepDataRow>
        <StepDataRow labelKey='common.down'>
          {formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.downPayment)}
        </StepDataRow>
        <StepDataRow labelKey={isNewVehicle(opportunity) ? 'common.msrp.price' : 'common.price'}>
          {formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.totalPrice)}
        </StepDataRow>
      </PGrid>
    </>
  )
}

const FinanceTable: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const financialProduct = vehicleSelectionStore.calculationResult as CalculationResult
  const currency = opportunity.product.price.currencyCode

  return (
    <>
      <PText weight='bold'>
        <FormattedMessage id='common.financingDetails' />
      </PText>
      <PGrid>
        <StepDataRow labelKey='common.apr'>{`${intl.formatNumber(
          financialProduct.annualPercentageRate || 0
        )}%`}</StepDataRow>
        <StepDataRow labelKey='common.term'>
          {financialProduct.term as number} {intl.formatMessage({ id: 'common.months' })}
        </StepDataRow>
        <StepDataRow labelKey='common.down'>
          {formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.downPayment)}
        </StepDataRow>
        <StepDataRow labelKey='common.financed'>
          {formatLocalizedCurrencyNoFraction(
            intl,
            currency,
            financialProduct.totalPrice - (financialProduct.downPayment ?? 0)
          )}
        </StepDataRow>
        <StepDataRow labelKey={isNewVehicle(opportunity) ? 'common.msrp.price' : 'common.price'}>
          {formatLocalizedCurrencyNoFraction(intl, currency, financialProduct.totalPrice)}
        </StepDataRow>
      </PGrid>
    </>
  )
}

export const LeaseFinanceVehicleContent: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const financialProduct = vehicleSelectionStore.calculationResult as CalculationResult
  const isLease = financialProduct.creditType === 'LSE'

  return (
    <>
      {isLease && <LeaseTable />}
      {!isLease && <FinanceTable />}

      <div className='xxsmall-space-top medium-space-bottom'>
        <MsrpPricingSection
          opportunity={opportunity}
          analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
          analyticsElementId={MsrpElementId.SIDEBAR_DESKTOP}
        />
      </div>

      <PText color='inherit' className='neutral-contrast-medium'>
        <FormattedMessage id='vehicleSelection.legal1' /> <FormattedMessage id='common.pfsDisclaimer' />
      </PText>
    </>
  )
}

export const LeaseFinanceVehicleSummary: React.FC = observer(() => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const intl = useIntl()
  const financialProduct = vehicleSelectionStore.calculationResult as CalculationResult
  const isLease = financialProduct.creditType === 'LSE'
  const currency = opportunity.product.price.currencyCode
  const price = (isLease ? financialProduct.monthlyLeasePayment : financialProduct.monthlyFinancePayment) || 0
  return (
    <>
      <PGrid className='xsmall-space-bottom'>
        <PGridItem size={12}>
          <VehicleSummaryYourPorscheModel />
        </PGridItem>
        <PGridItem size={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <PText size='medium' weight='semibold' align='left' data-testid='summaryPFSEstimatedMonthlyPayment'>
            {formatLocalizedCurrencyNoFraction(intl, currency, price)} <FormattedMessage id='common.perMonthAbbrev' />
          </PText>
          <PText className='xxsmall-space-top'>Dealer {opportunity.owner?.displayName}</PText>
          <PorscheApprovedSection analyticsPage={AnalyticsPage.DESKTOP_SIDEBAR} />
        </PGridItem>
        <PGridItem size={4}>
          <OpportunityVehicleSelectionImage
            viewMode='one-third-tile'
            opportunity={opportunity}
            className='small-space-top small-space-bottom'
          />
        </PGridItem>
      </PGrid>

      <LeaseFinanceVehicleContent />

      <VehicleSummaryStepsData />
    </>
  )
})
