import {
  PButton,
  PButtonGroup,
  PDivider,
  PGrid,
  PGridItem,
  PHeadline,
  PSelectWrapper,
  PText,
  PTextFieldWrapper
} from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import React, { useContext, useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { isCheckout2Enabled } from '../../common/checkout'
import { config } from '../../config'
import { IValidators, validateAll, validateField, validatePattern, validateRequired } from '../../helpers/validators'
import { SessionStorageService } from '../../services/sessionStorageService'
import { AutocompleteAddress } from '../AutocompleteAddress/AutocompleteAddress'
import { CheckoutStorageKeys } from '../checkout/checkoutStorageKeys'
import { unsavedDataModalStore } from '../checkout/unsavedDataModalStore'
import { PresenterContext } from '../entry/presenterProvider'
import './profileCardGuest.scss'
import { mapGuestProfileValuesToCustomerProfile } from './profileCardGuestState'
import { CA_PROVINCES, CA_PROVINCES_FR } from './provinces'
import { US_STATES } from './states'

export type IProfileCardDetails = {
  salutation: string
  firstName: string
  lastName: string
  phone: string
  email: string
  country: string
  street: string
  houseNumber: string
  supplement: string
  city: string
  zipCode: string
} & ({ state: string } | { province: string })

const english = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mrs.', value: 'Mrs.' }
]

const french = [
  { label: 'M.', value: 'M.' },
  { label: 'Mlle', value: 'Mlle' },
  { label: 'Mme', value: 'Mme' }
]

const languageOption = config().locale === 'fr-CA' ? french : english

const provinceOption = config().locale === 'fr-CA' ? CA_PROVINCES_FR : CA_PROVINCES

const defaultCountryOption = config().isMarket('ca')
  ? { label: 'Canada', value: 'CA' }
  : { label: 'United States', value: 'US' }

const getValidators = (intl: IntlShape): IValidators => {
  const required = (name: string) =>
    validateRequired(
      intl.formatMessage(
        {
          id: 'common.validation.required'
        },
        { field: intl.formatMessage({ id: `profile.${name}` }) }
      )
    )

  const pattern = (name: string, pattern: RegExp) =>
    validatePattern(
      pattern,
      intl.formatMessage(
        {
          id: 'common.validation.pattern'
        },
        { field: intl.formatMessage({ id: `profile.${name}` }) }
      )
    )

  return {
    salutation: required('salutation'),
    firstName: required('firstName'),
    lastName: required('lastName'),
    phone: [required('phone'), pattern('phone', /^[0-9]+$/)],
    email: [
      required('email'),
      pattern(
        'email',
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ],
    street: required('street'),
    ...(config().isMarket('us') && { state: required('selectState') }),
    ...(config().isMarket('ca') && { province: required('selectProvince') }),
    houseNumber: required('houseNumber'),
    city: required('city'),
    zipCode: [
      required('zip'),
      config().isMarket('us') ? pattern('zip', /^[A-Z0-9]{5,6}$/) : pattern('zip', /^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]/)
    ]
  }
}

export const ProfileCardGuest: React.FC<{ showAddressAutocomplete: boolean }> = ({ showAddressAutocomplete }) => {
  const presenter = useContext(PresenterContext)
  const intl = useIntl()

  const { largerThan: isDesktop } = useBreakpoint('l')

  // FIXME: add default values for 'details'?
  const [details, setDetails] = useState<IProfileCardDetails>(
    SessionStorageService.getAndParsePrefixedItem<IProfileCardDetails>(CheckoutStorageKeys.GUEST_PROFILE_VALUES) ??
      ({ country: defaultCountryOption.value } as any)
  )
  const [errors, setErrors] = useState<Record<string, string | undefined>>({})
  const validators = getValidators(intl)

  const handleChange = (name: string, value: string) => {
    let errorForField

    if (validators[name]) {
      errorForField = validateField(value, validators[name])
    }

    setDetails({ ...details, [name]: value })
    setErrors({ ...errors, [name]: errorForField })

    unsavedDataModalStore.setCheckoutFormDirty(true)
  }

  const formInput = (labelKey: string, name: string, required = true): JSX.Element => {
    return (
      <PGridItem size={{ base: 12, s: 6 }} className='form-input fs-mask' style={{ height: isDesktop ? '110px' : '' }}>
        <PTextFieldWrapper
          label={intl.formatMessage({ id: labelKey })}
          state={errors[name] ? 'error' : 'none'}
          message={errors[name]}
        >
          <input
            type='text'
            name={name}
            onChange={(e) => handleChange(name, e.target.value)}
            onBlur={(e) => handleChange(name, e.target.value.trim())}
            required={required}
            value={details[name]}
          />
        </PTextFieldWrapper>
      </PGridItem>
    )
  }

  const formSelect = (
    labelKey: string,
    name: string,
    options: { label: string; value: string }[],
    required = true,
    wide = false,
    defaultOption?: { label: string; value?: string }
  ): JSX.Element => {
    return (
      <PGridItem size={{ base: 12, s: wide ? 12 : 6 }} className='form-input'>
        <PSelectWrapper
          label={intl.formatMessage({ id: labelKey })}
          state={errors[name] ? 'error' : 'none'}
          message={errors[name]}
          className='fs-mask'
        >
          <select
            name={name}
            value={details[name]}
            onChange={(e) => handleChange(name, e.target.value)}
            onBlur={(e) => handleChange(name, e.target.value)}
            required={required}
          >
            {defaultOption ? <option value={defaultOption.value ?? ''}>{defaultOption.label}</option> : <option />}
            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
        </PSelectWrapper>
      </PGridItem>
    )
  }

  const save = () => {
    unsavedDataModalStore.setCheckoutFormDirty(false)

    const errors = validateAll(details, validators)
    setErrors(errors)

    if (Object.getOwnPropertyNames(errors).length === 0) {
      const opportunityStore = presenter.opportunityStore

      const guestCustomerProfile = mapGuestProfileValuesToCustomerProfile(details)
      opportunityStore.setCustomerProfile(guestCustomerProfile)
      SessionStorageService.setPrefixedItem(CheckoutStorageKeys.GUEST_PROFILE_VALUES, details)

      presenter.stackManager.editNext()
    }
  }

  return (
    <>
      <PGrid className='medium-space-bottom'>
        <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, l: 5 }}>
          <div className='medium-space-bottom'>
            <PText>
              <FormattedMessage id='profile.title.sub.guest' />
            </PText>
          </div>

          <div className='guest-form'>
            <PGrid gutter={16}>
              {formSelect('profile.salutation', 'salutation', languageOption)}
              <PGridItem size={{ base: 12, s: 6 }} />
              {formInput('profile.firstName', 'firstName')}
              {formInput('profile.lastName', 'lastName')}
              {formInput('profile.phoneNumber', 'phone')}
              {formInput('profile.email', 'email')}
              {formSelect('profile.country', 'country', [], false, true, defaultCountryOption)}
              {showAddressAutocomplete && (
                <PGridItem size={{ base: 12, s: 12 }} className='form-input'>
                  <AutocompleteAddress
                    country={config().market}
                    addressSelected={(address) => setDetails({ ...details, ...address })}
                  />
                </PGridItem>
              )}
              {formInput('profile.houseNumber', 'houseNumber')}
              {formInput('profile.street', 'street')}
              {formInput('profile.supplement', 'supplement', false)}
              {formInput('profile.city', 'city')}
              {config().isMarket('us')
                ? formSelect('profile.selectState', 'state', US_STATES, true)
                : formSelect('profile.selectProvince', 'province', provinceOption, true)}
              {formInput('profile.zip', 'zipCode')}
            </PGrid>
          </div>

          <PButtonGroup className='medium-space-top'>
            <PButton icon='check' variant='secondary' onClick={save}>
              <FormattedMessage id='profile.confirm' />
            </PButton>
          </PButtonGroup>
        </PGridItem>

        {!isCheckout2Enabled() && (
          <>
            <PGridItem
              size={{ base: 12, l: 1, xl: 1 }}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <PDivider
                color='neutral-contrast-low'
                orientation={{ base: 'horizontal', l: 'vertical' }}
                className='divider'
              />
            </PGridItem>
            <PGridItem size={{ base: 11, l: 6, xl: 6 }}>
              <PHeadline variant='headline-4'>
                <FormattedMessage id='profile.faq.q1' />
              </PHeadline>
              <PText className='ada-text-width'>
                <FormattedMessage id='profile.faq.a1' />
              </PText>

              <PHeadline variant='headline-4' className='small-space-top'>
                <FormattedMessage id='profile.faq.q2' />
              </PHeadline>
              <PText className='ada-text-width'>
                <FormattedMessage id='profile.faq.a2' />
              </PText>

              <PHeadline variant='headline-4' className='small-space-top'>
                <FormattedMessage id='profile.faq.q3' />
              </PHeadline>
              <PText className='ada-text-width'>
                <FormattedMessage id='profile.faq.a3' />
              </PText>
            </PGridItem>
          </>
        )}
      </PGrid>
    </>
  )
}
