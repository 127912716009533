import { useMatomo } from '@datapunt/matomo-tracker-react'
import { trackPageLoadEvent } from '@slatldisal/one-ga'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useLayoutEffect } from 'react'
import { isCheckout2Enabled, isFinanceOpportunity } from '../../common/checkout'
import { config } from '../../config'
import { matomoPcnaTrackPageView } from '../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import OpportunityService from '../../services/opportunityService'
import { defaultErrorHandler } from '../../util/defaultErrorHandler'
import { Checkout2Confirmation } from '../checkout2/confirmation/Checkout2Confirmation'
import { is3DSecuredURL, isApplicationComplete, PresenterContext } from '../entry/presenterProvider'
import './Confirmation.scss'
import { PclConfirmationPage } from './pcl/PclConfirmationPage'

import './pcna/OldConfirmation.scss'
import { PcnaConfirmationPage } from './pcna/PcnaConfirmationPage'

export const Confirmation: React.FC = observer(() => {
  const { pushInstruction } = useMatomo()
  const { user } = useContext(PresenterContext)
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  useLayoutEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    matomoPcnaTrackPageView(pushInstruction, 'checkout/order_confirmation')
    trackPageLoadEvent(AnalyticsEventTemplates.ORDER_CONFIRMATION)
  }, [pushInstruction])

  useEffect(() => presenter.refreshOpportunity(), [presenter])

  useEffect(
    () => {
      if (opportunity.opportunityState === 'DRAFT' && isApplicationComplete() && isFinanceOpportunity(opportunity)) {
        opportunity.financialProduct.payload = {
          ...opportunity.financialProduct.payload,
          aoacComplete: true
        }

        void new OpportunityService(defaultErrorHandler).complete(opportunity, user ?? undefined)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (opportunity.customerProfile?.payload?.secureLink && !is3DSecuredURL()) {
      window.location.href = opportunity.customerProfile.payload.secureLink
    }
  }, [opportunity?.customerProfile?.payload?.secureLink])

  return (
    <>
      {isCheckout2Enabled() && <Checkout2Confirmation />}
      {!isCheckout2Enabled() && (
        <div className='old-purchase-confirmation'>
          {config().isMarket('ca') ? <PclConfirmationPage /> : <PcnaConfirmationPage />}
        </div>
      )}
    </>
  )
})
