import { TradeInVehicleView } from '@pdiatl/common-external-models'
import { IntlShape } from 'react-intl'
import { fullMileageUnits } from '@slatldisal/checkout-i18n'
import { ICheckoutOpportunity } from '../../common/checkout'
import { validateAll } from '../../helpers/validators'
import { getValidators, validateForm } from './tradeInValidators'

export type Condition = TradeInVehicleView['condition']

export interface ITradeinState {
  tradeInValues: ITradeInValues
  status: 'clean' | 'dirty' | 'completed'
  errors: Record<string, string | undefined>
  intl?: IntlShape
}

export interface ITradeInValues {
  selection: 'manual' | 'kbb' | 'none'
  brand: string
  model: string
  year: string
  mileage: string
  color?: string
  condition?: Condition
  estimatedValue?: number
  existingLoan?: number
  vin?: string
  notes?: string
  kbbTradeIn?: TradeInVehicleView
}

export enum TradeInAction {
  FLIP = 'FLIP',
  FIELD_CHANGED = 'FIELD_CHANGED',
  FORM_SUBMITTED = 'FORM_SUBMITTED',
  KBB_ADDED = 'KBB_ADDED'
}

function validateChange(state: ITradeinState, field: string, payload: any): ITradeinState {
  const nextState = {
    ...state,
    tradeInValues: {
      ...state.tradeInValues,
      [field]: payload
    }
  }
  const errors = validateAll(nextState.tradeInValues, getValidators(state.intl!))
  const formValid = Object.getOwnPropertyNames(errors).length === 0
  const nextErrors = { ...state.errors, [field]: errors[field] }
  if (!errors[field]) {
    delete nextErrors[field]
  }
  return {
    ...nextState,
    tradeInValues: {
      ...nextState.tradeInValues,
      [field]: payload
    },
    errors: nextErrors,
    status: formValid ? 'completed' : 'dirty'
  }
}

export function formReducer(state: ITradeinState, action: any): ITradeinState {
  const validators = getValidators(state.intl!)

  switch (action.type) {
    case TradeInAction.FLIP: {
      return { ...state, tradeInValues: { ...state.tradeInValues, selection: action.newSelection } }
    }
    case TradeInAction.FIELD_CHANGED:
      return validateChange(state, action.field, action.payload)
    case TradeInAction.KBB_ADDED: {
      const kbbTradeIn = action.payload as TradeInVehicleView
      if (kbbTradeIn) {
        const nextKBBState = { ...state, tradeInValues: { ...state.tradeInValues, kbbTradeIn } }
        return { ...nextKBBState, ...validateForm(nextKBBState, validators) }
      }
      return state
    }
    case TradeInAction.FORM_SUBMITTED:
      return {
        ...state,
        ...validateForm(state, validators)
      }
    default:
      return state
  }
}

export const NOT_PROVIDED = 'Not provided'

const NO_TRADE_IN = {
  brand: NOT_PROVIDED,
  model: NOT_PROVIDED,
  currentMileage: {
    unitCode: 'MILES' as const,
    numericValue: 0
  },
  condition: 'NOT_PROVIDED' as const,
  notes: 'NO_TRADEIN_PROVIDED',
  yearOfConstruction: 0
}

export function mapTradeInStateToVehicleView(
  opportunity: ICheckoutOpportunity,
  tradeInValues: ITradeInValues
): TradeInVehicleView | undefined {
  const tradeInVehicleView = opportunity.tradeInVehicle
  const unitCode = fullMileageUnits(opportunity?.vehicle?.payload?.mileageUnit)

  const {
    selection,
    mileage,
    color,
    year,
    condition,
    estimatedValue = 0,
    existingLoan,
    kbbTradeIn,
    ...values
  } = tradeInValues

  if (selection === 'manual') {
    return {
      ...tradeInVehicleView,
      ...values,
      trim: undefined,
      currentMileage: {
        numericValue: Number.parseInt(mileage, 10),
        unitCode
      },
      yearOfConstruction: Number.parseInt(year, 10),
      options: { color, existingLoan },
      condition: condition ?? 'NOT_PROVIDED',
      valuation: {
        provider: 'self',
        estimatedValue: {
          tradeIn: [{ value: { currencyCode: '$', value: estimatedValue }, condition: condition ?? 'NOT_PROVIDED' }]
        }
      }
    }
  } else if (selection === 'kbb' && !!kbbTradeIn) {
    return kbbTradeIn
  } else if (selection === 'none' && kbbTradeIn) {
    return NO_TRADE_IN
  }
  return undefined
}
