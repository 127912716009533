import React, { PropsWithChildren, useContext } from 'react'
import { PFlex, PFlexItem, PHeadline } from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { IAnalyticsMap, PageLoadAnalytics, StackElementState } from './stackElement'
import { StepCircle2 } from './StepCircle2'
import { PresenterContext } from '../entry/presenterProvider'
import { onEnterKeyDown } from '../../common/onEnterKeyDown'
import './CollapsedStep2.scss'

export const CollapsedStep2: React.FC<
  PropsWithChildren<{
    index: number
    renderState: StackElementState
    label: string
    analyticsMap?: IAnalyticsMap
  }>
> = ({ index, renderState, label, analyticsMap, children }) => {
  const presenter = useContext(PresenterContext)
  const { smallerThan: isMobile } = useBreakpoint('m')
  const { smallerThan: isSmallMobile } = useBreakpoint('s')
  const isClickable = renderState === StackElementState.COMPLETED
  const onStepClick = () => presenter.stackManager.setCurrentEditIndex(index)

  const stepContainerProps = isClickable
    ? {
        tabIndex: 0,
        role: 'button',
        'aria-label': `Go to ${label}`,
        onClick: onStepClick,
        onKeyDown: (e: React.KeyboardEvent) => onEnterKeyDown(e, onStepClick),
        className: `card2 card-clickable ${isMobile ? 'xxsmall-space-bottom' : 'small-space-bottom'}`
      }
    : {
        className: `card2 ${isMobile ? 'xxsmall-space-bottom' : 'small-space-bottom'}`
      }

  return (
    <PageLoadAnalytics state={renderState} analyticsMap={analyticsMap}>
      <div {...stepContainerProps} id={`wizard-step-${index + 1}`}>
        <PFlex alignItems='center'>
          {isMobile && (
            <PFlexItem>
              <StepCircle2 index={index} />
            </PFlexItem>
          )}

          <PFlexItem grow={1} width='full'>
            <PFlex
              direction='column'
              justifyContent='center'
              className={`medium-space-top medium-space-bottom ${!isMobile ? 'medium-space-left' : ''}`}
              style={{ marginRight: isSmallMobile ? '32px' : '' }}
            >
              <PFlexItem>
                <PHeadline variant='headline-4' tag='h2'>
                  {label}
                </PHeadline>
              </PFlexItem>
              <PFlexItem>
                <div
                  className={isMobile ? 'medium-space-left medium-space-right' : ''}
                  style={{ marginRight: isSmallMobile ? '0 32px 0 32px' : '' }}
                >
                  {children}
                </div>
              </PFlexItem>
            </PFlex>
          </PFlexItem>
        </PFlex>
      </div>
    </PageLoadAnalytics>
  )
}
