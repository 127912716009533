import React from 'react'
import { PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'

export interface ISummaryHeaderProps {
  modelYear: number
  modelName: string
  dealer: string
}
export const VehicleSelectionHeader: React.FC<ISummaryHeaderProps> = (props) => {
  return (
    <>
      <PText>
        <FormattedMessage
          id='vehicleSelection.subtitle'
          values={{
            modelYear: props.modelYear || 'UNKNOWN YEAR',
            modelName: props.modelName || 'UNKNOWN MODEL',
            dealer: props.dealer || 'UNKNOWN DEALER'
          }}
        />
      </PText>
    </>
  )
}
