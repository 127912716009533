import { config } from '../config'

const { showErrorPage } = config()

export const defaultErrorHandler = (error: Error) => {
  console.log('ERROR', error) // @todo NewRelicHere?

  if (showErrorPage) {
    global.location?.assign('/error.html')
  }

  throw error
}
