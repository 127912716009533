import { useMatomo } from '@datapunt/matomo-tracker-react'
import {
  PButton,
  PButtonGroup,
  PFlex,
  PFlexItem,
  PRadioButtonWrapper,
  PText
} from '@porsche-design-system/components-react'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'
import { trackClickEvent } from '@slatldisal/one-ga'
import { observer } from 'mobx-react-lite'
import React, { useContext, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getFlow, isCheckout2Enabled } from '../../../common/checkout'
import { matomoPaymentOptionsActionMapper, matomoPcnaTrackClickEvent } from '../../../matomo/matomo'
import { AnalyticsEventTemplates } from '../../../onega/AnalyticsEventTemplates'
import { SessionStorageService } from '../../../services/sessionStorageService'
import { CheckoutStorageKeys } from '../../checkout/checkoutStorageKeys'
import { PresenterContext } from '../../entry/presenterProvider'
import { getStickyProgressBarOffset } from '../../stack/stackElement'
import {
  getVehicleSelectedValues,
  mapVehicleSelectionValuesToFinancialProduct
} from '../cash/cashVehicleSelectionState'
import { PaymentOptionDetails } from './PaymentOptionDetails'
import { vehicleSelectionStore } from './vehicleSelectionStore'
import { useFlags } from '../../../common/flags'

export type PaymentOptionType = undefined | 'cash' | 'leaseFinance' | 'ownFinancing' | 'porscheFinancialServices'
interface IPaymentOption {
  i18nKey: string
  value: PaymentOptionType
}

export const VehicleSelectionButtons: React.FC = observer(() => {
  const { trackEvent } = useMatomo()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  const { selectedPaymentOption, ownFinancingOptions, calculationResult } = vehicleSelectionStore

  const saveAndContinueEnabled =
    selectedPaymentOption &&
    (['cash', 'ownFinancing', 'porscheFinancialServices'].includes(selectedPaymentOption) ||
      calculationResult?.totalPrice)

  function setVehicleSelectionValuesInStorage(paymentType: PaymentOptionType) {
    const vehicleSelectionValuesString = getVehicleSelectedValues(paymentType, calculationResult!, ownFinancingOptions)
    SessionStorageService.setPrefixedItem(CheckoutStorageKeys.VEHICLE_SELECTION_VALUES, vehicleSelectionValuesString)
  }

  const handleSkip = () => {
    const paymentType = 'cash'
    setVehicleSelectionValuesInStorage(paymentType)
    vehicleSelectionStore.setSelectedPaymentOption(paymentType)
    opportunity.financialProduct = undefined

    matomoPcnaTrackClickEvent(trackEvent, 'skip-payment-options')
    trackClickEvent(AnalyticsEventTemplates.EXPLORE_PAYMENTS_STEP_SKIP())

    presenter.stackManager.editNext()
  }

  const handleSaveAndContinue = () => {
    setVehicleSelectionValuesInStorage(selectedPaymentOption)
    const vehicleSelectionValues = getVehicleSelectedValues(
      selectedPaymentOption,
      calculationResult!,
      ownFinancingOptions
    )
    opportunity.financialProduct = mapVehicleSelectionValuesToFinancialProduct(vehicleSelectionValues)

    matomoPcnaTrackClickEvent(trackEvent, matomoPaymentOptionsActionMapper(getFlow(opportunity)))
    trackClickEvent(AnalyticsEventTemplates.EXPLORE_PAYMENTS_STEP_SAVE_AND_CONTINUE(calculationResult))

    presenter.stackManager.editNext()
  }

  return (
    <PButtonGroup className='medium-space-bottom'>
      <PButton variant='secondary' onClick={handleSaveAndContinue} disabled={!saveAndContinueEnabled}>
        <FormattedMessage id='common.saveAndContinue' />
      </PButton>
      <PButton variant='tertiary' onClick={handleSkip}>
        <FormattedMessage id='common.skip' />
      </PButton>
    </PButtonGroup>
  )
})

const enabledPaymentOptions = (isFinancialCalculatorEnabled: boolean): IPaymentOption[] => {
  return isFinancialCalculatorEnabled
    ? [
        { i18nKey: 'vehicleSelection.paymentOptions.leaseFinance', value: 'leaseFinance' },
        { i18nKey: 'vehicleSelection.paymentOptions.cash', value: 'cash' },
        { i18nKey: 'common.paymentOptions.ownFinancing', value: 'ownFinancing' }
      ]
    : [
        { i18nKey: 'vehicleSelection.paymentOptions.leaseFinance', value: 'porscheFinancialServices' },
        { i18nKey: 'vehicleSelection.paymentOptions.cash', value: 'cash' },
        { i18nKey: 'common.paymentOptions.ownFinancing', value: 'ownFinancing' }
      ]
}

export const VehicleSelectionPaymentOptions: React.FC = observer(() => {
  const intl = useIntl()
  const { enableDefiFinancialCalculatorCheckoutUs } = useFlags()
  const { largerThan: isDesktop } = useBreakpoint('m')
  const isCheckout2Desktop = isCheckout2Enabled() && isDesktop
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const paymentRadiosRef = useRef<HTMLElement>(null)
  const dealer = opportunity.owner?.displayName ?? 'UNKNOWN DEALER'
  const { selectedPaymentOption, ownFinancingOptions } = vehicleSelectionStore
  const paymentOptions: IPaymentOption[] = enabledPaymentOptions(enableDefiFinancialCalculatorCheckoutUs)
  return (
    <>
      <PFlex direction='column' style={{ height: '100%' }}>
        <PFlexItem>
          <PText
            ref={paymentRadiosRef}
            size='small'
            className={isCheckout2Desktop ? 'small-space-top' : 'medium-space-top'}
          >
            <FormattedMessage id='vehicleSelection.paymentOptions' />
          </PText>
          <PFlex direction={{ base: 'column', s: 'row' }} className='small-space-top'>
            {paymentOptions.map((option) => (
              <PFlexItem style={{ margin: '0 30px 10px' }} key={option.value}>
                <PRadioButtonWrapper label={intl.formatMessage({ id: option.i18nKey })}>
                  <input
                    type='radio'
                    name={option.value}
                    checked={selectedPaymentOption === option.value}
                    onChange={() => {
                      if (option.value) {
                        trackClickEvent(AnalyticsEventTemplates.EXPLORE_PAYMENTS_STEP_PAYMENT_SELECTION(option.value))
                      }
                      vehicleSelectionStore.setCalculationResult(undefined)
                      vehicleSelectionStore.setSelectedPaymentOption(option.value)
                    }}
                  />
                </PRadioButtonWrapper>
              </PFlexItem>
            ))}
          </PFlex>

          <PaymentOptionDetails
            opportunity={opportunity}
            option={selectedPaymentOption}
            dealer={dealer}
            ownFinancingOptions={ownFinancingOptions}
            scrollToPaymentRadios={() =>
              window.scrollTo({ top: paymentRadiosRef.current!.offsetTop - getStickyProgressBarOffset() })
            }
          />
        </PFlexItem>
      </PFlex>
    </>
  )
})
