import React, { useContext } from 'react'
import { PFlex, PHeadline, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { useFlags } from '../../../common/flags'
import { config } from '../../../config'
import { isApplicationComplete, PresenterContext } from '../../entry/presenterProvider'
import { AoacFooter, CustomerSatisfactionSurvey, Footer } from './ui'
import { IFinanceOpportunity, isCheckout2Enabled, isGuest } from '../../../common/checkout'

export const ConfirmationInfoBlocks: React.FC = () => {
  const { showCustomerSatisfactionSurvey } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const creditType = opportunity?.financialProduct?.payload?.creditType
  const lseRtlCreditType = ['LSE', 'RTL'].includes(creditType)
  const guestFlow = isGuest()
  const guestLseRtl = guestFlow && lseRtlCreditType
  const loggedInLeaseFinanceFlow = !guestFlow && lseRtlCreditType

  if (!config().isMarket('us')) {
    return <></>
  }

  return (
    <>
      {loggedInLeaseFinanceFlow && !isCheckout2Enabled() && (
        <>
          <PHeadline variant='headline-2' align='center' className='huge-space-top small-space-bottom'>
            <FormattedMessage id='confirmation.dontSettle.header' />
          </PHeadline>

          <PFlex direction='column' alignItems='center'>
            <PText
              align='center'
              className={`${showCustomerSatisfactionSurvey ? 'big-space-bottom' : 'huge-space-bottom'} ada-text-width`}
            >
              <FormattedMessage id='confirmation.dontSettle.sub' />
            </PText>
          </PFlex>

          <div className='center-set'>
            {isApplicationComplete() ? <AoacFooter /> : <Footer opportunity={opportunity as IFinanceOpportunity} />}
          </div>
        </>
      )}

      {showCustomerSatisfactionSurvey && (
        <div className={`${guestLseRtl ? 'huge-space-top' : ''} big-space-bottom`}>
          <CustomerSatisfactionSurvey />
        </div>
      )}
    </>
  )
}
