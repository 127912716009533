import { IntlShape } from 'react-intl'
import { IQuestion } from '../components/questions/questions'

export function formatLocalizedCurrencyNoFraction(intl: IntlShape, currency: string, value?: number) {
  return intl.formatNumber(value ?? 0, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export function formatLocalizedNoFraction(intl: IntlShape, value?: number) {
  return intl.formatNumber(value ?? 0, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

export function formatLocalizedTwoFractionDigits(intl: IntlShape, value?: number) {
  return intl.formatNumber(value ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export function generateQuestions(prefix: string, num: number, values?: Record<string, any>): IQuestion[] {
  const questions: IQuestion[] = []
  for (let i = 1; i <= num; i++) {
    questions.push({
      q: { id: `${prefix}.q${i}`, values },
      a: { id: `${prefix}.a${i}`, values }
    })
  }
  return questions
}
