import { PDivider, PText } from '@porsche-design-system/components-react'
import { AutoCheckSummaryComponent } from '@slatldisal/vehicle-history-report'
import React, { useContext } from 'react'
import { isCheckout2Enabled } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { config } from '../../../config'
import { PresenterContext } from '../../entry/presenterProvider'

export const ConfirmationAutocheck: React.FC = () => {
  const { enableAutoCheckSummary } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  return (
    <>
      {config().isMarket('us') &&
        enableAutoCheckSummary &&
        opportunity.vehicle.conditionType !== 'NEW' &&
        opportunity.vehicle.payload && (
          <div className={isCheckout2Enabled() ? 'autocheck-summary2-container' : ''}>
            {isCheckout2Enabled() && (
              <PText theme='dark' size='large' weight='semibold' align='center'>
                Rest Assured Knowing Your Vehicle’s History
              </PText>
            )}
            {!isCheckout2Enabled() && <PDivider className='small-space-top medium-space-bottom' />}
            <AutoCheckSummaryComponent
              className='medium-space-top medium-space-bottom autocheck-summary'
              layout={isCheckout2Enabled() ? 'column' : 'row'}
              vin={opportunity.vehicle.vin}
              dealerId={opportunity.vehicle.payload.dealerId}
              apiUrl={config().vehicleHistory.endpointUrl}
            />
          </div>
        )}
    </>
  )
}
