import { PButton, PButtonGroup, PModal } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import ReactDOM from 'react-dom'
import { unsavedDataModalStore } from './unsavedDataModalStore'

export const UnsavedDataModal: React.FC = observer(() => {
  const intl = useIntl()
  const showModal = !!unsavedDataModalStore.showModalWithKey

  const modal = (
    <PModal
      heading={intl.formatMessage({ id: 'unsavedDataModal.header' })}
      open={showModal}
      onClose={() => unsavedDataModalStore.hideModal()}
    >
      {showModal && (
        <>
          <FormattedMessage id={unsavedDataModalStore.showModalWithKey} />
          <PButtonGroup className='medium-space-top'>
            <PButton
              variant='primary'
              aria-label='Confirm button'
              onClick={() => {
                if (unsavedDataModalStore.onConfirm) {
                  unsavedDataModalStore.onConfirm()
                }

                unsavedDataModalStore.hideModal()
              }}
            >
              <FormattedMessage id='unsavedDataModal.confirm' />
            </PButton>
            <PButton variant='tertiary' aria-label='Cancel button' onClick={() => unsavedDataModalStore.hideModal()}>
              <FormattedMessage id='unsavedDataModal.cancel' />
            </PButton>
          </PButtonGroup>
        </>
      )}
    </PModal>
  )

  if (showModal) {
    return ReactDOM.createPortal(modal, document.body)
  }

  return null
})
