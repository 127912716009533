import { User } from 'oidc-client'
import { ICheckoutOpportunity } from '../common/checkout'
import { config } from '../config'
import { httpStatusHandler } from './httpStatusHandler'
import { CURRENT_OPPORTUNITY_ID_STORAGE_KEY } from './sessionStorageService'

export default class OpportunityService {
  constructor(private errorHandler: (e: Error) => any) {}

  storage: Storage = global.sessionStorage

  private setAuthHeaders(request: RequestInit, oidcUser?: User) {
    if (oidcUser && !oidcUser.expired) {
      const { client_id: clientId } = config().pid.oauth
      if (!clientId) {
        throw new Error('client_id in oauth not defined')
      }

      request.headers = {
        ...request.headers,
        apikey: clientId,
        Authorization: `Bearer ${oidcUser.access_token}`
      }
    }
  }

  async fetch(leadId?: string, oidcUser?: User): Promise<ICheckoutOpportunity> {
    if (leadId) {
      this.storage.setItem(CURRENT_OPPORTUNITY_ID_STORAGE_KEY, leadId)
    } else {
      leadId = this.storage.getItem(CURRENT_OPPORTUNITY_ID_STORAGE_KEY) ?? undefined
    }

    if (!leadId) {
      throw new Error('No leadId to fetch')
    }

    const url = `${config().apiEndpoint}/draft/${leadId}`

    const completeRequest: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }

    this.setAuthHeaders(completeRequest, oidcUser)

    return await fetch(url, completeRequest)
      .then(httpStatusHandler)
      .then(async (r) => r.json())
      .catch(this.errorHandler)
  }

  async update(oppo: Partial<ICheckoutOpportunity>, oidcUser?: User): Promise<Response> {
    return this.putInternal(oppo, oidcUser, 'update')
  }

  async updateOrSwitchOnPaymentSkip(oppo: Partial<ICheckoutOpportunity>, oidcUser?: User): Promise<Response> {
    return this.putInternal(oppo, oidcUser, 'update-or-switch-on-payment-skip')
  }

  async complete(oppo: Partial<ICheckoutOpportunity>, oidcUser?: User): Promise<Response> {
    return this.putInternal(oppo, oidcUser, `complete/${config().locale}`)
  }

  private async putInternal(
    oppo: Partial<ICheckoutOpportunity>,
    oidcUser: User | undefined,
    path: string
  ): Promise<Response> {
    return await this.doRequest(path, 'PUT', oppo, oidcUser)
  }

  private async doRequest(path: string, method = 'PUT', body: any, oidcUser?: User): Promise<Response> {
    const url = `${config().apiEndpoint}/${path}`

    const completeRequest: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    this.setAuthHeaders(completeRequest, oidcUser)

    const response = await fetch(url, completeRequest).catch(this.errorHandler)

    if (!response.ok) {
      const { message = 'Internal Server Error' } = await response.json()

      throw new Error(message)
    }

    return response
  }
}
