import React, { useContext, useEffect, useRef } from 'react'
import { PButton, PButtonGroup, PGrid, PGridItem, PHeadline, PText } from '@porsche-design-system/components-react'
import { config } from '../../config'
import { PresenterContext } from '../entry/presenterProvider'
import { Questions } from '../questions/questions'
import { CheckoutDivider } from '../checkoutDivider/checkoutDivider'
import { trackClickEvent } from '@slatldisal/one-ga'
import { AnalyticsEventTemplates } from '../../onega/AnalyticsEventTemplates'
import { FormattedMessage } from 'react-intl'
import { generateQuestions } from '../../helpers/format'
import { observer } from 'mobx-react-lite'
import { isCheckout2Enabled } from '../../common/checkout'

export interface IHasDetail {
  detail: string
}

export const ProfileCard: React.FC = observer(() => {
  const phoneWidgetRef = useRef<HTMLElement>()
  const addressWidgetRef = useRef<HTMLElement>()
  const presenter = useContext(PresenterContext)
  if (!presenter.user) {
    throw new Error('Neither presenter.User nor oidcUser defined on ProfileCard')
  }

  const accessToken = presenter.user.access_token
  const apikey = config().profileWidget.clientId
  if (!apikey) {
    throw new Error('oauth client id not defined in ProfileCard')
  }

  const enabled = presenter.isProfileValid()

  useEffect(() => {
    const updateAddress = async (addressId: string): Promise<void> => {
      await presenter.selectAddress(addressId)
    }
    const updatePhone = async (phoneId: string): Promise<void> => {
      await presenter.selectPhone(phoneId)
    }

    const addressNode = addressWidgetRef.current
    const phoneNode = phoneWidgetRef.current

    const onAddressSelected = async (e: unknown) => {
      // It fires too frequent, waiting on proper event/solution
      // trackClickEvent(AnalyticsEventTemplates.ADDRESS_STEP_ADDR_SAVE, 'save')
      return updateAddress((e as IHasDetail).detail)
    }

    const onPhoneSelected = async (e: unknown) => {
      // It fires too frequent, waiting on proper event/solution ^^
      //   trackClickEvent(AnalyticsEventTemplates.ADDRESS_STEP_PHONE_SAVE, 'save')

      return updatePhone((e as IHasDetail).detail)
    }

    if (phoneNode) {
      phoneNode.addEventListener('phoneSelected', onPhoneSelected)
    }

    if (addressNode) {
      addressNode.addEventListener('addressSelected', onAddressSelected)
    }

    return () => {
      if (phoneNode) {
        phoneNode.removeEventListener('phoneSelected', onPhoneSelected)
      }

      if (addressNode) {
        addressNode.removeEventListener('addressSelected', onAddressSelected)
      }
    }
  }, [presenter])

  const finalize = () => {
    trackClickEvent(AnalyticsEventTemplates.ADDRESS_STEP_COMPLETE, { clickElementId: 'personal_details' })
    presenter.stackManager.editNext()
  }

  return (
    <>
      <PGrid direction={{ base: 'column', s: 'row' }} className='small-space-bottom'>
        <PGridItem size={isCheckout2Enabled() ? { base: 12 } : { base: 12, m: 5 }}>
          <PHeadline variant='headline-4' className='small-space-top'>
            <FormattedMessage id='profile.address' />
          </PHeadline>
          <PText className='neutral-contrast-low'>
            <FormattedMessage id='profile.addressPrompt' />
          </PText>

          <div className='surface-bg fs-mask' style={{ padding: '8px 0', marginTop: '10px' }}>
            <div className='small-space-bottom small-space-left small-space-right'>
              <myprofile-address-widget
                ref={addressWidgetRef}
                country={config().profile.country}
                locale={config().profile.locale}
                token={accessToken}
                apikey={apikey}
                initially-selected-address-id={null}
              />
            </div>
          </div>

          <PHeadline variant='headline-4' className='medium-space-top'>
            <FormattedMessage id='profile.phone' />
          </PHeadline>
          <PText className='neutral-contrast-low '>
            <FormattedMessage id='profile.phonePrompt' />
          </PText>
          <div className='surface-bg' style={{ padding: '8px 0', marginTop: '10px' }}>
            <div className='small-space-bottom small-space-left small-space-right'>
              <myprofile-phone-widget
                ref={phoneWidgetRef}
                country={config().profile.country}
                locale={config().profile.locale}
                token={accessToken}
                apikey={apikey}
              />
            </div>
          </div>

          <PButtonGroup className='medium-space-top medium-space-bottom'>
            <PButton disabled={!enabled} onClick={finalize}>
              <FormattedMessage id='profile.confirm' />
            </PButton>
          </PButtonGroup>
        </PGridItem>

        {!isCheckout2Enabled() && (
          <>
            <CheckoutDivider />
            <PGridItem size={{ base: 12, m: 6 }}>
              <Questions questions={generateQuestions('profile.faq', 3)} />
            </PGridItem>
          </>
        )}
      </PGrid>
    </>
  )
})
