import { PFlex, PText } from '@porsche-design-system/components-react'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'
import { StackElement, StackElementState } from '../stack/stackElement'
import { CalculationResult } from './pcna/leaseFinance/defiCalcFinanceData'
import { VehicleSelection } from './VehicleSelection'

export const VEHICLE_SELECTION_STACK_NAME = 'vehicleSelection'

const LockedView: React.FC = () => {
  return <div>You should never see this.</div>
}

export const CompletedViewCommon: React.FC = () => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunityClient = presenter.opportunityStore
  const opportunity = opportunityClient.opportunityCache

  const { modelYear, modelName } = opportunity.vehicle
  const dealer = opportunityClient.getOwner()?.displayName ?? 'UNKNOWN DEALER'

  return (
    <PFlex style={{ width: 'available' }} alignItems='center'>
      <CompletedTile>
        <PText>
          {modelYear} {modelName}, {intl.formatMessage({ id: 'vehicleSelection.title.dealer' })} {dealer}
        </PText>
      </CompletedTile>
    </PFlex>
  )
}

const EditView: React.FC = () => {
  const presenter = useContext(PresenterContext)

  const opportunity = presenter.opportunityStore.opportunityCache
  return (
    <VehicleSelection viewMode='full-tile' financeData={opportunity.financialProduct?.payload as CalculationResult} />
  )
}

export class VehicleSelectionStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(
      EditView,
      CompletedViewCommon,
      LockedView,
      () => StackElementState.EDIT,
      VEHICLE_SELECTION_STACK_NAME,
      index,
      label,
      'calculator',
      {
        analyticsPage: AnalyticsPage.CART,
        analyticsMap: {
          [StackElementState.EDIT]: {
            oneGAEvent: AnalyticsEventTemplates.LOAD_CART,
            matomoActionName: 'checkout/payment_options'
          }
        }
      }
    )
  }
}
