/* eslint-disable @typescript-eslint/no-unused-vars */
interface ICheckoutFlags {
  readonly enableKbbTradeIn: boolean
  readonly enableKbbChartOnConfirmationPage: boolean
  readonly enableGuestCheckoutPcl: boolean
  readonly enableTradeInStepPcna: boolean
  readonly enableTradeInStepPcl: boolean
  readonly enableGuestAddressAutocomplete: boolean
  readonly enablePcnaPaymentStep: boolean
  readonly enablePcnaPaymentStepSkip: boolean
  readonly enableAncillaryProductsPcna: boolean
  readonly enableNewSelectionOfAncillaryProducts: boolean
  readonly enableCheckout2: boolean
  readonly showCustomerSatisfactionSurvey: boolean
  readonly enableVehicleDetailsInSidebar: boolean
  readonly enableWarrantyInformationInSidebar: boolean
  readonly enableHistoryReportInSidebar: boolean
  readonly enablePorscheApproved: boolean
  readonly enableNewAllInPricing: boolean
  readonly enableCheckoutFinancingButton: boolean
  readonly enableCarfaxInCheckout: boolean
  readonly enableAutoCheckSummary: boolean
  readonly enableDefiFinancialCalculatorCheckoutUs: boolean
}

const productionFlags: ICheckoutFlags = {
  enableKbbTradeIn: true,
  enableKbbChartOnConfirmationPage: false,
  enableGuestCheckoutPcl: false,
  enableTradeInStepPcna: true,
  enableTradeInStepPcl: true,
  enableGuestAddressAutocomplete: true,
  enablePcnaPaymentStep: true,
  enablePcnaPaymentStepSkip: true,
  enableAncillaryProductsPcna: true,
  enableNewSelectionOfAncillaryProducts: true,
  enableCheckout2: true,
  showCustomerSatisfactionSurvey: false,
  enableVehicleDetailsInSidebar: true,
  enableWarrantyInformationInSidebar: true,
  enableHistoryReportInSidebar: false,
  enablePorscheApproved: true,
  enableNewAllInPricing: true,
  enableCheckoutFinancingButton: true,
  enableCarfaxInCheckout: true,
  enableAutoCheckSummary: false,
  enableDefiFinancialCalculatorCheckoutUs: false
}

function createFeatureFlags<T>(flags: T) {
  let testFlags = undefined as Partial<T> | undefined

  return {
    withFlags: <P>(wrappedComponent: React.ComponentType<P>) => wrappedComponent,

    useFlags: () => {
      if (testFlags) {
        return { ...flags, ...testFlags }
      }

      return flags
    },

    setFlagsForTesting: (testingFlags: Partial<T>) => {
      testFlags = testingFlags
    }
  }
}

export const { withFlags, useFlags, setFlagsForTesting } = createFeatureFlags(productionFlags)
