import {
  PAccordion,
  PButtonPure,
  PDivider,
  PFlex,
  PFlexItem,
  PGridItem,
  PText
} from '@porsche-design-system/components-react'
import { trackClickEvent } from '@slatldisal/one-ga'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AnalyticsEventTemplates } from '../../../../onega/AnalyticsEventTemplates'
import { StackManager } from '../../../../presenter/stackManager'
import { PresenterContext } from '../../../entry/presenterProvider'

export interface IStepData {
  closeModalFn?: Function
}

export const isStepDataShown = (stackName: string, stackManager: StackManager) =>
  stackManager.getStepIndex(stackName) < stackManager.currentStepIndex

const StepDataDivider: React.FC = () => <PDivider />

export const ClearStepData: React.FC<{ heading: string }> = ({ heading }) => {
  return (
    <>
      <StepDataDivider />
      <PText data-testid={heading} color='inherit' weight='bold' style={{ color: '#96989A', margin: '16px 0' }}>
        {heading}
      </PText>
    </>
  )
}

export const AccordionStepData: React.FC<PropsWithChildren<{ heading: string; autoCloseOnStepIndex?: number }>> =
  observer(({ heading, autoCloseOnStepIndex, children }) => {
    const presenter = useContext(PresenterContext)
    const currentStepIndex = presenter.stackManager.currentStepIndex
    const initiallyOpen = !!autoCloseOnStepIndex && currentStepIndex < autoCloseOnStepIndex
    const [open, setOpen] = useState(initiallyOpen)

    useEffect(() => {
      if (currentStepIndex === autoCloseOnStepIndex) {
        setOpen(false)
      }
    }, [currentStepIndex, autoCloseOnStepIndex])

    const testId = `accordion${heading.replace(/[^A-Z0-9]/gi, '')}`
    return (
      <>
        <StepDataDivider />
        <PAccordion
          data-testid={testId}
          heading={heading}
          open={open}
          onAccordionChange={(v: CustomEvent) => setOpen(v.detail.open)}
          className={open ? 'open' : 'closed'}
          style={{ margin: '10px 0' }}
          compact
        >
          {children}
        </PAccordion>
      </>
    )
  })

export const StepDataEditButton: React.FC<
  PropsWithChildren<{
    editStack: string
    editTitle: string
    closeModalFn?: Function
  }>
> = ({ editStack, editTitle, closeModalFn, children }) => {
  const presenter = useContext(PresenterContext)
  const StepButton = () => (
    <PButtonPure
      icon='edit'
      title={editTitle}
      className={children ? '' : 'small-space-top'}
      size='small'
      style={{ marginLeft: '-5px' }}
      onClick={() => {
        if (closeModalFn) {
          closeModalFn()
        }
        const stepIndex = presenter.stackManager.getStepIndex(editStack)
        presenter.stackManager.setCurrentEditIndex(stepIndex)

        trackClickEvent(AnalyticsEventTemplates.EDIT_STEP_CLICK(presenter.stackManager.getCurrentStep().analyticsPage))
      }}
    >
      <FormattedMessage id='common.edit' />
    </PButtonPure>
  )

  return children ? (
    <PFlex justifyContent='space-between'>
      <PFlexItem>{children}</PFlexItem>
      <PFlexItem>
        <StepButton />
      </PFlexItem>
    </PFlex>
  ) : (
    <StepButton />
  )
}

export const StepDataRow: React.FC<PropsWithChildren<{ label?: string; labelKey?: string }>> = ({
  label,
  labelKey,
  children
}) => {
  const className = label || labelKey ? 'small-space-top' : ''
  const testIdPrefix = `${
    label
      ? label.replace(/\W/g, '')
      : String(
          labelKey?.replace(/.*\./g, '').charAt(0).toLocaleUpperCase().concat(labelKey?.replace(/.*\./g, '').slice(1))
        )
  }`

  return (
    <>
      <PGridItem size={4} className={className} data-testid={`${testIdPrefix}Title`}>
        {label && <PText color='neutral-contrast-medium'>{label}</PText>}
        {!label && labelKey && (
          <PText color='neutral-contrast-medium'>
            <FormattedMessage id={labelKey} />
          </PText>
        )}
      </PGridItem>
      <PGridItem size={8} className={className} data-testid={`${testIdPrefix}Value`}>
        {children}
      </PGridItem>
    </>
  )
}
