import { useMatomo } from '@datapunt/matomo-tracker-react'
import React from 'react'
import './App.scss'
import { withFlags } from './common/flags'
import { EntryComponent } from './components/entry/entry'

export const App: React.FC = () => {
  const { enableLinkTracking } = useMatomo()

  enableLinkTracking()

  const Entry = withFlags(EntryComponent)

  return (
    <div className='app'>
      <Entry />
    </div>
  )
}

export default App
