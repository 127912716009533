import React from 'react'
import { PGridItem, PText } from '@porsche-design-system/components-react'
import { FormattedMessage } from 'react-intl'
import { config } from '../../../config'

function getDisclaimers(secondDisclaimerKey: string, className?: string): JSX.Element {
  return (
    <PGridItem size={12} className={className}>
      {config().isMarket('us') && (
        <PText size='small' color='neutral-contrast-medium' className='small-space-bottom'>
          <FormattedMessage id='vehicleSelection.legal1' />
        </PText>
      )}
      <PText size='small' color='neutral-contrast-medium' className='small-space-bottom'>
        <FormattedMessage id={secondDisclaimerKey} />
      </PText>
    </PGridItem>
  )
}

export const CashVehicleSelectionLegal: React.FC = () => {
  return getDisclaimers('vehicleSelection.legal2')
}

export const LeaseFinanceVehicleSelectionLegal: React.FC<{ className?: string }> = ({ className }) => {
  return getDisclaimers('common.pfsDisclaimer', className)
}
