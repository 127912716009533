import { PSpinner, PText } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { isGuest } from '../../common/checkout'
import { useFlags } from '../../common/flags'
import { config } from '../../config'
import { AnalyticsEventTemplates, AnalyticsPage } from '../../onega/AnalyticsEventTemplates'
import { useLoadScript } from '../../util/useLoadScript'
import { PresenterContext } from '../entry/presenterProvider'
import { CompletedTile } from '../stack/CompletedTile'
import { GraySimpleTile } from '../stack/GraySimpleTile'
import { StackElement, StackElementState } from '../stack/stackElement'
import { ProfileCard } from './profileCard'
import { ProfileCardGuest } from './profileCardGuest'

const { profilePostfix } = config()

export const PROFILE_STACK_NAME = 'profile'

const EditView: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const { enableGuestAddressAutocomplete } = useFlags()
  const scriptState = useLoadScript(`https://components.profile.porsche${profilePostfix}.com/v3/webcomponents.js`)

  if (scriptState !== 'ready') {
    return <PSpinner />
  }

  if (isGuest()) {
    return <ProfileCardGuest showAddressAutocomplete={enableGuestAddressAutocomplete} />
  }

  if (!presenter.user) {
    presenter.login()
    return null
  }

  void presenter.loadProfile()
  return <ProfileCard />
}

const LockedView: React.FC = () => {
  const intl = useIntl()

  return <GraySimpleTile prompt={intl.formatMessage({ id: 'profile.title.sub' })} />
}

export const countryCode = (code: string | undefined) => {
  if (code) {
    switch (code.toLowerCase()) {
      case 'us':
        return 'United States'
      case 'ca':
        return 'Canada'
    }
  }

  return undefined
}

const CompletedView: React.FC = observer(() => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  useEffect(() => {
    if (isGuest()) {
      return
    }

    if (presenter.user) {
      void presenter.loadProfile()
    } else {
      presenter.login()
    }
  }, [presenter])

  if (!opportunity.customerProfile?.addresses?.length) {
    return <PSpinner />
  }

  const address = opportunity.customerProfile?.addresses[0]
  const profile = opportunity.customerProfile

  const label = [
    profile?.firstName,
    profile?.lastName,
    address?.houseNumber,
    address?.street1,
    address?.stree2,
    address?.city,
    address?.postalCodeRegionValue,
    address?.postalCode,
    countryCode(address?.countryCode),
    opportunity.customerProfile?.phoneNumber
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <CompletedTile>
      <PText>
        {intl.formatMessage({ id: 'profile.title.sub.complete' })} <span>{label}</span>
      </PText>
    </CompletedTile>
  )
})

export class ProfileStackElement extends StackElement {
  constructor(index: number, label: string) {
    super(EditView, CompletedView, LockedView, () => StackElementState.EDIT, PROFILE_STACK_NAME, index, label, 'user', {
      analyticsPage: AnalyticsPage.PERSONAL_DATA,
      analyticsMap: {
        [StackElementState.EDIT]: {
          oneGAEvent: AnalyticsEventTemplates.PERSONAL_DATA,
          matomoActionName: 'checkout/personal_data'
        }
      }
    })
  }
}
