import React, { useContext } from 'react'
import { PHeadline, PText } from '@porsche-design-system/components-react'
import { PresenterContext } from '../../../entry/presenterProvider'
import { FormattedMessage } from 'react-intl'

export const VehicleSummaryYourPorscheModel: React.FC = () => {
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { modelYear, modelName } = opportunity.vehicle

  return (
    <>
      <PText>
        <FormattedMessage id='confirmation.yourPorsche' />
      </PText>
      <PHeadline
        variant='headline-4'
        tag='h1'
        className='xxsmall-space-top small-space-bottom'
        data-testid='summaryVehicleModel'
      >
        {modelYear} {modelName}
      </PHeadline>
    </>
  )
}
