import {
  PButton,
  PButtonGroup,
  PDivider,
  PGrid,
  PGridItem,
  PHeadline,
  PText
} from '@porsche-design-system/components-react'
import { AllInPricing, MsrpPricing } from '@slatldisal/msrp-pricing-us'
import { trackClickEvent } from '@slatldisal/one-ga'
import React, { useContext } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { getPriceLabel, ICheckoutOpportunity } from '../../../common/checkout'
import { useFlags } from '../../../common/flags'
import { config } from '../../../config'
import { AnalyticsEventTemplates, AnalyticsPage, MsrpElementId } from '../../../onega/AnalyticsEventTemplates'
import { DealerInformation } from '../../dealerInformation/dealerInformation'
import { PresenterContext } from '../../entry/presenterProvider'
import { NewAllInPricing } from '../../newAllInPricing/newAllInPricing'
import { SSLInfo } from '../sslInfo'
import { IVehicleSelectionProps } from '../VehicleSelection'
import { VehicleSelectionHeader } from '../vehicleSelectionHeader/VehicleSelectionHeader'
import { OpportunityVehicleSelectionImage } from '../vehicleSelectionImage/VehicleSelectionImage'
import { CashVehicleSelectionLegal } from '../vehicleSelectionLegal/VehicleSelectionLegal'

type CashVehicleSelectionProps = Omit<IVehicleSelectionProps, 'financeData'>

export const MsrpPricingSection: React.FC<{
  opportunity: ICheckoutOpportunity
  analyticsPage: AnalyticsPage
  analyticsElementId?: MsrpElementId
  alignment?: 'start' | 'end'
}> = ({ opportunity, analyticsPage, analyticsElementId, alignment = 'start' }) => {
  const isUsNewVehicle = config().isMarket('us') && opportunity.vehicle.conditionType === 'NEW'

  return (
    <div style={{ ...(isUsNewVehicle && { height: '40px' }) }}>
      <MsrpPricing
        vin={opportunity.vehicle?.vin}
        locale={config().locale}
        marketplaceKey={config().market}
        env={config().environment}
        alignment={alignment}
        conditionType={opportunity.vehicle.conditionType}
        analyticEvents={{
          onOpen: () =>
            trackClickEvent(
              AnalyticsEventTemplates.OPEN_MSRP(analyticsPage, analyticsElementId ?? MsrpElementId.DEFAULT)
            ),
          onClose: () => trackClickEvent(AnalyticsEventTemplates.CLOSE_MSRP(analyticsPage))
        }}
      />
    </div>
  )
}

const FullWidthCashVehicleSelection: React.FC<Omit<CashVehicleSelectionProps, 'viewMode'>> = () => {
  const { enableNewAllInPricing } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache

  return (
    <PGrid className='card-content-wrapper' direction={{ base: 'column', s: 'row' }}>
      <PGridItem size={{ base: 12, m: 5 }}>
        <div className='medium-space-bottom'>
          <VehicleSelectionHeader
            modelYear={opportunity.vehicle.modelYear ?? 0}
            modelName={opportunity.vehicle.modelName ?? ''}
            dealer={opportunity.owner?.displayName ?? ''}
          />
          <DealerInformation
            dealerUrl={opportunity.vehicle.payload?.dealerUrl}
            dealerName={opportunity.owner?.displayName}
            analyticsEvent={AnalyticsEventTemplates.DEALER_INFO_CLICK}
          />
        </div>

        <div className='small-space-right'>
          <PGrid>
            <PGridItem size={12}>
              <PDivider className='medium-space-bottom' />
            </PGridItem>
            <PGridItem size={6}>
              <PHeadline variant='headline-5' tag='h4'>
                <FormattedMessage id={getPriceLabel(opportunity)} />
              </PHeadline>
            </PGridItem>
            <PGridItem size={6}>
              <PHeadline variant='headline-5' align='right' tag='h4'>
                <FormattedNumber
                  value={opportunity.product?.price?.value ?? 0}
                  style='currency'
                  currency={opportunity.product?.price?.currencyCode}
                  currencyDisplay='narrowSymbol'
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
                {enableNewAllInPricing ? (
                  <NewAllInPricing
                    analyticEvents={{
                      onOpen: () =>
                        trackClickEvent(AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART)),
                      onClose: () =>
                        trackClickEvent(AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART))
                    }}
                  />
                ) : (
                  <AllInPricing
                    requestBodyParams={{
                      vin: opportunity.vehicle.vin,
                      listingId: opportunity.vehicle.payload?.listingId,
                      partnerNumber: opportunity.vehicle.payload?.dealerId,
                      price: opportunity.product.price.value!
                    }}
                    locale={config().locale}
                    marketplaceKey={config().market}
                    env={config().environment}
                    conditionType={opportunity.vehicle.conditionType}
                    hideLabel
                    analyticEvents={{
                      onOpen: () =>
                        trackClickEvent(AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART)),
                      onClose: () =>
                        trackClickEvent(AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART))
                    }}
                  />
                )}
              </PHeadline>
            </PGridItem>
            <PGridItem size={12}>
              <PText size='small' color='neutral-contrast-medium'>
                <FormattedMessage id='vehicleSelection.disclaimer' />
              </PText>
            </PGridItem>
            <PGridItem size={12}>
              <PDivider className='medium-space-top medium-space-bottom' />
            </PGridItem>
            <PGridItem size={8}>
              <PHeadline variant='headline-4' tag='h5'>
                <FormattedMessage id='common.total' />
              </PHeadline>
            </PGridItem>
            <PGridItem size={4}>
              <PHeadline variant='headline-4' align='right' tag='h5'>
                <FormattedNumber
                  value={opportunity.product?.price?.value ?? 0}
                  style='currency'
                  currency={opportunity.product?.price?.currencyCode}
                  currencyDisplay='narrowSymbol'
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
              </PHeadline>
              <MsrpPricingSection
                opportunity={opportunity}
                analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
                alignment='end'
              />
            </PGridItem>
            <PGridItem size={12}>
              <PButtonGroup className='medium-space-top'>
                <PButton
                  onClick={() => {
                    trackClickEvent(AnalyticsEventTemplates.SELECTION_STEP_COMPLETE, {
                      clickElementId: 'your_selection'
                    })
                    presenter.stackManager.editNext()
                  }}
                >
                  <FormattedMessage id='vehicleSelection.confirm' />
                </PButton>
              </PButtonGroup>
            </PGridItem>
            <PGridItem size={12} className='medium-space-top'>
              <CashVehicleSelectionLegal />
            </PGridItem>
          </PGrid>
        </div>
      </PGridItem>
      <PGridItem size={{ base: 12, m: 7 }}>
        <OpportunityVehicleSelectionImage viewMode='full-tile' opportunity={opportunity} />
        <SSLInfo align='flex-end' />
      </PGridItem>
    </PGrid>
  )
}

const OneThirdCashVehicleSelection: React.FC<Omit<CashVehicleSelectionProps, 'viewMode'>> = () => {
  const { enableNewAllInPricing } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const { modelYear, modelName } = opportunity.vehicle

  return (
    <PGrid className='card-content-wrapper' direction={{ base: 'column', s: 'column' }}>
      <PGridItem size={{ base: 12 }}>
        <OpportunityVehicleSelectionImage viewMode='one-third-tile' opportunity={opportunity} />
      </PGridItem>
      <PGridItem size={{ base: 12, m: 12 }}>
        <PHeadline variant='headline-4' className='big-space-top small-space-bottom' tag='h3'>
          {modelYear} {modelName}
        </PHeadline>
        <PHeadline variant='headline-4' className='small-space-bottom' tag='h3'>
          <FormattedMessage id='vehicleSelection.cash.yourEstimate' />
        </PHeadline>
        <div className='small-space-right'>
          <PGrid>
            <PGridItem size={12}>
              <PDivider className='medium-space-bottom' />
            </PGridItem>

            <PGridItem size={6}>
              <PHeadline variant='headline-5' tag='h4'>
                <FormattedMessage id={getPriceLabel(opportunity)} />
              </PHeadline>
            </PGridItem>
            <PGridItem size={6}>
              <PHeadline variant='headline-5' align='right' tag='h4'>
                <FormattedNumber
                  value={opportunity.product?.price?.value ?? 0}
                  style='currency'
                  currency={opportunity.product?.price?.currencyCode}
                  currencyDisplay='narrowSymbol'
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
                {enableNewAllInPricing ? (
                  <NewAllInPricing
                    analyticEvents={{
                      onOpen: () =>
                        trackClickEvent(AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART)),
                      onClose: () =>
                        trackClickEvent(AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART))
                    }}
                  />
                ) : (
                  <AllInPricing
                    requestBodyParams={{
                      vin: opportunity.vehicle.vin,
                      listingId: opportunity.vehicle.payload?.listingId,
                      partnerNumber: opportunity.vehicle.payload?.dealerId,
                      price: opportunity.product.price.value!
                    }}
                    locale={config().locale}
                    marketplaceKey={config().market}
                    env={config().environment}
                    conditionType={opportunity.vehicle.conditionType}
                    hideLabel
                    analyticEvents={{
                      onOpen: () =>
                        trackClickEvent(AnalyticsEventTemplates.ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART)),
                      onClose: () =>
                        trackClickEvent(AnalyticsEventTemplates.CLOSE_ALL_IN_PRICING_CHECKOUT(AnalyticsPage.CART))
                    }}
                  />
                )}
              </PHeadline>
            </PGridItem>
            <PGridItem size={12}>
              <MsrpPricingSection
                opportunity={opportunity}
                analyticsPage={AnalyticsPage.EXPLORE_PAYMENTS}
                alignment='end'
              />
            </PGridItem>
            <PGridItem size={12} className='medium-space-top'>
              <CashVehicleSelectionLegal />
            </PGridItem>
          </PGrid>
        </div>
      </PGridItem>
    </PGrid>
  )
}

export const CashVehicleSelection: React.FC<CashVehicleSelectionProps> = (props) => {
  const { viewMode } = props
  const isFullWidth = viewMode === 'full-tile'

  return isFullWidth ? <FullWidthCashVehicleSelection {...props} /> : <OneThirdCashVehicleSelection {...props} />
}
