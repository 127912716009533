import { PGrid } from '@porsche-design-system/components-react'
import { OpportunityView } from '@pdiatl/common-external-models'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { PresenterContext } from '../../../entry/presenterProvider'
import { PROFILE_STACK_NAME } from '../../../profile/profileStackElement'
import { AccordionStepData, ClearStepData, isStepDataShown, IStepData, StepDataEditButton, StepDataRow } from './common'

export const ProfileStepData: React.FC<IStepData> = observer(({ closeModalFn }) => {
  const intl = useIntl()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const stackManager = presenter.stackManager
  const profile = opportunity.customerProfile
  const hasProfile = profile && Object.keys(profile).length > 0
  const hasAddress = (opportunity.customerProfile?.addresses ?? []).length > 0
  const heading = intl.formatMessage({ id: 'profile.title' })

  if (!isStepDataShown(PROFILE_STACK_NAME, stackManager) || !hasProfile || !hasAddress) {
    return <ClearStepData heading={heading} />
  }

  const { name, email, address, phone } = getProfileDetails(opportunity)

  return (
    <AccordionStepData heading={heading}>
      <PGrid className='fs-mask'>
        <StepDataRow label='Name'>{name}</StepDataRow>
        <StepDataRow label='Email'>{email}</StepDataRow>
        <StepDataRow label='Phone'>{phone}</StepDataRow>
        <StepDataRow label='Address'>{address}</StepDataRow>
        <StepDataRow>
          <StepDataEditButton
            editStack={PROFILE_STACK_NAME}
            editTitle='Edit Personal Details'
            closeModalFn={closeModalFn}
          />
        </StepDataRow>
      </PGrid>
    </AccordionStepData>
  )
})

export class ProfileDetails {
  constructor(public name: string, public email: string, public address: string, public phone: string) {}

  toString(): string {
    return [this.name, this.email, this.phone, this.address].filter(Boolean).join('\n')
  }
}
export function getProfileDetails(opportunity: OpportunityView): ProfileDetails {
  const profile = opportunity.customerProfile!
  const address = (opportunity.customerProfile?.addresses ?? [])[0]
  if (!address) {
    return new ProfileDetails('', '', '', '')
  }

  const salutation = profile.salutation ?? ''
  const addressStreet = `${address.houseNumber ?? ''} ${address.street1 ?? ''}`
  const addressCity = [address.city, address.postalCodeRegion, address.postalCode].filter(Boolean).join(', ')

  return new ProfileDetails(
    `${salutation} ${profile.firstName!} ${profile.lastName!}`,
    profile.emailAddress!,
    `${addressStreet},\n${addressCity}`,
    profile.phoneNumber!
  )
}
