import React, { useContext } from 'react'
import { PHeadline, PText } from '@porsche-design-system/components-react'
import { observer } from 'mobx-react-lite'
import { FormattedMessage } from 'react-intl'
import { PresenterContext } from '../../../entry/presenterProvider'
import { useFlags } from '../../../../common/flags'
import { useBreakpoint } from '@slatldisal/checkout-shared-components'

export const Checkout2OrderBox: React.FC = observer(() => {
  const { enablePcnaPaymentStep } = useFlags()
  const presenter = useContext(PresenterContext)
  const opportunity = presenter.opportunityStore.opportunityCache
  const depositJourney = presenter.isDepositJourney(enablePcnaPaymentStep)
  const dealer = opportunity.owner!.displayName
  const referenceNo = opportunity.orderInformation?.orderId
  const { smallerThan: isMobile } = useBreakpoint('s')

  return (
    <div className='confirmation-order-number-box'>
      <div className='green-container'>
        <PHeadline align='center' tag='h1' variant='headline-3' className='small-space-bottom'>
          <FormattedMessage id='confirmation.orderBox.congratulations' />
        </PHeadline>
        <PText align='center' className='small-space-bottom' size='medium'>
          <FormattedMessage id='confirmation.orderBox.yourJourney' /> <b>{dealer}</b>
        </PText>
        <PText align='center' size={isMobile ? 'small' : 'medium'} weight='semibold'>
          {depositJourney && (
            <span style={{ whiteSpace: 'nowrap' }}>
              {referenceNo ? (
                <FormattedMessage id='confirmation.orderBox.referenceNo' values={{ referenceNo }} />
              ) : (
                <FormattedMessage id='confirmation.orderBox.referenceNoPending' />
              )}
            </span>
          )}
        </PText>
      </div>
    </div>
  )
})
